import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translations from "./translations"
import App from './App';
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import { locale, loadMessages } from "devextreme/localization";
import {changeWebpageLanguage} from "./utils/languageManager"
i18n
    .use(initReactI18next)
    .init({
        resources:
           translations,
        lng: navigator.language, // if you're using a language detector, do not define the lng option
        fallbackLng: "en"
    });

loadMessages(deMessages);
loadMessages(enMessages);
locale(navigator.language);

// const localStorageUser = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : undefined;
// if(localStorageUser ){
    // changeWebpageLanguage(localStorageUser.language)
    // refreshSession(localStorageUser)
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
 // document.querySelector(id="root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
