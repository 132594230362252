import React, { useState } from "react";
import Aufgabenliste from "../Aufgabenliste/Aufgabenliste";
import AufgabenGant  from "../AufgabenGant/AufgabenGant";
import DropDownButton from 'devextreme-react/drop-down-button';
import { useHistory } from "react-router-dom";

export default function () {
    const [Ansichten,SetAnsichten] = useState("Liste");// useState(["Liste", "Kanban"]);
    const [SelectedView,SetSelectedView] = useState("Liste");
    const history = useHistory();

    function onViewSelected(e){
     console.log('AnsichtenContaineronViewSelected')
     SetSelectedView(e.item);
     if (e.item === "Kanban"){
        history.push("/Aufgabenliste/AufgabenKanban");
     }else{
        history.push("/Aufgabenliste");
     }
    };
    console.log('AnsichtenContainer',Ansichten)
    return (
        <React.Fragment>
           
        {SelectedView == "Liste" ? 
            <Aufgabenliste 
                SelectedView={SelectedView} 
                Ansichten={["Liste", "Kanban"]}
                onViewSelected={onViewSelected} 
                /> 
            :
            <AufgabenGant 
                SelectedView={SelectedView} 
                Ansichten={["Liste", "Kanban"]}
                onViewSelected={onViewSelected} 
            /> }
        </React.Fragment>
    );
}