import React, {useCallback, useState, useEffect} from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import notify from 'devextreme/ui/notify';
import Api from '../../utils/api';
import favorites from './favorites.svg'
import { Modal, Table, CloseButton, Divider } from '@mantine/core';
import { useHistory } from 'react-router-dom';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { useScreenSize } from '../../utils/media-query';
import i18n from 'i18next';

const formatDate = new Intl.DateTimeFormat('de-DE').format;

const ProjektToolbar = ({
    setSearchText, 
    filteredData,
    setFilteredData, 
    dataArray, 
    searchText,
    filterArray,
    selectedProjects,
    ownLists,
    selectedList,
    setSelectedList,
    setOpenEditModal,
    setOpenDeleteModal,
    setSortType,
    setSelectedFilter,
    dataGridRef,
    ausgewählteSpalten,
    setAusgewählteSpalten,
}) => {

    const [favoritenModalOpen, setFavoritenModalOpen] = useState(false);
    const [selectedFavoritesModalOpen, setSelectedFavoritesModalOpen] = useState(false);
    const [showSelectedProject, setShowSelectedProject] = useState(false);
    const history = useHistory();
    const { isXSmall } = useScreenSize();
    const t = i18n.getFixedT(null, 'taskList');

    // Funktion zum Sortieren des Arrays basierend auf dem Sortiertyp
    const sortArray = (type) => {
        const types = {
        "Alphabetisch aufsteigend": 'subject',
        "Alphabetisch absteigend": 'subject',
        "Startdatum aufsteigend": 'start',
        "Startdatum absteigend": 'start',
        "Enddatum aufsteigend": 'end',
        "Enddatum absteigend": 'end',
        };
        const sortProperty = types[type];
        const sorted = [...filteredData].sort((a, b) => {
        if (type.includes("absteigend")) {
            return b[sortProperty].localeCompare(a[sortProperty]);
        } else {
            return a[sortProperty].localeCompare(b[sortProperty]);
        }
        });
        setFilteredData(sorted);
    };

    // Funktion zum Aktualisieren der Projekte basierend auf der ausgewählten Liste
    const updateProjectsByList = useCallback((list) => {
        if (list) {
        // Konvertieren Sie die JSON-Zeichenfolge in ein Array von Projekten
        const projects = JSON.parse(list.option);
    
        // Filtern Sie das dataArray, um nur Projekte zu behalten, die in der ausgewählten Liste enthalten sind
        const filteredProjects = dataArray.filter(obj => projects.some(proj => proj.id === obj.id));
    
        // Aktualisieren Sie das gefilterte Datenarray
        setFilteredData(filteredProjects);
        } else {
        // Wenn keine Liste ausgewählt ist, zeige alle Projekte an
        setFilteredData(dataArray);
        }
    }, [dataArray]);

    // Funktion zum Behandeln der Auswahl einer Liste aus dem Dropdown-Menü
    const handleListSelection = (list) => {
        console.log("Liste ausgewählt: ", list);
        setSelectedList(list);
        updateProjectsByList(list);
    };

    const allRoles = dataArray.reduce((acc, project) => {
      project.members.forEach(person => {
        if (!acc.includes(person.rolle.name)) {
          acc.push(person.rolle.name);
        }
      });
      return acc;
    }, []);

    let dropdownDatenquellefürSpaltenauswahl = [
      {
        label: t('subject'),
      },
      {
        label: t('start'),
      },
      {
        label: t('end'),
      },
      {
        label: t('company'),
      },
      ...allRoles.map(role => ({ label: role })),
    ];

    const resetbuttonOptions = {
        icon: 'revert',
        stylingMode: 'contained',
        onClick: () => {
          setSearchText('');
          setFilteredData(dataArray);
          setSelectedFilter(null);
          if (dataGridRef.current) {
            dataGridRef.current.instance.clearSelection();
          }
        }
    };

    const textBoxOptions = {
        placeholder: 'Suchen...',
        width: 180,
        value: searchText,
        onValueChanged: ({ value }) => {
          setSearchText(value);
          if (value) {
            filterArray(value); // Filtern Sie das Array, wenn der Suchtext gesetzt ist
          } else {
            setFilteredData(dataArray); // Setzen Sie filteredData auf das vollständige Array, wenn der Suchtext geleert wird
          }
        },
        buttons: [{
          name: 'search',
          location: 'after',
          options: {
            icon: 'search',
            stylingMode: 'text',
            onClick: () => filterArray(searchText) // Startet die Suche, wenn der Button gedrückt wird
          }
        }],
    };

    let choosedColumns = [];
    // Option für Spaltenauswahl
    const filterColumnOptions = {
      items: dropdownDatenquellefürSpaltenauswahl,
      value: ausgewählteSpalten,
      valueExpr: 'label',
      displayExpr: 'label',
      placeholder: 'Spalten auswählen',
      width: 180,
      showSelectionControls: true,
      maxDisplayedTags: 2,
      width: 200,
      dropDownOptions: {
        height: 400,
      },
      onValueChanged: function (e) {  
        choosedColumns = e.value;
        console.log("Ausgewählte Spalten: ", choosedColumns);
      },
      buttons: [{
        name: 'search',
        location: 'after',
        options: {
          icon: 'arrowright',
          stylingMode: 'text',
          onClick: function (e) {
            setAusgewählteSpalten(choosedColumns);
            console.log("Spaltenauswahl bestätigt: ", choosedColumns);
          }
        }
      }],
    };

    const addNewProjectListOptions = {
        
        icon: favorites,
        stylingMode: 'contained',
        onClick: () => {
          console.log("Neue Liste hinzufügen");
          console.log("Zeige Ausgewählte Projekte: ", selectedProjects);  //Ausgewählte Projekte anzeigen
          setFavoritenModalOpen(true);
        }
    };

    const editOwnLists = {
      text: 'Favoritenliste erstellen',
        icon: 'edit',
        stylingMode: 'contained',
        onClick: async () => {
          let listName = window.prompt("Bitte geben Sie einen Namen für die Liste ein");
          if (listName) {
              console.log("Neue Liste hinzufügen: ", listName);
              console.log("Zeige Ausgewählte Projekte: ", selectedProjects);  //Ausgewählte Projekte anzeigen

            // Abrufen der personID
            let personID;
            try {
              const response = await Api.getMe();
              personID = response.data.id;
              console.log("MyID", personID);
            } catch (error) {
              console.error("Fehler beim Abrufen der personID: ", error);
              notify("Fehler beim Abrufen der personID", "error", 3000);
              return;
            }
  
              // Erstellen Sie das zu sendende Objekt
              let data = {
                  personID: personID,
                  name: `Projektfav_${encodeURIComponent(listName)}`,
                  option: JSON.stringify(selectedProjects),  // Umwandlung des Arrays in einen String
              };
  
              // Senden Sie die PUT-Anforderung
              try {
                Api.OptionClient.createOption(data).then(response => {
                  notify("Liste erfolgreich erstellt", "success", 3000);
                });
              } catch (error) {
                  console.error("Fehler beim Speichern der Liste: ", error);
                  notify("Fehler beim Speichern der Liste", "error", 3000);
              }
          }
      }
    };

    const dropDownOptions = {
        items: [
          "Alphabetisch aufsteigend",
          "Alphabetisch absteigend",
          "Startdatum aufsteigend",
          "Startdatum absteigend",
          "Enddatum aufsteigend",
          "Enddatum absteigend",
        ],
        width: 180,
        text: "Sortieren nach...",
        onItemClick: ({ itemData }) => {
          setSortType(itemData);
          sortArray(itemData);
        }
    };

    function handleOnClick(item) {
      const optionArray = JSON.parse(item.option);
      console.log("Klicken auf: ", optionArray);
      setSelectedFavoritesModalOpen(true);
      setShowSelectedProject(optionArray);

    }

    useEffect(() => {
      console.log("Modal 2 Open: ", selectedFavoritesModalOpen);
    }, [selectedFavoritesModalOpen]);

    return (
      <>
        <Toolbar>

        <Item
          location={'before'}
          widget="dxButton"
          options={resetbuttonOptions}
        />

        <Item  
          location={'before'}
          widget="dxTextBox" 
          options={textBoxOptions}
        />

        {!isXSmall && (
          <Item
            location={'before'}
            widget="dxTagBox"
            options={filterColumnOptions}
          />
        )}

        <Item
          location={'before'}
          widget="dxButton"
          options={addNewProjectListOptions}
        />

        <Item
          location={'before'}
          widget="dxButton"
          options={editOwnLists}
        />
  
        <Item
          location={'after'}
          widget="dxDropDownButton"
          options={dropDownOptions}
        />

      </Toolbar>

      <Modal //Favoritenansicht Modal
        opened={favoritenModalOpen}
        centered
        onClose={() => setFavoritenModalOpen(false)}
        zIndex="500"
        withCloseButton={false}
      >

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6>Projektliste Löschen</h6>
          <CloseButton size="lg" onClick={() => setFavoritenModalOpen(false)} />
        </div>

        <Divider style={{paddingBottom: '15px'}}/>

        <Table>
          <thead>
            <tr>
              <th>Listenname</th>
              <th>Enthaltene Listen</th>
              <th>{/* Buttons */}</th>
            </tr>
          </thead>
          <tbody>
            {ownLists.map((list, index) => (
              <tr key={index}>
                <td>{list.name}</td>
                <td style={{cursor: 'pointer'}} onClick={() => handleOnClick(list)}>
                  {JSON.parse(list.option).map((item) => item.subject).join(", ")}
                </td>
                <td>
                  <div className="DeleteButtons">
                    <MdModeEdit 
                      size={25} 
                      color="#4CBB17" 
                      style={{marginRight: "0.5em", cursor: 'pointer'}}
                      onClick={() => {
                        console.log(list.name, "wurde zum bearbeiten angeklickt")
                        setOpenEditModal(true); //Bearbeiten Modal öffnen
                        setFavoritenModalOpen(false); //Favoriten Modal schließen
                      }}
                    />
                    <MdDelete
                      size={25}
                      color="red"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        console.log(list.name, "wurde zum löschen angeklickt")
                        try {
                          Api.OptionClient.deleteOption(list.name);
                          notify("Liste erfolgreich gelöscht", "success", 3000);
                        } catch (error) {
                          console.error("Fehler beim Löschen der Liste: ", error);
                          notify("Fehler beim Löschen der Liste", "error", 3000);
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>

      <Modal //Ansicht der ausgewählten Favoritenliste
        opened={selectedFavoritesModalOpen}
        onClose={() => setSelectedFavoritesModalOpen(false)}
        centered 
        zIndex="9999"
        size="lg"
      >
        <Table>
          <thead>
            <tr>
              <th>Projektname</th>
              <th>Startdatum</th>
              <th>Enddatum</th>
              <th>Mitglieder</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(showSelectedProject) ? showSelectedProject.map((item, index) => (
              <tr key={index} onClick={() => history.push(`/projekt/${item.id}`)} style={{cursor: 'pointer'}}>
                <td>{item.subject}</td>
                <td>{formatDate(new Date(item.start))}</td>
                <td>{formatDate(new Date(item.end))}</td>
                <td>
                  {Array.isArray(item.members) ? item.members.map((member, index) => 
                    member.member.first_Name + " " + member.member.last_Name
                  ).join(", ") : null}
                </td>
              </tr>
            )) : null}
          </tbody>
        </Table>
      </Modal>
    </>
    );
}
export default ProjektToolbar;