import React, { useState, useEffect, useCallback, useRef } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useScreenSize } from '../../utils/media-query';
import Tabs from 'devextreme-react/tabs';
import SubProjectsKanban from './../UnterprojekteTabelle/subProjectsKanban';
import SubProjectsGantt from './../UnterprojekteTabelle/subProjectsGantt';
import AufgabenMobile from './Toolbar-Komponenten/AufgabenListe';
import AufgabenKanban from './Toolbar-Komponenten/AufgabenKanban';

const listsData = ['List', 'Kanban', 'Gantt'];

function AufgabenlisteToolbar({p, Aufgaben, setAufgaben, subProjects}) {

    const [listView, kanbanView, ganttView] = listsData;
    const [view, setView] = useState(listView);
    const [index, setIndex] = useState(0);
    const { isXSmall } = useScreenSize();
    const [loading, setLoading] = useState(true);

    const getTabsWidth = useCallback(() => {
        return isXSmall ? 220 : 'auto';
    }, []);

    const onTabClick = useCallback((e) => {
        setView(e.itemData || '');
        setIndex(listsData.findIndex((d) => d === e.itemData));
    }, []);

    return (
        <div className='view-wrapper view-wrapper-task-list list-page'>
            <Toolbar className='toolbar-common theme-dependent'>
                <Item
                location='before'
                widget='dxTabs'
                >
                <Tabs
                    dataSource={listsData}
                    width={getTabsWidth()}
                    selectedIndex={index}
                    scrollByContent
                    showNavButtons={false}
                    onItemClick={onTabClick}
                />
                </Item>
            </Toolbar>

            {view === 'List' && <AufgabenMobile p={p} Aufgaben={Aufgaben} />}
            {view === 'Kanban' && <AufgabenKanban Aufgaben={Aufgaben} setAufgaben={setAufgaben} />}
            {view === 'Gantt' && <SubProjectsGantt Aufgaben={Aufgaben} />}
        </div>
    );
}
 
export default AufgabenlisteToolbar;