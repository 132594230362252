import React, { useState, useEffect } from 'react';
import Api from "../../utils/api";
import { IoMdSearch } from "react-icons/io";
import './AddMitglied.css';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Group, Button, Divider, Select } from '@mantine/core';
import KontaktlisteDataGrid from '../Kontakt-Grid/KontaktlisteDataGrid';
import EventBus from './EventBus';
import { Popover } from 'devextreme-react';
import { useScreenSize } from '../../utils/media-query';
import { Autocomplete } from 'devextreme-react';
import { CgProfile } from "react-icons/cg";

const AddMitglied = ({p, memberDS, buttonVisible, me}) => {

  const [dataArray, setDataArray] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    id: null,
    email: null,
    name: null,
    fullName: null,
    first_Name: null,
    last_Name: null
  });
  const [dataSource, setDataSource] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [kontaktGridModalOpened, setKontaktGridModalOpened] = useState(false);
  const [selectedName, setSelectedName] = useState({firstName: '', lastName: ''});
  const screenSize = useScreenSize();

  // Funktion zum Öffnen des Modals
  const openKontaktGridModal = () => setKontaktGridModalOpened(true);

  // Funktion zum Schließen des Modals
  const closeKontaktGridModal = () => setKontaktGridModalOpened(false);

  useEffect(() => {
    Api.people.getMyNetwork().then(response => {
      const updatedContactList = response.data.map(contact => ({
        ...contact,
        fullName: `${contact.first_Name} ${contact.last_Name}`
      }));
        setDataSource(updatedContactList);
    }).catch(error => {
        console.error(error);
    });
  }, []);

  // Funktion zum Laden der Daten
  useEffect(() => {
    Api.roles.getRoles().then(response => {
    const data = response.data;

    if (data.length === 0) {
      setNoDataMessage(p('noData'));
    } else {
      setDataArray(data);
      setRoles(data); // Rollen werden in einem State-Variablen gespeichert
    }
    }).catch(error => {
    console.error(error);
    });
    }, []);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSave = () => {
    const fullName = formData.fullName.split(' ');
    const roleName = formData.name.split('-'); // Rolle wird in ID und Name aufgeteilt
    const newMember = {
      member: {
          id: formData.id,
          first_Name: formData.first_Name ? formData.first_Name : fullName[0],
          last_Name: formData.last_Name ? formData.last_Name : fullName[1],
          eMail: formData.email,
      },
      rolle: {
          id: roleName[0],
          name: roleName[1],
      },
    };
    console.log('Kontakt gespeichert: ', newMember); // Konsolenausgabe für den gespeicherten Kontakt
    memberDS.insert(newMember);
  };

  useEffect(() => {
    const handleSelectedNameChanged = (newSelectedName) => {
      setKontaktGridModalOpened(false)
      console.log('Geklickter Kontakt:', newSelectedName); // Konsolenausgabe für den geklickten Kontakt

      // Überprüfen, ob newSelectedName gefüllt ist
      if (newSelectedName) {
        // Aktualisieren Sie das formData Objekt mit den Werten aus newSelectedName
        setFormData({
          ...formData,
          id: newSelectedName.id,
          first_Name: newSelectedName.first_Name,
          last_Name: newSelectedName.last_Name,
          fullName: `${newSelectedName.first_Name} ${newSelectedName.last_Name}` === 'null null' ? newSelectedName.eMail : `${newSelectedName.first_Name} ${newSelectedName.last_Name}`,
          email: newSelectedName.eMail,
        });
      }
    };

    EventBus.on('selectedNameChanged', handleSelectedNameChanged); // Event abonnieren mit on

    return () => {
      EventBus.off('selectedNameChanged', handleSelectedNameChanged); // Event abbestellen mit off
    };
  }, []);

  // Funktion zum Entfernen von newSelectedName aus formData wenn das AddMitglied Modal geschlossen wird
  const handleClose = () => {
    setFormData(' ');
    close();
  };

  const checkConditions = () => {
    if ( formData.fullName === null || formData.fullName === undefined) {
      return true;
    } else if (formData.name === null || formData.name === undefined) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {buttonVisible && (
      <Group position="right">
        <Button className='myButton' onClick={open}>{p('addMember')}</Button>
      </Group>
      )}
      
      <Modal opened={opened} onClose={close} title= {<h5 className="modal-title">{p('addMember')}</h5>} centered size='lg' withCloseButton={false} closeOnClickOutside={false}>
        <Divider margins="xs" />
        <form className='fromular' style={{ margin: '0 1.5em', marginTop: "3em" }}>
          <label className="label" style={{ marginTop: "1em" }}>{p('Name')}</label>
            <div className="input-with-button">
              <div className="autocomplete">
                <Autocomplete
                  dataSource={dataSource}
                  valueExpr="fullName"
                  value={formData.fullName}
                  onValueChanged={(e) => {
                    setFormData({...formData, fullName: e.value});
                  }}
                  onSelectionChanged={(e) => {
                    if (e.selectedItem) {
                      setFormData({
                        ...formData,
                        id: e.selectedItem.id,
                        fullName: e.selectedItem.fullName,
                        email: e.selectedItem.eMail,
                        first_Name: e.selectedItem.first_Name,
                        last_Name: e.selectedItem.last_Name,
                      })
                    } else {
                      setFormData({
                        ...formData,
                        id: null,
                        fullName: null,
                        email: null,
                        first_Name: null,
                        last_Name: null
                      })
                    }
                  }}
                />
              </div>
                <Button id='searchButton' variant="transparent" onClick={openKontaktGridModal}>
                  <IoMdSearch className="search-icon" size={30} color='#4CBB17'/>
                </Button>
                <Button id='meButton' variant='transparent' onClick={() => {
                  console.log('Me Button clicked', me.first_Name + " " + me.last_Name);
                  setFormData({
                    ...formData,
                    id: me.id,
                    fullName: me.first_Name + " " + me.last_Name,
                    email: me.eMail,
                    first_Name: me.first_Name,
                    last_Name: me.last_Name,
                    });
                    }}
                    style={{marginLeft: '-10px'}}
                  >
                    <CgProfile className="search-icon" size={20} color='gray'/>
                </Button>
                <Modal opened={kontaktGridModalOpened} onClose={closeKontaktGridModal} centered onScroll={false} size='95%'>
                    <KontaktlisteDataGrid />
                </Modal>
            </div>
              {screenSize.isLarge && (
              <div className="popover">
                <Popover target='#searchButton' showEvent='mouseenter' hideEvent='mouseleave' position='top' width='auto' height='auto'>
                {p('popoverText')}
                </Popover>
              </div>
              )}
          <label className="label" style={{ marginTop: "1em" }}>{p('role')}</label>
            <Select
              value={formData.name}
              onChange={value => handleInputChange({ target: { value, name: 'name' } })}
              data={Roles.map(role => ({ value: `${role.id}-${role.name}`, label: role.name }))}
              placeholder={p('selectRole')}
              dropdownPosition="bottom"
              maxDropdownHeight={100}
              styles={(theme) => ({
                input: {
                  '&:focus': {
                    borderColor: '#39b201',
                  },
                },
              })}
            />
            <div className="cancelOrSaveButtons">
            <Button onClick={handleClose} variant='filled' color='red' style={{marginRight: '10px'}}>{p('cancel')}</Button>
            <Button 
              onClick={handleSave}
              disabled={checkConditions()}
              styles={(theme) => ({
                root: {
                  backgroundColor: '#39b201',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#39b201',
                    color: 'white',
                  },
                }})}
              >
              {p('save')}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddMitglied;