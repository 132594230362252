import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {useHistory} from 'react-router-dom'
import './mail.scss';
// import Toolbar, {Item as TBItem} from "devextreme-react/toolbar";
import i18n from "i18next";
import { HtmlEditor, Toolbar, Item as EditorItem, MediaResizing } from 'devextreme-react/html-editor';

import TagBox from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Api from '../../utils/api';
import { useLocation } from 'react-router-dom';
import { notifySuccess } from "../../utils/notifyManager";
import { notifyError } from '../../utils/notifyManager';
import { notifyWarning } from '../../utils/notifyManager';
import { Modal, CloseButton, Divider, MultiSelect } from '@mantine/core';
import { startsWith } from 'lodash';

  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']; 
  const headerValues = [false, 1, 2, 3, 4, 5];
  const t = i18n.getFixedT(null, 'mails');
  function MailFrom () {
      return "Test";
  }

export default (props) => {
  //Mapping  
    const AnserMail = props.AnserMail;
    const selectedMail = props.selectedMail;
    const Forward = props.Forward;
    const Name = props.Name;
    const mailForMessage = props.mailForMessage;
    const location = useLocation();
    const pfadSegment = location.pathname.split("/");
    const mailSettingId = pfadSegment[2]

    //Zustände
    const [TagBoxToValues, setTagBoxToValues] = useState([]);
    const [TagBoxCCValues, setTagBoxCCValues] = useState();
    const [TextBoxSubjectValue, setTextBoxSubjectValue] = useState();
    const [MailMessage, setMailMessage] = useState();
    const [emailGesendet, setEmailGesendet] = useState(false);
    const [AnModal, setAnModal] = useState(false);
    const [Kontaktliste, setKontaktliste] = useState([]);
    const[ausgewählteKontakte, setAusgewählteKontakte] = useState([]);
    const [HTMLEditorHeight, setHTMLEditorHeight] = useState(null);

    useEffect(() => {
      console.log("selectedMail", selectedMail);
      console.log("ausgewählteKontakte", ausgewählteKontakte);
      console.log("TagBoxToValues", TagBoxToValues);
    }, []);

    useEffect(() => {
      if (selectedMail && selectedMail.from) {
        const emailAdressen = selectedMail.from.map(kontakt => ({ eMail: kontakt.eMail }));
        console.log("selectedMail.from", emailAdressen);
        setTagBoxToValues(emailAdressen);
      }
    }, [selectedMail]);

    useEffect(() => {
      console.log("TagBoxToValues", TagBoxToValues);
    }, [TagBoxToValues]);

    useEffect(() => {
      if (mailForMessage) {
        const emailObjekt = { eMail: mailForMessage };
        console.log("MailForMessage", emailObjekt);
        setTagBoxToValues([emailObjekt]);
      }
    }, [mailForMessage]);

       useEffect(() => {
      if (location && location.pathname.startsWith('/profile')) {
        setHTMLEditorHeight('30vh');
      } else {
        setHTMLEditorHeight('725px');
      }
    }, [location]);

    // Funktion zum Laden der Daten
    useEffect(() => {
      Api.people.getMyNetwork().then(response => {
      const data = response.data;
      if (data.length === 0) {
        console.log("Keine Daten vorhanden");
      } else {
        setKontaktliste(data);
      }
      }).catch(error => {
      console.error(error);
      });
    }, []);

    const sendEmail = async () => {
      console.log("Senden gedrückt");
      const emailData = {
        to: TagBoxToValues ? 
          TagBoxToValues.map(email => ({ EMail: email })) 
          : selectedMail && selectedMail.from ? 
          selectedMail.from.map(obj => ({ EMail: obj.eMail })) 
          : [],
        cc: TagBoxCCValues ? 
          TagBoxCCValues.map(email => ({ EMail: email })) 
          : selectedMail && selectedMail.cc ? 
          selectedMail.cc.map(obj => ({ EMail: obj.eMail })) 
          : [],
        subject: TextBoxSubjectValue || (selectedMail ? selectedMail.subject : ''),
        body: MailMessage || (selectedMail ? selectedMail.body : '')
      };
      console.log("emailData", emailData);

      if (emailData.to.length === 0) {
        notifyError(t('errorMissingRecipient'));
        return;
      }

      let sendMail = true;

      if (emailData.subject.length === 0) {
        let userConfirmed = window.confirm(t('missingSubject'));
        if (!userConfirmed) {
          sendMail = false;
        }
      }

      if (sendMail) {
        try {
          console.log("mailSettingId", mailSettingId);
          const response = await Api.mail.sendMails(mailSettingId, emailData); // Verwenden Sie die extrahierte ID
          console.log("response", response);
          setEmailGesendet(true);
          notifySuccess(t('successfullySent'));
          console.log("Mail gesendet")
        } catch (error) {
          if (error.response) {
            console.error(`HTTP error! status: ${error.response.status}`);
          } else {
            console.error('Error', error);
          }
        }
      }
    };

    const onCustomItemCreating = useCallback(
      (args) => {
        const newEmail = args.text;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(newEmail)) {
          const isItemInDataSource = Kontaktliste.some((item) => item.eMail === newEmail);
          if (!isItemInDataSource) {
            const newItem = { eMail: newEmail, first_Name: '', last_Name: '' };
            setKontaktliste([newItem, ...Kontaktliste]);
            args.customItem = newItem;
          } else {
            args.customItem = Kontaktliste.find((item) => item.eMail === newEmail);
          }
        } else {
          notifyError(`${newEmail} ist keine gültige E-Mail-Adresse`);
        }
      },
      [Kontaktliste],
    );

    return emailGesendet ? <div>{ t('noMailSelected') }</div> : (
    <React.Fragment>
    {/* {AnserMail === true ? "Antwort "+AnserMail : "Keine Antwort "+AnserMail}<br/>
    {Forward === true ? "Weiterleiten "+Forward : "Keine Weiterleitung "+Forward}<br/>
    {selectedMail != null ? "Zeile ausgewählt" : "Keine Zeile ausgewählt"}<br/> */}
    
     <div className='ButtonSend'>
        <Button
                    
                    text={t('SendButton')}
                    type="default"
                    stylingMode="contained"
                    onClick={() => sendEmail()}
                  // onClick={() => console.log("Senden", selectedMail)}
                    />
    </div>
      <div className='dx-field' >

        <Button 
          text="AN"
          type="normal"
          stylingMode="outlined"
          onClick={() => {console.log("An"); setAnModal(true);}}
        />

        <div className="dx-field-value">
          <TagBox 
            items={Kontaktliste.map(kontakt => ({ eMail: kontakt.eMail, first_Name: kontakt.first_Name, last_Name: kontakt.last_Name }))}
            displayExpr={item => item ? (item.first_Name && item.last_Name ? item.first_Name + ' ' + item.last_Name : item.eMail) : TagBoxToValues}
            valueExpr="eMail"
            value={TagBoxToValues}
            acceptCustomValue={true}
            onCustomItemCreating={onCustomItemCreating}
            onValueChanged={(e) => { 
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const validEmails = e.value.filter(value => emailRegex.test(value));
                validEmails.forEach(value => console.log("Gültige E-Mail-Adresse: ", value));
                setTagBoxToValues(validEmails);
            }}
          />
        </div>

      </div>

      <div className="dx-field">

        <Button
          text="CC"
          type="normal"
          stylingMode="outlined"
        />
            
        <div className="dx-field-value">
          <TagBox 
            items={Kontaktliste.map(kontakt => ({ eMail: kontakt.eMail, first_Name: kontakt.first_Name, last_Name: kontakt.last_Name }))}
            displayExpr={item => item ? (item.first_Name && item.last_Name ? item.first_Name + ' ' + item.last_Name : item.eMail) : ""}
            valueExpr="eMail"
            acceptCustomValue={true}
            onCustomItemCreating={onCustomItemCreating}
            onValueChanged={(e) => { 
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              const validEmails = e.value.filter(value => emailRegex.test(value));
              validEmails.forEach(value => console.log("Gültige E-Mail-Adresse: ", value));
              setTagBoxCCValues(validEmails);
          }}
          />
        </div>

      </div>

        <TextBox 
          placeholder= {t('Betreff')} 
          defaultValue={ selectedMail == null ? "" : selectedMail.subject}
          onValueChanged={(e) => {
            setTextBoxSubjectValue(e.value);
          }}
        />
          <HtmlEditor
            height={HTMLEditorHeight}
            defaultValue={selectedMail == null ? "" : selectedMail.body}
            onValueChanged={(e) => setMailMessage(e.value)}
          >
            <MediaResizing enabled={true} />
            <Toolbar>
              <EditorItem formatName="undo" />
              <EditorItem formatName="redo" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="bold" />
              <EditorItem formatName="italic" />
              <EditorItem formatName="strike" />
              <EditorItem formatName="underline" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="alignLeft" />
              <EditorItem formatName="alignCenter" />
              <EditorItem formatName="alignRight" />
              <EditorItem formatName="alignJustify" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="orderedList" />
              <EditorItem formatName="bulletList" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="header" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="color" />
              <EditorItem formatName="background" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="link" />
              <EditorItem formatName="image" />
            </Toolbar>
          </HtmlEditor>

          <Modal opened={AnModal} onClose={() => setAnModal(false)} centered closeOnClickOutside={false} withCloseButton={false} size="lg">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6>Empfänger hinzufügen</h6>
              <CloseButton size="lg" onClick={() => setAnModal(false)} />
            </div>
            <Divider style={{ marginTop: '10px' }} />
            <div style={{ marginTop: '10px', marginBottom: '200px'}}>
            <MultiSelect
              label="Kontakte wählen"
              placeholder="Kontaktliste durchsuchen"
              searchable
              clearable
              data={Kontaktliste.map(kontakt => ({
                label: `${kontakt.first_Name} ${kontakt.last_Name}`,
                value: kontakt.eMail
              }))}
              onChange={(values) => {
                setAusgewählteKontakte(values);
                console.log("eMail Array", values);
              }}
              style={{ width: '250px' }}
              styles={() => ({
                input: {
                  '&:focus': {
                     borderColor: '#39b201',
                  },
                },
              })}
            />
            </div>
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                type='danger' 
                stylingMode='contained' 
                onClick={() => setAnModal(false)}
              >
                Abbrechen
              </Button>
              <Button 
                  className='myButton' 
                  style={{marginLeft: '5px'}} 
                  onClick={() => { 
                      console.log("Auswählen gedrückt");
                      setAnModal(false);
                      setTagBoxToValues(prevValues => [...(prevValues || []), ...ausgewählteKontakte]);
                  }}
              >
                  Auswählen
              </Button>
            </div>
          </Modal>

    </React.Fragment>
  );
}