import React, {useEffect, useRef, useCallback, useMemo, useState} from 'react';
import TreeView from 'devextreme-react/tree-view';
import {navigation} from '../../app-navigation';
import {useScreenSize} from '../../utils/media-query';
import './side-navigation-menu.scss';
import i18n from 'i18next'
import Api from "../../utils/api"
import * as events from 'devextreme/events';
import { useLocation } from 'react-router-dom'
import EventBus from '../Mitgliederliste-Mobil/EventBus';
import { random } from 'lodash';

export default function (props) {
    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        const handleUpdateFilter = () => {
            console.log('EventBus: updateFilter');
            setUpdateKey(prevKey => prevKey + 1);
        };

        EventBus.on('updateFilter', handleUpdateFilter);
        EventBus.on('filterCreated', handleUpdateFilter);

        // Cleanup-Funktion, um den Event-Listener zu entfernen, wenn die Komponente unmountet
        return () => {
            EventBus.off('updateFilter', handleUpdateFilter);
        };
    }, []);

    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady
    } = props;

    function convertLanguage(item) {
        item.text = i18n.t('titles:' + item.text);
        if (item.items) {
            item.items.forEach(it => {
                    convertLanguage(it)
                }
            )
        }
        return item;
    }

    function randomId() {
        return Math.random().toString(36).substr(2, 9);
    }

    const {isLarge} = useScreenSize();
    // const items = useMemo(
    //     () => navigation.map((item) => {
    //             item = convertLanguage(item);
    //             return {...item, expanded: isLarge}
    //         }
    //     ),
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     []
    // );

    // const {navigationData: {currentPath}} = useNavigation();
    const location = useLocation();
    const currentPath = "";
    // const currentPath = 
    // (()=>{
    //     console.log(location.pathname);
    //     return location.pathname;
    //     // "/Aufgabenliste"
    //     }
    // );

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', e => {
            openMenu(e);
        });
    }, [openMenu]);

    const Filterliste = [
        {
            id: randomId(),
            filterName: 'Meine offenen Vorgänge',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Von mir erstellt',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Alle Vorgänge',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Offene Vorgänge',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Vor kurzem angezeigt',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Vor kurzem erstellt',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Vor kurzem geändert',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Kürzlich erledigt',
            filterBedingung: null,
            spaltenwahl: null
        },
        {
            id: randomId(),
            filterName: 'Kürzlich aktualisiert',
            filterBedingung: null,
            spaltenwahl: null
        }
    ];

    useEffect(() => {

        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }
        console.log(treeView);
       const currentPath = location.pathname;
       treeView.selectItem(null);
        if (currentPath !== undefined) {
            
            treeView.selectItem(currentPath);
            treeView.expandItem(currentPath);
        }
        

        
    },);


    // useEffect( () => {
    //     const treeView = treeViewRef.current && treeViewRef.current.instance;
    //     if (!treeView) {
    //         return;
    //     }
    //     if (compactMode) {
    //         treeView.collapseAll();
    //     }
    // }, [currentPath,compactMode])


    function convertFolder(folderArray, parentID, settingID) {
        let items = []
        console.log('FolderArray', folderArray)
        for(const folderItem of folderArray){
            let unreadMessageCount = '';
            // Überprüfen, ob das Attribut "unread_Message_Count" 1 oder mehr ist
            if(folderItem.folder.unread_Message_Count >= 1){
                console.log('Ungelesene Nachricht in diesem Ordner:', folderItem.folder.name);
                unreadMessageCount = ` (${folderItem.folder.unread_Message_Count})`;
            }

            const item = {
                text: `${folderItem.folder.name}${unreadMessageCount}`,
                path: `/Mail/${settingID}/${folderItem.folder.id}`,
                hasItems: !!folderItem.childFolder,
                parentId: parentID,
            };

            if(item.hasItems){
                item['items'] = convertFolder(folderItem.childFolder, item.path, settingID )
            }

            items.push(item)
        }
        return items
    }


    async function getAufgabenlisteItems() {
        let datafilter = [];

        try {
            const meRes = await Api.getMe();
            const meID = meRes.data.id;
            const res = await Api.OptionClient.getOptions();
            const data = res.data;
            datafilter = data.filter(item => item.name === `Aufgabenfav_${meID}`);
            if (datafilter.length === 0) {
                console.log('Kein Element wurde gefunden');

                // Konvertieren jedes Objekts in der Filterliste in einen JSON-String und fügen diese in ein Array ein
                const filterlisteAlsJsonStrings = Filterliste.map(obj => JSON.stringify(obj));
                
                let newOption = {
                    name: `Aufgabenfav_${meID}`,
                    option: JSON.stringify(filterlisteAlsJsonStrings), // Speichern als String des Arrays von JSON-Strings
                    id: meID
                };
                console.log('Neue Option', newOption);
                await Api.OptionClient.createOption(newOption);
                window.location.reload();
            } else {
                if (datafilter.length > 0 && datafilter[0].option) {
                    console.log("Datafilter:", datafilter);
                    const optionArray = JSON.parse(datafilter[0].option);
                    if (typeof optionArray[0] === 'object') {
                        // Alle Objekte sind bereits geparst, direkt die items setzen
                        const items = optionArray.map(obj => {
                            const { filterName } = obj;
                            return {
                                hasItems: false,
                                parentId: '/Aufgabenliste',
                                path: `/Aufgabenliste/${filterName}`,
                                text: filterName,
                            };
                        });
                        console.log('Zeige items:', items);
                        return items;
                    } else {
                        // Die vorhandene Logik beibehalten, da die Objekte als Strings vorliegen und geparst werden müssen
                        const items = optionArray.map(option => {
                            // Überprüfen, ob das Objekt bereits geparst ist
                            const obj = typeof option === 'string' ? JSON.parse(option) : option;
                            const { filterName } = obj;
                            return {
                                hasItems: false,
                                parentId: '/Aufgabenliste',
                                path: `/Aufgabenliste/${filterName}`,
                                text: filterName,
                            };
                        });
                        console.log('Zeige items:', items);
                        return items;
                    }
                } else {
                    console.log('Keine gültigen Daten für optionArray gefunden');
                    return []; 
                }
            }
        } catch (error) {
            console.error(error);
            return []; // Frühzeitige Rückkehr im Fehlerfall
        }      
    }


    function createChildren(parent) {
                if (!parent) {
            return Api.getMe()
                .then(res => res.data)
                .then(data => data.optionMailSettings)
                .then(settings => {
                    const items = JSON.parse(JSON.stringify(navigation))
                    items.map(item => {
                        convertLanguage(item)
                        if (item.path === '/Aufgabenliste') {
                            delete item.hasItems;
                            item.items = getAufgabenlisteItems();
                            console.log('Item', item)
                        }
                    });
                    console.log('Items', items)
                    for (const setting of settings) {
                        items.unshift({
                            text: setting.mailadresse,
                            path: `/Mail/${setting.id}`,
                            mailSettingId: setting.id,
                            icon: 'email',
                            hasItems: true
                        })
                    }
                    return items;
                })
                .catch(() => {
                    return JSON.parse(JSON.stringify(navigation));
                });
        }
        else if (parent.itemData.items) {
            return parent.itemData.items;
        } else if (parent.itemData.mailSettingId){
            return Api.mail.getMailFolders(parent.itemData.mailSettingId)
                .then(data => data.data)
                .then(data => {
                    let folderItems = convertFolder(data, parent.itemData.path, parent.itemData.mailSettingId )
                    return folderItems;
                });
        }
    }

    const isXSmallMedia = useScreenSize().isXSmall;

    const c = i18n.getFixedT(null, 'titles');

    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >   
            {isXSmallMedia && (
            <div className='logo-maprota' style={{ display: 'flex', alignItems: 'center', marginLeft: '28px' }}>
                <img src='logo192.png' alt='MaProTa Logo' width='35' height='35' />
                <span style={{ marginLeft: '10px', lineHeight: '80px', fontSize: '20px' }}>{c('HeaderName')}<sup>®</sup> </span>
            </div>
            )}

            {children}

            <div className={'menu-container'}>

                <TreeView
                    key={updateKey}
                    dataStructure="plain"
                    ref={treeViewRef}
                    rootValue=""
                    height={500}
                    createChildren={createChildren}
                    width={'100%'}
                    keyExpr="path"
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'dblclick'}
                    onItemClick={selectedItemChanged}
                    height={'100%'}
                />

            </div>
        </div>
    );
}
