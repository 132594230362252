// src/DataGridCards.js
import '../../../components/bulma-tabel-css/bulma.min.css';
import '../../../components/bulma-tabel-css/main.min.css';
import Api from "../../../utils/api";
import React, { useEffect, useState } from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import Modal from 'react-modal';
import '../Modal/Modal.css';
import { useAuth } from '../../../contexts/auth';
import EventBus from '../../../components/Mitgliederliste-Mobil/EventBus';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { ScrollView } from 'devextreme-react';
import { notifySuccess } from "../../../utils/notifyManager";

const DataGridCards = ({t, renderLogo, dataArray, setDataArray, filteredData, setFilteredData, ContactlistChanged, setContactlistChanged }) => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IsEditModalOpen,setIsEditModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState('');
  const { user } = useAuth();
  const [selectedName, setSelectedName] = useState(''); // Zustandsvariable definieren
  const [sortType, setSortType] = useState(''); // State für Sortierung
  const [searchText, setSearchText] = useState(''); // State für Suchtext

    // Funktion zum Laden der Daten
    useEffect(() => {
    Api.people.getMyNetwork().then(response => {
    const data = response.data;

    if (data.length === 0) {
      setNoDataMessage(t('noData'));
    } else {
      setDataArray(data);
      setFilteredData(data);
    }
    }).catch(error => {
    console.error(error);
    });
    }, [ContactlistChanged]);
  
      //Funktion zum Löschen eines Kontakts
    const handleDelete = (id, relationshipID) => {

      Api.people.deleteFromNetwork(id, relationshipID)
        .then(() => {
          // Lade die aktualisierten Daten nach erfolgreichem Löschen
          notifySuccess(t('removedFromNetwork'));
          setContactlistChanged(prevState => !prevState);
        })
        .catch(error => {
          console.error('Fehler beim Löschen:', error);
        });
    };

    //Funktion zum Editieren eines Kontakts
    const handleEdit = (id) => { 
      console.log('Kontakt wird geändert. ID:',user.id);
      const payload = {
        
          id: formData.id,
          First_Name: formData.firstName,
          Last_Name: formData.lastName,
          positionname: formData.position,
          EMail: formData.email,
        
        // relationship: {
        //   id: 2,
        // },
        // person_MeID: user.id,
      };

      Api.people.updateMyContact(formData.id, payload).then(response => {
        const updatedContact = response.data;
        setDataArray(prevDataArray => prevDataArray.map(contact => 
          contact.id === updatedContact.id ? updatedContact : contact
        ));
      setContactlistChanged(prevState => !prevState);
      }).catch(error => {
        console.error(error);
      });
      setIsEditModalOpen(false);
      console.log('Kontakt wurde geändert');
      notifySuccess(t('editContactMessage'));
    };

    /*In diesem Code wird die map Funktion verwendet, um durch jedes Kontaktobjekt in dataArray zu gehen. 
    Wenn die ID des Kontakts der ID des aktualisierten Kontakts entspricht, wird der Kontakt durch den aktualisierten Kontakt ersetzt. 
    Andernfalls bleibt der Kontakt unverändert.
    */

    //Const und Funktione für das Editieren eines Kontakts
    const [formData, setFormData] = useState({
      id : '',
      firstName: '',
      lastName: '',
      position: '',
      email: '',
    });

    const customStyles = {
      overlay: {
        zIndex: 9999, // Set a high value for zIndex
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // You can adjust the background color and opacity of the overlay
      },
      content: {
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: '0',
        padding: '0',
        position: 'absolute',
      },
    };

    function handleInputChange(event) {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    }

    function closeModal() {
      setIsEditModalOpen(false);
    }

    function handleContactClick(obj) {
      console.log('Kontakt wurde geklickt', obj);
      const newSelectedName = obj;
      setSelectedName(newSelectedName); // Zustand aktualisieren
      EventBus.emit('selectedNameChanged', newSelectedName); // Ereignis auslösen mit emit
    }

         // Funktion zum Sortieren des Arrays basierend auf dem Sortiertyp
  const sortArray = (type) => {
    const types = {
      "Alphabetisch aufsteigend": 'last_Name',
      "Alphabetisch absteigend": 'last_Name',
    };
    const sortProperty = types[type];
    const sorted = [...filteredData].sort((a, b) => {
      if (type.includes("absteigend")) {
        return b[sortProperty].localeCompare(a[sortProperty]);
      } else {
        return a[sortProperty].localeCompare(b[sortProperty]);
      }
    });
    setFilteredData(sorted);
  };

  // Funktion zum Filtern des Arrays basierend auf dem Suchtext
  const filterArray = (value) => {
    const filtered = dataArray.filter(obj => obj.fullname.toLowerCase().includes(value.toLowerCase()));
    setFilteredData(filtered); // Aktualisieren Sie das gefilterte Array für die Anzeige
  };

    const textBoxOptions = {
      placeholder: 'Suchen...',
      width: 100,
      value: searchText,
      onValueChanged: ({ value }) => {
        setSearchText(value);
        if (value) {
          filterArray(value); // Filtern Sie das Array, wenn der Suchtext gesetzt ist
        } else {
          setFilteredData(dataArray); // Setzen Sie filteredData auf das vollständige Array, wenn der Suchtext geleert wird
        }
      },
      buttons: [{
        name: 'search',
        location: 'after',
        options: {
          icon: 'search',
          stylingMode: 'text',
          onClick: () => filterArray(searchText) // Startet die Suche, wenn der Button gedrückt wird
        }
      }],
    };
  
    const dropDownOptions = {
      items: [
        "Alphabetisch aufsteigend",
        "Alphabetisch absteigend",
      ],
      width: 180,
      text: "Sortieren nach...",
      onItemClick: ({ itemData }) => {
        setSortType(itemData);
        sortArray(itemData);
      }
    };
  
    const resetbuttonOptions = {
      icon: 'revert',
      stylingMode: 'contained',
      onClick: () => {
        setSearchText('');
        setFilteredData(dataArray);
      }
    };



  return (
    <>

      <Toolbar>

        <Item
          location={'before'}
          widget="dxButton"
          options={resetbuttonOptions}
        />

        <Item  
          location={'before'}
          widget="dxTextBox" 
          options={textBoxOptions}
        />

        <Item
          location={'after'}
          widget="dxDropDownButton"
            options={dropDownOptions}
        />

      </Toolbar>

      {/* <ScrollView
        height='calc(100vh - 420px)'
        width='auto'
        direction='vertical'
      > */}
      <section className="section" style={{paddingTop: '0px'}}>
        <div className="container">
          <div className="b-table">
            <div className="table-wrapper has-mobile-cards">
              <table className="table is-fullwidth is-striped is-hoverable is-fullwidth" style={{marginTop: '-25px'}}>
              <div style={{ textAlign: 'center', marginTop: '1em', fontSize: '2em'  }}>
                {noDataMessage && <p>{noDataMessage}</p>}
                {/* Hier können Sie den Rest Ihrer Komponente mit den Daten dataArray rendern */}
              </div>
                <thead>
                  <tr>
                    <th>{t('contactType')}</th>
                    <th>{t('firstName')}</th>
                    <th>{t('lastName')}</th>
                    <th>{t('eMail')}</th>
                    <th>{t('positionName')}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((obj) => (
                    console.log('Kontakte werden geladen', dataArray),
                    <tr key={obj.id} onClick={() => handleContactClick(obj)}>
                      <td cellRender className="is-image-cell">
                        {renderLogo({ data: obj})}
                      </td>
                      <td data-label={t('firstName')}>{obj.first_Name}</td>
                      <td data-label={t('lastName')}>{obj.last_Name}</td>
                      <td data-label={t('eMail')}>{obj.eMail}</td>
                      <td data-label={t('positionName')}>{obj.positionname}</td>
                      <td>
                        <div className='editAndDeleteButtons'>
                          {obj.relationshipID !== 1 ? (
                            <MdModeEdit size={20} color="#4CBB17" style={{marginRight: "0.5em"}}
                            onClick={() => {
                              setFormData({
                                id : obj.id,
                                firstName: obj.first_Name,
                                lastName: obj.last_Name,
                                position: obj.positionname,
                                email: obj.eMail,
                              });
                              setIsEditModalOpen(true);
                            }}
                            />
                          ) : (
                            <div style={{display: 'inline-block', width: '20px', marginRight: '0.5em'}}></div>
                          )}
                            <MdDelete size={20} color="red" onClick={() => { setModalData(obj); setIsModalOpen(true); }} />
                        </div>

                          <Modal
                            isOpen={IsEditModalOpen}

                            onRequestClose={closeModal}
                            style={customStyles}
                          >

                            <div className="modal-head" style={{ display: "flex", marginBottom: "10px" }}>
                            
                              <h5 style={{ 
                                flex: "80%", 
                                marginTop: "1.5em" , 
                                textAlign: "center", 
                                fontSize: "1.5rem" }}
                                >
                                  {t('titleContactEdit')}</h5>
                            </div>


                              <form style={{ margin: '0 1.5em', marginTop: "3em" }}>

                              <label className="label" style={{ marginTop: "1em" }}>{t('firstName')}</label>
                              <input
                                className="input is-success"
                                type="text"
                                name='firstName'
                                placeholder={t('firstName')}
                                value={formData.firstName}
                                onChange={handleInputChange}
                              />

                              <label className="label" style={{ marginTop: "1em" }}>{t('lastName')}</label>
                              <input
                                className="input is-success"
                                type="text"
                                name='lastName'
                                placeholder={t('lastName')}
                                value={formData.lastName}
                                onChange={handleInputChange}
                              />

                              <label className="label" style={{ marginTop: "1em" }}>{t('eMail')}</label>
                              <input
                                className="input is-success"
                                type="email"
                                name='email'
                                placeholder={t('eMail')}
                                value={formData.email}
                                onChange={handleInputChange}
                              />

                              <label className="label" style={{ marginTop: "1em" }}>{t('positionName')}</label>
                              <input
                                className="input is-success"
                                type="text"
                                name='position'
                                placeholder={t('positionName')}
                                value={formData.position}
                                onChange={handleInputChange}
                              />
                              
                            </form>


                            <div className="buttons" style={{ position: 'absolute', bottom: '1.5em', right: '1.5em' }}>
                            <button onClick={closeModal} className="button is-danger is-inverted">{t('cancel')}</button>
                            <button onClick={handleEdit} className="button is-primary is-inverted" style={{color: '#4CBB17'}}>{t('btnSave')}</button> {/* Fehlermeldung: 401 */}
                            </div>

                          </Modal>

                        <Modal 
                            isOpen={isModalOpen}
                              style={{
                                content: {
                                  width: '85%',
                                  height: '15%',
                                  margin: 'auto',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: 'white',
                                  borderRadius: '8px',
                                  overflow: 'hidden',
                                },
                                overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                backdropFilter: 'blur(1px)',
                                zIndex: 1000
                                }
                              }}
                          >   
                            <p className='deleteDatensatz'>{t('deleteContact')}</p>
                            <div className='yesNoButtons' style={{marginTop: "1em"}}>
                              <button class="button is-success is-inverted"
                                  style={{marginRight: "1em"}} 
                                  onClick={() => {
                                    handleDelete(modalData.id, modalData.relationshipID);
                                    setIsModalOpen(false);
                                  }}
                                  >{t('yes')}</button>
                              <button class="button is-success is-inverted"
                                onClick={() => { setModalData(null); setIsModalOpen(false); }}
                              >
                              {t('no')}</button>
                          </div>
                          </Modal>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    {/* </ScrollView> */}
  </>
  );
}

export default DataGridCards;
