import React from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import  { DataGrid } from 'devextreme-react/data-grid';
import { Button as DevButton } from 'devextreme-react/button';
import { Modal, Divider } from '@mantine/core';
import notify from 'devextreme/ui/notify';
import { TextBox } from 'devextreme-react';
import Api from '../../utils/api';

const ProjektlisteBearbeiten = ({openEditModal, setOpenEditModal, selectedList, setSelectedList,selectedListToEdit, setSelectedListToEdit, ownLists, dataGridSource, setDataGridSource }) => {
    return (
        <Modal
        opened={openEditModal}
        onClose={() => setOpenEditModal(false)}
        closeOnClickOutside={false}
        withCloseButton={false}
        centered
      >
        <div style={{textAlign: 'center' }}>
          <h6>Projektlisten bearbeiten</h6>
        </div>


        <Divider  style={{marginBottom: '15px'}}/>

        <SelectBox
          placeholder='Listen auswählen zum Bearbeiten'
          dataSource={ownLists}
          displayExpr="name"
          valueExpr="name"
          value={selectedList ? selectedList.name : null}
          onValueChanged={(e) => {
            const selectedObject = ownLists.find(list => list.name === e.value);
            console.log("Ausgewähltes Liste: ", selectedObject);
            setSelectedList(selectedObject);
            setSelectedListToEdit(selectedObject);
          }}
          style={{ marginBottom: '20px'}}
        />

        <>
        {selectedList && (
          <>
            <div className="dx-field-label">Listenname</div>
              <TextBox
                type="text"
                value={selectedList ? selectedList.name : ''}
                onChange={(e) => {
                  console.log("Ausgewählte Liste: ", selectedList);
                  setSelectedListToEdit(selectedList)
                  console.log("Neuer Name: ", e.event.target.value);
                  const updatedList = { ...selectedList, name: e.event.target.value };
                  setSelectedList(updatedList);
                }}
                placeholder="Listenname ändern"
              />
          </>
        )}

        {selectedList && (
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '30px', paddingTop: '20px' }}>
            <DataGrid
              dataSource={dataGridSource}
              columns={['subject']}
              showBorders
              onRowClick={(e) => {
                console.log("Ausgewähltes Projekt: ",(e.data.subject));
                console.log("Ausgewählte Liste: ", selectedList.name);

                // selectedList zum Array parsen
                let selectedListArray = JSON.parse(selectedList.option);

                // Hinzufügen des ausgewählten Objekts zum Array
                selectedListArray.push(e.data);

                // Konvertiere aktualisierte Array zurück in eine JSON-Zeichenfolge
                let updatedSelectedList = JSON.stringify(selectedListArray);

                // Erstelle ein neues Objekt, das eine Kopie von selectedList ist, aber mit option ersetzt durch updatedSelectedList
                let newSelectedList = { ...selectedList, option: updatedSelectedList };

                // Speichern in setSelectedList
                setSelectedList(newSelectedList);

                // Entferne e.data aus dataGridSource
                setDataGridSource(dataGridSource.filter(item => item.subject !== e.data.subject));

                console.log("Aktualisierte ausgewählte Liste: ", newSelectedList);
              }}
            />
            <DataGrid
              dataSource={JSON.parse(selectedList.option).map((option) => ({ Option: option.subject }))}
              columns={['Option']}
              showBorders
              onRowClick={(e) => {
                console.log("Ausgewähltes Projekt: ", JSON.stringify(e.data));
                console.log("Ausgewählte Liste: ", selectedList);

                // Zum Array parsen
                let selectedListArray = JSON.parse(selectedList.option);

                // Finden das Objekt, das entfernt werden soll
                let objectToRemove = selectedListArray.find(option => option.subject === e.data.Option);

                // Entfernen des ausgewählten Objekts aus dem Array
                selectedListArray = selectedListArray.filter(option => option.subject !== e.data.Option);

                // Zurückkonvertieren des aktualisierten Arrays in eine JSON-Zeichenfolge
                let updatedSelectedList = JSON.stringify(selectedListArray);

                // Erstellen eines neuen Objekts mit option ersetzt durch updatedSelectedList
                let newSelectedList = { ...selectedList, option: updatedSelectedList };

                //Speichern in setSelectedList
                setSelectedList(newSelectedList);

                // Hinzzufügen des entfernten Objekts zu dataGridSource
                setDataGridSource([...dataGridSource, objectToRemove]);

                console.log("Aktualisierte ausgewählte Liste: ", newSelectedList);
              }}
            />
          </div>
        )}
        </>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '30px' }}>
          <DevButton 
            type='danger'
            stylingMode='contained'
            onClick={() => {
              setOpenEditModal(false);
              setSelectedList(null);
            }}
          >
            Schließen
          </DevButton>

          <DevButton
            className='myButton'
            onClick={async () => {
              console.log("Zu aktualisierende Liste: ", selectedList)
              console.log("Name der zu Aktualisierenden Liste:", selectedListToEdit.name);
              try {
                await Api.OptionClient.updateOption(selectedListToEdit.name, selectedList);
                notify("Liste erfolgreich aktualisiert", "success", 3000);
              } catch (error) {
                console.error("Fehler beim Aktualisieren der Liste: ", error);
                notify("Fehler beim Aktualisieren der Liste", "error", 3000);
              }
            }}
          >
            Speichern
          </DevButton>
        </div>

      </Modal>
    );
    }
export default ProjektlisteBearbeiten;