import React, { useState, useEffect } from 'react';
import { Button, Divider, Group, Modal } from '@mantine/core';
import { Autocomplete } from 'devextreme-react';
import { useDisclosure } from '@mantine/hooks';
import { IoMdSearch } from "react-icons/io";
import { useScreenSize } from '../../utils/media-query';
import i18n from 'i18next';
import Api from '../../utils/api';
import AufgabenlisteKomponente from './AufgabenlisteKomponente';
import EventBus from '../Mitgliederliste-Mobil/EventBus';
import './AddAufgabe.css';

const AddAufgabe = ({Aufgaben, setAufgaben}) => {

    const [opened, { open, close }] = useDisclosure(false);
    const screenSize = useScreenSize();
    const p = i18n.getFixedT(null, 'project');
    const t = i18n.getFixedT(null, 'taskList');
    const [dataArray, setDataArray] = useState([]);
    const [noDataMessage, setNoDataMessage] = useState('');
    const [Aufgabenliste, setAufgabenliste] = useState(null);
    const [taskGridModalOpened, setTaskGridModalOpened] = useState(false);
    const [formData, setFormData] = useState(null);
    
    // Funktion zum Öffnen des Modals
    const openTaskGridModal = () => setTaskGridModalOpened(true);

    // Funktion zum Schließen des Modals
    const closeTaskGridModal = () => setTaskGridModalOpened(false);

    // Funktion zum Laden der Daten
    useEffect(() => {
    Api.tasks.getTasks().then(response => {
    const data = response.data;
    let results = []; // Initialisieren Sie ein leeres Array
    for (let i = 0; i < data.length; i++) {
      results.push({
        subject: data[i].subject,
        id: data[i].id,
        start: data[i].start,
        end: data[i].end,
        status_Procent: data[i].status_Procent
      });
    }
    setAufgabenliste(results);

    if (data.length === 0) {
      setNoDataMessage(t('noData'));
    } else {
      setDataArray(data);
    }
    }).catch(error => {
    console.error(error);
    });
    }, []);

    useEffect(() => {
      const handleSelectedTaskChanged = (newSelectedTask) => {
      setFormData(newSelectedTask);

      setTaskGridModalOpened(false)
      console.log('Geklickte Aufgabe:', newSelectedTask); // Konsolenausgabe für den geklickten Kontakt
      };
  
      EventBus.on('selectedTaskChanged', handleSelectedTaskChanged); // // Event abonnieren mit on
  
      return () => {
        EventBus.off('selectedTaskChanged', handleSelectedTaskChanged); // Event abbestellen mit off
      };
    }, []);

    const handleClose = () => {
      setFormData(' ');
      close();
    };

    const handleSave = () => {
      console.log('Aufgabe speichern mit daten von formData:', formData);
      console.log('Aufgabenliste', Aufgaben);
      const addNewTaskToTaskList = [...Aufgaben, formData];
      console.log('addNewTaskToTaskList', addNewTaskToTaskList);
      setAufgaben(addNewTaskToTaskList);
      handleClose();
    }

    const isIdInAufgabenliste = () => {
      return Aufgabenliste?.some(task => task.id === formData?.id);
    }

    return (
        <div>
        
        <Group position="right">
          <Button 
            onClick={open}
            styles={(theme) => ({
              root: {
                backgroundColor: '#39b201',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#39b201',
                  color: 'white',
                },
              }})}
            >
            Aufgabe hinzufügen
          </Button>
        </Group>
        
        <Modal opened={opened} onClose={close} title= {<h5 className="modal-title">Aufgabe hinzufügen</h5>} centered size='lg'>
          <Divider margins="xs" />
          <form className='fromular' style={{ margin: '0 1.5em', marginTop: "3em" }}>
            <div className="input-with-button">
                <div className="contact-complete">
                    <Autocomplete
                      placeholder="Aufgabe suchen..."
                      dataSource={Aufgabenliste}
                      valueExpr="subject"
                      onValueChanged={(e) => setFormData({...formData, subject: e.value})}
                      value={formData?.subject}
                      onSelectionChanged={(e) => setFormData(formData, e.selectedItem)}
                      showClearButton={true}
                    />
                </div>
                  <Button id='searchTaskButton' variant="transparent" onClick={openTaskGridModal}>
                    <IoMdSearch className="search-icon" size={30} color='#4CBB17'/>
                  </Button>
            </div>
                  <Modal opened={taskGridModalOpened} onClose={closeTaskGridModal} centered size='95%'>
                  <AufgabenlisteKomponente />
                </Modal>
              <div className="cancelOrSaveButtons">
              <Button variant='filled' color='red' onClick={handleClose} style={{marginRight: '10px'}}>{p('cancel')}</Button>
              <Button 
                onClick={handleSave}
                disabled={!isIdInAufgabenliste()}
                styles={(theme) => ({
                  root: {
                    backgroundColor: '#39b201',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#39b201',
                      color: 'white',
                    },
                  }})}
                >
                {p('save')}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    );
}
export default AddAufgabe;