import React, {useEffect, useState} from 'react';

import CustomStore from "devextreme/data/custom_store";
import Api from "./api";
import * as notifyManager from "./notifyManager";
const profileDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        byKey: (key) => {
            return Api.people.getPersonById(key)
                .then(response => response.data);
        },
    })

const skillSuggestions = new CustomStore({
    load: () => {
        return Api.skills.getSkillSuggestions()
            .then(response => response.data)
    }
});

const skillDS = new CustomStore({
    load: () => {
        return Api.skills.getMySkills()
            .then(response => response.data)
    },
    insert: (input) => {
        if(input && input.length !== 0){
            return Api.skills.addMySkill({name: input})
                .then(() => notifyManager.notifySuccess(`Skill ${input} hinzugefügt`))
                .catch((e) => {
                    notifyManager.notifyError("Skill bereits vorhanden.")
                })
        }
    },
    remove: (event) => {

        if (event && event.itemData && event.itemData.name) {
            Api.skills.deleteMySkill(event.itemData.name)
                .then(() => {
                    // skillsDS.load();
                    notifyManager.notifySuccess(`Skill ${event.itemData.name} wurde entfernt`)
                })
                .catch(() => {
                    notifyManager.notifyError("Skill konnte nicht entfernt werden. Bitte versuchen Sie es später erneut.")
                })
        }
    }


});


const networkDS = new CustomStore({
        key: ['person_otherID'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyNetwork()
                .then(response => response.data)
        },

        remove: (data) => {
            return Api.people.deleteFromNetwork(data.id, data.relationship)
        }
    })

const networkRequestDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyRequests()
                .then(response => response.data);
        },

        insert: (data) => {
            return Api.people.insertToNetwork(data)
                .then(response => response.data);
        },

        remove: key => {
            return Api.people.deleteRequest(key)
        }
    })

export {profileDS, networkRequestDS, networkDS, skillDS, skillSuggestions}
