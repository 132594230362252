import React, {useState, useRef, useCallback, useEffect} from 'react';
import './Kontaktliste.scss';
import maprotaIcon from '../../logo192.png'
import {
    Value, Column, Popup,Form, ColumnChooser, DataGrid, FilterRow, HeaderFilter, GroupPanel, Scrolling, Editing, Grouping, Lookup, MasterDetail, Summary, RangeRule, RequiredRule, StringLengthRule, GroupItem, TotalItem, ValueFormat
     } from 'devextreme-react/data-grid';

import { Item, Label} from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import i18n from "i18next";
import Api from "../../utils/api"
import {useAuth} from '../../contexts/auth';
import List from "devextreme-react/list";
import {Button, LoadIndicator} from 'devextreme-react'
import { useHistory} from 'react-router-dom';
import {notifyError, notifySuccess} from "../../utils/notifyManager";
import {useScreenSize} from "../../utils/media-query";
import { ScrollView } from 'devextreme-react/scroll-view';
import DataGridCards from './Responsive Table/DataGridCards';
import KontaktlistePC from './PC Liste/KontaktlistePC';
import NewContactButton from './PC Liste/NewContactButtonPC';


function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
};

export default function () {

    const state = {
        CONTACT: 2,
        NETWORK: 1,
        LOCAL_NETWORK: 1,
        RECEIVED_REQUEST : 4,
        SENT_REQUEST: 3,
        Invited: 5,
        NOT_NETWORK: 0,
        NOT_NETWORK: 0,
        Invitation : 5,
        LOADING: 99
    }

    const {isXSmall, isSmall} = useScreenSize();
    const {user} = useAuth()
    const history = useHistory();
    let myNetwork = {}
    let [loadingReceivedRequests, setLoadingReceivedRequests] = useState(true)
    let [loadingSentRequests, setLoadingSentRequests] = useState(true)
    let [sentNetworkRequests, setSentNetworkRequests] = useState([])
    let [receivedNetworkRequests, setReceivedNetworkRequests] = useState([])
    const t = i18n.getFixedT(null, 'contacts');
    const n = i18n.getFixedT(null, 'notify');
    const [dataArray, setDataArray] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [ContactlistChanged, setContactlistChanged] = useState(false);

    const [NetworkContact, setNetworkContact] = useState([]);

    useEffect(() => {

        Api.people.getMyNetwork().then(response => {
            setNetworkContact(response.data);
        }).catch(error => {
        console.error(error);
        });
    },[]);


    // const networkDS = new CustomStore({
    //     key: ['id'],
    //     cacheRawData: false,
    //     reshapeOnPush: true,
    //     loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
    //     load: () => {
    //         return Api.people.getMyNetwork()
    //             .then(data => data.data)
    //             .then(data => {
    //                 myNetwork = data
    //                 return data
    //             })
    //     },

    //     insert: (item) => {
    //         const obj = {
    //             Person_other : item,
    //             relationship: {
    //                 "id": 2
    //             },
    //             person_MeID: user.id
    //         }

    //         return Api.people.insertToNetwork(obj)
    //     },

    //     remove: key => {

    //         let relationshipToDelete = myNetwork.find(item => item.id == key.id)
    //         if(relationshipToDelete){
    //             return Api.people.deleteFromNetwork(key.id, relationshipToDelete.relationshipID)
    //         }
    //         else{
    //             notifyError(t('failedToRemoveMyRequest'))
    //         }

    //     },

    //     update: (key, values) => {
    //         return Api.people.updateMyContact(key.id, values)
    //     }

    // });


    const networkSentRequestDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyRequests()
                .then(response => response.data)
                .then(data => {
                    //setLoadingSentRequests(false)
                   // return data.filter( request => request.person_MeID === user.id)
                   return data
                 })
                .then(filteredData => {
                    setLoadingSentRequests(false)
                    return setSentNetworkRequests(filteredData)
                })
        },



    });



    const networkReceivedRequestDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyReceived()
                .then(response => response.data)
                .then(data => {
                    return data
                })
                .then(filteredData => {
                    
                    setLoadingReceivedRequests(false)
                    return setReceivedNetworkRequests(filteredData)

                })
        },

        remove: key => {
            setLoadingReceivedRequests(false)
            return Api.people.deleteRequest(key)
        },

        insert: id => {
            const item = {
                person_otherID : id,
                person_MeID: user.id,
                relationship: {
                    "id": 1
                }
            };
            setLoadingReceivedRequests(false)
            return Api.people.insertToNetwork(item)
        }
    });

  

   

    const cancelReceivedRequest = (id) => {
        setLoadingReceivedRequests(true)
        networkReceivedRequestDS.remove(id)
            .then(() =>{
                notifySuccess(t('canceledRequest'))
                networkReceivedRequestDS.load()
            }).catch( () => {
            notifyError(t('failedToRemoveMyRequest'))
        })
    };

    const cancelSentRequest = (id) => {
        setLoadingSentRequests(true)
        networkReceivedRequestDS.remove(id)
            .then(() =>{
                notifySuccess(t('canceledRequest'))
                networkSentRequestDS.load()
            })
            .catch(() => {
                notifyError(t('failedToRemoveMyRequest'))
            })
    };

    const acceptRequest = (id) => {
        setLoadingReceivedRequests(true)
        networkReceivedRequestDS.insert(id)
            .then(() => {
                notifySuccess(t('acceptedRequest'))
                networkReceivedRequestDS.load()
            })
            .catch(() => {
                notifyError(t('failedAccept'))
            })
    };


 const renderLogo = (data) => {
       const obj = data.data
        if(obj && obj.relationshipID === 1){
            return <Button icon={maprotaIcon} onClick={() => {
                history.push(`/profile/${obj.id}`)
            }
            }/>
        }else {
            return <Button icon='card' onClick={() => {
                 //history.push(`/profile/${obj.person_otherID}`)
                 history.push(`/profile/${obj.id}`)
            }}/>
        }
    };




  

    function notifyRemovedContact() {
        notifySuccess(t('removedFromNetwork'))
    }
    function notifyAddedContact() {
        notifySuccess(t('insertedLocalContact'))
    }

    function isEditAvailable(e) {
        if (e.rowType === 'data' && e.column.command === 'edit')
            if (e.row.data.relationshipID === 1)
            {
                let editLink = e.cellElement.querySelector(".dx-link-edit");
                if(editLink) {
                    editLink.remove();
                }
            }
    }

const isMobile = isXSmall;



    function onConnect(obj){
       // console.log("Funktion für Vernetzung Kontaktliste", obj);
        let item = {}; // Initialisiere item als leeres Objekt
        item.person_MeID =  user.id;
        item.person_otherID = obj.reF_BP;
        item.RelationshipID  = state.SENT_REQUEST
        // item.relationship = {}; // Initialisiere relationship als leeres Objekt
        // item.relationship.id = state.SENT_REQUEST;
        console.log("Funktion für Vernetzung Kontaktliste vor Insert", item);
        Api.people.insertToNetwork(item).then(response => {
            if(response.status == 200){
                const SuccessMessage = n('InsertOK');
                notifySuccess(SuccessMessage);
                console.log(response);
            }
        }).catch(error => {
        console.error(error);

            if (error.response) {
                // Die Fehlermeldung von der API
                const apiErrorMessage = error.response.data.message || n('errSaving');
                notifyError(apiErrorMessage);
            } else if (error.request) {
                // Wenn kein Response empfangen wurde (z.B. Netzwerkfehler)
                console.error('No response received:', error.request);
                notifyError(n('errSaving'));
            } else {
                // Sonstige Fehler
                console.error('Error:', error.message);
                notifyError(n('errSaving'));
            }
        });

   }

   function oninvite(obj){
    // console.log("Funktion für Vernetzung Kontaktliste", obj);
     let item = {}; // Initialisiere item als leeres Objekt
     item.person_MeID =  user.id;
     item.person_otherID = obj.id;
     item.RelationshipID  = state.Invited;
     // item.relationship = {}; // Initialisiere relationship als leeres Objekt
     // item.relationship.id = state.SENT_REQUEST;
     console.log("Funktion für Vernetzung Kontaktliste vor Insert", item);
     Api.people.insertToNetwork(item).then(response => {
         if(response.status == 200){
             const SuccessMessage = n('InsertOK');
             notifySuccess(SuccessMessage);
             console.log(response);
         }
     }).catch(error => {
     console.error(error);

         if (error.response) {
             // Die Fehlermeldung von der API
             const apiErrorMessage = error.response.data.message || n('errSaving');
             notifyError(apiErrorMessage);
         } else if (error.request) {
             // Wenn kein Response empfangen wurde (z.B. Netzwerkfehler)
             console.error('No response received:', error.request);
             notifyError(n('errSaving'));
         } else {
             // Sonstige Fehler
             console.error('Error:', error.message);
             notifyError(n('errSaving'));
         }
     });

}

function onWithdrawConnectionRequest(obj){
     console.log("Funktion für Vernetzung Kontaktliste", obj);
     notifySuccess("SuccessMessage");
    

     };


    return (

        <React.Fragment>

            {/* <h2 className={'content-block'}>{t('title')}</h2>
            <div className={'content-block'}>
                <NewContactButton t={t}
                    setDataArray={setDataArray}
                    setContactlistChanged={setContactlistChanged}
                /> */}

                <div className={'content-block'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        <NewContactButton t={t}
                        setDataArray={setDataArray}
                        setContactlistChanged={setContactlistChanged}
                        />
                    </div>
                    <div style={{ textAlign: 'center', flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: '100%' }}>
                      {t('title')}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      {/* <DropDownButton
                        text={SelectedView === "Liste" ? "Tabellenansicht" : "Kanbanansicht"}
                        icon="table"
                        items={Ansichten}
                        onSelectionChanged={onViewSelected} 
                      /> */}
                    </div>
                  </div>
                  <div className={'content-block'}>   
                <div className={'dx-card responsive-paddings'}>

                    {isMobile ? (
                        <>
                                <DataGridCards 
                                    t={t} 
                                    renderLogo={renderLogo}
                                    dataArray={NetworkContact}
                                    setDataArray={setDataArray}
                                    filteredData={filteredData}
                                    setFilteredData={setFilteredData}
                                    setContactlistChanged={setContactlistChanged}
                                    ContactlistChanged={ContactlistChanged} 
                                />
                        </>          
                        
                        ) : (
                            <KontaktlistePC 
                                t={t} 
                                renderLogo={renderLogo}
                                dataArray={NetworkContact}
                                setDataArray={NetworkContact} 
                                filteredData={filteredData} 
                                setFilteredData={setFilteredData}
                                setContactlistChanged={setContactlistChanged}
                                ContactlistChanged={ContactlistChanged}
                                Mitgliederliste={NetworkContact}
                                onConnect={onConnect}
                                oninvite={oninvite}
                                onWithdrawConnectionRequest={onWithdrawConnectionRequest}
                            />
                        )}             
                
                </div>
           </div>
        </React.Fragment>
    );
};