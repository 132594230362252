import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React , {useEffect} from 'react';
import {Switch, Route, Redirect, Link, BrowserRouter, useNavigate} from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import {AuthProvider, PrivateContentRoute} from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import NotAuthenticatedContent from './NotAuthenticatedContent';
import {
  ProfilePage, ProjektListe, Aufgabenliste, Terminliste,
  Benutzerverwaltung, EinstellungProjects, EinstellungUnternehmen, EinstellungTask, Projekt, Aufgabe, MailContainer, Kontaktliste, EinstellungenMail, SearchResult,
  Feed
} from './pages';
import appInfo from './app-info';
import AufgabenGant from './pages/AufgabenGant/AufgabenGant';




export default function () {

  const screenSizeClass = useScreenSizeClass();
  const ti = useEffect(() => {
    document.title = appInfo.Headertitle;
    
  }, []);

  return (

      <AuthProvider>
      <BrowserRouter>
        <div className={`app ${screenSizeClass}`}>
          <Switch>
            <Route path="/login">
              <NotAuthenticatedContent/>
            </Route>

            <Route path="/checkMailAdress">
              <NotAuthenticatedContent/>
            </Route>

            <Route path="/create-account">
              <NotAuthenticatedContent/>
            </Route>

            <Route path="/reset-password">
              <NotAuthenticatedContent/>
            </Route>

            <Route path="/PasswordResetSetNew">
              <NotAuthenticatedContent/>
            </Route>

            

            <PrivateContentRoute path="/termin">
              <Terminliste/>
            </PrivateContentRoute>

            <PrivateContentRoute path='/projektliste'>
                <ProjektListe/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/profile/:id">
              <ProfilePage/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/profile">
              <ProfilePage/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/projekt/:id">
              <Projekt/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/projekt/">
              <Projekt/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Aufgabenliste">
              <Aufgabenliste/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/AufgabenKanban">
              <AufgabenGant/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Aufgabe/:id">
              <Aufgabe/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Aufgabe/">
              <Aufgabe/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/kontakte">
              <Kontaktliste/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Terminliste">
              <Terminliste/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Benutzerverwaltung">
              <Benutzerverwaltung/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/EinstellungUnternehmen">
              <EinstellungUnternehmen/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/EinstellungProjects">
              <EinstellungProjects/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/EinstellungTask">
              <EinstellungTask/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Projekt">
              <Projekt/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Aufgabe" >
              <Aufgabe/>
            </PrivateContentRoute>

            <PrivateContentRoute exact path="/Mail">
              <MailContainer/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/Mail/:id">
              <MailContainer/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/EinstellungenMail">
              <EinstellungenMail/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/search">
              <SearchResult/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/feed">
              <Feed/>
            </PrivateContentRoute>

            <PrivateContentRoute path="/">
              <Redirect to={'/feed'} />
            </PrivateContentRoute>

          </Switch>
        </div>
      </BrowserRouter>
    </AuthProvider>

  );
}
