import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Selection,
  Sorting,
  StateStoring
} from 'devextreme-react/data-grid';
import i18n from "i18next";
import CustomStore from 'devextreme/data/custom_store';
import Api from "../../utils/api";
import { useLocation } from 'react-router';
import './mail-grid.scss';

import { concat } from "lodash";

// import Daten2 from "../../MailGrid/MailGrid";

// function Daten(props){
// const t = i18n.getFixedT(null, 'mails');
// const [id,setid]                = useState(props.MailSetting.id);
// const [folderId, setfolderId] = useState(props.FolderID); //'';
// const [customDataSource, setcustomDataSource] =  useState(null);


// // //  useMemo(() => {
// // console.log( 'Component MAIL-GRID: Postfach '+ id +' Mailorder ' +  folderId  +' laden' );
// // console.log( 'Component MAIL-GRID: customDataSource '+ customDataSource +' laden' );


// const testlf = () => {
//   // You can use Hooks here!
//   setCount(99);



// }

// const [countEmails, setCountEMail] = useState(props.setCountEMail);



// useEffect(() => {
// const tmpDS = new CustomStore({
//                       key: 'id',
//                       cacheRawData: true,
//                       reshapeOnPush: false,
//                       loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
//                       load: () => {

//                           return Api.mail.getMails(id, folderId)
//                               .then(response => {
//                               //  console.log("Component MAIL-GRID: Response API"+ { response.data })
//                                   return response.data
//                               })
//                               .then(data => {
//                                   data.forEach((item, index) => {
//                                       item.id = index

//                                   })
//                                   console.log("Component MAIL-GRID: Daten geladen"+id)

//                                   return data
//                               })

//                               .catch(error => {
//                               });

//                       }

//                   })

//     console.log( 'Component MAIL-GRID: Postfach '+ id +' Mailorder ' +  folderId  +' geladen' );
//    // console.log( tmpDS );   
//       setcustomDataSource(tmpDS);

//               },[folderId]);


//               return customDataSource;

//             }
const t = i18n.getFixedT(null, 'mails');

//export default    (props) => {
function MailGRID  (props) {



  const [count, setCount] = useState(props.TestV);
  const testlf = () => {
    // You can use Hooks here!
    setCount(99);



  }

  const [countEmails, setCountEMail] = useState(props.setCountEMail);





  const [id, setid] = useState(props.MailSetting.id);
  const [folderId, setfolderId] = useState(props.FolderID); //'';
  // const [customDataSource, setcustomDataSource]   = useState(props.customDataSource)   

  // useEffect(() => {
  //   const tmp = Api.mail.getMails(props.MailSetting.id, props.FolderID);
  //   console.log(tmp);
  //   console.log('MAIL-GRID USEEFFECT', tmp);
  //   setcustomDataSource ( tmp.data );
  // },[]);
  const [customDataSource, setcustomDataSource] = useState();


  const customDataSource2 = useMemo(() => {
    return new CustomStore({
      key: 'id',
      cacheRawData: true,
      reshapeOnPush: false,
      loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
      load: () => {

        return Api.mail.getMails(id, folderId)
          .then(response => {
            console.log("Component MAIL-GRID: Response API", response.data)
            setcustomDataSource(response.data);
            return response.data
          })
          .then(data => {
            data.forEach((item, index) => {
              item.id = index

            })
            console.log("Component MAIL-GRID: Daten geladen" + id)
            console.log("Component MAIL-GRID: customDatasource", customDataSource)
            console.log("Component MAIL-GRID: data", data)
            setcustomDataSource(data);
            console.log("Component MAIL-GRID: customDatasource", customDataSource)
            return data
          })

          .catch(error => {
          });



      }

    });
  }, [id, folderId]);

  useEffect(() => {
    const tmpDS = new CustomStore({
      key: 'id',
      cacheRawData: true,
      reshapeOnPush: false,
      loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
      load: () => {

        return Api.mail.getMails(id, folderId)
          .then(response => {
            console.log("Component MAIL-GRID: Response API", response.data)
            setcustomDataSource(response.data);
            return response.data
          })
          .then(data => {
            data.forEach((item, index) => {
              item.id = index

            })
            console.log("Component MAIL-GRID: Daten geladen" + id)
            console.log("Component MAIL-GRID: customDatasource", customDataSource)
            console.log("Component MAIL-GRID: data", data)
            setcustomDataSource(data);
            console.log("Component MAIL-GRID: customDatasource", customDataSource)
            return data
          })

          .catch(error => {
          });



      }

    });

    console.log('Component MAIL-GRID: Postfach ' + id + ' Mailorder ' + folderId + ' geladen');
    // console.log( tmpDS );   
    setcustomDataSource(tmpDS);

  //}, [id, folderId]);
}, []);








  console.log('MAIL-GRID Lade Mail', customDataSource);
  return <React.Fragment>

    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
      <button onClick={() => testlf()}>
        Click me
      </button>
      <button onClick={props.handelTest}>
        Click me
      </button>

      <p>You clicked {countEmails} times</p>
    </div>

    <p> Mail {props.MailSetting.id} </p>
    <p> Mailfolder {props.FolderID} </p>


    {/* <p> hier {props.MailSetting} </p>  */}

    <DataGrid

      key="id"
      id="id"
      dataSource={customDataSource2}
      showBorders={true}
      hoverStateEnabled={true}
      cacheEnabled={true}
      // onSelectionChanged= { onSelectionChanged }
      onSelectionChanged={props.MailSelected}
      onRowPrepared={(rowElement, e) => {
        if (rowElement.rowType === 'data') {
          if (rowElement.data) {
            const isUnread = rowElement.data.unRead
            if (isUnread === true) {
              //rowElement.element.className +=  " unread"
              rowElement.rowElement.classList.add("unread");
              //  console.log("MAIL-GRID: UNREAD MESSAGE",rowElement.element.className)
              //  rowElement.element.className={ isUnread  ? " bg-red-500 " : ""};
              //rowElement.element.style += "font-weight: bold"; 
            }
            else {
              // rowElement.element.className =   rowElement.element.className.replace(" unread")
              // rowElement.element.className = "dx-widget dx-visibility-change-handler";
              rowElement.rowElement.classList.remove("unread");
              console.log("MAIL-GRID: READ MESSAGE", rowElement.element.className)
            }
          }
        }
      }}


    >
      {/* <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="EntityGrid"
        /> */}
      <Editing
        mode="row"
        allowAdding={false}
        allowDeleting={false}
        allowUpdating={false}
      />
      <Sorting mode="single" />
      <Selection mode="single" />
      <ColumnChooser enabled={false} />
      <Column dataField="id" caption="ID" allowEditing={false} visible={false}> </Column>
      {/* <Column dataField="unRead" caption={t('unRead')}> </Column> */}
      <Column dataField="from[0].last_Name" caption={t('from')}> </Column>
      <Column dataField="subject" caption={t('subject')}> </Column>
      <Column dataField="date_From" caption={t('receiving_date')} dataType="date" sortOrder="desc" > </Column>


    </DataGrid>

  </React.Fragment>

}; 
export default  React.memo(MailGRID);
