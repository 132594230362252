import { locale } from "devextreme/localization";
import i18n from "i18next";
import Api from "../utils/api"

let FALLBACK_LANGUAGE = {};
let langs = Api.getLanguages().then(data => {
    langs = data.data
    FALLBACK_LANGUAGE = langs.find(lng => lng.default === true)
})


function changeWebpageLanguage(me){
    let lang
    if(me && me.language){
        lang = me.language.name
    }
    else if(typeof me === 'string'){
        lang = me
    }else if(me && me.name){
        lang = me.name
    }
    else {
        lang = navigator.language
    }
    locale(lang);
    return i18n.changeLanguage(lang)
}

function getUser() {
    if (Api.api_token !== undefined) {
        return Api.getMe()
            .then(response => response.data)
    }
    return undefined

}

function _calculateDefaultLanguage(){
    console.log(FALLBACK_LANGUAGE)
    const user = getUser()
    if (user !== undefined) {
        return user.language ? user.language : FALLBACK_LANGUAGE
    }
    for (let lang of langs) {
        if (navigator.language.toLowerCase().includes(lang.name))
            return lang
    }
    return FALLBACK_LANGUAGE
}

function getDefaultLanguage() {
    const defaultLanguage = _calculateDefaultLanguage();
    changeWebpageLanguage(defaultLanguage)
    return defaultLanguage;
}

export {changeWebpageLanguage, getDefaultLanguage}
