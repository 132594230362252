import React, {useState} from 'react';
import { PostEntry, PostCreate } from '../../components';
import { Button, TextBox } from 'devextreme-react';
import i18n from "i18next";
import { set } from 'lodash';


export default function App() {
    const t = i18n.getFixedT(null, 'Feed');
    
    // Dummy-Daten für 5 Posts
    const posts = [
        {
            profilePic: "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
            userName: "John Doe",
            timePosted: "2 hours ago",
            postText: "Just finished an amazing project! Check out my new portfolio.",
            postImage: "https://example.com/project1.jpg",
            likes: 123,
            comments: 45,
            shares: 10
        },
        {
            profilePic: "https://randomuser.me/api/portraits/women/11.jpg",
            userName: "Jane Smith",
            timePosted: "3 hours ago",
            postText: "Excited to start a new job at Tech Corp!",
            postImage: "https://example.com/project2.jpg",
            likes: 200,
            comments: 60,
            shares: 20
        },
        {
            profilePic: "https://randomuser.me/api/portraits/women/10.jpg",
            userName: "Alex Johnson",
            timePosted: "5 hours ago",
            postText: "Had a great meeting with the team today.",
            postImage: "https://example.com/project3.jpg",
            likes: 150,
            comments: 30,
            shares: 15
        },
        {
            profilePic: "https://randomuser.me/api/portraits/men/9.jpg",
            userName: "Emily Davis",
            timePosted: "6 hours ago",
            postText: "Looking forward to the weekend!",
            postImage: "https://example.com/project4.jpg",
            likes: 95,
            comments: 25,
            shares: 5
        },
        {
            profilePic: "https://randomuser.me/api/portraits/men/10.jpg",
            userName: "Michael Brown",
            timePosted: "8 hours ago",
            postText: "Excited to share my new blog post with you all.",
            postImage: "https://example.com/project5.jpg",
            likes: 175,
            comments: 40,
            shares: 25
        }
    ];

    const NewpostFromUser = {
        profilePic: "https://randomuser.me/api/portraits/men/10.jpg",
        userName: "Lars",
        timePosted: "8 hours ago",
        postText: "Excited to share my new blog post with you all.",
        postImage: "https://example.com/project5.jpg",
        likes: 175,
        comments: 40,
        shares: 25
    };

    const [posts2, setPosts2] = useState(posts);

    const handleCreatePost = (newPost) => {

         
        console.log("Insert Button", newPost);

        const newPost2311 = {
            profilePic: "https://randomuser.me/api/portraits/men/10.jpg",
            userName: "Lars", // Deinen Benutzernamen verwenden
            timePosted: "Just now",
            postText:  "Test",
            postImage: "", // Falls es ein Bild gibt, hier angeben
            likes: 0,
            comments: 0,
            shares: 0
        };   
            
            
            
       

        setPosts2([newPost2311, ...posts]); // Fügt den neuen Post am Anfang der Liste hinzu
      
    };

    const [showCreateForm, setShowCreateForm] = useState(false);


    return (
        <React.Fragment>
    <div className={'content-block'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                    <Button 
                        icon="plus"
                        className='myButton'
                        text={t('btnCreatePopUPNewPost')}
                       // onClick={doneClick} 
                      />
                    </div>
                    <div style={{ textAlign: 'center', flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: '100%' }}>
                      {t('txtTitleHeader')}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      {/* <DropDownButton
                        text={SelectedView === "Liste" ? "Tabellenansicht" : "Kanbanansicht"}
                        icon="table"
                        items={Ansichten}
                        onSelectionChanged={onViewSelected} 
                      /> */}
                    </div>
                  </div>
                  <div className={'content-block'}>  



        <div className="App">

           {/* <PostCreate onCreatePost={handleCreatePost} /> */}
            <PostCreate onCreatePost={handleCreatePost} />



            {posts2.map((post, index) => (
              
                <PostEntry
                    key={index}
                    profilePic={post.profilePic}
                    userName={post.userName}
                    timePosted={post.timePosted}
                    postText={post.postText}
                    postImage={post.postImage}
                    likes={post.likes}
                    comments={post.comments}
                    shares={post.shares}
                />
            ))}
        </div>

        </div>
        </React.Fragment>
    );
 
}

 
