import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import notify from "devextreme/ui/notify"
import {useTranslation} from "react-i18next";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useAuth } from '../../contexts/auth';
import './login-form.scss';
import i18n from "i18next";
import {useLocation} from 'react-router-dom'
import MicrosoftLogo from "./MicrosoftLogin.png";
import { Divider } from '@mantine/core';
import { Alert } from '@mantine/core';
import { IoAlertOutline } from "react-icons/io5";
import { useMsal } from '@azure/msal-react';

export default function (props) {
  const t = i18n.getFixedT(null, 'auth');
  const c = i18n.getFixedT(null, 'common');

  const passwordEditorOptions = { stylingMode: 'filled', placeholder: c('password'), mode: 'password' };
  const emailEditorOptions = { stylingMode: 'filled', placeholder: c('email')};
  const rememberMeEditorOptions = { text: t('rememberMe'), elementAttr: { class: 'form-text' } };

  const location = useLocation()
  const history = useHistory();
  const { loginFunction } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});
  let { from } = location.state || { from: { pathname: "/" } };
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password } = formData.current;
    setLoading(true);
    loginFunction({username: email, password})
        .then(res => {
          setLoading(false);
          console.log("redirect to from " + from)
          history.push('/Aufgabenliste');
          // if(from === '/')history.replace('/aufgabenverwaltung')
          // else history.replace(from)
        })
        .catch(error => {
          setLoading(false);
          console.log(error.response);
          
          if(error.response == undefined )
          {
            notify({
              message:  error.message,// error.response.data.title &&  error.response?.data ,
              type: "error",
              displayTime: 5000
            });
          }  
          else if( error.response.data.title != undefined) 
          {
            notify({
              message:  error.response.data.title,// error.response.data.title &&  error.response?.data ,
              type: "error",
              displayTime: 5000
            });
          }
          else
          {
            notify({
              message:   error.response.data,
              type: "error",
              displayTime: 5000
            });
          }
        });

  }, [loginFunction]);


  const onCreateAccountClick = useCallback(() => {
    history.push('/create-account');
  }, [history]);


  

 
  
    const { instance, accounts  } = useMsal();
  
    const handleMicrosoftLogin = () => {
      try {
         instance.loginRedirect({
          scopes: ["user.read"],
        });
      } catch (error) {
        console.error('Login failed', error);
      }
    };

    const loginRequest = {
      scopes: ["User.Read"]};

    const getAccessToken = async () => {
      if (accounts.length > 0) {
          const request = {
              ...loginRequest,
              account: accounts[0],
          };
          const response = await instance.acquireTokenSilent(request);
          console.log("Access Token: ", response.accessToken);
      }
  };

  //   useEffect(() => {
  //     if (accounts.length > 0) {
  //         getAccessToken();
  //     }
  // }, [accounts]);



  


  // function handleMicrosoftLogin() {
  //   console.log('Click');

  //   instance.loginPopup(loginRequest).catch(e => {
  //     console.error(e);
  // });

    // msalInstance.loginPopup(msalConfig)
    // .then(response => {
    //     console.log(response);
    // })
    // .catch(e => {
    //     console.error(e);
    // });

    // notify({
    //   message: 'Microsoft Login not implemented yet',
    //   type: 'error',
    //   displayTime: 5000,
    // });
  //}

 

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>

        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message={t('requiredMail')} />
          <Label text={c('email')}/>
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
          cssClass={'password'}
        >
          <RequiredRule message={t('requiredPassword')} />
          <Label text={c('password')}/>
              </Item>
              {
        <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        }
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : t('login')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>

        <Item>
            <div className={'login-link'}>
              <div className={'microsoft-login'}>
                <Divider label={'ODER'} labelPosition='center'/>
                <img id='MicrosoftLogo' src={MicrosoftLogo} alt={'microsoft-login'} onClick={handleMicrosoftLogin}/>
              </div>
            </div>
        </Item>

        <Item>
          <div className={'line'}>
            <div className={'login-link'}>
              <Link to={'/reset-password'}>{i18n.t('glossary:forgotPassword')}</Link>

              <div className={'linkToSignUp'}>
                <Link to={'/create-account'}>{i18n.t('glossary:signUp')}</Link>{i18n.t('glossary:tryMaProta')}
              </div>
            </div>
          </div>
        </Item>
              {/* <Item>
                  <div className={'link'}>
                      <Link to={'/reset-password'}>Forgot password?</Link>
                  </div>
              </Item>
                  <ButtonItem>
                      <ButtonOptions
                          text={'Create an account'}
                          width={'100%'}
                          onClick={onCreateAccountClick}
                      />
                  </ButtonItem>
              */}
      </Form>
    </form>
  );
}


