import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  GroupPanel,
  Selection,
  Sorting,
  StateStoring
} from 'devextreme-react/data-grid';
import i18n from "i18next";
import CustomStore from 'devextreme/data/custom_store';
import Api from "../../utils/api";

import './mail-grid2.scss';


function DataRow(props) {
  const formatDate = new Intl.DateTimeFormat('en-US').format;
  console.log("DataRow",props);
  return (
    <React.Fragment>
      <tr className="main-row">
        <td colSpan="2">Nachname</td>
      </tr>
      <tr className="main-row">
        <td>Betreff</td>
        <td>Datum</td>
      </tr>
      <tr className="notes-row">
        <td colSpan="2"><div>Inhalt</div></td>
      </tr>
    </React.Fragment>
  );
}

function MailGRID  (props) {
  const t = i18n.getFixedT(null, 'mails');
  const [countEmails, setCountEMail] = useState(props.setCountEMail);
  const [id, setid] = useState(props.MailSetting.id);
  const [folderId, setfolderId] = useState(props.FolderID); //'';
  const customDataSource   = props.customDataSource;
  const selectedMail       = props.selectedMail != null ? props.selectedMail.id : null  ;
  const localePath         = props.localePath;


  return <React.Fragment>
    
    <DataGrid
      
      dataRowRender={DataRow()}
      key="id"
      id="id"
      dataSource={customDataSource}
      focusedRowEnabled={true}
      //selectedRowKeys={selectedMail}
      focusedRowKey={selectedMail}
      focusStateEnabled={true}
      showBorders={true}
      hoverStateEnabled={true}
      cacheEnabled={true}
      paging={false}
      Scrolling mode="virtual" 
      stateStoring={true}
      // onSelectionChanged= { onSelectionChanged }
      onSelectionChanged={props.MailSelected}
    //  onRowDblClick={doubleClick}
      onRowPrepared={(rowElement, e) => {
        if (rowElement.rowType === 'data') {
          if (rowElement.data) {
            const isUnread = rowElement.data.unRead
            if (isUnread === true) {
              rowElement.rowElement.classList.add("unread");
            }
            else {
              rowElement.rowElement.classList.remove("unread");
            }
          }
        }
      }}
   

    >
      {/* <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="EntityGrid"
        /> */}
     
      <Editing
        mode="row"
        allowAdding={false}
        allowDeleting={false}
        allowUpdating={false}
      />
      
      <Sorting mode="single" />
      <Selection mode="single"  />
      <ColumnChooser enabled={false} 
  
      />
      
      <Column dataField="id" caption="ID" allowEditing={false} visible={false}> </Column>
      {/* <Column dataField="unRead" caption={t('unRead')}> </Column> */}
      {
        (localePath !== 'Gesendet' && localePath !== 'OUTBOX') &&
        <Column dataField="from[0].last_Name" caption={t('from')} />
      }
      
      <Column 
        dataField="subject" 
        caption={t('subject')}
        calculateCellValue={data => data.subject || t('noMailSubject')}
      > 
      </Column>

      <Column dataField="date" caption={t('receiving_date')} dataType="date" sortOrder="desc" > </Column>


    </DataGrid>

  </React.Fragment>

}; 
export default  React.memo(MailGRID);




