import React, { useState, useRef, useCallback } from 'react';
import { Link, useHistory } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './reset-password-form.scss'
import i18n from "i18next";
import Api from "../../utils/api"

export default function (props) {

  const emailEditorOptions = { stylingMode: 'filled', placeholder: i18n.t('common:email'), mode: 'email' };
  const submitButtonAttributes = { class: 'submit-button' };

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  function handleErrors(response) {
    if (response.status === 200) {
      return response;
    }else {
      throw Error(response.statusText);
    }
  }

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email } = formData.current;
    setLoading(true);

    Api.auth.resetPassword(email)
        .then( handleErrors)
        .then(data => {
          setLoading(false);
          notify(i18n.t('notify:passwordResetOK'), 'success', 2500);
          history.push('/login');
        }).catch(error => {
      setLoading(false);
        notify({
        message: error.message,
        type: "error",
        displayTime: 5000
      });

    });
    // Send reset password request
    // console.log(email);

    // history.push('/login');
    // notify(notificationText, 'success', 2500);
  }, [history]);

  return (
    <form className={'reset-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message={i18n.t('common:requiredInput')} />
          <EmailRule message={i18n.t('common:invalidInput')} />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : i18n.t('auth:btnResetPswd')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            <Link to={'/login'}>
            {i18n.t('auth:returnTo')}
            </Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}


