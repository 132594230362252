import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm, VerifyPage, SetNewPasswordForm } from './components';
import i18n from "i18next";

import {useAuth} from "./contexts/auth";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

  


export default function () {
    const t = i18n.getFixedT(null, 'auth');
    const auth = useAuth()
    
    const msalConfig = {
      auth: {
        clientId: "af2acde3-9c6b-45d1-917f-c2d4e4f24727",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "http://localhost:3000", // Deine Redirect-URI
      },
      cache: {
        cacheLocation: "sessionStorage", // oder "localStorage"
        storeAuthStateInCookie: false, // Setze dies auf true, wenn du ältere Browser unterstützen musst
      },
    };
    const msalInstance = new PublicClientApplication(msalConfig);



  return  auth.isAuthed !== false && localStorage.getItem('authUser') ? <Redirect to={'/Aufgabenliste'} /> : (
    <Switch>
      {/* <Route exact path='/checkMailAdress' >
         <SingleCard title={t('title')}> 
          <CheckMailAdress />
         </SingleCard> 
      </Route> */}
      <Route exact path='/login' >
        <SingleCard title={t('title')}>
          <MsalProvider instance={msalInstance}>
            <LoginForm />
          </MsalProvider>
        </SingleCard>
      </Route>
    <Route exact path='/checkMailAdress' >
        <SingleCard title={t('accountVerify')}>
            <VerifyPage />
        </SingleCard>
    </Route>
      <Route exact path='/create-account' >
        <SingleCard title={t('createAccount')}>
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path='/reset-password' >
        <SingleCard
            title={t('pwReset')}
          description={t('resetPswdDesc')}
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode' >
        <SingleCard title={t('changePswd')}>
          <ChangePasswordForm />
        </SingleCard>
      </Route>

      <Route exact path='/PasswordResetSetNew' >
        <SingleCard title={t('changePswd')}>
          <SetNewPasswordForm />
        </SingleCard>
      </Route>
      {/*<Redirect to={'/login'} />*/}
    </Switch>

    
  );
}
