import React, {useEffect, useState, useRef} from 'react';
import i18n from "i18next";
import './SearchResult.scss';
import {useLocation, useHistory} from 'react-router-dom'
import Api from '../../utils/api'
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { TextBox } from 'devextreme-react/text-box'
import { ScrollView, Tooltip } from 'devextreme-react';
import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';
import { LiaNetworkWiredSolid } from "react-icons/lia";
import CustomStore from "devextreme/data/custom_store";
import { notifySuccess, notifyError } from '../../utils/notifyManager';
import {useAuth} from '../../contexts/auth';
import KontaktListeKomponente from '../../components/Kontaktliste/KontaktListeKomponente';

export default function () {
    
    const c = i18n.getFixedT(null, 'contacts');
    const t = i18n.getFixedT(null, 'taskList');
    const p = i18n.getFixedT(null, 'projectList');
    const location = useLocation()
    const [searchInput, setSearchInput] = useState()
    const history = useHistory()
    const {user} = useAuth()
    
    const [searchPeople, setSearchPeople] = useState([])
    const [searchTasks, setSearchTasks] = useState([])
    const [searchProjects, setSearchProjects] = useState([])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('de-DE').format(date);
    };

    function getParams() {
        const params = new URLSearchParams(location.search)
        let search = params.get('search')
        return { search }
    }

    const networkRequestDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyRequests()
                .then(response => response.data);
        },

        insert: (data) => {
            return Api.people.insertToNetwork(data)
                .then(response => response.data);
        },

        remove: key => {
            return Api.people.deleteRequest(key)
        }
    });

    useEffect(() => {
        const {search} = getParams()
        console.log("Search", search)

        if (search) {
            setSearchInput(search)
        }
    }, [])


    useEffect( () => {
        Api.people.getPeople().then(response => {
            console.log("Personen suche mit: ", searchInput)
            if (searchInput) {
                history.push(`?search=${encodeURIComponent(searchInput)}`);
            }
            let data = response.data;
            let filteredData = data;
            // let filteredData = data.filter(person => {
            //     return (person.first_Name.includes(searchInput) || person.last_Name.includes(searchInput) || person.eMail.includes(searchInput)) && person.id !== user.id;
            // });
            console.log("Filtered Data People", filteredData);
            setSearchPeople(filteredData)
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

        Api.tasks.getTasks().then(response => {
            console.log("Aufgaben suche mit: ", searchInput)
            let data = response.data;
            let filteredData = data.filter(task => {
                return task.people.some(person => 
                    person.member.first_Name.includes(searchInput) || person.member.last_Name.includes(searchInput)
                )
            });
            console.log("Filtered Data Tasks", filteredData);
            setSearchTasks(filteredData)
        }).catch(error => {
            console.error('Error fetching data:', error);
        });

        Api.projects.getProjects().then(response => {
            console.log("Projekte suche mit: ", searchInput)
            let data = response.data;
            let filteredData = data.filter(project => {
                return project.members.some(person =>
                    person.member.first_Name.includes(searchInput) || person.member.last_Name.includes(searchInput)
                )
            });
            console.log("Filtered Data Projects", filteredData);
            setSearchProjects(filteredData)
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [searchInput])

    function handlePeopleClick(obj) {
        history.push(`/profile/${obj.id}`)
    }

    function handleTaskClick(obj) {
        history.push(`/Aufgabe/${obj.id}`)
    }

    function handleProjectClick(obj) {
        history.push(`/projekt/${obj.id}`)
    }

    function handleAddContact(obj) {
        console.log("Vernetzen Button Clicked", obj)
        let contact = {
            "person_MeID": user.id,
            "person_OtherID": obj.id,
            relationshipID: {
                "id": 1
            }
        }
        networkRequestDS.insert(contact)
        .then(res => res.data)
        .then(data => {
            notifySuccess(c('sentRequest'))
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            notifyError(c('failedAddContact'))
        });
    }

    const renderContentNetwork = () => {
        return (
            <p>Mit Kontakt vernetzen</p>
        );
    };

    return (

        <React.Fragment>
            <h2 className={'content-block'}>{t('title')}</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>

                <TextBox 
                    stylingMode='filled'
                    value={searchInput}
                    onValueChanged={({ value }) => setSearchInput(value)}
                    width={window.innerWidth <= 767 ? "100%" : "40%"}
                    buttons={[{
                        name: 'search',
                        location: 'after',
                        options: {
                            icon: 'search',
                            text: 'Durchsuchen',
                            onClick: () => {
                                console.log("Search Button Clicked", searchInput)
                            }
                        }
                    }]}
                />

                <div className={"tab-container"}>
                    <TabPanel>
                        <Item title="Personen" icon="group">
                        <KontaktListeKomponente
                            t={c}
                            filteredData={searchPeople}
                        />
                        </Item>

                        <Item title="Aufgaben" icon="edit">
                        <ScrollView
                            height={'52vh'}
                            width={'auto'}
                            direction="vertical"
                        >
                            <section className="section" style={{paddingTop: '20px'}}>
                            <div className="container">
                                <div className="b-table">
                                <div className="table-wrapper has-mobile-cards">
                                    <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                        <th>{t('subject')}</th>
                                        <th>{t('start')}</th>
                                        <th>{t('end')}</th>
                                        <th>{t('status')}</th>
                                        </tr>
                                    </thead>
                                    <tbody id='tbody'>
                                        {searchTasks.map((obj) => (
                                        <tr key={obj.id} onClick={() => handleTaskClick(obj)} style={{cursor: 'pointer'}}>
                                            <td className='zelle' data-label={t('subject')}>{obj.subject}</td>
                                            <td data-label={t('start')}>{formatDate(new Date (obj.start))}</td>
                                            <td data-label={t('end')}>{formatDate(new Date (obj.end))}</td>
                                            <td data-label={t('status')}>{obj.status_Procent}%</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                            </section>
                        </ScrollView>
                        </Item>

                        <Item title="Projekte" icon="paste">
                        <ScrollView
                            height={'52vh'}
                            width={'auto'}
                            direction="vertical"
                        >
                            <section className="section" style={{paddingTop: '20px'}}>
                            <div className="container">
                                <div className="b-table">
                                <div className="table-wrapper has-mobile-cards">
                                    <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                        <th>{p('subject')}</th>
                                        <th>{p('start')}</th>
                                        <th>{p('end')}</th>
                                        <th>{p('status')}</th>
                                        </tr>
                                    </thead>
                                    <tbody id='tbody'>
                                        {searchProjects.map((obj) => (
                                        <tr key={obj.id} onClick={() => handleProjectClick(obj)} style={{cursor: 'pointer'}}>
                                            <td className='zelle' data-label={p('subject')}>{obj.subject}</td>
                                            <td data-label={p('start')}>{formatDate(new Date (obj.start))}</td>
                                            <td data-label={p('end')}>{formatDate(new Date (obj.end))}</td>
                                            <td data-label={p('status')}>{obj.status_Procent}%</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                            </section>
                        </ScrollView>
                        </Item>
                    </TabPanel>
                </div>
                </div>
            </div>


        </React.Fragment>
    );




};
