import React from "react";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import i18n from "i18next";
import {useHistory} from 'react-router-dom'

function test(e){
    console.log("Button New");
}

export default (props) => {
const t = i18n.getFixedT(null, 'mails');

const createMailButtonOptions = {
    icon: 'plus',
    text: t('CreateNewMail'),
    //onClick: () => history.push(`/Aufgabe`, {mail: selectedMail })
   // onClick: () => test()
   onClick: () => props.handleButtonNewMail()
};

const createTaskButtonOptions = {
    icon: 'export',
    text: t('btnMail2Task'),
    //onClick: () => history.push(`/Aufgabe`, {mail: selectedMail })
    disabled: props.selectedMail == null
};
const replyButtonOptions = {
    icon: 'revert',
    text: t('btnReply'),
    // onClick: this.sendEmail.bind(this),
    disabled: props.selectedMail == null,
    onClick: () => props.handleButtonAnserMail()
};
const forwardButtonOptions = {
    icon: 'redo',
    text: t('btnForward'),
    // onClick: this.sendEmail.bind(this),
    disabled: props.selectedMail == null,
    onClick: () => props.handleButtonForwardMail()
    
};
const deleteButtonOptions = {
    icon: 'remove',
    text: t('btnDelete'),
    // onClick: this.sendEmail.bind(this),
    disabled: props.selectedMail == null
};



return <React.Fragment>
<Toolbar>

    <Item location="before"
        locateInMenu="auto"
        widget="dxButton"
        options={replyButtonOptions}
    />
    <Item location="before"
        locateInMenu="auto"
        widget="dxButton"
        options={deleteButtonOptions} 
    />
    <Item location="before"
        locateInMenu="auto"
        widget="dxButton"
        options={createTaskButtonOptions}
    />
    <Item location="before"
        locateInMenu="auto"
        widget="dxButton"
        options={forwardButtonOptions} 
    />

</Toolbar>

</React.Fragment>

};