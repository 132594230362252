import React from 'react';
import appInfo from './app-info';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import "./content.scss"

export default function(props) {
  return (
    <SideNavBarLayout title={appInfo.title}>
        {props.children}
          <Footer>
              Copyright MaProTa©
              <a href="https://www.maprota.com/de/impressum/" target="_blank" className='footer-impressum'>Impressum </a> 
              <a href="https://www.maprota.com/de/datenschutzerklaerung/" target="_blank" className='footer-datenschutz'>Datenschutz & AGB </a>
              <div className="version-right">{appInfo.Versionnr}</div>

      </Footer>
    </SideNavBarLayout>
  );
}
