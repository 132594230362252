// src/DataGridCards.js
import '../../../components/bulma-tabel-css/bulma.min.css';
import '../../../components/bulma-tabel-css/main.min.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Checkbox, Divider } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MdDelete } from 'react-icons/md';
import { FaArrowUpAZ, FaArrowDownZA, FaArrowUp19, FaArrowDown91, FaArrowUpShortWide, FaArrowDownShortWide } from "react-icons/fa6";

const AufgabenMobile = ({p, Aufgaben, buttonVisible, setDoDeleteTaskID}) => {

  const history = useHistory();
  const formatDate = (date) => {
    const d = new Date(date),
      day = ('0' + d.getDate()).slice(-2),
      month = ('0' + (d.getMonth() + 1)).slice(-2),
      year = d.getFullYear();
      
      return [day, month, year].join('.');
  };
  const [opened, { open, close }] = useDisclosure(false);
  const [checkedRemoveFromProject, setCheckedRemoveFromProject] = useState(false);
  const [checkedRemoveCompletely, setCheckedRemoveCompletely] = useState(false);
  const [sichtbareAufgaben, setSichtbareAufgaben] = useState([]);
  const [taskId, setTaskId] = useState(null);

  const performAction = () => {
    if (checkedRemoveFromProject) {
      console.log("Aufgabe wurde aus dem Projekt entfernt");
      const index = Aufgaben.findIndex((task) => task.id === taskId);
       if (index !== -1) {
        Aufgaben.splice(index, 1);
        const neueSichtbareAufgaben = sichtbareAufgaben.filter((task) => task.id !== taskId);
        setSichtbareAufgaben(neueSichtbareAufgaben);
        close();
        console.log("Neues Array: ", Aufgaben); // Array wird korrekt aktualisiert, jedoch wird die Änderung nicht gespeichert.
      }
    }
    if (checkedRemoveCompletely) {
      console.log("Aufgabe wurde zum Entfernen aus der Aufgabenliste markiert");
      setDoDeleteTaskID(taskId);
      const index = Aufgaben.findIndex((task) => task.id === taskId);
      if (index !== -1) {
        Aufgaben.splice(index, 1);
        const neueSichtbareAufgaben = sichtbareAufgaben.filter((task) => task.id !== taskId);
        setSichtbareAufgaben(neueSichtbareAufgaben);
      }
      close();
    }
    // Checkboxen zurücksetzen
    setCheckedRemoveFromProject(false);
    setCheckedRemoveCompletely(false);
  };

  function doCancel() {
    setCheckedRemoveCompletely(false);
    setCheckedRemoveFromProject(false);
    close();
  };
  
  // useState für Sortierreihenfolge
  const [istAufgabeAufsteigend, setIstAufsteigend] = useState(true);
  const [istStartAufsteigend, setIstStartAufsteigend] = useState(true);
  const [istEndeAufsteigend, setIstEndeAufsteigend] = useState(true);
  const [istStatusAufsteigend, setIstStatusAufsteigend] = useState(true);

  // Funktion zum Wechseln der Sortierreihenfolge Aufgabenname
  const toggleSortierReihenfolgeName = () => {
    setIstAufsteigend(!istAufgabeAufsteigend);
    Aufgaben.sort((a, b) => istAufgabeAufsteigend ? b.subject.localeCompare(a.subject) : a.subject.localeCompare(b.subject));
  };

  // Funktion zum Wechseln der Sortierreihenfolge Start
  const toggleSortierReihenfolgeStart = () => {
    setIstStartAufsteigend(!istStartAufsteigend);
    Aufgaben.sort((a, b) => istStartAufsteigend ? b.start.localeCompare(a.start) : a.start.localeCompare(b.start));
  };

  // Funktion zum Wechseln der Sortierreihenfolge Ende
  const toggleSortierReihenfolgeEnde = () => {
    setIstEndeAufsteigend(!istEndeAufsteigend);
    Aufgaben.sort((a, b) => istEndeAufsteigend ? b.end.localeCompare(a.end) : a.end.localeCompare(b.end));
  };

  // Funktion zum Wechseln der Sortierreihenfolge Status
  const toggleSortierReihenfolgeStatus = () => {
    setIstStatusAufsteigend(!istStatusAufsteigend);
    Aufgaben.sort((a, b) => istStatusAufsteigend ? b.status_Procent - a.status_Procent : a.status_Procent - b.status_Procent);
  };

  // Sortiere die Aufgabenliste beim Initialisieren
  useEffect(() => {
    Aufgaben.sort((a, b) => a.subject.localeCompare(b.subject));
  }, []);


  return (
    <>
      <section style={{paddingTop: '20px'}}> {/* padding 48px */}
        <div className="container">
          <div className="b-table">
            <div className="table-wrapper has-mobile-cards">
              <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
              <thead>
                    <tr style={{fontSize: '16px'}}>
                      <th>
                        {(p('Aufgabe'))}
                        {istAufgabeAufsteigend ? <FaArrowUpAZ onClick={toggleSortierReihenfolgeName} style={{cursor: 'pointer'}}/> : <FaArrowDownZA onClick={toggleSortierReihenfolgeName} style={{cursor: 'pointer'}} />}
                      </th>
                      <th>
                        {(p('Start'))}
                        {istStartAufsteigend ? <FaArrowUpShortWide onClick={toggleSortierReihenfolgeStart} style={{cursor: 'pointer'}}/> : <FaArrowDownShortWide onClick={toggleSortierReihenfolgeStart} style={{cursor: 'pointer'}} />}
                      </th>
                      <th>
                        {(p('Ende'))}
                        {istEndeAufsteigend ? <FaArrowUpShortWide onClick={toggleSortierReihenfolgeEnde} style={{cursor: 'pointer'}}/> : <FaArrowDownShortWide onClick={toggleSortierReihenfolgeEnde} style={{cursor: 'pointer'}} />}
                      </th>
                      <th>
                        {(p('Status'))}
                        {istStatusAufsteigend ? <FaArrowUp19 onClick={toggleSortierReihenfolgeStatus} style={{cursor: 'pointer'}}/> : <FaArrowDown91 onClick={toggleSortierReihenfolgeStatus} style={{cursor: 'pointer'}} />}
                      </th>
                      <th>{/* Buttons */}</th>
                    </tr>
              </thead>
                <tbody style={{fontSize: '14px', content: 'auto'}}>
                  {Aufgaben.map((task) => (
                      <tr key={task.id}>
                      {/* <td data-label={p('ID')}>{task.id}</td> */} {/* ID wird nicht angezeigt, da besser anzusehen */}
                          <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label={p('subject')} onClick={() => history.push(`/Aufgabe/${task.id}`)}>{task.subject}</td>
                          <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label={p('start')} onClick={() => history.push(`/Aufgabe/${task.id}`)}>{formatDate(task.start)}</td>
                          <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label={p('end')} onClick={() => history.push(`/Aufgabe/${task.id}`)}>{formatDate(task.end)}</td>
                          <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label={p('status')} onClick={() => history.push(`/Aufgabe/${task.id}`)}>{`${task.status_Procent}%`}</td>
                          <td class="is-actions-cell">
                          {buttonVisible && (
                          <div className="buttons is-right" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant="filled" color="red" size='/' onClick={() => { setTaskId(task.id); open(); }} style={{width: '34px'}}>
                            <MdDelete style={{height: '20px', width: '20px'}}/>
                            </Button>
                          </div>
                          )}
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <Modal opened={opened} onClose={close} title= {<h5 className="modal-title">Aufgabe löschen</h5>} centered size='lg' withCloseButton={false} closeOnClickOutside={false}>
      <Divider margins="xs" />
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px', marginBottom: '40px'}}>
          <p>
            Bitte wählen Sie aus, ob Sie das Projekt aus dem Projekt entfernen möchten 
            oder ob es vollständig aus Ihrer Aufgabenliste entfernt werden soll.
          </p>

          <div style={{marginTop: '20px'}}>
            
            <div style={{marginBottom: '10px'}}>
              <Checkbox 
                label="Aufgabe aus dem Projekt entfernen"
                color= 'green'
                checked={checkedRemoveFromProject}
                onChange={e => setCheckedRemoveFromProject(e.target.checked)}
              />
            </div>

            <Checkbox 
            label="Aufgabe aus der Aufgabenliste vollständig entfernen"
            color= 'green'
            checked={checkedRemoveCompletely}
            onChange={e => setCheckedRemoveCompletely(e.target.checked)}
            />
            
          </div>

        </div>

        <div style={{display: 'flex', justifyContent: 'flex-end'}}>

          <div style={{marginRight: '10px'}}>
            <Button color='red' 
              onClick={doCancel}>Abbrechen</Button>
          </div>

          <Button
           onClick={performAction}
           styles={(theme) => ({
            root: {
              backgroundColor: '#39b201',
              color: 'white',
              '&:hover': {
                backgroundColor: '#39b201',
                color: 'white',
              },
            }})}
          >Durchführen</Button>
        </div>
      </Modal>
    </>
  );
}

export default AufgabenMobile;