import React, { useState } from 'react';
import './postCreate.css'; // Importiere CSS für Stile
import i18n from "i18next";
import { Button } from 'devextreme-react';

function PostCreate({ onCreatePost }) {

    const t = i18n.getFixedT(null, 'Feed');

    // const [profilePic, setProfilePic] = useState('');
    // const [userName, setUserName] = useState('');
    // const [timePosted, setTimePosted] = useState('');
    // const [postText, setPostText] = useState('');
    // const [postImage, setPostImage] = useState('');
    // const [likes, setLikes] = useState('');
    // const [comments, setComments] = useState('');
    // const [shares, setShares] = useState('');

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Validierung oder Datenverarbeitung hier
    //     const newPost = {
    //         profilePic,
    //         userName,
    //         timePosted,
    //         postText,
    //         postImage,
    //         likes: parseInt(likes, 10),
    //         comments: parseInt(comments, 10),
    //         shares: parseInt(shares, 10),
    //     };

        // Callback-Funktion aufrufen, um den neuen Post zu erstellen
    //    onCreatePost(newPost);

        // Formular zurücksetzen
        // setProfilePic('');
        // setUserName('');
        // setTimePosted('');
        // setPostText('');
        // setPostImage('');
        // setLikes('');
        // setComments('');
        // setShares('');
  //  };

  const [text, setText] = useState("");

  // Funktion, die auf Tastaturereignisse reagiert
  const handleKeyDown = (event) => {
      console.log(`Key pressed: ${event.key}`); // Zeigt den gedrückten Schlüssel im Konsolenprotokoll an

      // Hier kannst du zusätzliche Logik hinzufügen, z.B. bestimmte Tastenaktionen verarbeiten
      if (event.key === "Enter") {
          console.log("Enter key was pressed!");
          // Füge hier deine spezifische Logik für das Drücken der Enter-Taste ein
      }
  };



    return (
        <div className="posts-container">
        <div class="post-box">
        <textarea placeholder={t('txtPlaceholder') } 
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={handleKeyDown} // Event-Handler hinzufügen
         ></textarea>
        
        <div class="actions">
        { text.length < 1  && (  <Button icon="plus"
                        className='myButton'
                        text={t('btnCreatePost')}
                        onClick={onCreatePost}  disabled={true} />
        )}

        { text.length > 0  && (
            <Button icon="plus"
            className='myButton'
            text={t('btnCreatePost')}
            onClick={onCreatePost} />
        )} 
        


        </div>
       
    </div>
 
    </div>
    );
}

export default PostCreate;
