import React, { useState, useEffect } from 'react';
import { Group, Button, FileButton, Modal, Box, ScrollArea } from '@mantine/core';
import { MdUploadFile, MdClose } from "react-icons/md";
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import notify from 'devextreme/ui/notify'
import Api from '../../utils/api';
import CustomStore from 'devextreme/data/custom_store';

import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';

function handleErrors(response) {
    if (!response.ok) {
        console.log('Fehler DS' + response.statusText);
        throw Error(response.statusText);
    } else {
        for (var pair of response.headers.entries()) {
            console.log('LARS' + pair[0] + ': ' + pair[1]);
        }
    }
    return response;
}

const AddDokuments = ({p}) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [previewModalOpened, setPreviewModalOpened] = useState(false); // Neuer State für das Vorschaumodal
    const [files, setFiles] = useState([])
    const [previewUrl, setPreviewUrl] = useState(null)
    const [isPDF, setIsPDF] = useState(false)
    const location = useLocation();
    const [archiveBusinessObjektType, setArchiveBusinessObjektType] = useState(location.pathname.split('/')[1]);
    const [BussinesObjektID, setBusinessObjektID] = useState(location.pathname.split('/').pop())

    useEffect(() => {
        switch (archiveBusinessObjektType) {
            case 'Aufgabe':
                setArchiveBusinessObjektType('Task');
            break;
            case 'projekt':
                setArchiveBusinessObjektType('Project');
            break;
        default:
            setArchiveBusinessObjektType(location.pathname.split('/')[1]);
        }
    }, [])


    useEffect(() => {
        console.log("Hier das Businesstype: ", archiveBusinessObjektType)
        console.log("Hier die Location: ", BussinesObjektID)
        console.log("Dokuemnt: ", files)
    }, [archiveBusinessObjektType, BussinesObjektID, files])

    const openPreview = (file) => {
        const url = URL.createObjectURL(file);
        setPreviewUrl(url);
        setPreviewModalOpened(true); // Öffnen des Vorschaumodals
    };

    const closePreview = () => {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
        setPreviewModalOpened(false); // Schließen des Vorschaumodals
    };

    const DocDS = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        insert: (data) => {
            Api.Dokuments.createDokument(data, data.file)
                .then(handleErrors)
                .catch((handleErrors) => {
                    throw 'Network error'+handleErrors
                });
        }
    });


    async function saveDocuments() {
        if (files.length === 0) return;

        // Aktualisiere die Initialisierung von `data` hier, um die neuesten Werte zu erfassen
        let data = {
            "ObjektID": BussinesObjektID,
            "ObjektType": archiveBusinessObjektType,
            'file' : files[0]
        }; 
        console.log("data: ", data);
        try {
          //  const response = await Api.Dokuments.createDokument(data, formData);
          DocDS.insert(data)
          .then(
              (dataObj) => {
                  notify('Daten wurden gespeichert')
              },
              (error) => {
                  notify('Fehler beim Update' + error)
              }
          );
            
          //  console.log("Response: ", response);
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    function cancelSave() {
        console.log("Dokumente abbrechen")
        setFiles([])
        close()
    }

    return (
        <>
            <Group position="right">
                <Button 
                    className='myButton' 
                    rightIcon={<MdUploadFile size={24} />} 
                    onClick={() => open()}
                >
                    Dokument hinzufügen
                </Button>
            </Group>

            <Modal 
                opened={opened} 
                onClose={close} 
                centered
                closeOnClickOutside={false}
                withCloseButton={false}
                size='auto'
            >
                <Group position="center">
                    <FileButton 
                        onChange={(newFiles) => setFiles((currentFiles) => [...currentFiles, ...newFiles])} 
                        accept="*/*" 
                        multiple
                    >
                        {(props) => <Button {...props} rightIcon={<MdUploadFile size={24} />} className='myButton' >Datei hochladen</Button>}
                    </FileButton>
                </Group>

                <ScrollArea h={300} w={600}>
                    <Box
                        h={300}
                        w={600}
                    >
                        <div>
                            <div className="b-table">
                                <div className="table-wrapper has-mobile-cards">
                                    <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                        <thead style={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: 1 }}>
                                            <tr>
                                                <th>Dateiname</th>
                                                <th>Typ</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {files.map((file, index) => (
                                                <tr
                                                    key={index}
                                                    onClick={() => {
                                                        if (file.type.startsWith('image/') || file.type === 'application/pdf') {
                                                            openPreview(file);
                                                            if (file.type === 'application/pdf') {
                                                            setIsPDF(true);
                                                            }
                                                        }
                                                    }}
                                                    style={{ cursor: (file.type.startsWith('image/') || file.type === 'application/pdf') ? 'pointer' : 'default' }}
                                                >
                                                    <td>{file.name}</td>
                                                    <td>{file.type}</td>
                                                    <td>
                                                        <Button color="red" size="xs" onClick={(e) => { e.stopPropagation(); setFiles((currentFiles) => currentFiles.filter((_, i) => i !== index)); }}>
                                                            Remove
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ScrollArea>

                <Group position="right">
                    <div style={{ marginTop: '10px', gap: '5px', display: 'flex' }}>
                        <Button color='red' onClick={() => cancelSave()}>Abbrechen</Button>
                        <Button className='myButton' onClick={() => saveDocuments()}>Speichern</Button>
                    </div>
                </Group>
            </Modal>

            {previewModalOpened && !isPDF && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.85)',
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img src={previewUrl} alt="Vorschau" style={{ maxWidth: '80%', maxHeight: '80%' }} />
                    <Button
                        variant="filled"
                        color="red"
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                        onClick={closePreview}
                    >
                        <MdClose size={24} />
                    </Button>
                </div>
            )}

            {previewModalOpened && isPDF && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.85)',
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <iframe src={previewUrl} title="PDF Vorschau" style={{ width: '80%', height: '80%' }}></iframe>
                    <Button
                        variant="filled"
                        color="red"
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                        onClick={closePreview}
                    >
                        <MdClose size={24} />
                    </Button>
                </div>
            )}
        </>
    )
}
export default AddDokuments;