import React, {useEffect, useState, useRef} from "react";
import Api from "../../utils/api";
import { MdDelete } from "react-icons/md";
import { ScrollView } from "devextreme-react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {
    DataGrid,
    Column,
    FilterRow,
    Selection
} from 'devextreme-react/data-grid';
import DropDownButton from 'devextreme-react/drop-down-button';
import themes from 'devextreme/ui/themes';

const AufgabenlisteEigeneTabelle = (props) => {

    const selectedList = props.selectedList;
    const allRoles = props.allRoles;
    const renderMembers = props.renderMembers;
    const handleClick = props.handleClick;
    const formatDate = props.formatDate;
    const t = props.t;
    const open = props.open;
    const setModalData = props.setModalData;
    const location = useLocation();
    const setAktuelleFilterBedingung = props.setAktuelleFilterBedingung;
    const neuerFilter = props.filteredData;
    const setFilterBedingungAusEigenerTabelle = props.setFilterBedingungAusEigenerTabelle;
    const setBedingungenFürPopup = props.setBedingungenFürPopup;
    const ausgewählteSpalten = props.ausgewählteSpalten;
    const setAusgewählteSpalten = props.setAusgewählteSpalten;

    const [myID, setMyID] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [filter, setFilter] = useState([]);
    const [dataArray, setDataArray] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const dataGridRef = useRef(null);
    const [checkBoxesMode, setCheckBoxesMode] = useState(
        themes.current().startsWith('material') ? 'always' : 'onClick',
    );
    const [allMode, setAllMode] = useState('allPages');
    const [myFullName, setMyFullName] = useState(null);

    useEffect(() => {
        console.log("Ausgewählte Spalten: ", ausgewählteSpalten);
    }, [ausgewählteSpalten]);

    useEffect(() => {
        if (neuerFilter) {
            setFilteredData(neuerFilter);
        }
    }, [neuerFilter]);

    useEffect(() => {
        Api.getMe().then(response => {
            const id = response.data.id;
            console.log("NutzerID:", id);
            setMyID(id);
            setMyFullName(response.data.fullname)
            // Nachdem die NutzerID gesetzt wurde, Tasks abrufen
            Api.tasks.getTasks().then(response => {
                setTasks(response.data);
            }).catch(error => {
                console.error(error);
            });

            Api.OptionClient.getOptions().then(response => {
                const data = response.data;
                const Aufgabenfilter = data.filter((option) => option.name === `Aufgabenfav_${id}`);
                if (Aufgabenfilter.length !== 0) {
                    const AlleFilter = JSON.parse(Aufgabenfilter[0].option);
                    setFilter(AlleFilter);
                }
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        const pathTeile = location.pathname.split('/');
        if (pathTeile.length <= 2) {
            return;
        }
        const AktuellerPfad = pathTeile.slice(2).join('/');

        const SucheAktuellernFilter = filter?.filter((filterString) => filterString.filterName === AktuellerPfad);
        const AktuellerFilter = SucheAktuellernFilter[0];
        console.log("AktuellerFilter: ", AktuellerFilter);
            if (AktuellerFilter?.spaltenwahl) {
                console.log("Spaltenauswahl: ", AktuellerFilter.spaltenwahl);
                setAusgewählteSpalten(AktuellerFilter.spaltenwahl);
            } else {
                setAusgewählteSpalten([]);
            }
            if (AktuellerFilter?.filterBedingung === null) {
                let filteredData = [];
                switch (AktuellerFilter?.filterName) {
                    case 'Meine offenen Vorgänge':
                        filteredData = tasks.filter(task => task.people.some(person => person.rolle.id === 3 && person.member.id === myID) && task.status_Procent < 100);
                        const filterBedingungMeineOffeneVorgänge = [
                            ["Bearbeiter (C)", "=", myFullName],
                            "and",
                            ["Status", "<", 100]
                        ];
                        setFilterBedingungAusEigenerTabelle(filterBedingungMeineOffeneVorgänge);
                        setBedingungenFürPopup(filterBedingungMeineOffeneVorgänge);
                        break;
                    case 'Von mir erstellt':
                        filteredData = tasks.filter(task => task.people.some(person => person.rolle.id === 1 && person.member.id === myID));
                        const filterBedingungVonMirErstellt = [
                            ["Ersteller (R)", "=", myFullName],
                        ];
                        setFilterBedingungAusEigenerTabelle(filterBedingungVonMirErstellt);
                        setBedingungenFürPopup(filterBedingungVonMirErstellt);
                        break;
                    case 'Alle Vorgänge':
                        filteredData = tasks;
                        setFilterBedingungAusEigenerTabelle(null);
                        break;
                    case 'Offene Vorgänge':
                        filteredData = tasks.filter(task => task.status_Procent < 100);
                        const filterBedingungOffeneVorgänge = [
                            [t('status'), "<", 100],
                        ];
                        setFilterBedingungAusEigenerTabelle(filterBedingungOffeneVorgänge);
                        setBedingungenFürPopup(filterBedingungOffeneVorgänge);
                        break;
                    case 'Vor kurzem geändert':
                        let eineWocheZurueck = new Date();
                        eineWocheZurueck.setDate(eineWocheZurueck.getDate() - 7);
                        filteredData = tasks.filter(task => {
                            let lastUpdate = new Date(task.lastUpdate);
                            return lastUpdate >= eineWocheZurueck;
                        });
                        setFilterBedingungAusEigenerTabelle(null);
                        break;
                    case 'Kürzlich erledigt':
                        let eineWocheZurueckErledigt = new Date();
                        eineWocheZurueckErledigt.setDate(eineWocheZurueckErledigt.getDate() - 7);
                        filteredData = tasks.filter(task => {
                            let lastUpdate = new Date(task.lastUpdate);
                            return lastUpdate >= eineWocheZurueckErledigt && task.status_Procent === 100;
                        });
                        setFilterBedingungAusEigenerTabelle(null);
                        break;

                    case 'Vor kurzem erstellt':
                        let eineWocheZurueckErstellt = new Date();
                        eineWocheZurueckErstellt.setDate(eineWocheZurueckErstellt.getDate() - 7);
                        filteredData = tasks.filter(task => {
                            let lastCreated = new Date(task.created);
                            return lastCreated >= eineWocheZurueckErstellt;
                        });
                        const vorSiebenTagen = new Date();
                        vorSiebenTagen.setDate(vorSiebenTagen.getDate() - 7);

                        const datumVorSiebenTagen = vorSiebenTagen.toLocaleDateString('de-DE');

                        const vorUnterEinerWocheErstellt = [
                            [t('created'), "<=", datumVorSiebenTagen], //Übersetzung noch hinzufügen
                        ];
                        setFilterBedingungAusEigenerTabelle(vorUnterEinerWocheErstellt);
                        break;
                    default: //Falls kein Filter zutrifft, wird die Liste leer angezeigt
                        filteredData = [];
                        break;
                }
                if (filteredData.length !== 0) {
                    setDataArray(filteredData);
                    setFilteredData(filteredData);
                } else {
                    setDataArray(null);
                    setFilteredData(null);
                }
            }else {
                let filteredData = [];
                const SucheFilterBedingung = filter?.filter((filterString) => filterString.filterName === AktuellerPfad);
                const gefundeneFilterBedingung = SucheFilterBedingung[0];
                const aktuelleFilterBedingung = gefundeneFilterBedingung?.filterBedingung;
                if (aktuelleFilterBedingung === null) {
                    return;
                }
                console.log("FilterBedingungArray:", aktuelleFilterBedingung);
                setFilterBedingungAusEigenerTabelle(aktuelleFilterBedingung);
                setAktuelleFilterBedingung(aktuelleFilterBedingung); // {object: "", operation: "", value: ""}

                const fieldMap = {
                    [t('subject')]: 'subject',
                    [t('start')]: 'start',
                    [t('end')]: 'end',
                    [t('status')]: 'status_Procent',
                    [t('followUp')]: 'follow_up',
                    [t('company')]: 'company',
                    [t('department')]: 'department',
                    [t('created')]: 'created'
                };
            
                const isDate = (value) => value instanceof Date;
                const isString = (value) => typeof value === 'string';
                const isNumber = (value) => typeof value === 'number';
            
                // Funktion zum Entfernen der Zeitkomponente
                const stripTime = (date) => {
                    const newDate = new Date(date);
                    newDate.setHours(0, 0, 0, 0);
                    return newDate;
                };
            
                const evaluateCondition = (task, condition) => {
                    const [field, operation, value] = condition;
                    const mappedField = fieldMap[field] || field;
                    const taskValue = task[mappedField];
                    
                    let compareValue = value;
                    let taskCompareValue = taskValue;
                    
                    if (field === 'Ersteller (R)' || field === 'Bearbeiter (C)' || field === 'Gesamtverantwortlicher (A)' || field === 'Beobachter (I)') {
                        return evaluateRoleFilter(task, field, operation, value);
                    }
                    
                    if (isString(value) && !isNaN(Date.parse(value))) {
                        compareValue = stripTime(new Date(value)).getTime();
                        taskCompareValue = stripTime(new Date(taskValue)).getTime();
                    } else if (isDate(value)) {
                        compareValue = stripTime(value).getTime();
                        taskCompareValue = stripTime(new Date(taskValue)).getTime();
                    } else if (isString(value)) {
                        compareValue = value.toLowerCase();
                        taskCompareValue = taskValue.toLowerCase();
                    } else if (isNumber(value)) {
                        compareValue = Number(value);
                        taskCompareValue = Number(taskValue);
                    }
                    
                    switch (operation) {
                        case '=':
                            return taskCompareValue === compareValue;
                        case '!=':
                        case '<>':
                            return taskCompareValue !== compareValue;
                        case '<':
                            return taskCompareValue < compareValue;
                        case '<=':
                            return taskCompareValue <= compareValue;
                        case '>':
                            return taskCompareValue > compareValue;
                        case '>=':
                            return taskCompareValue >= compareValue;
                        case 'contains':
                            return isString(taskCompareValue) && taskCompareValue.includes(compareValue);
                        case 'notcontains':
                            return isString(taskCompareValue) && !taskCompareValue.includes(compareValue);
                        case 'startswith':
                            return isString(taskCompareValue) && taskCompareValue.startsWith(compareValue);
                        case 'endswith':
                            return isString(taskCompareValue) && taskCompareValue.endsWith(compareValue);
                        default:
                            return false;
                    }
                };        
            
                const evaluateRoleFilter = (task, field, operation, value) => {
                    return task.people.some(person => {
                        if (field === 'Ersteller (R)') {
                            return evaluateRoleCondition(person, 'Ersteller (R)', operation, value);
                        } else if (field === 'Bearbeiter (C)') {
                            return evaluateRoleCondition(person, 'Bearbeiter (C)', operation, value);
                        } else if (field === 'Gesamtverantwortlicher (A)') {
                            return evaluateRoleCondition(person, 'Gesamtverantwortlicher (A)', operation, value);
                        } else if (field === 'Beobachter (I)') {
                            return evaluateRoleCondition(person, 'Beobachter (I)', operation, value);
                        }
                        return false;
                    });
                };
            
                const evaluateRoleCondition = (person, roleType, operation, value) => {
                    const roleName = person.rolle.name;
                    const fullName = `${person.member.first_Name} ${person.member.last_Name}`;
                    switch (operation) {
                        case '=':
                            return roleName === roleType && fullName === value;
                        case '!=':
                        case '<>':
                            return roleName === roleType && fullName !== value;
                        case 'contains':
                            return roleName === roleType && fullName.includes(value);
                        case 'notcontains':
                            return roleName === roleType && !fullName.includes(value);
                        case 'startswith':
                            return roleName === roleType && fullName.startsWith(value);
                        case 'endswith':
                            return roleName === roleType && fullName.endsWith(value);
                        default:
                            return false;
                    }
                };
            
                const evaluateFilter = (task, filter) => {
                    if (Array.isArray(filter[0])) {
                        let result = evaluateCondition(task, filter[0]); // Initiale Bedingung evaluieren
                        for (let i = 1; i < filter.length; i += 2) {
                            const logicalOperator = filter[i];
                            const nextCondition = filter[i + 1];
                            if (logicalOperator.toLowerCase() === 'or') {
                                result = result || evaluateCondition(task, nextCondition);
                            } else if (logicalOperator.toLowerCase() === 'and') {
                                result = result && evaluateCondition(task, nextCondition);
                            }
                        }
                        return result;
                    } else {
                        return evaluateCondition(task, filter);
                    }
                };
            
                const filteredTasks = tasks.filter(task => evaluateFilter(task, aktuelleFilterBedingung));
            
                setFilteredData(filteredTasks);
                setDataArray(filteredTasks);
            }
    }, [myID,location, filter, tasks]);

    useEffect(() => {
        console.log("selectedFilter: ", selectedFilter);
  
        if (selectedFilter && typeof selectedFilter === 'object' && selectedFilter !== null) {
          const arrayNames = Object.keys(selectedFilter);
          console.log("Array Names: ", arrayNames);
  
          let tempArray = []; // Gemeinsames tempArray
  
          // Überprüfen, ob irgendein Schlüssel nicht "subject", "start", "end" oder "company" ist
          if (arrayNames.some(name => !["subject", "start", "end", "status_Procent", "follow_up", "company"].includes(name))) {
            for (let i = 0; i < arrayNames.length; i++) {
              for (let j = 0; j < selectedFilter[arrayNames[i]].length; j++) {
                const selectedValue = selectedFilter[arrayNames[i]][j]; // Der Wert des j-ten Elements im i-ten Array in selectedFilter
                console.log('selectedValue: ', selectedValue);
                console.log('arrayNames[i]: ', arrayNames[i]);
  
                const filter = dataArray.filter(obj => {
                  obj.people.some(member => {
                    if (member.rolle.name === arrayNames[i] && (member.member.first_Name + ' ' + member.member.last_Name) === selectedValue) {
                      tempArray.push(obj);
                    }
                  });   
                })
              }
            }
          } 
          
          if (arrayNames.some(name => ["subject", "start", "end", "status_Procent", "follow_up", "company"].includes(name))) {
              for (let i = 0; i < arrayNames.length; i++) {
                  for (let j = 0; j < selectedFilter[arrayNames[i]].length; j++) {
                      const selectedValue = selectedFilter[arrayNames[i]][j]; // Der Wert des j-ten Elements im i-ten Array in selectedFilter
  
                      const filtered = dataArray.filter(obj => obj[arrayNames[i]] === selectedValue);
                      console.log("FilterAufgaben: ", filtered);
                      tempArray.push(...filtered);
                  }
              }
          }
          console.log("TempArray: ", tempArray);
          if (tempArray.length === 0) {
            setDataArray(dataArray);
          } else {
            setFilteredData([...tempArray]);
          }
        }
    }, [selectedFilter]);

    const DropDownButtonOptions = {
        width: 250,
    };

    const dropDownContentRender = (key) => {

        const uniqueValues = Array.from(new Set(dataArray.filter(obj => obj.hasOwnProperty(key)).map(obj => obj[key])))
        .filter(value => value !== null)
        .sort((a, b) => a - b)
        .map(value => ({ [key]: value }));
  
        return (
          <div>
            {/* <ScrollView
              height={400}
              direction="both"
            > */}
              <DataGrid
                ref={dataGridRef}
                dataSource={uniqueValues.map(obj => ({ ...obj }))}              
                keyExpr={key}
                showBorders={true}
                onSelectionChanged={(e) => {
                  const keysObject = {[key]: e.selectedRowKeys };
                  console.log("KeysObject: ", keysObject);
                  if (selectedFilter && Object.keys(selectedFilter).length > 0) {
                      setSelectedFilter({...selectedFilter, ...keysObject});
                  } else {
                      setSelectedFilter(keysObject);
                  }
                  if (e.selectedRowsData.length === 0) {
                    setFilteredData(dataArray);
                  }
                }}
              >
                <Selection mode="multiple" selectAllMode={allMode} showCheckBoxesMode={checkBoxesMode}/>
                <FilterRow visible={true} />
                <Column 
                  dataField={key} 
                  dataType={key === "start" || key === "end" ? "date" : undefined}
                  cellTemplate={(cellElement, cellInfo) => {
                    if (key === "start" || key === "end" || key === "follow_up") {
                      const date = new Date(cellInfo.value);
                      const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                      cellElement.textContent = formattedDate;
                    } else {
                      cellElement.textContent = cellInfo.value;
                    }
                  }} 
                />
              </DataGrid>
            {/* </ScrollView> */}
          </div>
        );
    };

    const dropDownContentRenderRole = (key) => {
        if (typeof key === 'object' && key !== null) {
          key = key.role;
        }
    
        const ersteller = Array.from(new Set(dataArray.flatMap(obj => 
        obj.people
            .filter(member => member.rolle.name === key)
            .map(member => `${member.member.first_Name} ${member.member.last_Name}`)
        )));
    
        return (
          <div>
            {/* <ScrollView
              height={400}
              direction="both"
            > */}
              <DataGrid
                ref={dataGridRef}
                dataSource={ersteller.map(name => ({ name }))}
                keyExpr="name"
                showBorders={true}
                onSelectionChanged={(e) => {
                    const selectedNames = e.selectedRowKeys;
                    const neueDaten = dataArray.filter(obj =>
                        obj.people.some(member =>
                            selectedNames.includes(`${member.member.first_Name} ${member.member.last_Name}`)
                        )
                    );
                    console.log("SelectedNames: ", selectedNames);
                    setSelectedFilter({ ...selectedFilter, [key]: selectedNames });
                }}
              >
                <Selection mode="multiple" selectAllMode={allMode} showCheckBoxesMode={checkBoxesMode}/>
                <FilterRow visible={true} />
                <Column 
                  dataField="name" 
                  cellTemplate={(cellElement, cellInfo) => {
                    cellElement.textContent = cellInfo.value;
                  }} 
                />
              </DataGrid>
            {/* </ScrollView> */}
          </div>
        );
    };

    return (
        <>
            {!dataArray ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45vh' }}>
                    <h2>Keine Daten</h2>
                </div>
            ) : dataArray.length === 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadIndicator 
                        height={60}
                        width={60}
                    />
                </div>
            ) : (
                // <ScrollView
                //     height={'calc(100vh - 500px)'}
                //     width={'auto'}
                //     direction="both"
                // >
                    <section className="section">
                        <div className="container">
                            <div className="b-table">
                            <div className="table-wrapper has-mobile-cards">
                                <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Betreff')) && (
                                    <th>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('subject')}
                                        <DropDownButton
                                            icon='filter'
                                            hint='Betreff auswählen'
                                            stylingMode='text'
                                            showArrowIcon={false}
                                            valueExpr='id'
                                            displayExpr='name'
                                            onItemClick={(e) => {console.log(e.itemData)}}
                                            dropDownContentRender={() => dropDownContentRender("subject")}
                                            dropDownOptions={DropDownButtonOptions}
                                        />
                                        </div>
                                    </th>
                                    )}
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Start')) && (
                                    <th>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('start')}
                                        <DropDownButton
                                        icon='filter'
                                        hint='Startzeitpunkt auswählen'
                                        stylingMode='text'
                                        showArrowIcon={false}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onItemClick={(e) => {console.log(e.itemData)}}
                                        dropDownContentRender={() => dropDownContentRender("start")}
                                        dropDownOptions={DropDownButtonOptions}
                                    />
                                    </div>
                                    </th>
                                    )}
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Ende')) && (
                                    <th>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('end')}
                                        <DropDownButton
                                        icon='filter'
                                        hint='Endzeitpunkt auswählen'
                                        stylingMode='text'
                                        showArrowIcon={false}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onItemClick={(e) => {console.log(e.itemData)}}
                                        dropDownContentRender={() => dropDownContentRender("end")}
                                        dropDownOptions={DropDownButtonOptions}
                                        />
                                        </div>
                                    </th>
                                    )}
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Status')) && (
                                    <th>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('status')}
                                        <DropDownButton
                                        icon='filter'
                                        hint='Endzeitpunkt auswählen'
                                        stylingMode='text'
                                        showArrowIcon={false}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onItemClick={(e) => {console.log(e.itemData)}}
                                        dropDownContentRender={() => dropDownContentRender("status_Procent")}
                                        dropDownOptions={DropDownButtonOptions}
                                        />
                                        </div>
                                    </th>
                                    )}
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Erinnerung')) && (
                                    <th>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('followUp')}
                                        <DropDownButton
                                        icon='filter'
                                        hint='Endzeitpunkt auswählen'
                                        stylingMode='text'
                                        showArrowIcon={false}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onItemClick={(e) => {console.log(e.itemData)}}
                                        dropDownContentRender={() => dropDownContentRender("follow_up")}
                                        dropDownOptions={DropDownButtonOptions}
                                        />
                                        </div>
                                    </th>
                                    )}
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Unternehmen')) && (
                                    <th>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('company')}
                                        <DropDownButton
                                        icon='filter'
                                        hint='Endzeitpunkt auswählen'
                                        stylingMode='text'
                                        showArrowIcon={false}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onItemClick={(e) => {console.log(e.itemData)}}
                                        dropDownContentRender={() => dropDownContentRender("company")}
                                        dropDownOptions={DropDownButtonOptions}
                                        />
                                        </div>
                                    </th>
                                    )}
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Abteilung')) && (
                                    <th>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        {t('department')}
                                        <DropDownButton
                                        icon='filter'
                                        hint='Endzeitpunkt auswählen'
                                        stylingMode='text'
                                        showArrowIcon={false}
                                        valueExpr='id'
                                        displayExpr='name'
                                        onItemClick={(e) => {console.log(e.itemData)}}
                                        dropDownContentRender={() => dropDownContentRender("department")}
                                        dropDownOptions={DropDownButtonOptions}
                                        />
                                        </div>
                                    </th>
                                    )}
                                    {allRoles.map(role => (
                                    (ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes(role)) && (
                                        <th key={role}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {role}
                                            <DropDownButton
                                            icon='filter'
                                            hint={`${role} auswählen`}
                                            stylingMode='text'
                                            showArrowIcon={false}
                                            valueExpr='id'
                                            displayExpr='name'
                                            onItemClick={(e) => { console.log(e.itemData) }}
                                            dropDownContentRender={() => dropDownContentRenderRole({ role })}
                                            dropDownOptions={DropDownButtonOptions}
                                            />
                                        </div>
                                        </th>
                                    )
                                    ))}
                                    <th>{/* buttons */}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((obj) => (
                                    <tr key={obj.id} className='tbodycss'>
                                    {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Betreff')) && (
                <td data-label={t('subject')}       onClick={() => handleClick(obj.id)}>{obj.subject}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Start')) && (
                    <td data-label={t('start')} onClick={() => handleClick(obj.id)}>{formatDate(new Date (obj.start))}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Ende')) && (
                    <td data-label={t('end')} onClick={() => handleClick(obj.id)}>{formatDate(new Date (obj.end))}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Status')) && (
                <td data-label={t('status')}        onClick={() => handleClick(obj.id)}>{obj.status_Procent}%</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Erinnerung')) && (
                <td data-label={t('followUp')}      onClick={() => handleClick(obj.id)}>{formatDate(new Date (obj.follow_up))}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Unternehmen')) && (
                <td data-label={t('company')}       onClick={() => handleClick(obj.id)}>{obj.company == undefined ? '' : obj.company.name }</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Abteilung')) && (
                <td data-label={t('department')}    onClick={() => handleClick(obj.id)}>{obj.department == undefined ? '' : obj.department.name }</td>
                )}
                {window.innerWidth > 768 && (
                  <>
                    {allRoles.map(role => (
                      (ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes(role)) && (
                        <td key={role}>
                          {obj.people.some(person => person.rolle.name === role) && (
                            renderMembers(obj, role)
                          )}
                        </td>
                      )
                    ))}
                  </>
                )}
                {window.innerWidth <= 768 && (
                  <>
                    {allRoles.map(role => (
                      (obj.people.some(person => person.rolle.name === role)) && (
                        <td key={role} data-label={t(role)} onClick={() => handleClick(obj.id)}>
                          {renderMembers(obj, role)}
                        </td>
                      )
                    ))}
                  </>
                )}
                                    <td>
                                        <div className="DeleteButtons">
                                        <MdDelete
                                            size={30}
                                            color="red"
                                            onClick={() => {
                                            setModalData(obj);
                                            {open()}
                                            }}
                                        />
                                        </div>
                                    </td>
                                </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </section>
                // </ScrollView>
            )}
        </>
    );
}
export default AufgabenlisteEigeneTabelle;