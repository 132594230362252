import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {useHistory} from 'react-router-dom'
import './mail.scss';
// import Toolbar, {Item as TBItem} from "devextreme-react/toolbar";
import i18n from "i18next";



export default ({selectedMail}) => {
    const history = useHistory()
    const t = i18n.getFixedT(null, 'mails');

    if(!selectedMail) return <div>{ t('noMailSelected') }</div>


    // const createTaskButtonOptions = {
    //     icon: 'plus',
    //     text: t('btnMail2Task'),
    //     onClick: () => history.push(`/Aufgabe`, {mail: selectedMail })
    // };
    // const replyButtonOptions = {
    //     icon: 'revert',
    //     text: t('btnReply'),
    //     // onClick: this.sendEmail.bind(this),
    // };
    // const forwardButtonOptions = {
    //     icon: 'redo',
    //     text: t('btnForward'),
    //     // onClick: this.sendEmail.bind(this),
    // };
    // const deleteButtonOptions = {
    //     icon: 'remove',
    //     text: t('btnDelete'),
    //     // onClick: this.sendEmail.bind(this),
    // };


    return <React.Fragment>
        {/* <Toolbar>
            <Item location="before"
                  locateInMenu="auto"
                  widget="dxButton"
                  options={createTaskButtonOptions} />
            <Item location="before"
                  locateInMenu="auto"
                  widget="dxButton"
                  options={replyButtonOptions} />
            <Item location="before"
                  locateInMenu="auto"
                  widget="dxButton"
                  options={forwardButtonOptions} />
            <Item location="before"
                  locateInMenu="auto"
                  widget="dxButton"
                  options={deleteButtonOptions} />

        </Toolbar> */}

            {/*<div className="meta">*/}

                <div style={{color: 'black'}}>
                    <div className={"metadata-header"}>Von:</div><div className={"metadata-header-desc"}>{selectedMail != null ? selectedMail.from[0].last_Name != '' ? selectedMail.from[0].last_Name : selectedMail.from[0].eMail : ' '}</div>
                    {/* <div className={"metadata-header"}>CC:</div><div className={"metadata-header-desc"}>{selectedMail.CC[0] != null ? selectedMail.CC[0].last_Name : '<TODO>'}</div> */}
                    <div className={"metadata-header"}>Datum:</div><div className={"metadata-header-desc"}>{new Date(selectedMail.date_From).toLocaleString()}</div>
                    <div className={"metadata-header"}>Betreff:</div><div className={"metadata-header-desc"}>{selectedMail ? selectedMail.subject : ''}</div>
                </div>
            {/*</div>*/}
            <hr className="line"/>
                <div className='email-content' style={{overflowX: 'scroll', width: '100%'}}>
                    <div dangerouslySetInnerHTML={{__html: selectedMail.body}}/>
                </div>

        </React.Fragment>


}
