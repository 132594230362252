import React, {useEffect, useState, useCallback} from 'react';
import { Toolbar, Item } from 'devextreme-react/toolbar';
import Api from '../../utils/api';
import notify from 'devextreme/ui/notify';
import Popup from 'devextreme-react/popup';
import { Button } from '@mantine/core';
import { FaUndoAlt } from "react-icons/fa";
import "../../pages/Aufgabenliste/Aufgabenliste.scss";
import { useScreenSize } from '../../utils/media-query';
import { TagBox } from 'devextreme-react/tag-box';
import {Button as DxButton} from 'devextreme-react/button';

import {
  Column,
  DataGrid,
  Editing,
  RowDragging,
  Scrolling,
} from 'devextreme-react/data-grid';

import EventBus from './../Mitgliederliste-Mobil/EventBus';

const AufgabenlisteToolbar = ({setSearchText,filteredData, setFilteredData, dataArray, searchText, setSortType, Aufgabenfilter, selectedProjects, t, ausgewählteSpalten, setAusgewählteSpalten, showFilterWithText, setSelectedFilter, filter, user}) => {

  const [showDragIcons, setShowDragIcons] = useState(true);
  const [newFilter, setNewFilter] = useState([...filter]);
  const [searchTextBox, setSearchTextBox] = useState(null);
  const isXSmall = useScreenSize().isXSmall;
  let newUpdatedFilter = [...filter];

  useEffect(() => {
    if (window.innerWidth < 768) {
      setSearchTextBox(100);
    } else {
      setSearchTextBox(180);
    }
  }, []);

  useEffect(() => {
    console.log("Neuer Filter: ", newFilter);
  }, [filter]);

  // Funktion zum Filtern des Arrays basierend auf dem Suchtext
  const filterArray = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const filtered = dataArray.filter(obj => {
      // Überprüfen, ob der Suchtext im 'subject' Feld enthalten ist
      if (obj.subject.toLowerCase().includes(lowerCaseValue)) {
        return true;
      }

      // Überprüfen, ob der Suchtext in den Mitgliedernamen enthalten ist
      if (obj.people) {
        const memberNames = obj.people.map(person => {
          if (person.member.first_Name && person.member.last_Name) {
            return `${person.member.first_Name} ${person.member.last_Name}`;
          } else if (person.member.eMail) {
            return person.member.eMail;
          } else {
            return '';
          }
        });

        if (memberNames.some(name => name.toLowerCase().includes(lowerCaseValue))) {
          return true;
        }
      }

      // Wenn der Suchtext weder im 'subject' Feld noch in den Mitgliedernamen enthalten ist
      return false;
    });

    setFilteredData(filtered); // Aktualisieren für die Anzeige
  };

  const [DropDownButtonOpened, setDropDownButtonOpened] = useState(false); // Status des Dropdowns
  const dropDownWidth = DropDownButtonOpened ? 180 : 80; // Breite des Dropdowns ändert sich basierend auf dem Status von DropDownButtonOpened
  const [startOwnFilter, setStartOwnFilter] = useState(false); // Status des eigenen Filters
  const [popupVisible, setPopupVisible] = useState(false); // Status des Popups

  // Funktion zum Sortieren des Arrays basierend auf dem Sortiertyp
  const sortArray = (type) => {
    const types = {
    "Alphabetisch aufsteigend": 'subject',
    "Alphabetisch absteigend": 'subject',
    "Startdatum aufsteigend": 'start',
    "Startdatum absteigend": 'start',
    "Enddatum aufsteigend": 'end',
    "Enddatum absteigend": 'end',
    };
    const sortProperty = types[type];
    const sorted = [...filteredData].sort((a, b) => {
    if (type.includes("absteigend")) {
        return b[sortProperty].localeCompare(a[sortProperty]);
    } else {
        return a[sortProperty].localeCompare(b[sortProperty]);
    }
    });
    setFilteredData(sorted);
  };

  // Extrahiere alle einzigartigen Rollennamen aus den Projektmitgliedern
  const allRoles = dataArray.reduce((acc, project) => {
    project.people.forEach(person => {
      if (!acc.includes(person.rolle.name)) {
        acc.push(person.rolle.name);
      }
    });
    return acc;
  }, []);

  let dropdownDatenquellefürSpaltenauswahl = [
    {
      label: t('subject'),
    },
    {
      label: t('start'),
    },
    {
      label: t('end'),
    },
    {
      label: t('status'),
    },
    {
      label: t('followUp'),
    },
    {
      label: t('company'),
    },
    {
      label: t('department'),
    },
    ...allRoles.map(role => ({ label: role })),
  ];

    // Optionen für die Zurücksetzen-Schaltfläche
    const resetbuttonOptions = {
        icon: 'revert',
        stylingMode: 'contained',
        hint: 'Zurücksetzen',
        onClick: () => {
          setSearchText('');
          setFilteredData(dataArray);
          setAusgewählteSpalten([]);
          setStartOwnFilter(false);
          setSelectedFilter(null);
          showFilterWithText();
        }
    };

    // Optionen für die Textbox
    const textBoxOptions = {
        placeholder: 'Suchen...',
        width: searchTextBox,
        value: searchText,
        onValueChanged: ({ value }) => {
          setSearchText(value);
          if (value) {
            filterArray(value); // Filtern Sie das Array, wenn der Suchtext gesetzt ist
          } else {
            setFilteredData(dataArray); // Setzen Sie filteredData auf das vollständige Array, wenn der Suchtext geleert wird
          }
        },
        buttons: [{
          name: 'search',
          location: 'after',
          options: {
            icon: 'search',
            stylingMode: 'text',
            onClick: () => filterArray(searchText) // Startet die Suche, wenn der Button gedrückt wird
          }
        }],
    };

    const newProjektfilter = {
      icon: 'add',
      hint: 'Eigene Liste erstellen',
      stylingMode: 'contained',
      onClick: async () => {
        let filterName = window.prompt("Bitte geben Sie einen Namen für den Filter ein");
        if (filterName) {
          console.log("Neue Liste hinzufügen: ", filterName);
          console.log("Zeige Ausgewählte Aufgaben: ", selectedProjects);

          // Abrufen der personID
          let personID;
          try {
            const response = await Api.getMe();
            personID = response.data.id;
            console.log("MyID", personID);
          } catch (error) {
            console.error("Fehler beim Abrufen der personID: ", error);
            notify("Fehler beim Abrufen der personID", "error", 3000);
            return;
          }
    
          // Erstellen Sie das zu sendende Objekt
          let data = {
            personID: personID,
            name: `Aufgabenfav_${encodeURIComponent(filterName)}`,
            option: JSON.stringify(selectedProjects),  // Umwandlung des Arrays in einen String
          };
    
          // Senden Sie die PUT-Anforderung
          try {
            Api.OptionClient.createOption(data).then(response => {
              notify("Liste erfolgreich erstellt", "success", 3000);
            });
          } catch (error) {
            console.error("Fehler beim Speichern der Liste: ", error);
            notify("Fehler beim Speichern der Liste", "error", 3000);
          }
        }
      }
    };

    const showProjektfilter = {
      icon: 'filter',
      dataSource: Aufgabenfilter,
      displayExpr: item => item && item.name ? item.name.replace('Aufgabenfilter_', '') : '',
      valueExpr: 'name',
      width: dropDownWidth,
      onItemClick: ({ itemData }) => {
        const arrayData = JSON.parse(itemData.option);
        setFilteredData(arrayData);
        setDropDownButtonOpened(!DropDownButtonOpened);
      },
      onButtonClick: () => {
        setDropDownButtonOpened(!DropDownButtonOpened);
      },
      useSelectMode: true,
      useItemTextAsTitle: true,
      hint: 'Eigene Liste anzeigen',
      type: 'normal',
      stylingMode: 'text',
      opened: DropDownButtonOpened,
    };

    let choosedColumns = [];
    // Option für Spaltenauswahl
    const filterColumnOptions = {
      items: dropdownDatenquellefürSpaltenauswahl,
      value: ausgewählteSpalten,
      valueExpr: 'label',
      displayExpr: 'label',
      placeholder: 'Spalten auswählen',
      width: searchTextBox,
      showSelectionControls: true,
      maxDisplayedTags: 2,
      width: 200,
      dropDownOptions: {
        height: 400,
      },
      onValueChanged: function (e) {  
        choosedColumns = e.value;
        console.log("Ausgewählte Spalten: ", choosedColumns);
      },
      buttons: [{
        name: 'search',
        location: 'after',
        options: {
          icon: 'arrowright',
          stylingMode: 'text',
          onClick: function (e) {
            setAusgewählteSpalten(choosedColumns);
            console.log("Spaltenauswahl bestätigt: ", choosedColumns);
          }
        }
      }],
    };


    // Optionen für die Dropdown-Schaltfläche
     const dropDownOptions = {
        items: [
          "Alphabetisch aufsteigend",
          "Alphabetisch absteigend",
          "Startdatum aufsteigend",
          "Startdatum absteigend",
          "Enddatum aufsteigend",
          "Enddatum absteigend",
        ],
        width: searchTextBox,
        text: "Sortieren nach...",
        dropDownOptions: {
          width: 200
        },
        onItemClick: ({ itemData }) => {
          setSortType(itemData);
          sortArray(itemData);
        }
    };

    const deleteOwnFilters = {
      icon: "preferences",
      hint: 'Eigene Listen verwalten',
      stylingMode: 'contained',
      onClick: () => {
        if ((user && user.id) && newFilter) {
          setPopupVisible(true);
          console.log("Prüfe filter: ", newFilter);
        } else {
          console.error("Informationen nicht verfügbar.");
        }
      }
    };

    function handleFilterDelete(filterName) {
      console.log("Ausgewählter Filter zum löschen: ", filterName)
      const neuesFilterArray = filter.filter(filter => filter.filterName !== filterName)
      console.log("Altes Filter Array: ", filter)
      console.log("Neues Filter Array: ", neuesFilterArray)
      console.log("User ID: ", user.id)

      let objekt = {
        name: `Aufgabenfav_${user.id}`,
        option: JSON.stringify(neuesFilterArray),
        personID: user.id
      }

      let showObjekt = {
        name: `Aufgabenfav_${user.id}`,
        option: neuesFilterArray,
        personID: user.id
      }

      console.log("Neues Objekt: ", objekt)
      console.log("Neues Objekt mit Array Option: ", showObjekt)

      Api.OptionClient.updateOption(`Aufgabenfav_${user.id}`, objekt)
        .then(response => {
          if (response.status == 201) {
            console.log('Option erfolgreich aktualisiert');
            EventBus.emit("updateFilter", 'update'); //Aktualisiert den Zustand in der Navigation
          } else {
            console.error('Fehler beim Aktualisieren der Option');
            // Behandlung von Fehlern, z.B. Benachrichtigung des Benutzers
          }
        })
        .catch(error => {
          console.error('Fehler bei der Anfrage:', error);
          // Behandlung von Netzwerk- oder Serverfehlern
        });
    }

    const onReorder = useCallback((e) => {
      const visibleRows = e.component.getVisibleRows();
      const orderedFilter = [...newFilter];
    
      const toIndex = orderedFilter.findIndex((item) => item.filterName === visibleRows[e.toIndex].data.filterName);
      const fromIndex = orderedFilter.findIndex((item) => item.filterName === e.itemData.filterName);
    
      orderedFilter.splice(fromIndex, 1);
      orderedFilter.splice(toIndex, 0, e.itemData);

      console.log("Alte Reihenfolge: ", newFilter);
      console.log("Neue Reihenfolge: ", orderedFilter);
    
      setNewFilter(orderedFilter);

        if (user && user.id) {
          let objekt = {
            name: `Aufgabenfav_${user.id}`,
            option: JSON.stringify(orderedFilter),
            personID: user.id
          }

          Api.OptionClient.updateOption(`Aufgabenfav_${user.id}`, objekt)
            .then(response => {
              if (response.status == 201) {
                console.log('Option erfolgreich aktualisiert')
                EventBus.emit("updateFilter", 'update');
              } else {
                console.error('Fehler beim Aktualisieren der Option');
              }
            })
            .catch(error => {
              console.error('Fehler bei der Anfrage:', error);
            });
        } else {
          console.error("User informationen nicht verfügbar:", user);
        }
    }, [newFilter]);

    function handleFilterReset() {
      try {
        Api.OptionClient.deleteOption(`Aufgabenfav_${user.id}`).then(response => {
          notify("Listen erfolgreich zurückgesetzt", "success", 3000);
          EventBus.emit("updateFilter", 'update');
        });
      } catch (error) {
        console.error("Fehler beim Zurücksetzen der Listen: ", error);
        notify("Fehler beim Zurücksetzen der Listen", "error", 3000);
      }
    }

    function handleFilterUpdate(data, spaltenwahl) {
      console.log("Ausgewählter Filter zum aktualisieren: ", data);
      console.log("Neue Spaltenauswahl: ", spaltenwahl);
      console.log("Altes Filter Array: ", newUpdatedFilter);
    
      for (let i = 0; i < newUpdatedFilter.length; i++) {
        if (newUpdatedFilter[i].id === data.id) {
          newUpdatedFilter[i].filterName = data.filterName; // Neue Spaltenwerte hinzufügen
          newUpdatedFilter[i].spaltenwahl = spaltenwahl; // Neue Spaltenwerte hinzufügen
          break;
        }
      }
      console.log("Aktualisiertes Filter Array: ", newUpdatedFilter);
    }

    function handleSaveUpdates() {
      if (newUpdatedFilter) {
        console.log("Neue Filter in die Api speichern: ", newUpdatedFilter);

        let objekt = {
          name: `Aufgabenfav_${user.id}`,
          option: JSON.stringify(newUpdatedFilter),
          personID: user.id
        }

        Api.OptionClient.updateOption(`Aufgabenfav_${user.id}`, objekt)
          .then(response => {
            if (response.status == 201) {
              console.log('Option erfolgreich aktualisiert');
              EventBus.emit("updateFilter", 'update');
              notify("Änderungen erfolgreich gespeichert", "success", 3000);
            } else {
              console.error('Fehler beim Aktualisieren der Option');
              notify("Fehler beim Aktualisieren der Option", "error", 3000);
            }
          })
          .catch(error => {
            console.error('Fehler bei der Anfrage:', error);
            notify("Fehler bei der Anfrage", "error", 3000);
          });
      } else {
        console.error("Informationen nicht verfügbar.");
      }
    }
    
    const TagBoxEditor = ({ daten, spaltenwahl, handleFilterUpdate }) => {
      const initialSpaltenwahl = Array.isArray(spaltenwahl) ? spaltenwahl : [];
      const [value, setValue] = useState([...initialSpaltenwahl]);
    
      useEffect(() => {
        console.log("Initial ausgewählte Spalten: ", spaltenwahl);
        console.log("Dropdown Datenquelle: ", dropdownDatenquellefürSpaltenauswahl);
      }, [spaltenwahl]);
    
      useEffect(() => {
        console.log("Aktualisierte ausgewählte Spalten: ", value);
        handleFilterUpdate(daten, value);
      }, [value]);
    
      return (
        <TagBox
          dataSource={dropdownDatenquellefürSpaltenauswahl}
          displayExpr="label"
          valueExpr="label"
          showSelectionControls={true}
          maxDisplayedTags={1}
          value={value}
          onValueChanged={e => {
            console.log("Neue ausgewählte Spalten: ", e.value);
            setValue(e.value);
          }}
          width={200}
        />
      );
    };

    return (
      <>
        {searchTextBox && (
        <Toolbar>
            <Item
            location={'before'}
            widget="dxButton"
            options={resetbuttonOptions}
            />

            <Item  
            location={'before'}
            widget="dxTextBox" 
            options={textBoxOptions}
            />

            {/*
            <Item
            location={'before'}
            widget="dxButton"
            options={newProjektfilter}
            />

            <Item
            location={'before'}
            widget="dxDropDownButton"
            options={showProjektfilter}
            />
            */}

            {!isXSmall && (
            <Item
              location={'before'}
              widget="dxTagBox"
              options={filterColumnOptions}
            />
            )}

            <Item
              location={'after'}
              widget="dxDropDownButton"
              options={dropDownOptions}
            />

            {user && newFilter && (
              <Item
              location={'after'}
              widget="dxButton"
              options={deleteOwnFilters}
              />
            )}

        </Toolbar>
        )}

        <Popup
          visible={popupVisible}
          onHiding={() => setPopupVisible(false)}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Eigene Listen verwalten"
          height="auto"
          width="45vh"
        >
          <DataGrid
            dataSource={newFilter}
            keyExpr="id"
            showBorders={true}
            onRowRemoved={(e) => handleFilterDelete(e.data.filterName)}
            onRowUpdating={(e) => handleFilterUpdate(e)}
            onRowClick={(e) => console.log(e)}
          >

            <RowDragging
              allowReordering={true}
              onReorder={onReorder}
              showDragIcons={showDragIcons}
            />
            <Editing
              mode="row"
              allowDeleting={true}
              allowUpdating={true}
            />
            <Scrolling mode="virtual" />

            <Column dataField="filterName" />
            <Column 
              dataField="spaltenwahl" 
              editCellComponent={(props) => 
                <TagBoxEditor 
                  daten={props.data.data}
                  spaltenwahl={props.data.value} 
                  handleFilterUpdate={handleFilterUpdate} 
                />
              } 
            />
              
          </DataGrid>

          <div style={{marginTop: '10px', display: 'flex', justifyContent: 'flex-end'}}>
            <Button leftIcon={<FaUndoAlt size="1rem" />}
              onClick={handleFilterReset}
              icon='undo'
              color='red'
            >
              Listen zurücksetzen
            </Button>
            <DxButton
              text='Speichern'
              className='myButton'
              onClick={handleSaveUpdates}
              style={{marginLeft: '10px'}}
            />
          </div>

        </Popup>
      </>
    );
}
export default AufgabenlisteToolbar;