
import React from 'react';
import { Button } from 'devextreme-react/button';
import maprotaIcon from '../../logo192.png'
import { useHistory } from 'react-router-dom';


const RenderLogo = (data) => {
    const history = useHistory();
    //   console.log("KontaktlisteKomponente_renderLogo",data);
       const obj = data.data
       if (obj && obj.relationshipID === 0){ // MaProTa User
                return <Button icon={maprotaIcon}    onClick={() => {
                                                                       history.push(`/profile/${obj.id}`)
                                                                    }
                        }/>
        } 
        else   if(obj && obj.relationshipID === 1){ // Verbunden
            return <Button icon={maprotaIcon} onClick={() => {
                                                               history.push(`/profile/${obj.id}`)
                                                             }
            }/>
          
           
        }
        else if (obj && obj.relationshipID === 3){ // Anfrage gesendet
           return <Button icon='/AnfrageGestellt.png'   onClick={() => {
               history.push(`/profile/${obj.id}`)
           }
               }/>
        }
        else if (obj && obj.relationshipID === 4){ // Anfrage erhalten
           return <Button icon='AnfrageEmpfangen.png'   onClick={() => {
               history.push(`/profile/${obj.id}`)
           }
               }/>
        }
      
        else {
          
            return <Button icon='card' onClick={() => {
                                                            history.push(`/profile/${obj.id}`)
                                                        }
           }/>
        }
   };

   export default RenderLogo;