import React, {useState, useEffect, useCallback} from 'react';
import {SelectBox} from 'devextreme-react/select-box';
import { PiSquareSplitVertical, PiSquareSplitHorizontal } from "react-icons/pi";
import Api from '../../utils/api';
import { set } from 'lodash';

const MailViewEinstellung = (props) => {

    const setViewMode = props.setViewMode;
    const [MailViewMode, setMailViewMode] = useState(null);
    const [myID, setMyID] = useState(null)
    const Anzeigeeinstellungen = [
        {
            id: 1,
            name: "Liste Links",
            component: PiSquareSplitHorizontal
        }, {
            id: 2,
            name: "Liste Oben",
            component: PiSquareSplitVertical
        }
    ];
    const onValueChanged = useCallback((e) => {
        let newOptionData;

        if (e.value === 1) {
            newOptionData = {
                name: "MailViewMode",
                option: "ListeLinks",
                personID: myID
            }
            Api.OptionClient.updateOption(newOptionData.name, newOptionData).then(() => {
                console.log("MailViewMode aktualisiert:", newOptionData)
                setMailViewMode(newOptionData.option)
                setViewMode(newOptionData.option)
            })
        } else if (e.value === 2) {
           newOptionData = {
                name: "MailViewMode",
                option: "ListeOben",
                personID: myID
            }
            Api.OptionClient.updateOption(newOptionData.name, newOptionData).then(() => {
                console.log("MailViewMode aktualisiert:", newOptionData)
                setMailViewMode(newOptionData.option)
                setViewMode(newOptionData.option)
            })
        }
    });
    const renderItem = (data) => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{marginRight: '10px', height: '30px', width: '30px'}}>
                    <data.component style={{height: '100%', width: '100%'}}/>
                </div>
                <div>{data.name}</div>
            </div>
        );
    };

    useEffect(() => {
        let personID;
        Api.getMe().then((response) => {
            setMyID(response.data.id)
            personID = response.data.id;
        })
        Api.OptionClient.getOptions().then((response) => {
            const mailViewModeOption = response.data.filter(option => option.name === "MailViewMode")
            console.log("MailViewMode Option gefunden:", mailViewModeOption)
            setMailViewMode(mailViewModeOption[0].option)
            if (mailViewModeOption.length === 0) {
                console.log("MailViewMode Option nicht gefunden")
                let OptionData = {
                    name: "MailViewMode",
                    option: "ListeLinks",
                    personID: personID 
                }
                Api.OptionClient.createOption(OptionData).then(() => {
                    console.log("MailViewMode aktualisiert:", OptionData)
                    setMailViewMode(OptionData.option)
                })
            }})
    }, [MailViewMode])

    return (
        <>
            {MailViewMode &&
                <div className='Anzeigeeinstellungen-Mailcontainer' style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                    <div style={{marginRight: '10px'}}>Anzeigeeinstellungen:</div>
                    <div className="selectbox-AnzeigeEinstellungen">
                        <SelectBox
                            dataSource={Anzeigeeinstellungen}
                            displayExpr="name"
                            valueExpr="id"
                            itemRender={renderItem}
                            onValueChanged={onValueChanged}
                            defaultValue={MailViewMode === "ListeLinks" ? 1 : 2}
                            width={150}
                        />
                    </div>
                </div>
            }
        </>
    );
};
export default MailViewEinstellung;