import React, {useEffect, useState, useCallback} from 'react';
import './profile.scss';
import {useHistory, useLocation} from 'react-router-dom';
import {notifySuccess, notifyError} from "../../utils/notifyManager";
import i18n from "i18next";
import CustomStore from "devextreme/data/custom_store";
import Api from "../../utils/api";
import {LoadIndicator, ScrollView, DropDownButton} from "devextreme-react";
import { Button } from 'devextreme-react/button';
import {useAuth} from '../../contexts/auth';
import { Avatar, Timeline, Text } from '@mantine/core';
import AvatarPlaceholder from './../Benutzerverwaltung/personPlaceholder.png';
import { useScreenSize } from '../../utils/media-query';
import { Popup } from 'devextreme-react/popup';
import Mail from './../../components/MailCreate/Mail';
import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';
import { FaRegAddressCard, FaPaste, FaBookmark, FaShareAlt } from 'react-icons/fa';
import SkillsRingProgress from '../../components/Skills-RingProgress/skillsRingProgress';


const state = {
    CONTACT: 2,
    NETWORK: 1,
    LOCAL_NETWORK: 1,
    RECEIVED_REQUEST : -2,
    SENT_REQUEST: -1,
    NOT_NETWORK: 0,
    LOADING: 99
}

function get_Empty_GUID(){
    return "00000000-0000-0000-0000-000000000000";
}

const tabNames = ['Persönliches', 'Projekte', 'Aufgaben', 'Netzwerk'];

export default () => {
    const location = useLocation()


    const p = i18n.getFixedT(null, 'profile');
    const c = i18n.getFixedT(null, 'common');
    const con = i18n.getFixedT(null, 'contacts');


    const [loading, setLoading] = React.useState(false);
    const [profile, setProfile] = useState({})
    const [networkState, setNetworkState] = useState(state.LOADING)
    const history = useHistory();
    const {user} = useAuth()
    const { isXSmall } = useScreenSize();
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [mailForMessage, setMailforMessage] = useState(profile?.eMail);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    function getProfileID(){
        const path = location.pathname;
        let parts = path.split('/');
        const id = parts.pop(); // Number(parts.pop() || parts.pop());
        if(id  == "profile" ){
            return null
        }
        else
        {
              return id //  isNaN(id) ? -1 : id
        }
    }

    useEffect(() => {
        try {
            const id = getProfileID()
            if(id === user.id || id === null){
                return history.push("/Benutzerverwaltung")
            }

            setLoading(true)
            loadProfile(id)

        } catch (e) {
            setLoading(false)
            history.push("/Aufgabenliste")
        }

    }, [location.pathname]);

    useEffect(() => {
        console.log('selectedTabIndex', selectedTabIndex);
    }, [selectedTabIndex]);

    const profileDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        byKey: (key) => {
            return Api.people.getPersonById(key)
                .then(response => response.data);
        },
    });

    const networkDS = new CustomStore({
        key: ['person_otherID'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyNetwork()
                .then(response => response.data)
        },

        remove: (data) => {
            return Api.people.deleteFromNetwork(data.id, data.relationship)
        }
    });

    const networkRequestDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyRequests()
                .then(response => response.data);
        },

        insert: (data) => {
            return Api.people.insertToNetwork(data)
                .then(response => response.data);
        },

        remove: key => {
            return Api.people.deleteRequest(key)
        }
    });

    function setNetworkStatus(relationshipID) {
        switch (relationshipID) {
            case state.NETWORK:
                setNetworkState(state.NETWORK)
                break;
            case state.LOCAL_NETWORK:
                setNetworkState(state.LOCAL_NETWORK)
                break;
            case state.SENT_REQUEST:
                setNetworkState(state.SENT_REQUEST)
                break;
            case state.RECEIVED_REQUEST:
                setNetworkState(state.RECEIVED_REQUEST)
                break;
            case state.CONTACT:
                 setNetworkState(state.CONTACT)
                 break;
            default:
                setNetworkState(state.NOT_NETWORK)
        }
    }

    function loadProfile(id) {
        profileDS.byKey(id)
            .then(person => {
                setProfile(person)
                setMailforMessage(person.eMail)
                setLoading(false)
                return setNetworkStatus(person.relationshipID)
            }).catch(error => {
            setLoading(false)
            setNetworkState(state.NOT_NETWORK)
            notifyError(i18n.t('notify:failedLoading'))

        })
    }

    function handleAddContact(){
        setNetworkState(state.LOADING)
        let obj = {
            "person_MeID": user.id,
            "person_OtherID": profile.id,
            relationship: {
                id:1
            }
        }
        networkRequestDS.insert(obj)
            .then(res => res.data)
            .then(data => {
                notifySuccess(con('sentRequest'))
                setNetworkState(state.SENT_REQUEST)
            })
            .catch(e =>{
                setNetworkState(state.NOT_NETWORK)
                notifyError(con('failedAddContact'))
            })
    }

    function handleAcceptRequest(){
        setNetworkState(state.LOADING)

        let obj = {
            "person_MeID": user.id,
            "person_otherID": getProfileID(),
            relationship: {
                id:1
            }
        }
        networkRequestDS.insert(obj)
            .then(res => res.data)
            .then(data => {
                notifySuccess(con('acceptedRequest'))
                setNetworkState(state.NETWORK)
            })
            .catch(e =>{
                notifyError(con('failedAccept'))
                setNetworkState(state.RECEIVED_REQUEST)
            })
    }

    function handleRemoveFromNetwork(){
        setNetworkState(state.LOADING)

        const data = {
            id: profile.id,
            relationship: profile.relationshipID
        }

        networkDS.remove(data)
            .then(res => res.data)
            .then(data => {
                notifyError(con('removedFromNetwork'))
                setNetworkState(state.NOT_NETWORK)
            })
            .catch(e =>{
                notifyError(con('couldNotRemoveFromNetwork'))
                setNetworkState(state.NETWORK)
            })
    }

    function handleCancelMyRequest(){
            setNetworkState(state.LOADING)
        networkRequestDS.remove(getProfileID())
            .then(() =>{
                notifySuccess(con('canceledRequest'))
                setNetworkState(state.NOT_NETWORK)
            })
            .catch(e =>{
                notifyError(con('failedToRemoveMyRequest'))
                setNetworkState(state.SENT_REQUEST)
            })
    }

    

    function loadNetworkButtons() {
     
     
        switch (networkState) {
            case state.NETWORK: return (
                <div>
                    <Button
                      //  width={300}
                        text="Vom Netzwerk entfernen"
                        type="danger"
                        onClick={handleRemoveFromNetwork}
                        stylingMode="contained"
                    />
                </div>);
            case state.RECEIVED_REQUEST:
                return (<div>
                    <Button
                      //  width={200}
                        text="Akzeptieren"
                        type="success"
                        onClick={handleAcceptRequest}
                        stylingMode="contained"
                    />
                    <Button
                      //  width={200}
                        text="Ablehnen"
                        type="danger"
                        onClick={handleCancelMyRequest}
                        stylingMode="contained"
                    />
                </div>);
            case state.SENT_REQUEST:
                return (<div>
                    <Button
                      //  width={200}
                        text="Anfrage abbrechen"
                        type="danger"
                        onClick={handleCancelMyRequest}
                        stylingMode="contained"
                    />
                </div>);

            case state.LOADING:
                return <LoadIndicator visible={true}/>;
            case state.NOT_NETWORK:
                return (<div>
                    <Button
                        icon='card'
                        text= {con('btnUserAdd')}
                        onClick={handleAddContact}
                        className='myButton'
                    />
                </div>);
              case state.CONTACT:
                if(profile.reF_BP == get_Empty_GUID() ){
                    return (<div>   
                        <Button
                        
                        text="Kontakt löschen"
                        type="danger"
                        onClick={handleRemoveFromNetwork}
                        stylingMode="contained"
                        />
                    </div>);
                }
                else{
                    return (
                    <div>
                      <Button
                          icon='card'
                          text="Zum Netzwerk hinzufügen"
                          onClick={handleAddContact}
                          className='myButton'
                      />
                      <hr></hr>
                       <Button
                        
                        text="Kontakt löschen"
                        type="danger"
                        onClick={handleRemoveFromNetwork}
                        stylingMode="contained"
                        />
                  </div>);
                }
            default:
               return (<div> DEFAULT</div>);

        }
    }

    const togglePopup = (e) => {
        setPopupVisibility(!isPopupVisible);
    };

    const renderContent = () => {
        return (
            <>
                <Mail mailForMessage={mailForMessage} />
            </>
        );
    };

    const tabs = [
      { title: "Persönliches", icon: <FaRegAddressCard /> },
      { title: "Projekte", icon: <FaPaste /> },
      { title: "Aufgaben", icon: <FaBookmark /> },
    ];

    return (
      

        <React.Fragment>
          
            {loading ? <LoadIndicator visible={loading}/> :
                    <div>
                        <div className={'content-block'}>
                          <div className={'dx-card responsive-paddings'}>
                            <div id='headContainer'>
                                  <div id='headLeftContainer'>
                                    <div className='profile'>
                                      <div id='profile-picture'>
                                        <Avatar 
                                          size={isXSmall ? 100 : 160}
                                          radius="xxl" 
                                          src={profile.picture || AvatarPlaceholder}
                                          styles={{ 
                                            root: { 
                                              border: '3px solid white', 
                                              borderRadius: '50%' 
                                            } 
                                          }}
                                        />
                                      </div>
                                      <div id='profile-info'>
                                        <p id="bold-text">{profile.first_Name} {profile.last_Name}</p>
                                        {profile.positionname && <p>{profile.positionname}</p>}
                                        {profile.phone && (
                                          <>
                                            <strong>Telefon:</strong> <span>{profile.phone}</span>
                                          </>
                                        )}
                                      </div>
                                      <div className="button-container">
                                        {loadNetworkButtons()}
                                        <Button
                                          icon='message'
                                          text='Nachricht senden'
                                          className='myButton'
                                          onClick={togglePopup}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div id='headRightContainer'>
                                    <img 
                                      src='/DefaultHeaderProfil.jpg' //später pictureURL
                                      alt="Titelbild" 
                                      className="title-image" 
                                    />
                                    <div className="custom-tab-panel">
                                      {tabs.map((tab, index) => (
                                        <div
                                          key={index}
                                          className={`tab-item ${selectedTabIndex === index ? 'active' : ''}`}
                                          onClick={() => setSelectedTabIndex(index)}
                                        >
                                          {tab.icon}
                                          <span>{tab.title}</span>
                                        </div>
                                        ))}
                                    </div>
                                  </div>
                            </div>
                                <div className='divider'/>
                                <div className="custom-tab-panel-mobile">
                                      {tabs.map((tab, index) => (
                                        <div
                                          key={index}
                                          className={`tab-item ${selectedTabIndex === index ? 'active' : ''}`}
                                          onClick={() => setSelectedTabIndex(index)}
                                        >
                                          {tab.icon}
                                          <span>{tab.title}</span>
                                        </div>
                                        ))}
                                    </div>
                                <div id='view'>
                                  {selectedTabIndex === 0 && (
                                    <ScrollView
                                      direction='vertical'
                                      showScrollbar='onScroll'
                                    >
                                      <Timeline active={1} bulletSize={24} lineWidth={5} color="green">

                                        <Timeline.Item title={<span className="large-title">Skills</span>}>
                                          <SkillsRingProgress />
                                        </Timeline.Item>

                                        <Timeline.Item  title={<span className="large-title">Andere Dinge die später folgen...</span>}>
                                        </Timeline.Item>

                                      </Timeline>
                                    </ScrollView>
                                  )}
                                  {selectedTabIndex === 1 && (
                                    <ScrollView
                                      direction='both'
                                      showScrollbar='onScroll'
                                    >
                                      <div id="projects-view">
                                        <div className="profile-item">
                                          <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                            <thead>
                                              <tr>
                                                <th>Projektname</th>
                                                <th>Startdatum</th>
                                                <th>Enddatum</th>
                                                <th>Projektleiter</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Projekt 1</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Projekt 2</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Projekt 3</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </ScrollView>
                                  )}
                                  {selectedTabIndex === 2 && (
                                    <ScrollView
                                      direction='vertical'
                                      showScrollbar='onScroll'
                                    >
                                      <div id="tasks-view">
                                        <div className="profile-item">
                                          <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                            <thead>
                                              <tr>
                                                <th>Aufgabenname</th>
                                                <th>Startdatum</th>
                                                <th>Enddatum</th>
                                                <th>Projektleiter</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Aufgabe 1</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Aufgabe 2</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Aufgabe 3</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </ScrollView>
                                  )}
                                </div>
                            </div>
                        </div>
                        <Popup
                            visible={isPopupVisible}
                            onHiding={togglePopup}
                            hideOnOutsideClick={true}
                            title='Nachricht senden'
                            contentRender={renderContent}
                        />
                    </div>
                
            }
        </React.Fragment>
    );
};

const colCountByScreen = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4
};
