// src/DataGridCards.js
import '../../../components/bulma-tabel-css/bulma.min.css';
import '../../../components/bulma-tabel-css/main.min.css';
import Api from "../../../utils/api";
import React, { useEffect, useState } from 'react';
import '../Modal/Modal.css';
import { useAuth } from '../../../contexts/auth';
import { Tooltip } from 'devextreme-react';
import './KontaktlistePC.css';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Input } from '@mantine/core';
import { notifySuccess } from "../../../utils/notifyManager";
import Toolbar, { Item } from 'devextreme-react/toolbar';
import KontaktListeKomponente from '../../../components/Kontaktliste/KontaktListeKomponente';
import i18n from "i18next";

const KontaktlistePC = ({dataArray, setDataArray, filteredData, setFilteredData, ContactlistChanged, setContactlistChanged, Mitgliederliste,
  onConnect,    // Vernetzten
  oninvite,     // Einladung
  onWithdrawConnectionRequest            // Anfrage zurückziehen
 }) => {
  
  const t = i18n.getFixedT(null, 'contacts');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState('');
  const { user } = useAuth();
  const [selectedName, setSelectedName] = useState(''); // Zustandsvariable definieren
  const [opened, { open, close }] = useDisclosure(false);
  const [sortType, setSortType] = useState(''); // State für Sortierung
  const [searchText, setSearchText] = useState(''); // State für Suchtext

    // Funktion zum Laden der Daten
    useEffect(() => {
      const fetchDataAndProcessPictures = async () => {
        try {
          const response = await Api.people.getMyNetwork();
          const data = response.data;
    
          const dataWithFullName = data.map(obj => ({
            ...obj,
            fullname: `${obj.first_Name} ${obj.last_Name}`
          }));
          console.log('Daten:', dataWithFullName);
    
          if (data === undefined || data.length === 0) {
            setNoDataMessage(t('noData'));
          } else {
            const data2 = await updatePictureURLs(dataWithFullName); // 'await' wird hier korrekt verwendet
            console.log('KontaktlistePC_data2', data2);
            setFilteredData(data2);
            console.log('KontaktlistePC_filteredData', data2);
          }
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchDataAndProcessPictures();
    }, [ContactlistChanged]);

    async function updatePictureURLs(data) {
      return await Promise.all(data.map(async (employee) => {
        if (employee.pictureURL) {
          const url = employee.pictureURL;
          console.log("URL: ", url);
          const idMatch = url.match(/id=(\d+)/);
          if (idMatch) {
            const id = idMatch[1];
            try {
              console.log(`Dokumenten-ID: ${id}`);
              const blob = await Api.Dokuments.getDokuments(id);
              const objectURL = URL.createObjectURL(blob);
              console.log("Download-URL:", objectURL);
              employee.pictureURL = objectURL;
            } catch (error) {
              console.error("Fehler beim Anzeigen des Dokuments:", error);
            }
          }
        }
        return employee;
      }));
    };


    useEffect(() => {
      console.log("Mitgliederliste?", Mitgliederliste);
    }, [Mitgliederliste]);

  
      //Funktion zum Löschen eines Kontakts
    const handleDelete = (id, relationshipID) => {

      Api.people.deleteFromNetwork(id, relationshipID)
        .then(() => {
          // Lade die aktualisierten Daten nach erfolgreichem Löschen
          notifySuccess(t('removedFromNetwork'));
          setContactlistChanged(prevState => !prevState);
        })
        .catch(error => {
          console.error('Fehler beim Löschen:', error);
        });
    };

    //Funktion zum Editieren eines Kontakts
    const handleEdit = (id) => { 
      console.log('Kontakt wird geändert. ID:',user.id);
      const payload = {
        
          id: formData.id,
          First_Name: formData.firstName,
          Last_Name: formData.lastName,
          positionname: formData.position,
          EMail: formData.email,
        
        // relationship: {
        //   id: 2,
        // },
        // person_MeID: user.id,
      };

      Api.people.updateMyContact(formData.id, payload).then(response => {
        const updatedContact = response.data;
        setDataArray(prevDataArray => prevDataArray.map(contact => 
          contact.id === updatedContact.id ? updatedContact : contact
        ));
      setContactlistChanged(prevState => !prevState);
      }).catch(error => {
        console.error(error);
      });
      close();
      console.log('Kontakt wurde geändert');
      notifySuccess(t('editContactMessage'));

    };

    /*In diesem Code wird die map Funktion verwendet, um durch jedes Kontaktobjekt in dataArray zu gehen. 
    Wenn die ID des Kontakts der ID des aktualisierten Kontakts entspricht, wird der Kontakt durch den aktualisierten Kontakt ersetzt. 
    Andernfalls bleibt der Kontakt unverändert.
    */

    //Const und Funktione für das Editieren eines Kontakts
    const [formData, setFormData] = useState({
      id : '',
      firstName: '',
      lastName: '',
      position: '',
      email: '',
    });

    const customStyles = {
      overlay: {
        zIndex: 9999, // Set a high value for zIndex
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // You can adjust the background color and opacity of the overlay
      },
      content: {
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: '0',
        padding: '0',
        position: 'absolute',
      },
    };

    function handleInputChange(event) {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    }

     // Funktion zum Sortieren des Arrays basierend auf dem Sortiertyp
  const sortArray = (type) => {
    const types = {
      "Alphabetisch aufsteigend": 'last_Name',
      "Alphabetisch absteigend": 'last_Name',
    };
    const sortProperty = types[type];
    const sorted = [...filteredData].sort((a, b) => {
      if (type.includes("absteigend")) {
        return b[sortProperty].localeCompare(a[sortProperty]);
      } else {
        return a[sortProperty].localeCompare(b[sortProperty]);
      }
    });
    setFilteredData(sorted);
  };

  // Funktion zum Filtern des Arrays basierend auf dem Suchtext
  const filterArray = (value) => {
    const filtered = dataArray.filter(obj => obj.fullname.toLowerCase().includes(value.toLowerCase()));
    setFilteredData(filtered); // Aktualisieren Sie das gefilterte Array für die Anzeige
  };

    const textBoxOptions = {
      placeholder: 'Suchen...',
      width: 180,
      value: searchText,
      onValueChanged: ({ value }) => {
        setSearchText(value);
        if (value) {
          filterArray(value); // Filtern Sie das Array, wenn der Suchtext gesetzt ist
        } else {
          setFilteredData(dataArray); // Setzen Sie filteredData auf das vollständige Array, wenn der Suchtext geleert wird
        }
      },
      buttons: [{
        name: 'search',
        location: 'after',
        options: {
          icon: 'search',
          stylingMode: 'text',
          onClick: () => filterArray(searchText) // Startet die Suche, wenn der Button gedrückt wird
        }
      }],
    };
  
    const dropDownOptions = {
      items: [
        "Alphabetisch aufsteigend",
        "Alphabetisch absteigend",
      ],
      width: 180,
      text: "Sortieren nach...",
      onItemClick: ({ itemData }) => {
        setSortType(itemData);
        sortArray(itemData);
      }
    };
  
    const resetbuttonOptions = {
      icon: 'revert',
      stylingMode: 'contained',
      onClick: () => {
        setSearchText('');
        setFilteredData(dataArray);
      }
    };

    if (dataArray.length === 0) {
      return (
        <div className="container">
          <h2>{noDataMessage}</h2>
        </div>
      );
    }

   

  return (
    <>

        <Toolbar>

          <Item
            location={'before'}
            widget="dxButton"
            options={resetbuttonOptions}
          />

          <Item  
            location={'before'}
            widget="dxTextBox" 
            options={textBoxOptions}
            />

          <Item
            location={'after'}
            widget="dxDropDownButton"
            options={dropDownOptions}
          />

        </Toolbar>

        <KontaktListeKomponente 
          setSelectedName={setSelectedName} 
          setFormData={setFormData}
          open={open}
          onConnect={onConnect}    // Vernetzten
          oninvite={oninvite}     // Einladung
          setModalData={setModalData}
          setIsModalOpen={setIsModalOpen}
          filteredData={filteredData}
          Mitgliederliste={Mitgliederliste}
          onWithdrawConnectionRequest={onWithdrawConnectionRequest}
        /> 

        <Modal opened={opened} onClose={close} withCloseButton={false} centered closeOnClickOutside={false}>

        <div className="modal-head">
            {t('titleContactEdit')}
        </div>


        <form className='form'>

        
            <label className="label">{t('firstName')}</label>
            <Input
            type="text"
            name='firstName'
            placeholder={t('firstName')}
            value={formData.firstName}
            onChange={handleInputChange}
            styles={(theme) => ({
                input: {
                  '&:focus': {
                    borderColor: '#39b201',
                  },
                },
              })}
            />

            <label className="label">{t('lastName')}</label>
            <Input
            type="text"
            name='lastName'
            placeholder={t('lastName')}
            value={formData.lastName}
            onChange={handleInputChange}
            styles={(theme) => ({
                input: {
                  '&:focus': {
                    borderColor: '#39b201',
                  },
                },
              })}
            />

            <label className="label">{t('eMail')}</label>
            <Input
            type="email"
            name='email'
            placeholder={t('eMail')}
            value={formData.email}
            onChange={handleInputChange}
            styles={(theme) => ({
                input: {
                  '&:focus': {
                    borderColor: '#39b201',
                  },
                },
              })}
            />

            <label className="label">{t('positionName')}</label>
            <Input
            type="text"
            name='position'
            placeholder={t('positionName')}
            value={formData.position}
            onChange={handleInputChange}
            styles={(theme) => ({
                input: {
                  '&:focus': {
                    borderColor: '#39b201',
                  },
                },
              })}
            />
            
        </form>


        <div className="buttons">
            <Button id='cancelButton' onClick={() => { close() }} color='red'>{t('cancel')}</Button>
            <Button
                styles={(theme) => ({
                    root: {
                    backgroundColor: '#39b201',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#39b201',
                        color: 'white',
                    },
                }})} 
                onClick={handleEdit}>{t('btnSave')}
            </Button>
        </div>

        </Modal>

        <Modal opened={isModalOpen} onClose={close} withCloseButton={false} centered closeOnClickOutside={false}>  
            <p className='deleteDatensatz'>{t('deleteContact')}</p>
                <div className='yesNoButtons'>
                    <Button
                        id='cancelButton'
                        color='red'
                        onClick={() => { setModalData(null); setIsModalOpen(false); }}
                    >
                        {t('no')}
                    </Button>

                    <Button
                        styles={(theme) => ({
                            root: {
                            backgroundColor: '#39b201',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#39b201',
                                color: 'white',
                            },
                        }})}
                        onClick={() => {
                        handleDelete(modalData.id, modalData.relationshipID);
                        setIsModalOpen(false);
                        }}
                    >
                        {t('yes')}
                    </Button>
                </div>
        </Modal>
    </>
  );
}

export default KontaktlistePC;
