import React, {useState, useEffect, useMemo} from 'react';
import CustomStore from 'devextreme/data/custom_store';
import SelectBox from "devextreme-react/select-box";
import Form, {Item, SimpleItem, EmptyItem, Label} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import {Button, LoadIndicator} from 'devextreme-react';
import {useAuth} from '../../contexts/auth';
import {DropDownBox} from 'devextreme-react/drop-down-box'
import {TreeView} from 'devextreme-react/tree-view'
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Column, DataGrid, Editing, Lookup, RequiredRule} from 'devextreme-react/data-grid';
import i18n from "i18next";
import Api from "../../utils/api"
import * as _ from 'lodash'
import AddMitglied from '../../components/Mitgliederliste-Mobil/AddMitglied';
import MitgliederMobile from '../../components/Mitgliederliste-Mobil/MitgliederMobile';
import { useScreenSizeClass } from '../../utils/media-query';
import './Aufgabe.css';
import { HtmlEditor, Toolbar, Item as EditorItem, MediaResizing } from 'devextreme-react/html-editor';
import AddDokuments from '../../components/Documents/AddDokuments';
import DocumentsList from '../../components/Documents/DocumentsList';
import AufgabeEditor from '../../components/HTMLEditor/aufgabeEditor';
import ProjectTaskPicture from '../../components/Documents/ProjectTaskPicture';

export default function (props) {
    const [loading, setLoading] = useState(false);
    const [Task, setTask] = useState({});
    const TaskDefault = {};
    const [Mitglieder, setMitglieder] = useState([]);
    const p = i18n.getFixedT(null, 'project');
    const location = useLocation();
    const t = i18n.getFixedT(null, 'task');
    const history = useHistory();
    const {user} = useAuth(); //TODO remove
    const [Documents, setDocuments] = useState([]);
    const screenSizeClass = useScreenSizeClass();
    const [buttonVisible, setButtonVisible] = useState(true); // Edit und Delete Button
    const [MentionsKontakte, setMentionsKontakte] = useState([]);

   // const navigate = useNavigate();
   

    // const url = (() => { //TODO REMOVE LATER
    //     if (window.location.hostname.indexOf("host") > 0) {
    //         return "https://api.maprota.com/";
    //     } else {
    //         return "https://api.maprota.com/";
    //     }
    // })();

    const handleDelete = (id) => (event) => {
        event.preventDefault(); // Zum verhindern des reloads der Seite
        console.log('Gelöschtes Mitglied mit ID Nummer:', id); // Zum Überprüfen der id
        setMitglieder(Mitglieder.filter(people => people.id !== id)); //Hier statt member => people => nur noch in der Nutzlast vorhanden nach Aufgabe Speichern
    };

    function get_Empty_GUID(){
        return "00000000-0000-0000-0000-000000000000";
    };

    function getTaskID(){
      //  const { id_tmp } = useParams();
        
        const path = location.pathname;
        let parts = path.split('/');
        const id = parts.pop()  //Number(parts.pop() || parts.pop());
        if(id  == "Aufgabe" ){
            return null
        }
        else
        {
              return id //  isNaN(id) ? -1 : id
        }
        
    }

    function handleNotification(response, action) {
        if (response >= 200 && response < 300) {
            switch (action) {
                case "insert":
                    notify(p('CreateTaskSuccess'), 'success', 3000);
                    break;
                case "update":
                    notify(p('UpdateTaskSuccess'), 'success', 3000);
                    break;
                default:
                    notify(p('errorSaving'), 'error', 3000);
                    break;
            }
        } else {
         notify(p('errorSaving'), 'error', 3000);
        }
    }

    useEffect(() => {
        Api.people.getMyNetwork()
            .then(response => {
                const updatedData = response.data.map(person => ({
                    ...person,
                    fullname: person.first_Name + " " + person.last_Name
                }));
                const fullnames = updatedData.map(person => ({ fullname: person.first_Name + " " + person.last_Name}));
                setMentionsKontakte(fullnames);
                setLoading(false);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Mitglieder:', error);
            });
    }, []);


    useEffect(() => {
       // const params = useParams();
       // console.log(params); 

        const taskID = getTaskID()
        console.log("Aufgabe: taskid wurde übermittelt: " + taskID)
        if (taskID != null  && taskID != get_Empty_GUID()) {
            console.log("Aufgabe: Lade Aufgabe mit der ID: " + taskID)
            setLoading(true)
            loadTask(taskID)
        }
        else {
            TaskDefault.id = get_Empty_GUID();
            TaskDefault.subject = String.EmptyItem;
            TaskDefault.start = new Date(Date.now() + 0 * 24 * 60 * 60 * 1000)
            TaskDefault.follow_up = new Date(Date.now() + 6 * 24 * 60 * 60 * 1000)
            TaskDefault.end = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
            TaskDefault.status_Procent = 0;
            TaskDefault.description = null;
            TaskDefault.company = null;
            TaskDefault.people = Mitglieder;
            setTask(TaskDefault);
        }
    }, []);

    function loadTask(id) {
        taskDS.byKey(id)
            .then(dataObj => {
                setTask(dataObj)
                setMitglieder(dataObj.people)
                setDocuments(dataObj.documents)
                setLoading(false)
            }).catch(error => {
            setLoading(false)
            notify({
                message: i18n.t('notify:failedLoading'),
                type: "error",
                displayTime: 5000
            });

        })

    };

    useEffect(() => {
        memberDS.load()
            .then(data => {
                console.log('Mitglieder geladen:', data);
            })
            .catch(error => {
                console.error('Fehler beim Laden der Mitglieder:', error);
            });
    }, [Mitglieder]);

    function goBack() {
        history.goBack();
    };

    const taskDS = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.tasks.getTasks()
                .then(response => response.data);
        },
        remove: (key) => {
            Api.tasks.deleteTask(key)
                .catch(() => {
                    throw 'Network error'
                });
        },
        byKey: (key) => {
            return Api.tasks.getTaskByKey(key)
                .then(response => response.data);
        },
        insert: (values) => {
            Api.tasks.createTask(values)
                .then(response => {
                    console.log('Response:', response);
                    handleNotification(response.status, "insert");
                })
                .catch(() => {
                    handleNotification(500, "insert");
                });
        },
        update: (key, values) => {
            Api.tasks.updateTask(key, values)
            .then(response => {
                handleNotification(response.status, "update");
            })
                .catch(() => {
                    throw 'Network error'
                });
        }
    });


    const companyDS = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            load: () => {
                return Api.companies.getCompanies()
                    .then(response => response.data);

            },
            valueExpr: 'id',
            displayExpr: 'Name'


        });
    }, []);


    var roleDS = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'Name',
            load: () => {
                return Api.roles.getRoles()
                    .then(response => response.data);
            }
        });
    }, []);

    var peopleDS = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'Name',
            load: () => {
                return Api.people.getPeople()
                    .then(response => {
                        return response.data
                    });
            }
        });
    }, []);

    let memberDS = useMemo(() => {
        return new CustomStore({

            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            load: () => {

                return Mitglieder.map(item => {
                    item.member.eMail = item.member.id
                    return item
                })
            },
            insert: (item) => {

                if (item && item.member && item.rolle && item.member.eMail && item.rolle.id) {
                    const arr = Mitglieder
                    arr.push(item)
                    setMitglieder(arr)
                }
                setTask({
                        ...Task,
                        people: Mitglieder
                    }
                )
            },
            update: (item, updatedValue) => {
                if (updatedValue.rolle)
                    item.rolle = updatedValue.rolle
                if (updatedValue.member) {
                    item.member = updatedValue.member
                    item.member.id = item.member.eMail
                }

                setTask({
                        ...Task,
                        people: Mitglieder
                    }
                )
            },
            remove: (deletedItem) => {
                if (deletedItem.id) {
                    let members = Mitglieder;
                    console.log('Mitglied zu löschen:', deletedItem);
                    console.log('Mitglieder vor dem Löschen:', members);
                    members = members.filter(item => !(item.member.id === deletedItem.member.id && item.rolle.id === deletedItem.rolle.id));
                    console.log('Mitglieder nach dem Löschen:', members);
                    setMitglieder(members)
                    setTask({
                            ...Task,
                            people: members
                        }
                    )
                }
            }
        });
    }, [Task, Mitglieder]);


    const departmentDS = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'Name',
            load: () => {
                return Api.departments.getDepartments()
                    .then(response => response.data);
            }
        });
    }, []);


    const projectDS = new CustomStore({
        key: 'id',
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.projects.getProjects()
                .then(response => {
                    return response.data
                })
                .then(data => data.filter(item => item.parent_Project === null))
                .catch(error => {
                    console.log(error.message)
                });
        }

    });

    function onUpdateDropdownType(e) {
        onUpdateDropdown(e, "department")
    }

    function onUpdateDropdownCompanyFrom(e) {
        onUpdateDropdown(e, "company")
    }

    function onUpdateDropdownCompanyTo(e) {
        onUpdateDropdown(e, "companyTo")
    }

    function onUpdateDropdownProject(e) {
        if (e) {
            setTask({
                ...Task,
                "project": e.itemData ? {
                    id: e.itemData.id,
                    subject: e.itemData.subject

                } : null // This code replace the font object
            });

        }
    }

    function onUpdateDropdown(e, key) {
        if (e) {
            setTask({
                ...Task,
                [key]: e.selectedItem ? {
                    id: e.selectedItem.id
                } : null // This code replace the font object
            });

        }
    };

    function renderType() {
        return renderDropdown("department", departmentDS, onUpdateDropdownType)
    }

    function renderCompanyFrom() {
        return renderDropdown("company", companyDS, onUpdateDropdownCompanyFrom)
    }

    function renderCompanyTo() {
        return renderDropdown("companyTo", companyDS, onUpdateDropdownCompanyTo)
    }

    function renderDropdown(value, ds, cb) {
        return (
            <SelectBox
                dataSource={{store: ds}}
                valueExpr="id"
                value={Task[value] ? Task[value].id : ""}
                displayExpr="name"
                searchEnabled={true}
                showClearButton={true}
                onSelectionChanged={cb}
            />
        );
    }


    function renderProjects() {
        return <DropDownBox
            valueExpr="ID"
            displayExpr="name"
            contentRender={treeViewRender}
            value={Task.project ? Task.project.subject : ""}
            showClearButton={true}
        />
    };


    function renderGridMembers() {

        return (
            <DataGrid 
            dataSource={memberDS}
            columnAutoWidth={true} >
                <Editing
                    mode="batch"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}
                />
                <Column dataField="member.id" caption="ID" allowEditing={false} visible={false}> </Column>
                <Column dataField="member.eMail" caption={i18n.t('common:email')}>
                    <Lookup dataSource={peopleDS} valueExpr="id" displayExpr="eMail"/>
                </Column>

                <Column dataField="rolle.id" caption={t('role')}>
                    <Lookup dataSource={roleDS} valueExpr="id" displayExpr="name"/>
                </Column>

            </DataGrid>);
    }


    function uploadcomplet(e) {
        //TODO Notify
        console.log('Upload fertig :') // "top"
        console.log(Object.values(e));
        console.log(e.request.response.text);
    }

    function renderDataGridDokumente(data) {

        return (
            <div>
                <AddDokuments />
                <DocumentsList Documents={Documents} />
            </div>
        );
    }

    function onClick_Button() {
        if (Task.id != get_Empty_GUID() ) {
            taskDS.update(Task.id, Task)
        } else {
            taskDS.insert(Task)
        }
    };

    function treeViewRender() {
        return (
            <TreeView dataSource={projectDS}
                      keyExpr="id"
                      itemsExpr="subProjects"
                      displayExpr="subject"
                      selectByClick={true}
                      onItemSelectionChanged={onUpdateDropdownProject}
            />
        );
    }

    var customStoreRollen = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'Name',
            load: () => {
                return Api.roles.getRoles()
                    .then(response => response.data);
            }
        });
    }, []);

    var customStoreMitglieder = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'first_Name',
            load: () => {
                return Api.people.getMyNetwork()
                    .then(response => response.data);
            }
        });
    }, []);

    function renderDataGrid(data) {

        //  if (PROJECT.people != null) {

        //      console.log(Object.values(Mitglieder));
        console.log('Render Mitglieder in :') // "top"
        console.log(Object.values(Mitglieder));
        return (    
               <>
                        <AddMitglied 
                        p={p} 
                        Mitglieder={Mitglieder}
                        memberDS={memberDS}
                        buttonVisible={buttonVisible}
                        me={user}
                        />
                        <MitgliederMobile 
                            p={p} 
                            Mitglieder={Mitglieder}
                            setMitglieder={setMitglieder}
                            memberDS={memberDS}
                            buttonVisible={buttonVisible}              
                        />
                        </>
            );
    }

    function renderDescription() {   
        return (
            <AufgabeEditor Task={Task} MentionsKontakte={MentionsKontakte}/>
        )
    }

    function renderPicture() {
        return (
            <ProjectTaskPicture />
        );
    }

    return <React.Fragment>

        <h2 className={'content-block'}>{t('title')}</h2>
        <div className={'content-block'}>
            <Button
                type="normal"
                text={i18n.t('common:btnBack')}
                onClick={goBack}
            />
            <div className={'dx-card responsive-paddings'}>
                <div className="form-container">

                    {
                        loading ? <LoadIndicator visible={loading}/> :
                            <>
                       
                            <Form
                                colCount={12}
                                formData={Task}
                                // onFieldDataChanged={onValueChanged}
                            >

                              {/*  <Item dataField="id" colSpan={12} disabled={true} caption="ID"/> */}
                                <Item dataField="subject" colSpan={8} isRequired={true}>
                                    <Label text={t('subject')}/>
                                </Item>

                                <SimpleItem
                                    colSpan={4}
                                    dataField="picture"
                                    render={renderPicture}
                                />

                                <Item dataField="start" editorType="dxDateBox" dataType="date" format="dd.MM.yyyy"
                                      colSpan={4}>
                                    <Label text={t('start')}/>
                                </Item>

                                <Item dataField="follow_up" editorType="dxDateBox" dataType="date" format="dd.MM.yyyy"
                                      colSpan={4}>
                                    <Label text={t('followUp')}/>
                                </Item>

                                <Item dataField="end" editorType="dxDateBox" dataType="date" format="dd.MM.yyyy"
                                      colSpan={4}>
                                    <Label text={t('end')}/>
                                </Item>

                                <Item dataField="status_Procent" editorType="dxNumberBox" colSpan={4}
                                      editorOptions={{
                                          max: 100,
                                          min: 0
                                      }}
                                >
                                    <Label text={t('status')}/>
                                </Item>


                                <SimpleItem dataField="Type" render={renderType} colSpan={4}>
                                    <Label text={t('type')}/>
                                </SimpleItem>
                                {/*<SimpleItem dataField="company" render={renderCompanyFrom} colSpan={3}>*/}
                                {/*    <Label text={t('companyFrom')}/>*/}
                                {/*</SimpleItem>*/}

                                {/*<SimpleItem dataField="CompaniesTo" render={renderCompanyTo} colSpan={3}>*/}
                                {/*    <Label text={t('companyTo')}/>*/}
                                {/*</SimpleItem>*/}

                                <SimpleItem render={renderProjects} colSpan={4}>
                                    <Label text={t('projects')}/>
                                </SimpleItem>
                                <EmptyItem colSpan={12}/>
                                {!loading && (
                                <SimpleItem dataField="description" colSpan={12}
                                            render={renderDescription}
                                        >
                                    <RequiredRule message={i18n.t('common:requiredInput')} />
                                    <Label text={p('descr')}/>
                                </SimpleItem>
                                )}
                                {/* <SimpleItem colSpan={12} dataField="Mitglieder" name="order" render={renderGridMembers}>
                                    <Label text={t('participants')}/>
                                </SimpleItem> */}
                               
                             
                                <SimpleItem colSpan={12} dataField="Dokumente" name="documents"
                                            render={renderDataGridDokumente}>
                                    <Label text={t('documents')}/>
                                </SimpleItem>

                                <SimpleItem colSpan={12} dataField="Mitglieder" name="order"
                                        editorOptions={{caption: "Mitglieder"}} caption="Mitglieder"
                                        render={renderDataGrid}>
                                    <Label text={p('participants')}/>
                                </SimpleItem>
                               
                            </Form>
                            
                    {setTask.id === get_Empty_GUID() ? (
                        <Button
                        colSpan={4}
                        icon="plus"
                        text={t('btnCreateTask')}
                        onClick={onClick_Button}
                        useSubmitBehavior={true}
                        className='myButton'
                        />
                        ) : (
                        <Button

                        colSpan={4}
                        icon="plus"
                        text={t('btnSaveTask')}
                        onClick={onClick_Button}
                        useSubmitBehavior={true}
                        className='myButton'
                        />
                        )}
                        </>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
};