import React, { useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import FilterBuilder, { Field } from 'devextreme-react/filter-builder';
import Button from 'devextreme-react/button';

const FilterPopup = ({isPopupVisible, setPopupVisibility, t, allRoles, dataArray, setFilteredData, filteredData, selectedFilter}) => {

    const [filter, setFilter] = useState(null);

    const togglePopup = () => {
        setPopupVisibility(!isPopupVisible);
    };

    const applyFilter = () => {

        if (!filter || !filter.value) {
            console.log("Zeige filter an: ", filter);
            setFilteredData(dataArray);
            return;
        } // Wenn kein Filter gesetzt ist, zeige alle Daten an

        const object = filter.value[0];
        const operation = filter.value[1];
        let value = filter.value[2];

        let newObject;
        if (object === "Betreff" ) {
            newObject = "subject";
        } else if (object === "Start") {
            newObject = "start";
        } else if (object === "Ende") {
            newObject = "end";
        } else if (object === "Unternehmen") {
            newObject = "company";
        };

        

        console.log("Filter Objekt:", object); 
        console.log("Filter Operation:", operation);
        console.log("Filter Value:", value);
        console.log("Zu Filternde Daten:", dataArray)
        
        switch (object) {
            case t('subject'):
            case t('company'):
                switch (operation) {
                    case 'contains':
                        setFilteredData(dataArray.filter((item) => item[newObject].includes(value)));
                        break;
                    case 'notcontains':
                        setFilteredData(dataArray.filter((item) => !item[newObject].includes(value)));
                        break;
                    case 'startswith':
                        setFilteredData(dataArray.filter((item) => item[newObject].startsWith(value)));
                        break;
                    case 'endswith':
                        setFilteredData(dataArray.filter((item) => item[newObject].endsWith(value)));
                        break;
                    case '=':
                        setFilteredData(dataArray.filter((item) => item[newObject] === value));
                        break;
                    case '<>':
                        setFilteredData(dataArray.filter((item) => item[newObject] !== value));
                        break;
                    case (operation === "=" && value === null):
                        setFilteredData(dataArray.filter((item) => item[newObject] === ''));
                        break;
                    case (operation === "<>" && value === null):
                        setFilteredData(dataArray.filter((item) => item[newObject] !== ''));
                        break;
                    default:
                        console.log('Unbekannte Operation: ', operation);
                }
                break;
            case t('start'):
            case t('end'):
                switch (operation) {
                    case '=':
                        setFilteredData(dataArray.filter((item) => {
                            let itemDate = new Date(item[newObject]);
                            itemDate.setHours(0,0,0,0); // Setze Stunden auf 0, damit nur Datum verglichen wird
                            let valueDate = new Date(value);
                            valueDate.setHours(0,0,0,0);
                            return itemDate.getTime() === valueDate.getTime();
                        }));
                        break;
                    case '<>': //Nicht gleich
                    setFilteredData(dataArray.filter((item) => {
                        let itemDate = new Date(item[newObject]);
                        itemDate.setHours(0,0,0,0);
                        let valueDate = new Date(value);
                        valueDate.setHours(0,0,0,0);
                        return itemDate.getTime() !== valueDate.getTime();
                    }));
                        break;
                    case '<':
                        setFilteredData(dataArray.filter((item) => {
                            let itemDate = new Date(item[newObject]);
                            itemDate.setHours(0,0,0,0);
                            let valueDate = new Date(value);
                            valueDate.setHours(0,0,0,0);
                            return itemDate.getTime() < valueDate.getTime();
                        }));
                        break;
                case '<=':
                    setFilteredData(dataArray.filter((item) => {
                        let itemDate = new Date(item[newObject]);
                        itemDate.setHours(0,0,0,0);
                        let valueDate = new Date(value);
                        valueDate.setHours(0,0,0,0);
                        return itemDate.getTime() <= valueDate.getTime();
                    }));
                    break;
                case '>':
                    setFilteredData(dataArray.filter((item) => {
                        let itemDate = new Date(item[newObject]);
                        itemDate.setHours(0,0,0,0);
                        let valueDate = new Date(value);
                        valueDate.setHours(0,0,0,0);
                        return itemDate.getTime() > valueDate.getTime();
                    }));
                    break;
                case '>=':
                    setFilteredData(dataArray.filter((item) => {
                        let itemDate = new Date(item[newObject]);
                        itemDate.setHours(0,0,0,0);
                        let valueDate = new Date(value);
                        valueDate.setHours(0,0,0,0);
                        return itemDate.getTime() >= valueDate.getTime();
                    }));
                    break;
                    case 'between':
                        setFilteredData(dataArray.filter((item) => new Date(item[newObject]) >= new Date(value[0]) && new Date(item[newObject]) <= new Date(value[1])));
                        console.log("Value[0]: ", new Date(value[0]));  
                        console.log("Value[1]: ", new Date(value[1]));  
                        break;
                    default:
                        console.log('Unbekannte Operation: ', operation);
                }
                break;
            default:
                console.log('Unbekanntes Objekt: ', object);
        }

        if (operation === "and") {
            console.log("AND Operation");
        }
    };

    return (
        <Popup
            visible={isPopupVisible}
            hideOnOutsideClick={true}
            onHiding={togglePopup}
            defaultWidth={window.innerWidth < 767 ? "90%" : 750}
            defaultHeight={300} 
            resizeEnabled={true}
            dragEnabled={true}
            position="top"
            showTitle={true}
            title='Filter Popup'
            showCloseButton={true}
        >

            <FilterBuilder onValueChanged={setFilter} width={window.innerWidth < 767 ? "70%" : "auto"}>
                <Field 
                    dataField={t('subject')}
                />
                <Field dataField={t('start')} dataType="date">
                    
                </Field>
                <Field dataField={t('end')} dataType="date">

                </Field>
                <Field
                    dataField={t('company')}
                    dataType="string"
                />
                {allRoles.map((role) => (
                    <Field
                        key={role}
                        dataField={role}
                        dataType="string"
                    />
                ))}
            </FilterBuilder>

            <div style={{position: 'absolute', right: '20px', bottom: '20px'}}>
                <Button
                    className='myButton'
                    text="Apply Filter"
                    onClick={applyFilter}
                />
            </div>
           
        </Popup>
    );
}
export default FilterPopup;