import React, {useState, useRef, useCallback, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Form, {

    SimpleItem,
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule, GroupItem
} from 'devextreme-react/form';
//import {TextBox} from 'devextreme-react'
import LoadIndicator from 'devextreme-react/load-indicator';
import './create-account-form.scss';
import CustomStore from "devextreme/data/custom_store";
import i18n from "i18next";
import { SelectBox} from "devextreme-react";
import Api from "../../utils/api";
import notify from "devextreme/ui/notify"
import {getDefaultLanguage, changeWebpageLanguage} from "../../utils/languageManager"
const DEFAULT_LANGUAGE = {
    "id": 1,
    "name": "Englisch"
}
export default function (props) {

   // const t = i18n.getFixedT(null, 'auth');

    const [languages, setLanguages] = React.useState();
    //const [selectedLanguage, setSelectedLanguage] = React.useState();

    const emailEditorOptions = { stylingMode: 'filled', placeholder: i18n.t('common:email'), mode: 'email' };
    const passwordEditorOptions = { stylingMode: 'filled', placeholder: i18n.t('common:password'), mode: 'password' };
    const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: i18n.t('common:confPassword'), mode: 'password' };


    const defaultUser = {
        email: 'sandra@example.com',
        avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png',
        name: 'Dummy User',
        //Vorname: 'Lars',
        //Nachname: 'Fuchs',
        Position: 'TESTER',
        Username: null,
        Password: null,
        token: null
    }

    const customStoreTerminliste = new CustomStore({

        key: ['ID']
        ,
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        insert: (values) => {
            defaultUser.Username = values.name// "TESTUSER";
            defaultUser.Password = values.Password;
            defaultUser.name = values.name;
            defaultUser.lang = values.language;

            const params = {
                "username": values.name,
                "password": values.Password,
                "language": values.language
            }
            Api.auth.createAccount(params)
                .then(handleErrors)
                .then(response => response.data)
                .then(data => {
                    notify({
                        message: data,
                        type: "success",
                        displayTime: 5000
                    });
                })
                .catch(error => {
                    notify({
                        message: error.message,
                        type: "error",
                        displayTime: 5000
                    });

                });
            //  .catch(() => { throw 'Network error' });
        }
    });
    function handleErrors(response) {
        if (response.status === 201) {
            return response;
        }else {
            throw Error(response.statusText);
        }
    }

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const formData = useRef({});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password } = formData.current;
    const lng = formData.current.lang ? formData.current.lang : DEFAULT_LANGUAGE;
    setLoading(true);
    // Send create account request
      console.log(email, password);
      defaultUser.name = email;
      defaultUser.Password = password;
      defaultUser.language = lng;
      await customStoreTerminliste.insert(defaultUser).then(
          data => {
              console.log('Success CSTORE:', data.token);
          }
      );
      console.log('Fertig Abruf DB:' + customStoreTerminliste);

    history.push('/login');
  }, [history]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

    function languageSelectionChanged(e) {
        if (e) {
            changeWebpageLanguage(e.selectedItem)
            formData.current.lang = (e.selectedItem)
        }
    }

    function renderComp() {

         if(languages !== undefined){
             getDefaultLanguage()
             console.log(i18n.languages)
            console.log(languages[0])
            return (
                <SelectBox
                    dataSource={languages}
                    valueExpr="id"
                    // placeholder={i18n.t('common:selectPlaceholder')}
                    displayExpr="name"
                    onSelectionChanged={languageSelectionChanged}
                    defaultValue={getDefaultLanguage().id}
                />
            )
    }
    }

    // function getDefaultLanguage(){
    //    const browserLng = navigator.language
    //
    //     changeWebpageLanguage(navigator.language)
    // }


    function loadLanguages(count = 0) {
        if(count > 3){
            notify({
                message: i18n.t('notify:failedLoading'),
                type: "error",
                displayTime: 5000
            });
        }
        else {
            Api.getLanguages()
                .then(res => res.data)
                .then(langs => {
                    setLanguages(langs)
                })
                .catch(err => {
                    loadLanguages(count ? ++count:1)
                })
        }
    }
    useEffect(() => {

        loadLanguages()

    }, []);

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message={i18n.t('common:requiredInput')} />
          <EmailRule message={i18n.t('common:invalidInput')} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message={i18n.t('common:requiredInput')} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message={i18n.t('common:requiredInput')} />
          <CustomRule
            message={i18n.t('auth:pswdNotMatch')}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
          <SimpleItem render={renderComp}>
              <Label text={i18n.t('profile:langSel')}/>
          </SimpleItem>
        {/*<SelectLanguageForm/>*/}

        <Item>
          <div className='policy-info'>
            {i18n.t('glossary:beforeText')}<Link to={'/login'}>{i18n.t('glossary:termsOfService')}</Link>{i18n.t('glossary:midText')}<Link to={'/login'}>{i18n.t('glossary:privacyPolicy')}</Link>{i18n.t('glossary:afterText')}
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : i18n.t('auth:btnCreateAccount')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            {i18n.t('glossary:accountExists?')}<Link to={'/login'}>{i18n.t('auth:login')}</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}


