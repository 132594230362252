import React from 'react';
import { RingProgress, Text, SimpleGrid  } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import './skillsRingProgress.css';

const SkillsRingProgress = () => {
    const matches = useMediaQuery('(max-width: 600px)');

    return (
        <SimpleGrid cols={matches ? 1 : 4}>
            <div className='ringDiv'>
                <RingProgress
                    sections={[{ value: 80, color: 'green' }]}
                    label={
                    <Text color="black" weight={700} align="center" size="xl">
                        80%
                    </Text>
                    }
                />
                <Text>Angular JS</Text>
            </div>

            <div className='ringDiv'>
                <RingProgress
                    sections={[{ value: 75, color: 'green' }]}
                    label={
                    <Text color="black" weight={700} align="center" size="xl">
                        75%
                    </Text>
                    }
                />
                <Text>Boostrap</Text>
            </div>

            <div className='ringDiv'>
                <RingProgress
                    sections={[{ value: 90, color: 'green' }]}
                    label={
                    <Text color="black" weight={700} align="center" size="xl">
                        90%
                    </Text>
                    }
                />
                <Text>PHP</Text>
            </div>

            <div className='ringDiv'>
                <RingProgress
                    sections={[{ value: 85, color: 'green' }]}
                    label={
                    <Text color="black" weight={700} align="center" size="xl">
                        85%
                    </Text>
                    }
                />
                <Text>Node</Text>
            </div>
        </SimpleGrid>
    );
};
export default SkillsRingProgress;