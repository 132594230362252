import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';
import sample from './login.mp4';
import { Footer } from '../../components';
import appInfo from '../../app-info';



export default ({title, description, children}) => (

    <div height={'100%'} width={'100%'} className={'with-footer single-card '}>
        <div className={'dx-card content'}>
            <div className={'header'}>
                <img src="../../logo192.png" alt="MaProTa" className={'image'}/>
                <div className={'title'}>
                    {title}
                </div>
                <div className={'description'}>{description}</div>
            </div>
            {children}
            
        </div>
        
        <video className='videoTag' autoPlay loop muted playsInline style={{ objectFit: 'cover', width: '100%', height: '100vh', position: 'fixed', top: 0, left: 0 }}>
            <source src={sample} type='video/mp4'/>
        </video>

            <div className="footer-content">
                <div className='copyright'>Copyright MaProTa© </div>
                <a href="https://www.maprota.com/de/impressum/" target="_blank" className='impressum'> Impressum </a> 
                <a href="https://www.maprota.com/de/datenschutzerklaerung/" target="_blank" className='datenschutz'>Datenschutz & AGB</a>
                <div className="version">{appInfo.Versionnr}</div>
            </div>
    </div>

);
