import React, { useState, useEffect } from 'react';
import HtmlEditor, { Toolbar, Item as EditorItem, MediaResizing } from 'devextreme-react/html-editor';

function AufgabeEditor({ Task, MentionsKontakte }) {
    const [fileUploaded, setFileUploaded] = useState(false);

    const FileUploaderOptions = {
        icon: 'image',
        onClick: function () {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            fileInput.style.display = 'none';

            fileInput.addEventListener('change', function (event) {
                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                        const base64String = event.target.result;
                        Task.description += `<img src="${base64String}" alt="${file.name}">`;
                        setFileUploaded(prevState => !prevState);
                        console.log('Beschreibung', Task.description);
                    };
                    reader.readAsDataURL(file);
                }
            });

            document.body.appendChild(fileInput);
            fileInput.click();
            document.body.removeChild(fileInput);
        }
    };

    const mentionsConfig = [{
        dataSource: MentionsKontakte,
        searchExpr: 'fullname',
        displayExpr: 'fullname',
        valueExpr: 'fullname',
    }];

    useEffect(() => {
        // Dieser Effekt wird ausgeführt, wenn sich fileUploaded ändert
    }, [fileUploaded]);

    return (
        <HtmlEditor
            mentions={mentionsConfig}
            onValueChanged={(e) => Task.description = e.value}
            defaultValue={Task.description}
            key={fileUploaded} // Schlüssel hinzufügen, um den Editor neu zu rendern
        >
            <MediaResizing enabled={true} />
            <Toolbar>
                <EditorItem formatName="undo" />
                <EditorItem formatName="redo" />
                <EditorItem formatName="separator" />
                <EditorItem formatName="bold" />
                <EditorItem formatName="italic" />
                <EditorItem formatName="strike" />
                <EditorItem formatName="underline" />
                <EditorItem formatName="separator" />
                <EditorItem formatName="alignLeft" />
                <EditorItem formatName="alignCenter" />
                <EditorItem formatName="alignRight" />
                <EditorItem formatName="alignJustify" />
                <EditorItem formatName="separator" />
                <EditorItem formatName="orderedList" />
                <EditorItem formatName="bulletList" />
                <EditorItem formatName="separator" />
                <EditorItem formatName="header" />
                <EditorItem formatName="separator" />
                <EditorItem formatName="color" />
                <EditorItem formatName="background" />
                <EditorItem formatName="separator" />
                <EditorItem formatName="link" />
                <EditorItem widget="dxButton" options={FileUploaderOptions} />
            </Toolbar>
        </HtmlEditor>
    );
}

export default AufgabeEditor;