import React, {useState} from 'react';
import Toolbar, {Item} from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import {Template} from 'devextreme-react/core/template';
import Autocomplete from '../usersearch_autocomplete/autocomplete'
import {useScreenSize} from "../../utils/media-query";

export default ({menuToggleEnabled, title, toggleMenu}) => {

    const {isXSmall, isSmall} = useScreenSize();
    const [isSearchBarVisible, setSearchBarVisible] = useState(false)
    function getSearchBar() {
        return <Autocomplete isSearchBarVisible={isSearchBarVisible} setSearchBarVisible={setSearchBarVisible} />
    }

    function getDemo(){
        return  (
            <p style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '1.2em',
                backgroundColor: '#f0f0f0',
                padding: '5px',
                borderRadius: '3px'
            }}>
               Minimum Viable Product VERSION
            </p>
        );
    }
    function getDemoMini(){
        return  (
            <p style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '1.2em',
                backgroundColor: '#f0f0f0',
                padding: '5px',
                borderRadius: '3px'
            }}>
               MVP VERSION
            </p>
        );
    }

    return (


        <header className={'header-component'}>
            <Toolbar className={'header-toolbar'}>
                <Item
                    visible={menuToggleEnabled && !isSearchBarVisible}
                    location={'before'}
                    widget={'dxButton'}
                    cssClass={'menu-button'}
                >
                    <Button icon="menu" stylingMode="text" onClick={toggleMenu}/>

                </Item>
                <Item
                    location={'before'}
                    cssClass={'header-title'}
                    visible={!!title && !isSearchBarVisible}
                >
                    <div className={"dx-toolbar dx-toolbar-label"}>{title}</div>
                </Item>

                <Item
                    location={isXSmall || isSmall ? 'after': 'center'}
                    cssClass={'header-title'}
                    render={isXSmall || isSmall ? getDemoMini : getDemo}
                    visible={!isSearchBarVisible}
                />

                <Item
                    location={isXSmall || isSmall ? 'after': 'center'}
                    cssClass={'header-title'}
                    render={getSearchBar}
                />

                <Item
                    location={'after'}
                    locateInMenu={'auto'}
                    menuItemTemplate={'userPanelTemplate'}
                >
                    <Button
                        className={'user-button authorization'}
                        width="auto"
                        height={'100%'}
                        stylingMode={'text'}
                    >
                        <UserPanel menuMode={'context'}/>
                    </Button>
                </Item>
                <Template name={'userPanelTemplate'}>
                    <UserPanel menuMode={'list'}/>
                </Template>
            </Toolbar>
        </header>
    );

}
