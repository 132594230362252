import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import Api from '../../utils/api';
import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';
import EventBus from '../../components/Mitgliederliste-Mobil/EventBus';
import { ScrollView } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button } from '@mantine/core';

const formatDate = new Intl.DateTimeFormat('de-DE').format;


const AufgabenPC = () => {
    const [dataArray, setDataArray] = useState([]);
    const [noDataMessage, setNoDataMessage] = useState('');
    const t = i18n.getFixedT(null, 'taskList');
    const [filteredData, setFilteredData] = useState([]); // Gefilterte Daten für die Anzeige
    const [searchText, setSearchText] = useState(''); // State für Suchtext
    const [sortType, setSortType] = useState(''); // State für Sortierung
    const history = useHistory();
    const m = i18n.getFixedT(null, 'modal');
    const [modalData, setModalData] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);

    // Funktion zum Laden der Daten
    useEffect(() => {
    Api.tasks.getTasks().then(response => {
    const data = response.data;

    if (data.length === 0) {
      setNoDataMessage(t('noData'));
    } else {
      setDataArray(data);
      setFilteredData(data);
    }
    }).catch(error => {
    console.error(error);
    });
    }, []);

    // Funktion zum Sortieren des Arrays basierend auf dem Sortiertyp
    const sortArray = (type) => {
        const types = {
        "Alphabetisch aufsteigend": 'subject',
        "Alphabetisch absteigend": 'subject',
        "Startdatum aufsteigend": 'start',
        "Startdatum absteigend": 'start',
        "Enddatum aufsteigend": 'end',
        "Enddatum absteigend": 'end',
        };
        const sortProperty = types[type];
        const sorted = [...filteredData].sort((a, b) => {
        if (type.includes("absteigend")) {
            return b[sortProperty].localeCompare(a[sortProperty]);
        } else {
            return a[sortProperty].localeCompare(b[sortProperty]);
        }
        });
        setFilteredData(sorted);
    };

    //Funktion zum auswählen einer Aufgabe
    function onRowClick(obj) {
        const newSelectedTask = obj;
        EventBus.emit('selectedTaskChanged', newSelectedTask); // Ereignis auslösen mit emit
    }

    // Function to handle task deletion
    const handleDelete = (id) => {
    Api.tasks
      .deleteTask(id)
      .then(() => {
        // Load updated data after successful deletion
        return Api.tasks.getTasks();
      })
      .then((response) => {
        setDataArray(response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Löschen:', error);
      });
    };

    const DataRow = ({ obj }) => {
        return (
        <React.Fragment>
            <tr key={obj.id} className='tbodycss' onClick={() => onRowClick(obj)}>
                <td data-label={t('subject')}>{obj.subject}</td>
                <td data-label={t('start')}>{formatDate(new Date(obj.start))}</td>
                <td data-label={t('end')}>{formatDate(new Date(obj.end))}</td>
                <td data-label={t('status')}>{obj.status_Procent}</td>
                <td data-label={t('followUp')}>{formatDate(new Date(obj.follow_up))}</td>
                <td data-label={t('company')}>{obj.company == undefined ? '' : obj.company.name }</td>
                <td data-label={t('department')}>{obj.department == undefined ? '' : obj.department.name }</td>
              {/*  <td>
                    <div className="DeleteButtons">
                    <MdDelete
                        size={30}
                        color="#4CBB17"
                        onClick={() => {
                        setModalData(obj);
                        {open()}
                        }}
                    />
                    </div>
                </td> */}
            </tr>
        </React.Fragment>
        );
    };

    // Funktion zum Filtern des Arrays basierend auf dem Suchtext
    const filterArray = (value) => {
        const filtered = dataArray.filter(obj => obj.subject.toLowerCase().includes(value.toLowerCase()));
        setFilteredData(filtered); // Aktualisieren Sie das gefilterte Array für die Anzeige
    };

    // Optionen für die Textbox
    const textBoxOptions = {
        placeholder: 'Suchen...',
        width: 180,
        value: searchText,
        onValueChanged: ({ value }) => {
          setSearchText(value);
          if (value) {
            filterArray(value); // Filtern Sie das Array, wenn der Suchtext gesetzt ist
          } else {
            setFilteredData(dataArray); // Setzen Sie filteredData auf das vollständige Array, wenn der Suchtext geleert wird
          }
        },
        buttons: [{
          name: 'search',
          location: 'after',
          options: {
            icon: 'search',
            stylingMode: 'text',
            onClick: () => filterArray(searchText) // Startet die Suche, wenn der Button gedrückt wird
          }
        }],
    };

    // Optionen für die Dropdown-Schaltfläche
    const dropDownOptions = {
        items: [
          "Alphabetisch aufsteigend",
          "Alphabetisch absteigend",
          "Startdatum aufsteigend",
          "Startdatum absteigend",
          "Enddatum aufsteigend",
          "Enddatum absteigend",
        ],
        width: 180,
        text: "Sortieren nach...",
        onItemClick: ({ itemData }) => {
          setSortType(itemData);
          sortArray(itemData);
        }
    };

    // Optionen für die Zurücksetzen-Schaltfläche
    const resetbuttonOptions = {
        icon: 'revert',
        stylingMode: 'contained',
        onClick: () => {
          setSearchText('');
          setFilteredData(dataArray);
        }
    };

  return (
    <>
        <Toolbar>

            <Item
            location={'before'}
            widget="dxButton"
            options={resetbuttonOptions}
            />

            <Item  
            location={'before'}
            widget="dxTextBox" 
            options={textBoxOptions}
            />

            <Item
            location={'after'}
            widget="dxDropDownButton"
            options={dropDownOptions}
            />

        </Toolbar>

        <ScrollView
            height={'calc(100vh - 480px)'}
            width={'auto'}
            direction="vertical"
        >
            <section className="section">
                <div className="container">
                    <div className="b-table">
                    <div className="table-wrapper has-mobile-cards">
                        <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                        <div style={{ textAlign: 'center', marginTop: '1em', fontSize: '2em'  }}>
                        {noDataMessage && <p>{noDataMessage}</p>}
                        {/* Hier können Sie den Rest Ihrer Komponente mit den Daten dataArray rendern */}
                        </div>
                        <thead>
                            <tr>
                            <th>{t('subject')}</th>
                            <th>{t('start')}</th>
                            <th>{t('end')}</th>
                            <th>{t('status')}</th>
                            <th>{t('followUp')}</th>
                            <th>{t('company')}</th>
                            <th>{t('department')}</th>
                            <th>{/* buttons */}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((obj) => (
                            <DataRow obj={obj} key={obj.id} />
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </section>
        </ScrollView>

        <Modal opened={opened} onClose={close} closeOnClickOutside={false} withCloseButton={false} centered>
          <p className="deleteDatensatz">{t('deleteTask')}</p>
            <div className="yesNoButtons" style={{ marginTop: '1em' }}>
              <Button
                className='myButton'
                style={{ marginRight: '1em' }}
                onClick={() => {
                  handleDelete(modalData.id);
                  close();
                  }}
                >
                {m('btnYes')}
              </Button>
              <Button
                onClick={() => { close(); }}
                color="red"
              >
                {m('btnNo')}
              </Button>
            </div>
        </Modal>
    </>
  );
};

export default AufgabenPC;
