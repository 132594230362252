import React, { useEffect } from 'react';
import Gantt, 
    { Tasks, Column, Toolbar, Item, Validation, Editing }
from 'devextreme-react/gantt';
import 'devexpress-gantt/dist/dx-gantt.min.css';

function SubProjectsGantt({ Aufgaben }) {

    const tasks = Aufgaben.map((task) => {
        return {
            id: task.id,
            subject: task.subject,
            start: new Date(task.start).toISOString(), // Konvertieren in ISO-String
            end: new Date(task.end).toISOString(), // Konvertieren in ISO-String
        };
    }).sort((a, b) => new Date(a.start) - new Date(b.start)); // Sortieren nach Startdatum

    useEffect(() => {
        console.log("Aufgaben", Aufgaben);
        console.log("tasks", tasks);
    }, [Aufgaben, tasks]);
     
    return (
        <div className='gantt'>
            <Gantt taskListWidth={400} scaleType='months' height={400}>
                <Tasks dataSource={tasks} startExpr='start' endExpr='end' titleExpr='subject' />
                <Column dataField='subject' caption='Aufgaben' width={200}/>
                <Column dataField='start' caption='Start' dataType='date' sortOrder='asc' />
                <Column dataField='end' caption='End' dataType='date' />
                <Toolbar>
                    <Item name='zoomIn' />
                    <Item name='zoomOut' />
                </Toolbar>
                <Validation autoUpdateParentTasks={true} />
                <Editing enabled={true} />
            </Gantt>
        </div>
    );
};

export default SubProjectsGantt;