import React, { useCallback, useEffect, useState } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { MdEdit } from "react-icons/md";
import Api from "../../utils/api";
import i18n from "i18next";
import './Kanban.css';
import DropDownButton from 'devextreme-react/drop-down-button';

function doneClick() {
    //  notify('The Done button was clicked');
}

function getLists(taskArray) {
  const tasksMap = taskArray.reduce((result, task) => {
    const { status_Procent } = task;
    let status;
    if (status_Procent === 0) {
      status = 'Not Started';
    } else if (status_Procent === 100) {
      status = 'Completed';
    } else {
      status = 'In Progress';
    }

    if (result[status]) {
      result[status].push(task);
    } else {
      result[status] = [task];
    }
    return result;
  }, {});
  return ['Not Started', 'In Progress', 'Completed'].map(status => tasksMap[status]);
}

function getColor(status_Procent) {
    if (status_Procent === 0) {
        return 'red'; // rot, wenn status_Procent 0 ist
    } else if (status_Procent > 0 && status_Procent < 100) {
        return 'yellow'; // gelb, wenn status_Procent zwischen 1 und 99 ist
    } else if (status_Procent === 100) {
        return 'green'; // grün, wenn status_Procent 100 ist
    }
}

const Card = ({ task }) => {
    const history = useHistory(); // Hinzufügen dieser Zeile
    const taskmanager = `${task.people[0].member.first_Name} ${task.people[0].member.last_Name}`;

    return (
        <div className="card dx-card dx-theme-text-color dx-theme-background-color">
            <div className={`card-priority priority-${getColor(task.status_Procent)}`}></div>
            <div className="card-content">
                <div className="card-subject">{task.subject}</div>
                <div className="card-assignee">{taskmanager}</div>
            </div>
            <div className="edit-icon">
                <MdEdit size={15} color='39b201' onClick={() => history.push(`/aufgabe/${task.id}`)}/>
            </div>
        </div>
    );
};

const List = ({
  title, index, tasks, onTaskDrop,
  }) => (
     <div className="kanban-list-tasks">
    
      <div className="list-title dx-theme-text-color">{title}</div>
      {/* <ScrollView
        className="scrollable-list"
        direction="vertical"
        showScrollbar="always"
      > */}
        <Sortable
          className="sortable-cards"
          group="cardsGroup"
          data={index}
          onReorder={onTaskDrop}
          onAdd={onTaskDrop}
        >
          {tasks?.map((task) => (
            <Card
              key={task.id}
              task={task}
            ></Card>
          ))}
        </Sortable>
      {/* </ScrollView> */}
    </div>
);

export default function ({onViewSelected, SelectedView, Ansichten}) {
  const [lists, setLists] = useState([]);
  const t = i18n.getFixedT(null, 'taskList');

    useEffect(() => {
        Api.tasks.getTasks()
            .then(tasks => {
                console.log('Aufgabenliste:', tasks.data);
                const taskList = tasks.data;
                setLists(getLists(taskList));
                console.log('Neu Liste:', taskList);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }, []);

  const onTaskDrop = useCallback(
    ({
      fromData, toData, fromIndex, toIndex,
    }) => {
      const updatedLists = [...lists];
      const item = updatedLists[fromData][fromIndex];
      const oldStatus_Procent = item.status_Procent;

      // Aktualisieren Sie den status_Procent basierend auf dem Zielstatus
      if (toData === 0) {
        item.status_Procent = 0;
        console.log('status_Procent von', item.subject, " ist:", item.status_Procent);
      } else if (toData === 1) {
        item.status_Procent = 50;
        console.log('status_Procent von', item.subject, " ist:", item.status_Procent);
      } else if (toData === 2) {
        item.status_Procent = 100;
        console.log('status_Procent von', item.subject, " ist:", item.status_Procent);
      }

      // Überprüfen Sie, ob sich status_Procent geändert hat
      if (oldStatus_Procent !== item.status_Procent) {
        // Aktualisieren Sie die Aufgabe in der API
        console.log('Aktualisiere Aufgabe:', item);
        Api.tasks.updateTask(item.id, item)
          .catch(() => { throw 'Network error'; });
      } else {
        console.log('Keine Änderung in status_Procent, Aufgabe nicht aktualisiert');
      }

      updatedLists[fromData] = updatedLists[fromData].filter((_, idx) => idx !== fromIndex);
      if (!updatedLists[toData]) {
        updatedLists[toData] = [];
      }
      updatedLists[toData].splice(toIndex, 0, item);
      setLists(updatedLists);
    },
    [lists],
  );

  const status = ["Nicht begonnen", "In Bearbeitung", "Abgeschlossen"];

  return (

    <React.Fragment>

        <div className={'content-block'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                      <Button 
                        icon="plus"
                        className='myButton'
                        text={t('btnAdd')}
                        onClick={doneClick} 
                      />
                    </div>
                    <div style={{ textAlign: 'center', flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: '100%' }}>
                      {t('title')}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <DropDownButton
                         text={SelectedView === "Liste" ? "Tabellenansicht" : "Kanbanansicht"}
                        //text={SelectedView}
                        icon="table"
                        items={Ansichten}
                        onSelectionChanged={onViewSelected} 
                      />
                    </div>
        </div>
        <div className={'content-block'}>
           
            <div className={'dx-card responsive-paddings'}>
                {/* <div id="kanban-tasks"> */}
                    {/* <ScrollView
                        className="scrollable-board"
                        direction="horizontal"
                        showScrollbar="always"
                    > */}
                        <Sortable
                        className="sortable-lists"
                        itemOrientation="horizontal"
                        handle=".list-title"
                        onReorder={({ fromIndex, toIndex }) => {
                            setLists((state) => {
                            const newState = [...state];
                            const item = newState.splice(fromIndex, 1)[0];
                            newState.splice(toIndex, 0, item);
                            return newState;
                            });
                        }}
                        >
                        {lists.map((tasks, listIndex) => (
                            <List
                            key={listIndex}
                            title={status[listIndex]}
                            index={listIndex}
                            tasks={tasks}
                            onTaskDrop={onTaskDrop}
                            />
                        ))}
                        </Sortable>
                    {/* </ScrollView> */}
                    {/* </div> */}
                </div>
            </div>
        </React.Fragment>
  );
}


