import React, { useState,useEffect,useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {Column, ColumnChooser, DataGrid, GroupPanel, Editing,  HeaderFilter, StateStoring, Scrolling} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import i18n from "i18next";
import Api from "../../utils/api";
import '../../pages/Aufgabenliste/Aufgabenliste.scss';
import { ScrollView } from 'devextreme-react/scroll-view';
import ListMobile from '../../pages/Aufgabenliste/MobileAnsicht/ListeMobile';
import EventBus from '../../components/Mitgliederliste-Mobil/EventBus';
import AufgabenPC from './AufgabenPC';


function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);

    }

    return response;
}

function doneClick() {
    //  notify('The Done button was clicked');
}

function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
};


function getUser() {
    return Api.getMe()
        .then(response => response.data)
}

export default function () {

    const t = i18n.getFixedT(null, 'taskList');
    const { recoveryCode } = useParams();
    const history = useHistory();
    const [isModalOpen, setModalOpen] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [user, setUser] = useState(null);


    const customDataSource = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.tasks.getTasks()
                .then(response => response.data);

        },
    });

    const formatDate = new Intl.DateTimeFormat('en-US').format;
    function DataRow(rowInfo) {
        
        return  <React.Fragment>
            <tr className="main-row">
              <td rowSpan="2"><img src={rowInfo.data.Picture} /></td>
              <td>{rowInfo.data.Prefix}</td>
              <td>{rowInfo.data.FirstName}</td>
              <td>{rowInfo.data.LastName}</td>
              <td>{rowInfo.data.Position}</td>
              <td>{formatDate(new Date(rowInfo.data.BirthDate))}</td>
              <td>{formatDate(new Date(rowInfo.data.HireDate))}</td>
            </tr>
            <tr className="notes-row">
              <td colSpan="6"><div>{rowInfo.data.Notes}</div></td>
            </tr>
          </React.Fragment>
        

         };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
    const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
    };
         
    window.addEventListener('resize', handleResize);
         
    // Cleanup function
    return () => {
    window.removeEventListener('resize', handleResize);
    };
    }, []);

    return (

        <React.Fragment>

            <h3 className={'content-block'}>{t('title')}</h3>
            <div className={'content-block'}>

                <div className={'dx-card responsive-paddings'}>
                    
                {isMobile ? 
                <>
                <ListMobile t={t} /> 
                </>
                : 

                <ScrollView
                    height={'57vh'}
                    width={'auto'}
                    direction="vertical"
                >
                    <AufgabenPC />  
                </ScrollView>
                }
                </div>
            </div>
        </React.Fragment>
    );
};
