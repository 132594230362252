import React from 'react';
import './postEntry.css';



export default function PostEntry({ profilePic, userName, timePosted, postText, postImage, likes, comments, shares }) {

  

    
    return (
        <React.Fragment>
            
            <div className="posts-container">
                <div className="post-entry">
                    <div className="post-header">
                        <img src={profilePic} alt="User Profile" className="profile-pic" />
                        <div className="post-user-info">
                            <h4>{userName}</h4>
                            <p>{timePosted}</p>
                        </div>
                    </div>
                    <div className="post-content">
                        <p>{postText}</p>
                        {postImage && <img src={postImage} alt="Post content" className="post-image" />}
                    </div>
                    <div className="post-footer">
                        <div className="post-footer-item">
                            <span><button>👍</button> {likes}</span>
                            <span><button>👎</button> {likes}</span>
                        </div>
                        <div className="post-footer-item">
                            <span>💬 {comments}</span>
                        </div>
                        <div className="post-footer-item">
                            <span>🔁 {shares}</span>
                        </div>
                    </div>
                </div>
        </div>
        </React.Fragment>
    );
}


