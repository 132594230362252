import React from 'react';
import List from 'devextreme-react/list';
import 'whatwg-fetch';
import DataGrid, {
    RemoteOperations, Editing
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Form, {
    SimpleItem,
    GroupItem,
    Label
} from 'devextreme-react/form';
import { ButtonGroup } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { Button } from 'devextreme-react';
import { Upload } from 'devextreme-react/file-manager';

import DataSource from 'devextreme/data/data_source';





const serviceUrl = '';


function saveTextBoxInstance(e) {
    this.textBoxInstance = e.refresh();
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}



const customDataSource = new CustomStore({
    key: ['id', 'ID']
    ,
    cacheRawData: false,
    reshapeOnPush: true,
    loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
    load: () => {
        return fetch('https://localhost:25443/api/Companies')

            .then(response => response.json());

    },
    insert: (values) => {
        return fetch('https://localhost:25443/api/Companies', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(handleErrors)
            .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
        return fetch('https://localhost:25443/api/Companies/' + key, {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(handleErrors)
            .catch(() => { throw 'Network error' });
    },
    remove: (key) => {
        return fetch('https://localhost:25443/api/Companies/' + key.id, {
            method: 'DELETE'

        })
            .then(handleErrors)
            .catch(() => { throw 'Network error' });
    }

});

const dataSource = new DataSource({
    store: customDataSource,
    reshapeOnPush: true
});

function onClick(e) {
  
    customDataSource.insert({ CountryID: 1, name:"Lars" })
        .then(
            (dataObj) => {
                console.log(dataObj);
                dataSource.reload();
                 },
            (error) => { notify('Fehler beim Hinzufuegen') }
    );
    notify('Daten wurden hinzugef�gt') 
   
    
   // tabel1.dataSource = customDataSource;

    //const buttonText = e.component.option('text');
    //notify(`The ${(buttonText)} button was clicked`);
}

function onClick2(e) {
    customDataSource.update(5,{ id: 5, name: "Claudia", CountryID: 1 })
        .then(
            (dataObj) => { notify('Daten wurden geupdatet') },
            (error) => { notify('Fehler beim Update') }
        );
}

function onClick3(e) {
    customDataSource.remove(2)
        .then(
            (dataObj) => { notify('Daten wurden gel�scht') },
            (error) => { notify('Fehler beim l�schen') }
        );
}

export default () => (
  <React.Fragment>

        <h2 className={'content-block'}>Einstellung Unternehmen</h2>
        <Form formData={customDataSource}>

            <GroupItem>
                <SimpleItem dataField="Name" />
                <SimpleItem dataField="Street" />
                <SimpleItem dataField="countryID" />

            </GroupItem>

          </Form>
        <Button
            width={120}
            text="Hinzuf�gen"
            type="normal"
            stylingMode="contained"
            onClick={onClick}
        />
        <Button
            width={120}
            text="Update"
            type="normal"
            stylingMode="contained"
            onClick={onClick2}
        />

        <Button
            width={120}
            text="delete"
            type="normal"
            stylingMode="contained"
            onClick={onClick3}
        />




    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>

                <DataGrid

                    id="tabel1"
                    dataSource={dataSource}
                    showBorders={true}
                    height={600}
                    remoteOperations={true}
                    activeStateEnabled={true}
                >
                    <Editing
                        allowDeleting={true}
                    />


                </DataGrid>

      </div>
    </div>
  </React.Fragment>
    );


