import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';

import SelectBox from "devextreme-react/select-box";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import Scheduler, { Resource, Value } from 'devextreme-react/scheduler';
import { useAuth } from '../../contexts/auth';
import { Column, ColumnChooser, DataGrid, FilterRow, HeaderFilter, GroupPanel, Scrolling, Editing, Grouping, Lookup, MasterDetail, Summary, RangeRule, RequiredRule, StringLengthRule, GroupItem, TotalItem, ValueFormat } from 'devextreme-react/data-grid';
import i18n from "i18next";





function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    else
    {

    }
    console.log('Alle Termine geladen Anzahl:' + Object.keys(response.json()).length);
       // customStoreTerminliste.load();
    return response;
}



function onAppointmentUpdating(options) {
   // options.newData = Object.assign(options.oldData, options.newData);
    console.log('onAppointmentUpdating')
};



const currentDate = new Date();

const views = ['day', 'workWeek', 'month'];


export default function (props) {
    const t = i18n.getFixedT(null, 'appointmentList');
    const [Datum, setDatum] = useState(currentDate);

    const { user } = useAuth();

    const url = (() => {
        if (window.location.hostname.indexOf("host") > 0) {
            return "https://localhost:25443/";
        } else {
            return "https://api.maprota.com/";
        }
    })();

    const data = [
        {
            subject: 'Google AdWords Strategy',
            roomId: 1,
            start: new Date(2020, 4, 5, 9, 0),
            end: new Date(2020, 4, 5, 10, 30)
        }, {
            subject: 'New Brochures',
            roomId: 5,
            start: new Date(2020, 4, 5, 11, 30),
            end: new Date(2020, 4, 5, 14, 15)
        }, {
            subject: 'Brochure Design Review',
            roomId: 5,
            start: new Date(2020, 4, 5, 13, 15),
            end: new Date(2020, 4, 5, 16, 15)
        }, {
            subject: 'Website Re-Design Plan',
            roomId: 5,
            start: new Date(2020, 4, 5, 16, 45),
            end: new Date(2020, 4, 5, 11, 15)
        }, {
            subject: 'Rollout of New Website and Marketing Brochures',
            roomId: 2,
            start: new Date(2020, 4, 9, 8, 15),
            end: new Date(2020, 4, 9, 10, 45)
        }, {
            subject: 'Update Sales Strategy Documents',
            roomId: 3,
            start: new Date(2020, 4, 9, 12, 0),
            end: new Date(2020, 4, 9, 13, 45)
        }, {
            subject: 'Non-Compete Agreements',
            roomId: 3,
            start: new Date(2020, 4, 9, 8, 15),
            end: new Date(2020, 4, 9, 9, 0)
        }, {
            subject: 'Update NDA Agreement',
            roomId: 1,
            start: new Date(2020, 4, 10, 11, 45),
            end: new Date(2020, 4, 10, 13, 45)
        }, {
            subject: 'Update Employee Files with New NDA',
            roomId: 4,
            start: new Date(2020, 4, 18, 14, 0),
            end: new Date(2020, 4, 18, 16, 45)
        }, {
            subject: 'Submit Questions Regarding New NDA',
            roomId: 4,
            start: new Date(2020, 4, 18, 8, 0),
            end: new Date(2020, 4, 18, 9, 30)
        }, {
            subject: 'Submit Signed NDA',
            roomId: 4,
            start: new Date(2020, 4, 18, 12, 45),
            end: new Date(2020, 4, 18, 14, 0)
        }, {
            subject: 'Review Revenue Projections',
            roomId: 4,
            start: new Date(2020, 4, 25, 17, 15),
            end: new Date(2020, 4, 25, 18, 0)
        }, {
            subject: 'Comment on Revenue Projections',
            roomId: 1,
            start: new Date(2020, 4, 22, 9, 15),
            end: new Date(2020, 4, 22, 11, 15)
        }, {
            subject: 'Provide New Health Insurance Docs',
            roomId: 4,
            start: new Date(2020, 4, 22, 12, 45),
            end: new Date(2020, 4, 22, 14, 15)
        }, {
            subject: 'Review Changes to Health Insurance Coverage',
            roomId: 4,
            start: new Date(2020, 4, 25, 14, 15),
            end: new Date(2020, 4, 25, 15, 30)
        }, {
            subject: 'Review Training Course for any Ommissions',
            roomId: 4,
            start: new Date(2020, 4, 22, 14, 0),
            end: new Date(2020, 4, 22, 12, 0)
        }, {
            subject: 'Recall Rebate Form',
            roomId: 2,
            start: new Date(2020, 4, 23, 12, 45),
            end: new Date(2020, 4, 23, 13, 15)
        }, {
            subject: 'Create Report on Customer Feedback',
            roomId: 3,
            start: new Date(2020, 4, 23, 15, 15),
            end: new Date(2020, 4, 23, 17, 30)
        }, {
            subject: 'Review Customer Feedback Report',
            roomId: 3,
            start: new Date(2020, 4, 17, 16, 15),
            end: new Date(2020, 4, 17, 18, 30)
        }, {
            subject: 'Customer Feedback Report Analysis',
            roomId: 3,
            start: new Date(2020, 4, 17, 9, 30),
            end: new Date(2020, 4, 17, 10, 30)
        }, {
            subject: 'Prepare Shipping Cost Analysis Report',
            roomId: 3,
            start: new Date(2020, 4, 23, 12, 30),
            end: new Date(2020, 4, 23, 13, 30)
        }, {
            subject: 'Provide Feedback on Shippers',
            roomId: 3,
            start: new Date(2020, 4, 23, 14, 15),
            end: new Date(2020, 4, 23, 16, 0)
        }, {
            subject: 'Select Preferred Shipper',
            roomId: 1,
            start: new Date(2020, 4, 26, 17, 30),
            end: new Date(2020, 4, 26, 20, 0)
        }, {
            subject: 'Complete Shipper Selection Form',
            roomId: 5,
            start: new Date(2020, 4, 25, 8, 30),
            end: new Date(2020, 4, 25, 10, 0)
        }, {
            subject: 'Upgrade Server Hardware',
            roomId: 5,
            start: new Date(2020, 4, 26, 12, 0),
            end: new Date(2020, 4, 26, 14, 15)
        }, {
            subject: 'Upgrade Personal Computers',
            roomId: 5,
            start: new Date(2020, 4, 26, 14, 45),
            end: new Date(2020, 4, 26, 16, 30)
        }
    ];

    const obj = {
        method: 'GET',
        headers: {

            'Content-Type': 'application/json',
            'Accept': '	text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
            'Accept-Encoding': 'gzip, deflate, br',
            'Accept-Language': 'de, en- US; q = 0.7, en; q = 0.3',
            'Connection': 'keep-alive',
            'Host': 'localhost: 44320',
            'Upgrade-Insecure-Requests': '1',
            'User-Agent': 'Mozilla/ 5.0(Windows NT 10.0; Win64; x64; rv: 77.0) Gecko / 20100101 Firefox / 77.0',
            'TE': 'Trailers',
            'Authorization': 'Bearer ' + user.token

        }
    };

    const customStoreTerminliste = new CustomStore({
        //key: ['ID']
        //,
        //cacheRawData: false,
        //reshapeOnPush: true,
        //loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return fetch(url + 'api/Meetings/calender?Datum=' + new Date(Datum).toISOString(), obj)
                .then(response => response.json());


        },
        remove: (key) => {
            console.log('DELETE Methode' + key.ID);
            //var res = encodeURI(key.ID);
            var res = encodeURIComponent(key.ID);
            console.log('DELETE Methode Encode' + res);
            return fetch(url +'api/Meetings/' + res, {

                method: 'DELETE',
                headers: {

                    'Content-Type': 'application/json',
                    'Accept': '	text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Accept-Language': 'de, en- US; q = 0.7, en; q = 0.3',
                    'Connection': 'keep-alive',
                    'Host': 'localhost: 44320',
                    'Upgrade-Insecure-Requests': '1',
                    'User-Agent': 'Mozilla/ 5.0(Windows NT 10.0; Win64; x64; rv: 77.0) Gecko / 20100101 Firefox / 77.0',
                    'TE': 'Trailers',
                    'Authorization': 'Bearer ' + user.token
                }

            })
                .then(handleErrors)
                .then((res) => {
                    console.log('DELETE Methode erflogreich');
                    //customStoreTerminliste.push([{ type: "remove", key: res }]);

                    customStoreTerminliste.load();
                    console.log('DELETE Methode erflogreich nachgeladen');
                })
                .catch(() => { throw 'Network error' });
        },
        byKey: (key) => {
            return fetch(url +'api/Meetings/' + key)

                .then(response => response.json());

        },
        insert: (values) => {
            Termin.id = 0;
            Termin.subject = values.text;
            Termin.start = values.startDate;
            Termin.end = values.endDate;

            return fetch(url +'api/Meetings', {
                method: 'POST',
                body: JSON.stringify(Termin),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '	text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Accept-Language': 'de, en- US; q = 0.7, en; q = 0.3',
                    'Connection': 'keep-alive',
                    'Host': 'localhost: 44320',
                    'Upgrade-Insecure-Requests': '1',
                    'User-Agent': 'Mozilla/ 5.0(Windows NT 10.0; Win64; x64; rv: 77.0) Gecko / 20100101 Firefox / 77.0',
                    'TE': 'Trailers',
                    'Authorization': 'Bearer ' + user.token
                }
            })
                .then(handleErrors)
                .then((res) => {
                     console.log('Insert Methode erflogreich');
                     //customStoreTerminliste.push([{ type: "remove", key: res }]);

                     customStoreTerminliste.load();
                    console.log('Insert Methode erflogreich nachgeladen');
                 })
                .catch(() => { throw 'Network error' });
        },
        update: (key, values) => {
            Termin.ID = key.ID;
            Termin.subject = values.text;
            Termin.start = values.startDate;
            Termin.end = values.endDate;

            return fetch(url +'api/Meetings/' + key.ID, {
                method: 'PUT',
                body: JSON.stringify(Termin),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '	text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Accept-Language': 'de, en- US; q = 0.7, en; q = 0.3',
                    'Connection': 'keep-alive',
                    'Host': 'localhost: 44320',
                    'Upgrade-Insecure-Requests': '1',
                    'User-Agent': 'Mozilla/ 5.0(Windows NT 10.0; Win64; x64; rv: 77.0) Gecko / 20100101 Firefox / 77.0',
                    'TE': 'Trailers',
                    'Authorization': 'Bearer ' + user.token
                }
            })
                .then(handleErrors)
                .then((res) => {
                    console.log('Update Methode erflogreich');
                    //customStoreTerminliste.push([{ type: "remove", key: res }]);

                    customStoreTerminliste.load();
                    console.log('Update Methode erflogreich nachgeladen');
                })
                .catch(() => { throw 'Network error' });
        }
    });

    const Termin = {
        id: 0,
        subject: null,
        end: null,
        start: null
    };

    const DataSourceTermin = new DataSource({
        reshapeOnPush: true,
        key: "ID",
        store: customStoreTerminliste
        ,
        map: function (itemData) {
            console.log('Mapping' + itemData.subject);
            return {
                ID: itemData.id,
                text: itemData.subject,
                endDate: new Date(itemData.end),
                startDate: new Date(itemData.start)
            }
        }




    });

    //const views = [{
    //    type: 'month',
    //    name: 'Auto Mode',
    //    maxAppointmentsPerCell: 'auto'
    //}, {
    //    type: 'month',
    //    name: 'Unlimited Mode',
    //    maxAppointmentsPerCell: 'unlimited'
    //}, {
    //    type: 'month',
    //    name: 'Numeric Mode',
    //    maxAppointmentsPerCell: 2
    //    }];

   function onOptionChanged(e) {
        if (e.name === 'currentDate') {
            //   this.setState({ currentDate: e.value });
            setDatum(e.value);
            console.log('aktuelles Datum' + e.value);

        }
    };

        return (
            <React.Fragment>

        <h2 className={'content-block'}>{t('title')}</h2>
        <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>



                        <Scheduler
                            key="id"
                            dataSource={DataSourceTermin}
                            views={views}
                            timeZone="Europe/Berlin"
                            firstDayOfWeek={1}
                            defaultCurrentView="month"
                            defaultCurrentDate={currentDate}
                            height={600}
                            showAllDayPanel={true}
                            reshapeOnPush={true}
                            onOptionChanged={onOptionChanged}


                        >


                </Scheduler>
              </div>
        </div>
    </React.Fragment>
        );

}


