import React, { useMemo, useEffect, useState } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next'
import { Avatar } from '@mantine/core';
import AvatarPlaceholder from "./personPlaceholder.png";
import Api from '../../utils/api';
import EventBus from '../Mitgliederliste-Mobil/EventBus';

export default function ({ menuMode }) {


    const history = useHistory();
    const { user, logOut, refreshSession } = useAuth();
    const [pictureURL, setPictureURL] = useState(null);

    if(user.first_Name === null && user.last_Name === null){
            user.name = user.eMail
    }else {
        let first = user.first_Name ? user.first_Name + " ": ""
        let last = user.last_Name ? user.last_Name: ""
        user.name = first + last
    }

    const openProfile = () => {
        history.push("/Benutzerverwaltung")
    }

    const menuItems = useMemo(() => ([
    {
      text: i18n.t('titles:profile'),
      icon: 'user',
        onClick:  openProfile
    },
    {
      text: i18n.t('auth:logout'),
      icon: 'runner',
      onClick: logOut.bind(this, () => history.push("/login"))
    }
  ]), [logOut]);

  useEffect(() => {
    loadProfilPicture();
  }, [user.pictureURL]);

  useEffect(() => {
    EventBus.on('refreshData', handleRefreshData);
    return () => {
        EventBus.off('refreshData', handleRefreshData);
    };
  }, []);

  async function handleRefreshData() {
    await refreshSession();
    loadProfilPicture();
  }

  async function loadProfilPicture() {
    if (!user.pictureURL) {
        return AvatarPlaceholder;
    }
    const url = user.pictureURL;
    console.log("URL: ", url);
    const idMatch = url.match(/id=(\d+)/);
    if (idMatch) {
        const id = idMatch[1];
        try {
            console.log(`Dokumenten-ID: ${id}`);
            const blob = await Api.Dokuments.getDokuments(id)
            const objectURL = URL.createObjectURL(blob);
            console.log('Download-URL:', objectURL);
            setPictureURL(objectURL); // Set the picture URL in state
        } catch (error) {
            console.error('Fehler beim Anzeigen des Dokuments:', error);
        }
    }
  }


  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
              <div style={{ marginRight: '10px'}}>
                <Avatar
                  size={28}
                  radius="xxl" 
                  src={pictureURL}
                  styles={{ 
                      root: { 
                          border: '1px solid #d2d3d5', // Fügt einen 2px breiten, soliden, schwarzen Rand hinzu
                          borderRadius: '50%' // Macht den Rand rund
                      } 
                  }}
                />
              </div>
              <div className={'user-name'}>{user.name}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width="auto"
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
