import React, { useState } from 'react';
import './User-Setting.scss'; // Importiere die CSS-Datei

export default function ({ menuMode }) 
{
    const [brightness, setBrightness] = useState(50);
    const [volume, setVolume] = useState(30);
    const [contrast, setContrast] = useState(70);

    return  (   <div className="settings-page">
        <div className="sidebar">
          <h2>Menü</h2>
          <ul>
            <li>
              <h3>Helligkeit</h3>
              <p>Steuerung der Bildschirmhelligkeit</p>
            </li>
            <li>
              <h3>Lautstärke</h3>
              <p>Anpassung der Lautstärke</p>
            </li>
            <li>
              <h3>Kontrast</h3>
              <p>Ändern des Bildkontrasts</p>
            </li>
          </ul>
        </div>
        <div className="settings-content">
          <div className="setting">
            <label>Helligkeit</label>
            <input
              type="range"
              min="0"
              max="100"
              value={brightness}
              onChange={(e) => setBrightness(e.target.value)}
            />
            <span>{brightness}%</span>
          </div>
          <div className="setting">
            <label>Lautstärke</label>
            <input
              type="range"
              min="0"
              max="100"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
            />
            <span>{volume}%</span>
          </div>
          <div className="setting">
            <label>Kontrast</label>
            <input
              type="range"
              min="0"
              max="100"
              value={contrast}
              onChange={(e) => setContrast(e.target.value)}
            />
            <span>{contrast}%</span>
          </div>
        </div>
      </div>
    );
};
