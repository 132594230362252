import React, {useEffect, useState } from 'react'
import './autocomplete.scss';
import Api from "../../utils/api";
import {useScreenSize} from "../../utils/media-query";
import { Autocomplete } from 'devextreme-react';
import {useHistory} from 'react-router-dom'
import {Button} from 'devextreme-react';

export default function ({isSearchBarVisible, setSearchBarVisible}) {

    const [searchData, setSearchData] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const history = useHistory()
    const {isXSmall, isSmall} = useScreenSize();

    useEffect(() => {
        console.log("Geladene Daten", searchData)
    },[searchData])
    
    useEffect(() => {
        Promise.all([
            Api.people.getPeople(),
            Api.tasks.getTasks(),
            Api.projects.getProjects()
        ]).then(([peopleResponse, tasksResponse, projectsResponse]) => {
            const peopleData = peopleResponse.data.map(person => ({
                ...person,
                subject: `${person.first_Name} ${person.last_Name}`
            }));
            const allData = [
                {
                    key: "Personen",
                    items: peopleData
                },
                {
                    key: "Aufgaben",
                    items: tasksResponse.data
                },
                {
                    key: "Projekte",
                    items: projectsResponse.data
                }
            ];
            setSearchData(allData);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);

    const onItemClick = (e) => {
        console.log("Item Clicked", e.itemData)
        if (e.itemData.key === "Personen") {
            history.push(`/profile/${e.itemData.id}`)
        } else if (e.itemData.key === "Aufgaben") {
            history.push(`/Aufgabe/${e.itemData.id}`)
        } else if (e.itemData.key === "Projekte") {
            history.push(`/projekt/${e.itemData.id}`)
        } else {
            console.error("Unknown item type", e.itemData)
        }
    }

    function RenderPCSearchBar() {
        return (
            <Autocomplete
                dataSource={searchData}
                placeholder='Suche...'
                valueExpr="subject"
                grouped={true}
                hint="Suche nach Personen, Aufgaben oder Projekten"
                minSearchLength={2}
                onItemClick={onItemClick}
                stylingMode='filled'
                width="300px"
                onFocusOut={() => {
                    setSearchBarVisible(false)
                }}
                value={searchValue}
                onValueChanged={({ value }) => setSearchValue(value)}
                buttons={[{
                    name: 'searchButton',
                    location: 'after',
                    options: {
                        icon: 'search',
                        hint: 'Suche erweitern',
                        hoverStateEnabled: false,
                        stylingMode: 'text',
                        onClick: () => {
                            console.log("Search Button Clicked")
                            history.push(`search?search=${searchValue}`)
                        }
                    }
                }]}
            />
        );
    }

    function renderMobileSearchBar() {
        return <React.Fragment>
            {isSearchBarVisible && <div className={"div1"}>{RenderPCSearchBar()}</div>}
            {
                !isSearchBarVisible &&<div className={"div1"}><Button icon="search" onClick={() => {
                    setSearchBarVisible(true)
                }}/></div>
            }
                </React.Fragment>

    }

    return (
        isSmall || isXSmall ? renderMobileSearchBar() : RenderPCSearchBar()
    );
}