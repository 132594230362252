import React, { useEffect, useState, useCallback, useRef } from 'react';
import i18n from 'i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import Api from '../../../utils/api';
import '../../../components/bulma-tabel-css/bulma.min.css';
import '../../../components/bulma-tabel-css/main.min.css';
import EventBus from '../../../components/Mitgliederliste-Mobil/EventBus';
import { ScrollView } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Avatar, Divider } from '@mantine/core';
import AufgabenlisteToolbar from '../../../components/Aufgabenliste-Komponenten/Aufgabenliste-toolbar';
import AufgabenlisteEigeneTabelle from '../../../components/Aufgabenliste-Komponenten/Aufgabenliste-eigeneTabelle';
import { CheckBox } from 'devextreme-react/check-box';
import { formatQuery } from 'react-querybuilder';
import DropDownButton from 'devextreme-react/drop-down-button';
import {
  DataGrid,
  Column,
  FilterRow,
  Selection
} from 'devextreme-react/data-grid';
import themes from 'devextreme/ui/themes';
import { LuClipboardList } from 'react-icons/lu';
import { IoFilterOutline } from "react-icons/io5";
import './../Aufgabenliste.scss';
import FilterPopup from './FilterPopup';
import notify from 'devextreme/ui/notify';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('de-DE').format(date);
};

const AufgabenlistePC = ({
  filter, 
  user, 
  dataArray, 
  setDataArray, 
  filteredData, 
  setFilteredData, 
  aktualisieren, 
  setAktualisieren, 
  showFilterWithText, 
  noDataMessage, 
  setAktuelleFilterBedingung,
  selectedFilter, 
  setSelectedFilter, 
  setFilterObject, 
  filterObject, 
  setFilterBedingungAusEigenerTabelle, 
  setBedingungenFürPopup,
  ausgewählteSpalten,
  setAusgewählteSpalten,
}) => {
    const t = i18n.getFixedT(null, 'taskList');
    const [searchText, setSearchText] = useState(''); // State für Suchtext
    const [sortType, setSortType] = useState(''); // State für Sortierung
    const history = useHistory();
    const m = i18n.getFixedT(null, 'modal');
    const [modalData, setModalData] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);
    const [selectedList, setSelectedList] = useState(null);
    const [Aufgabenfilter, setAufgabenfilter] = useState([]);
    const location = useLocation();
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [JSONAbfrage, setJSONAbfrage] = useState('');
    const [Abfragedaten, setAbfragedaten] = useState([]);
    const dataGridRef = useRef(null);
    const [checkBoxesMode, setCheckBoxesMode] = useState(
      themes.current().startsWith('material') ? 'always' : 'onClick',
    );
    const [allMode, setAllMode] = useState('allPages');

    useEffect(() => {
      if (!JSONAbfrage) {
          return;
      } else {
          const parsedJSON = JSON.parse(JSONAbfrage);
          if (!parsedJSON.rules || parsedJSON.rules.length === 0) {
              return;
          }
          const rulesData = parsedJSON.rules.map(rule => {
            let value;
            if (rule.field === "status_Procent" && rule.operator !== "between" && rule.operator !== "notBetween") {
              value = parseInt(rule.value);
            } else {
              value = rule.value;
            }

            return {
              Operator: rule.operator,
              Wert: value,
              Feld: rule.field
            };
          });
          if (parsedJSON.combinator === 'and') {
              rulesData.push({ Combinator: 'and' });
          }
          setAbfragedaten(rulesData);
          console.log("Abfrage Daten:", rulesData);
      }
    }, [JSONAbfrage]);

    useEffect(() => {
        if (Abfragedaten.length === 0) {
            return;
        } else {
            const filteredData = dataArray.filter(item => {
                let result = true;
                Abfragedaten.forEach(rule => {
                    if (rule.Combinator) {
                        return;
                    }
                    switch (rule.Feld) {
                        case 'subject':
                        case 'company':
                            switch (rule.Operator) {
                                case 'contains':
                                    if (!item[rule.Feld].toLowerCase().includes(rule.Wert.toLowerCase())) {
                                        result = false;
                                    }
                                    break;
                                case '=':
                                    result = item[rule.Feld] === rule.Wert;
                                    break;
                                case 'beginsWith':
                                    if (!item[rule.Feld].toLowerCase().startsWith(rule.Wert.toLowerCase())) {
                                        result = false;
                                    }
                                    break;
                                case 'endsWith':
                                    if (!item[rule.Feld].toLowerCase().endsWith(rule.Wert.toLowerCase())) {
                                        result = false;
                                    }
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 'start':
                        case 'end':
                        case 'follow_up':
                            switch (rule.Operator) {
                                case '=':
                                    result = item[rule.Feld] === rule.Wert;
                                    break;
                                case '!=':
                                    result = item[rule.Feld] !== rule.Wert;
                                    break;
                                case '<':
                                    result = item[rule.Feld] < rule.Wert;
                                    break;
                                case '>':
                                    result = item[rule.Feld] > rule.Wert;
                                    break;
                                case '<=':
                                    result = item[rule.Feld] <= rule.Wert;
                                    break;
                                case '>=':
                                    result = item[rule.Feld] >= rule.Wert;
                                    break;
                                case 'between':
                                    let [start, end] = rule.Wert.split(',');
                                    result = item[rule.Feld] >= start && item[rule.Feld] <= end;
                                    break;
                                case 'notBetween':
                                    let [startNot, endNot] = rule.Wert.split(',');
                                    result = item[rule.Feld] < startNot || item[rule.Feld] > endNot;
                                    break;
                                default:
                                    break;
                            }
                            break;
                            case 'status_Procent':
                              switch (rule.Operator) {
                                  case '=':
                                      result = item[rule.Feld] === rule.Wert;
                                      break;
                                  case '!=':
                                      result = item[rule.Feld] !== rule.Wert;
                                      break;
                                  case '<':
                                      result = item[rule.Feld] < rule.Wert;
                                      break;
                                  case '>':
                                      result = item[rule.Feld] > rule.Wert;
                                      break;
                                  case '<=':
                                      result = item[rule.Feld] <= rule.Wert;
                                      break;
                                  case '>=':
                                      result = item[rule.Feld] >= rule.Wert;
                                      break;
                                  case 'between':
                                      let [start, end] = rule.Wert.split(',').map(Number);
                                      result = item[rule.Feld] >= start && item[rule.Feld] <= end;
                                      break;
                                  case 'notBetween':
                                      let [startNot, endNot] = rule.Wert.split(',').map(Number);
                                      result = item[rule.Feld] < startNot || item[rule.Feld] > endNot;
                                      break;
                                  default:
                                      break;
                              }
                        default:
                            break;
                    }
                });
                return result;
            });
            setFilteredData(filteredData);
        }
    }, [Abfragedaten]);

    useEffect(() => {
      if (ausgewählteSpalten.length === 0) {
      return;
      } else {
          for (let i = 0; i < ausgewählteSpalten.length; i++) {
              console.log("Zeige ausgewählte Spalten:",ausgewählteSpalten[i].label);
          }
      }
    }, [ausgewählteSpalten]);

    useEffect(() => {
      const path = location.pathname;
      console.log("URL Pfad", path);
      setSelectedList(path);
    }, [location]);

    // Funktion zum Laden der Daten
    useEffect(() => {
      Api.OptionClient.getOptions().then(response => {
        let Aufagbenfavoriten = response.data.filter(item => {
          let decodedName = decodeURIComponent(item.name);
          return decodedName.includes('Aufgabenfav_');
        }).map(item => {
          let decodedName = decodeURIComponent(item.name);
          return {...item, name: decodedName.substring("Aufgabenfav_".length)};
        });
        setAufgabenfilter(Aufagbenfavoriten);
      }).catch(error => {
        console.error('Fehler beim Laden der Optionen:', error);
      });
    }, [aktualisieren]);

    useEffect(() => {
      console.log("selectedFilter: ", selectedFilter);

      if (selectedFilter && typeof selectedFilter === 'object' && selectedFilter !== null) {
        const arrayNames = Object.keys(selectedFilter);
        console.log("Array Names: ", arrayNames);

        let tempArray = []; // Gemeinsames tempArray

        // Überprüfen, ob irgendein Schlüssel nicht "subject", "start", "end" oder "company" ist
        if (arrayNames.some(name => !["subject", "start", "end", "status_Procent", "follow_up", "company"].includes(name))) {
          for (let i = 0; i < arrayNames.length; i++) {
            for (let j = 0; j < selectedFilter[arrayNames[i]].length; j++) {
              const selectedValue = selectedFilter[arrayNames[i]][j]; // Der Wert des j-ten Elements im i-ten Array in selectedFilter
              console.log('selectedValue: ', selectedValue);
              console.log('arrayNames[i]: ', arrayNames[i]);

              const filter = dataArray.filter(obj => {
                obj.people.some(member => {
                  if (member.rolle.name === arrayNames[i] && (member.member.first_Name + ' ' + member.member.last_Name) === selectedValue) {
                    tempArray.push(obj);
                  }
                });   
              })
            }
          }
        } 
        
        if (arrayNames.some(name => ["subject", "start", "end", "status_Procent", "follow_up", "company"].includes(name))) {
            for (let i = 0; i < arrayNames.length; i++) {
                for (let j = 0; j < selectedFilter[arrayNames[i]].length; j++) {
                    const selectedValue = selectedFilter[arrayNames[i]][j]; // Der Wert des j-ten Elements im i-ten Array in selectedFilter

                    const filtered = dataArray.filter(obj => obj[arrayNames[i]] === selectedValue);
                    console.log("FilterAufgaben: ", filtered);
                    tempArray.push(...filtered);
                }
            }
        }
        console.log("TempArray: ", tempArray);
        if (tempArray.length === 0) {
          setFilteredData(dataArray);
        } else {
          setFilteredData([...tempArray]);
        }
      }
    }, [selectedFilter]);

    //Funktion zum anklicken einer Aufgabe
    const handleClick = (id) => {
        history.push(`/Aufgabe/${id}`);
    };

    // Function to handle task deletion
    const handleDelete = async (id) => {
      try {
        await Api.tasks.deleteTask(id);
        setAktualisieren(!aktualisieren);
        notify(t('taskDeletedSuccss'), "success", 3000);
      } catch (error) {
        console.error('Fehler beim Löschen:', error);
        notify(t('taskDeleteFailed'), "error", 3000);
      }
    };

    function renderMembers(obj, role) {
      const members = obj.people.filter(person => person.rolle.name === role);

      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          {members.map((person, index) => {
            if (index >= 2) {
              return null;
            }

            let memberName = '';
            let memberPicture = person.member.picture ? <Avatar src={person.member.picture} alt="picture" variant='transparent' radius="xl" /> : <Avatar variant='transparent' radius="xl" />; // Wenn das Bild null ist, wird der Avatar Placeholder angezeigt

            if (person.member.first_Name && person.member.last_Name) {
              memberName = `${person.member.first_Name} ${person.member.last_Name}`;
            } else if (person.member.first_Name) {  //Fall, wenn nur der Vorname vorhanden ist
              memberName = `${person.member.first_Name}`;
            } else if (person.member.last_Name) { //Fall, wenn nur der Nachname vorhanden ist
              memberName = `${person.member.last_Name}`;
            } else {
              memberName = `${person.member.eMail}`;   //Fall, wenn nur die E-Mail vorhanden ist
            }
            // Wenn es mehr als ein Mitglied für diese Rolle gibt, füge ein Komma hinzu
            if (index < members.length - 1 && index < 1) {
              memberName += ', ';
            }
            return (
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                {memberPicture}
                <span>{memberName}</span>
              </div>
            );
          })}
          {members.length > 2 && <span>,...</span>}
        </div>
      );
    }

    const handleSelectAll = useCallback((e) => {
      const newCheckedItems = {};
      const newSelectedProjects = [];
  
      filteredData.forEach(obj => {
        newCheckedItems[obj.id] = e.value;
        if (e.value) {
          newSelectedProjects.push(obj);
        }
      });
  
      setSelectAllChecked(e.value);
      setCheckedItems(newCheckedItems);
      setSelectedProjects(newSelectedProjects);
    }, [filteredData]);

    const onValueChanged = useCallback((obj) => (e) => {
      const newCheckedItems = { ...checkedItems, [obj.id]: e.value };
      setCheckedItems(newCheckedItems);

      if (e.value) {
        console.log("Die Checkbox ist ausgewählt", obj);
        setSelectedProjects([...selectedProjects, obj]);
      } else {
        console.log("Die Checkbox ist nicht ausgewählt", obj);
        setSelectedProjects(selectedProjects.filter(project => project.id !== obj.id));
      }
    }, [checkedItems, selectedProjects]);

    const DataRow = ({ obj }) => {
        return (
        <React.Fragment>
            <tr key={obj.id} className='tbodycss'>
                <td class="is-checkbox-cell">
                  <label class="checkbox">
                  <CheckBox
                    value={checkedItems[obj.id] || false}
                    onValueChanged={onValueChanged(obj)}
                  />
                  </label>
                </td>

                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Betreff')) && (
                <td data-label={t('subject')}       onClick={() => handleClick(obj.id)}>{obj.subject}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Start')) && (
                    <td data-label={t('start')} onClick={() => handleClick(obj.id)}>{formatDate(new Date (obj.start))}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Ende')) && (
                    <td data-label={t('end')} onClick={() => handleClick(obj.id)}>{formatDate(new Date (obj.end))}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Status')) && (
                <td data-label={t('status')}        onClick={() => handleClick(obj.id)}>{obj.status_Procent}%</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Erinnerung')) && (
                <td data-label={t('followUp')}      onClick={() => handleClick(obj.id)}>{formatDate(new Date (obj.follow_up))}</td>
                )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Unternehmen')) && (
                // <td data-label={t('company')}       onClick={() => handleClick(obj.id)}>{obj.company == undefined ? '' : obj.company.name }</td>
                null )}
                {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Abteilung')) && (
                // <td data-label={t('department')}    onClick={() => handleClick(obj.id)}>{obj.department == undefined ? '' : obj.department.name }</td>
               null )}
                {window.innerWidth > 768 && (
                  <>
                    {allRoles.map(role => (
                      (ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes(role)) && (
                        <td key={role}>
                          {obj.people.some(person => person.rolle.name === role) && (
                            renderMembers(obj, role)
                          )}
                        </td>
                      )
                    ))}
                  </>
                )}
                {window.innerWidth <= 768 && (
                  <>
                    {allRoles.map(role => (
                      (obj.people.some(person => person.rolle.name === role)) && (
                        <td key={role} data-label={t(role)} onClick={() => handleClick(obj.id)}>
                          {renderMembers(obj, role)}
                        </td>
                      )
                    ))}
                  </>
                )}
                <td>
                    <div className="DeleteButtons">
                    <MdDelete
                        size={30}
                        color="red"
                        onClick={() => {
                        setModalData(obj);
                        {open()}
                        }}
                    />
                    </div>
                </td>
            </tr>
        </React.Fragment>
        );
    };

    if (dataArray.length === 0) {
      return (
        <div className="container">
          <h2>{noDataMessage}</h2>
        </div>
      );
    }

    // Extrahiere alle einzigartigen Rollennamen aus den Projektmitgliedern
    const allRoles = dataArray.reduce((acc, project) => {
      project.people.forEach(person => {
        if (!acc.includes(person.rolle.name)) {
          acc.push(person.rolle.name);
        }
      });
      return acc;
    }, []);

    const DropDownButtonOptions = {
      width: 250,
    };

    const dropDownContentRender = (key) => {

      const uniqueValues = Array.from(new Set(dataArray.filter(obj => obj.hasOwnProperty(key)).map(obj => obj[key])))
      .filter(value => value !== null)
      .sort((a, b) => a - b)
      .map(value => ({ [key]: value }));

      console.log("UniqueValues: ", uniqueValues);

      return (
        <div>
          <ScrollView
            height={400}
            direction="both"
          >
            <DataGrid
              ref={dataGridRef}
              dataSource={uniqueValues.map(obj => ({ ...obj }))}              
              keyExpr={key}
              showBorders={true}
              onSelectionChanged={(e) => {
                const keysObject = {[key]: e.selectedRowKeys };
                console.log("KeysObject: ", keysObject);
                if (filterObject){
                  setFilterObject({...filterObject, ...keysObject});
                } else {
                  setFilterObject(keysObject);
                }
                if (selectedFilter && Object.keys(selectedFilter).length > 0) {
                    setSelectedFilter({...selectedFilter, ...keysObject});
                } else {
                    setSelectedFilter(keysObject);
                }
                if (e.selectedRowsData.length === 0) {
                  setFilteredData(dataArray);
                }
              }}
            >
              <Selection mode="multiple" selectAllMode={allMode} showCheckBoxesMode={checkBoxesMode}/>
              <FilterRow visible={true} />
              <Column 
                dataField={key} 
                dataType={key === "start" || key === "end" ? "date" : undefined}
                cellTemplate={(cellElement, cellInfo) => {
                  if (key === "start" || key === "end" || key === "follow_up") {
                    const date = new Date(cellInfo.value);
                    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                    cellElement.textContent = formattedDate;
                  } else {
                    cellElement.textContent = cellInfo.value;
                  }
                }} 
              />
            </DataGrid>
          </ScrollView>
        </div>
      );
    };
  
    const dropDownContentRenderRole = (key) => {
      if (typeof key === 'object' && key !== null) {
        key = key.role;
      }
  
      const ersteller = Array.from(new Set(dataArray.flatMap(obj => 
      obj.people
          .filter(member => member.rolle.name === key)
          .map(member => `${member.member.first_Name} ${member.member.last_Name}`)
      )));
  
      return (
        <div>
          <ScrollView
            height={400}
            direction="both"
          >
            <DataGrid
              ref={dataGridRef}
              dataSource={ersteller.map(name => ({ name }))}
              keyExpr="name"
              showBorders={true}
              onSelectionChanged={(e) => {
                  const selectedNames = e.selectedRowKeys;
                  const neueDaten = dataArray.filter(obj =>
                      obj.people.some(member =>
                          selectedNames.includes(`${member.member.first_Name} ${member.member.last_Name}`)
                      )
                  );
                  console.log("SelectedNames: ", selectedNames);
                  setSelectedFilter({ ...selectedFilter, [key]: selectedNames });
              }}
            >
              <Selection mode="multiple" selectAllMode={allMode} showCheckBoxesMode={checkBoxesMode}/>
              <FilterRow visible={true} />
              <Column 
                dataField="name" 
                cellTemplate={(cellElement, cellInfo) => {
                  cellElement.textContent = cellInfo.value;
                }} 
              />
            </DataGrid>
          </ScrollView>
        </div>
      );
    };

  return (
    <>
        <AufgabenlisteToolbar 
            setSearchText={setSearchText}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            dataArray={dataArray}
            searchText={searchText}
            setSortType={setSortType}
            Aufgabenfilter={Aufgabenfilter}
            selectedProjects={selectedProjects}
            t={t}
            ausgewählteSpalten={ausgewählteSpalten}
            setAusgewählteSpalten={setAusgewählteSpalten}
            setSelectedFilter={setSelectedFilter}
            showFilterWithText={showFilterWithText}
            filter={filter}
            user={user}
        />

        {selectedList === "/Aufgabenliste" ? (
        <>
        {/* <ScrollView
            height={'calc(100vh - 510px)'}
            width={'100%'}
            direction="both"
        > */}
            <section className="section">
                <div className="container">
                    <div className="b-table">
                    <div className="table-wrapper has-mobile-cards">
                        <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                            <th>
                              <div style={{display: 'flex', justifyContent: 'center'}}>
                                <CheckBox
                                  value={selectAllChecked}
                                  onValueChanged={handleSelectAll}
                                />
                              </div>
                            </th>
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Betreff')) && (
                              <th>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  {t('subject')}
                                  <DropDownButton
                                    icon='filter'
                                    hint='Betreff auswählen'
                                    stylingMode='text'
                                    showArrowIcon={false}
                                    valueExpr='id'
                                    displayExpr='name'
                                    onItemClick={(e) => {console.log(e.itemData)}}
                                    dropDownContentRender={() => dropDownContentRender("subject")}
                                    dropDownOptions={DropDownButtonOptions}
                                  />
                                </div>
                              </th>
                            )}
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Start')) && (
                            <th>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                {t('start')}
                                <DropDownButton
                                icon='filter'
                                hint='Startzeitpunkt auswählen'
                                stylingMode='text'
                                showArrowIcon={false}
                                valueExpr='id'
                                displayExpr='name'
                                onItemClick={(e) => {console.log(e.itemData)}}
                                dropDownContentRender={() => dropDownContentRender("start")}
                                dropDownOptions={DropDownButtonOptions}
                              />
                              </div>
                              </th>
                            )}
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Ende')) && (
                              <th>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  {t('end')}
                                  <DropDownButton
                                  icon='filter'
                                  hint='Endzeitpunkt auswählen'
                                  stylingMode='text'
                                  showArrowIcon={false}
                                  valueExpr='id'
                                  displayExpr='name'
                                  onItemClick={(e) => {console.log(e.itemData)}}
                                  dropDownContentRender={() => dropDownContentRender("end")}
                                  dropDownOptions={DropDownButtonOptions}
                                />
                                </div>
                              </th>
                            )}
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Status')) && (
                              <th>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                  {t('status')}
                                  <DropDownButton
                                  icon='filter'
                                  hint='Endzeitpunkt auswählen'
                                  stylingMode='text'
                                  showArrowIcon={false}
                                  valueExpr='id'
                                  displayExpr='name'
                                  onItemClick={(e) => {console.log(e.itemData)}}
                                  dropDownContentRender={() => dropDownContentRender("status_Procent")}
                                  dropDownOptions={DropDownButtonOptions}
                                />
                                </div>
                              </th>
                            )}
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Erinnerung')) && (
                              <th>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  {t('followUp')}
                                  <DropDownButton
                                  icon='filter'
                                  hint='Endzeitpunkt auswählen'
                                  stylingMode='text'
                                  showArrowIcon={false}
                                  valueExpr='id'
                                  displayExpr='name'
                                  onItemClick={(e) => {console.log(e.itemData)}}
                                  dropDownContentRender={() => dropDownContentRender("follow_up")}
                                  dropDownOptions={DropDownButtonOptions}
                                />
                                </div>
                              </th>
                            )}
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Unternehmen')) && (
                              // <th>
                              //     <div style={{display: 'flex', alignItems: 'center'}}>
                              //     {t('company')}
                              //     <DropDownButton
                              //     icon='filter'
                              //     hint='Endzeitpunkt auswählen'
                              //     stylingMode='text'
                              //     showArrowIcon={false}
                              //     valueExpr='id'
                              //     displayExpr='name'
                              //     onItemClick={(e) => {console.log(e.itemData)}}
                              //     dropDownContentRender={() => dropDownContentRender("company")}
                              //     dropDownOptions={DropDownButtonOptions}
                              //   />
                              //   </div>
                              // </th>
                            null )}
                            {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Abteilung')) && (
                              // <th>
                              //     <div style={{display: 'flex', alignItems: 'center'}}>
                              //     {t('department')}
                              //     <DropDownButton
                              //     icon='filter'
                              //     hint='Endzeitpunkt auswählen'
                              //     stylingMode='text'
                              //     showArrowIcon={false}
                              //     valueExpr='id'
                              //     displayExpr='name'
                              //     onItemClick={(e) => {console.log(e.itemData)}}
                              //     dropDownContentRender={() => dropDownContentRender("department")}
                              //     dropDownOptions={DropDownButtonOptions}
                              //   />
                              //   </div>
                              // </th>
                              null
                            )}
                            {allRoles.map(role => (
                              (ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes(role)) && (
                                <th key={role}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {role}
                                    <DropDownButton
                                      icon='filter'
                                      hint={`${role} auswählen`}
                                      stylingMode='text'
                                      showArrowIcon={false}
                                      valueExpr='id'
                                      displayExpr='name'
                                      onItemClick={(e) => { console.log(e.itemData) }}
                                      dropDownContentRender={() => dropDownContentRenderRole({ role })}
                                      dropDownOptions={DropDownButtonOptions}
                                    />
                                  </div>
                                </th>
                              )
                            ))}
                            <th>{/* buttons */}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((obj) => (
                            <DataRow obj={obj} key={obj.id} />
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </section>
        {/* </ScrollView> */}
        </>
        ) : (
          <AufgabenlisteEigeneTabelle 
            selectedList={selectedList}
            allRoles={allRoles}
            renderMembers={renderMembers}
            handleClick={handleClick}
            formatDate={formatDate}
            t={t}
            open={open}
            setModalData={setModalData}
            setAktuelleFilterBedingung={setAktuelleFilterBedingung}
            filteredData={filteredData}
            setFilterBedingungAusEigenerTabelle={setFilterBedingungAusEigenerTabelle}
            setBedingungenFürPopup={setBedingungenFürPopup}
            ausgewählteSpalten={ausgewählteSpalten}
            setAusgewählteSpalten={setAusgewählteSpalten}
          />
        )}

        <Modal opened={opened} onClose={close} closeOnClickOutside={false} withCloseButton={false} centered>
          <p className="deleteDatensatz">{t('deleteTask')}</p>
            <div className="yesNoButtons" style={{ marginTop: '1em' }}>
              <Button
                className='myButton'
                style={{ marginRight: '1em' }}
                onClick={() => {
                  handleDelete(modalData.id);
                  close();
                  }}
                >
                {m('btnYes')}
              </Button>
              <Button
                onClick={() => { close(); }}
                color="red"
              >
                {m('btnNo')}
              </Button>
            </div>
        </Modal>
    </>
  );
};

export default AufgabenlistePC;
