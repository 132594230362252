import React, {useState, useRef, useCallback, useEffect} from 'react';
import '../../pages/Kontaktliste/Kontaktliste.scss';
import * as maprotaIcon from '../../logo192.png'
import CustomStore from 'devextreme/data/custom_store';
import i18n from "i18next";
import Api from "../../utils/api"
import {useAuth} from '../../contexts/auth';
import {Button} from 'devextreme-react'
import { useHistory} from 'react-router-dom';
import {notifyError, notifySuccess} from "../../utils/notifyManager";
import {useScreenSize} from "../../utils/media-query";
import DataGridCards from '../../pages/Kontaktliste/Responsive Table/DataGridCards';
import ContactModal from '../../pages/Kontaktliste/Modal/ContactModal';
import KontaktlisteGridPC from './KontaktlisteGridPC';
import KontaktlistePC from '../../pages/Kontaktliste/PC Liste/KontaktlistePC';


function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
};

export default function () {

    const {isXSmall, isSmall} = useScreenSize();
    const {user} = useAuth()
    const history = useHistory();
    let myNetwork = {}
    const [dataArray, setDataArray] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [Mitgliederliste, setMitgliederliste] = useState(true)

    const t = i18n.getFixedT(null, 'contacts');

    const networkDS = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.people.getMyNetwork()
                .then(data => data.data)
                .then(data => {
                    myNetwork = data
                    return data
                })
        },

        insert: (item) => {
            const obj = {
                Person_other : item,
                relationship: {
                    "id": 2
                },
                person_MeID: user.id
            }

            return Api.people.insertToNetwork(obj)
        },

        remove: key => {

            let relationshipToDelete = myNetwork.find(item => item.id == key.id)
            if(relationshipToDelete){
                return Api.people.deleteFromNetwork(key.id, relationshipToDelete.relationshipID)
            }
            else{
                notifyError(t('failedToRemoveMyRequest'))
            }

        }

    });

 const renderLogo = (data) => {
       const obj = data.data
        if(obj && obj.relationshipID === 1){
            return <Button icon={maprotaIcon} onClick={() => {
                history.push(`/profile/${obj.id}`)
            }
            }/>
        }else {
            return <Button icon='card' onClick={() => {
                 //history.push(`/profile/${obj.person_otherID}`)
                 history.push(`/profile/${obj.id}`)
            }}/>
        }
    };

    useEffect(() => {
    networkDS.load()
    }, []);

    
    function notifyRemovedContact() {
        notifySuccess(t('removedFromNetwork'))
    }
    function notifyAddedContact() {
        notifySuccess(t('insertedLocalContact'))
    }

    function isEditAvailable(e) {
        if (e.rowType === 'data' && e.column.command === 'edit')
            if (e.row.data.relationshipID === 1)
            {
                let editLink = e.cellElement.querySelector(".dx-link-edit");
                if(editLink) {
                    editLink.remove();
                }
            }
    }

    const isMobile = isXSmall;

    return (

        <React.Fragment>

            <h2 className={'content-block'}>{t('title')}</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>

                    {isMobile ? (
                    <>
                        <ContactModal t={t} />  
                        <DataGridCards t={t} renderLogo={renderLogo} />
                    </>          
                    
                    ) : (
                    <>
                  {/*      <KontaktlisteGridPC
                            t={t} 
                            renderLogo={renderLogo} 
                            dataArray={dataArray}
                            setDataArray={setDataArray}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                        />
                        */}

                        <KontaktlistePC
                            t={t} 
                            renderLogo={renderLogo} 
                            dataArray={dataArray}
                            setDataArray={setDataArray}
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            Mitgliederliste={Mitgliederliste}

                        />
                    </>
                    )}             
            
                </div>
            </div>
        </React.Fragment>
    );
};