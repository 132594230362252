import React, {useEffect, useState} from 'react';
import 'devextreme-react/text-area';
import './Benutzerverwaltung.scss';
import {SelectBox, LoadIndicator, Button, ScrollView} from "devextreme-react";
import Api from '../../utils/api'
import notify from "devextreme/ui/notify"
import i18n from "i18next";
import AvatarPlaceholder from "./personPlaceholder.png";
import { Avatar, ActionIcon, FileButton, Button as MantineButton, Timeline } from '@mantine/core';
import EventBus from '../../components/Mitgliederliste-Mobil/EventBus';
import { FaRegAddressCard, FaPaste, FaBookmark, FaCamera } from 'react-icons/fa';
import SkillsRingProgress from '../../components/Skills-RingProgress/skillsRingProgress';
import { UserSetting } from '../../components';

export default function () {
    const t = i18n.getFixedT(null, 'profile');
    const c = i18n.getFixedT(null, 'common');

    const [languages, setLanguages] = React.useState();

    const [loading, setLoading] = React.useState(false);

    const [employee, setEmployee] = React.useState({});

    const [file, setFile] = useState(null);
    const [titleFile, setTitleFile] = useState(null);

    const [pictureURL, setPictureURL] = useState(null);

    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);


    useEffect(() => {
        if (file) { 
            saveDocuments();
        } else if (titleFile) {
          console.log("Titelbild wurde geändert");
        }
    }, [file, titleFile]);

    async function saveDocuments() {
        if (file.length === 0) return;
    
        // Aktualisiere die Initialisierung von `data` hier, um die neuesten Werte zu erfassen
        let data = {
            "ObjektID": employee.id,
            "ObjektType": "ProfilPicture",
            'file': file
        };
        console.log("data: ", data);
    
        try {
            if (employee.pictureURL) {
                // Extrahiere die ID aus der URL
                const urlParams = new URLSearchParams(employee.pictureURL.split('?')[1]);
                const id = urlParams.get('id');
    
                if (id) {
                    // Lösche das existierende Dokument
                    await Api.Dokuments.deleteDokument(id);
                }
            }
    
            // Erstelle das neue Dokument
            Api.Dokuments.createDokument(data, file)
                .then(response => {
                    notify('Daten wurden gespeichert');
                    setTimeout(() => {
                        loadUser();
                        setTimeout(() => {
                            loadProfilPicture();
                        }, 2000); // 2 Sekunden Verzögerung für loadProfilPicture
                    }, 0); // Sofortige Ausführung von loadUser
                    EventBus.emit('refreshData');
                }).catch(error => {
                    notify('Fehler beim Update: ' + error);
                });
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    useEffect(() => {
        loadProfilPicture();
    }, [employee.pictureURL]);

    async function loadProfilPicture() {
        if (!employee.pictureURL) {
            return AvatarPlaceholder;
        }
        const url = employee.pictureURL;
        console.log("URL: ", url);
        const idMatch = url.match(/id=(\d+)/);
        if (idMatch) {
            const id = idMatch[1];
            try {
                console.log(`Dokumenten-ID: ${id}`);
                const blob = await Api.Dokuments.getDokuments(id)
                const objectURL = URL.createObjectURL(blob);
                console.log('Download-URL:', objectURL);
                setPictureURL(objectURL); // Set the picture URL in state
            } catch (error) {
                console.error('Fehler beim Anzeigen des Dokuments:', error);
            }
        }
    }

    function avatarRender() {
        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <Avatar 
                    size={128} 
                    radius="xxl" 
                    src={pictureURL} // Verwende den geladenen Bild-URL aus dem State
                    styles={{ 
                        root: { 
                            border: '1px solid #d2d3d5', // Fügt einen 2px breiten, soliden, schwarzen Rand hinzu
                            borderRadius: '50%' // Macht den Rand rund
                        } 
                    }}
                />
                <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
                    <FileButton onChange={setFile} accept="image/png,image/jpeg">
                        {(props) => <ActionIcon {...props} radius="xl" variant="default"><FaCamera size={16} color='#39b201' /></ActionIcon>}
                    </FileButton>
                </div>
            </div>
        );
    }

    function getUser() {
        return Api.getMe()
            .then(response => response.data)
    }

    function getLanguages() {
        return Api.getLanguages()
            .then(response => response.data)
    }

    useEffect(() => {

        loadUser()

    }, []);

    function loadUser() {
        setLoading(true);
        getUser()
            .then(data => {
                setEmployee(data);
                return getLanguages()
            })
            .then(data => {
                setLoading(false);
                setLanguages(data)
            })
            .catch(() => {
                setLoading(false);
                notify({
                    message: i18n.t('notify:failedLoading'),
                    type: "error",
                    displayTime: 5000
                });
            })
    }


    function doMatch(val1, val2) {
        if (val1 === undefined || val1 === null || val1.length === 0) {
            if (val2 === undefined || val2 === null || val2.length === 0) {
                return true
            }
        }
        return val1 === val2;
    }

    function isValidPassword(val) {
        if (val === undefined) {
            return false
        }

        if (val === null) {
            return false
        }

        if (val.length === 0) {
            return false
        }

        //here you can insert other requirements for the password.

        return true;


    }

    function refreshData() {
        loadUser()
    }

    function saveData() {
        setLoading(true);
        if (doMatch(employee.pass1, employee.pass2)) {
            if (isValidPassword(employee.pass1)) {
                employee.password = employee.pass1
            }

            // if (employee.Sprache) {
            //     console.log(languages);
            //     //TODO remove later.
            //     employee.language = {
            //         id: languages.items.findIndex(item => item === employee.Sprache),
            //         name: employee.Sprache
            //     }
            // }

            Api.updateUser(employee)
                .then(result => {
                    setLoading(false);
                    if (result.status === 204) {
                        notify({
                            message: i18n.t('notify:userSaved'),
                            type: "success",
                            displayTime: 3000
                        });
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    notify({
                        message: i18n.t('notify:errSaving'),
                        type: "error",
                        displayTime: 3000
                    })
                })
        } else {
            setLoading(false);
            employee.pass1 = "";
            employee.pass2 = "";
            notify({
                message: i18n.t("notify:pswdNotMatch"),
                type: "error",
                displayTime: 5000
            });
        }
    }

    function languageSelectionChanged(e) {
        if (e) {
            setEmployee({
                ...employee,
                language: e.selectedItem
            })
        }

    }

    function getSelectedValue() {
        if(employee && employee.language && employee.language.id !== undefined){
            return languages.find( item => item.id === employee.language.id).id
        }
        return -1
    }

    const togglePopup = (e) => {
        setPopupVisibility(!isPopupVisible);
    };

    function renderComp() {
        if (languages !== undefined) {
            return (
                <SelectBox
                    dataSource={languages}
                    valueExpr="id"
                    displayExpr="name"
                    value={getSelectedValue()}
                    // value={employee.language.name}
                    onSelectionChanged={languageSelectionChanged}
                />
            )

        }
    }

    const tabs = [
        { title: t('Personal'), icon: <FaRegAddressCard /> },
        { title: t('MenuPro'), icon: <FaPaste /> },
        { title: t('MenuTask'), icon: <FaBookmark /> },
        { title:  t('MenuSetting'), icon: <FaBookmark /> }
    ];

    return (
        <React.Fragment>
            {/* <h2 className={'content-block'}>{t('title')}</h2> */}
            {loading ? <LoadIndicator visible={loading}/> :
                    <div>
                        <div className={'content-block'}>
                            <div className={'dx-card responsive-paddings'}>
                            <div id='headContainer'>
                                  <div id='headLeftContainer'>
                                    <div className='profile'>
                                      <div id='profile-picture'>
                                        {avatarRender()}
                                      </div>
                                      <div id='profile-info'>
                                        <p id="bold-text">{employee.first_Name} {employee.last_Name}</p>
                                        <p>{employee.positionname || "Keinen Positionnamen angegeben"}</p>
                                        <strong>Telefon:</strong> <span>{employee.phone || "Keine Nummer hinterlegt"}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div id='headRightContainer'>
                                    <div className="image-container">
                                      <img 
                                        src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-10.png' //später pictureURL
                                        alt="Titelbild" 
                                        className="title-image" 
                                      />
                                      <div className="file-button-container">
                                        <FileButton onChange={setTitleFile} accept="image/png,image/jpeg">
                                          {(props) => <MantineButton {...props} color='gray' leftIcon={<FaCamera />}>Titelbild bearbeiten</MantineButton>}
                                        </FileButton>
                                      </div>
                                    </div>
                                    <div className="custom-tab-panel">
                                      {tabs.map((tab, index) => (
                                        <div
                                          key={index}
                                          className={`tab-item ${selectedTabIndex === index ? 'active' : ''}`}
                                          onClick={() => setSelectedTabIndex(index)}
                                        >
                                          {tab.icon}
                                          <span>{tab.title}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                          </div>
                                <div className='divider'/>
                                <div className="custom-tab-panel-mobile">
                                      {tabs.map((tab, index) => (
                                        <div
                                          key={index}
                                          className={`tab-item ${selectedTabIndex === index ? 'active' : ''}`}
                                          onClick={() => setSelectedTabIndex(index)}
                                        >
                                          {tab.icon}
                                          <span>{tab.title}</span>
                                        </div>
                                        ))}
                                    </div>
                                <div id='view'>
                                  {selectedTabIndex === 0 && (
                                    <ScrollView
                                      direction='vertical'
                                      showScrollbar='onScroll'
                                    >
                                      <Timeline active={1} bulletSize={24} lineWidth={5} color="green">

                                        <Timeline.Item title={<span className="large-title">Skills</span>}>
                                          <SkillsRingProgress />
                                        </Timeline.Item>

                                        <Timeline.Item  title={<span className="large-title">Andere Dinge die später folgen...</span>}>
                                        </Timeline.Item>

                                      </Timeline>
                                    </ScrollView>
                                  )}
                                  {selectedTabIndex === 1 && (
                                    <ScrollView
                                      direction='both'
                                      showScrollbar='onScroll'
                                    >
                                      <div id="projects-view">
                                        <div className="profile-item">
                                          <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                            <thead>
                                              <tr>
                                                <th>Projektname</th>
                                                <th>Startdatum</th>
                                                <th>Enddatum</th>
                                                <th>Projektleiter</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Projekt 1</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Projekt 2</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Projekt 3</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </ScrollView>
                                  )}
                                  {selectedTabIndex === 2 && (
                                    <ScrollView
                                      direction='vertical'
                                      showScrollbar='onScroll'
                                    >
                                      <div id="tasks-view">
                                        <div className="profile-item">
                                          <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                            <thead>
                                              <tr>
                                                <th>Aufgabenname</th>
                                                <th>Startdatum</th>
                                                <th>Enddatum</th>
                                                <th>Projektleiter</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Aufgabe 1</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Aufgabe 2</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                              <tr>
                                                <td>Aufgabe 3</td>
                                                <td>01.01.2021</td>
                                                <td>31.12.2021</td>
                                                <td>Max Mustermann</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </ScrollView>
                                    )}
                                    {selectedTabIndex === 3 && (
                                        <ScrollView
                                          direction='vertical'
                                          showScrollbar='onScroll'
                                        >
                                          
                                              <UserSetting>

                                              </UserSetting>
                                                
                                              
                                          
                                          
                                        </ScrollView>
                                  )}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </React.Fragment>
    );
}


const passwordEditorOptions = {mode: 'password'};
const buttonAttributes = {
    class: 'btn-submit'
};
