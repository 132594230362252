import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {useHistory} from 'react-router-dom'
import './mail.scss';
// import Toolbar, {Item as TBItem} from "devextreme-react/toolbar";
import i18n from "i18next";
import { HtmlEditor, Toolbar, Item as EditorItem, MediaResizing } from 'devextreme-react/html-editor';
  
import TagBox from 'devextreme-react/tag-box';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Api from '../../utils/api';
import { useLocation } from 'react-router-dom';
import { notifySuccess } from "../../utils/notifyManager";
import { notifyError } from '../../utils/notifyManager';

  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [ "Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana" ];
  const headerValues = [false, 1, 2, 3, 4, 5];
  const t = i18n.getFixedT(null, 'mails');
  function MailFrom () {
      return "Test";
  }

export default (props) => {
  //Mapping  
    const AnserMail = props.AnserMail;
    const selectedMail = props.selectedMail;
    const setSelectedRow = props.setSelectedRow;
    const Forward = props.Forward;
    const Name = props.Name;
    const location = useLocation();
    const pfadSegment = location.pathname.split("/");
    const mailSettingId = pfadSegment[2]
    const setNewMailMobile = props.setNewMailMobile;

    //Zustände
    const [TagBoxToValues, setTagBoxToValues] = useState();
    const [TagBoxCCValues, setTagBoxCCValues] = useState();
    const [TextBoxSubjectValue, setTextBoxSubjectValue] = useState();
    const [MailMessage, setMailMessage] = useState();

    useEffect(() => {
      console.log("selectedMail überprüfen", selectedMail);
    }, [selectedMail]);

    const sendEmail = async () => {
      console.log("Senden gedrückt");
      const emailData = {
        to: TagBoxToValues ? 
          TagBoxToValues.map(email => ({ EMail: email })) 
          : selectedMail && selectedMail.from ? 
          selectedMail.from.map(obj => ({ EMail: obj.eMail })) 
          : [],
        cc: TagBoxCCValues ? 
          TagBoxCCValues.map(email => ({ EMail: email })) 
          : selectedMail && selectedMail.cc ? 
          selectedMail.cc.map(obj => ({ EMail: obj.eMail })) 
          : [],
        subject: TextBoxSubjectValue || (selectedMail ? selectedMail.subject : ''),
        body: MailMessage || (selectedMail ? selectedMail.body : '')
      };
      console.log("emailData", emailData);

      if (emailData.to.length === 0) {
        notifyError(t('errorMissingRecipient'));
        return;
      }

      let sendMail = true;

      if (emailData.subject.length === 0) {
        let userConfirmed = window.confirm(t('missingSubject'));
        if (!userConfirmed) {
          sendMail = false;
        }
      }

      if (sendMail) {
        try {
          console.log("mailSettingId", mailSettingId);
          const response = await Api.mail.sendMails(mailSettingId, emailData); // Verwenden Sie die extrahierte ID
          console.log("response", response);
          notifySuccess(t('successfullySent'));
          setNewMailMobile(PrevState => !PrevState);
          setSelectedRow(PrevState => !PrevState);
          console.log("Mail gesendet")
        } catch (error) {
          if (error.response) {
            console.error(`HTTP error! status: ${error.response.status}`);
          } else {
            console.error('Error', error);
          }
        }
      }
    };

    return (

    <React.Fragment>
    {/* {AnserMail === true ? "Antwort "+AnserMail : "Keine Antwort "+AnserMail}<br/>
    {Forward === true ? "Weiterleiten "+Forward : "Keine Weiterleitung "+Forward}<br/>
    {selectedMail != null ? "Zeile ausgewählt" : "Keine Zeile ausgewählt"}<br/> */}

     <div className='ButtonSenden'>
        <Button
                    
                    text={t('SendButton')}
                    type="default"
                    stylingMode="contained"
                    onClick={() => sendEmail()}
                    style={{marginBottom: '25px'}}
                  // onClick={() => console.log("Senden", selectedMail)}
                    />
    </div>
      <div className='dx-field' >

        <Button 
          text="AN"
          type="normal"
          stylingMode="outlined"
        />

        <div className="dx-field-value">
          <TagBox
            acceptCustomValue={true}     
            defaultValue={selectedMail == null ? "" : Forward === true ? "" :  [selectedMail.from[0].eMail]}
            onValueChanged={(e) => setTagBoxToValues(e.value)}
          />
        </div>

      </div>

      <div className="dx-field">

        <Button
          text="CC"
          type="normal"
          stylingMode="outlined"
        />
            
        <div className="dx-field-value">
          <TagBox 
            acceptCustomValue={true}
            onValueChanged={(e) => setTagBoxCCValues(e.value)}
          />
        </div>

      </div>

        <TextBox 
          placeholder= {t('Betreff')} 
          defaultValue={ selectedMail == null ? "" : selectedMail.subject}
          onValueChanged={(e) => {
            setTextBoxSubjectValue(e.value);
          }}
        />
          <HtmlEditor
            height="550px"
            defaultValue={selectedMail == null ? "" : selectedMail.body}
            onValueChanged={(e) => setMailMessage(e.value)}
          >
            <MediaResizing enabled={true} />
            <Toolbar multiline={true}>
              <EditorItem formatName="undo" />
              <EditorItem formatName="redo" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="bold" />
              <EditorItem formatName="italic" />
              <EditorItem formatName="strike" />
              <EditorItem formatName="underline" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="alignLeft" />
              <EditorItem formatName="alignCenter" />
              <EditorItem formatName="alignRight" />
              <EditorItem formatName="alignJustify" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="orderedList" />
              <EditorItem formatName="bulletList" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="header" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="color" />
              <EditorItem formatName="background" />
              <EditorItem formatName="separator" />
              <EditorItem formatName="link" />
              {/* Mobil noch nicht verfügbar}
              <EditorItem formatName="image" />
              */}
            </Toolbar>
          
          </HtmlEditor>

    </React.Fragment>
  );
}