import React, { useCallback, useEffect, useRef } from 'react';
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  RequiredRule,
  ButtonOptions
} from 'devextreme-react/form';
import Validator from 'devextreme/ui/validator';
import eyeopen from './eyeopen.svg'
import i18n from "i18next";
import { useLocation } from 'react-router-dom';
import Api from '../../utils/api';
import notify from 'devextreme/ui/notify';
import { useHistory } from 'react-router-dom';
import './setNewPasswortForm.css';

const customer = { Password: '', ConfirmPassword: '' };

const passwordComparison = () => customer.Password;
const t = i18n.getFixedT(null, 'auth');

export default function (props) {
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const EMail_validate_Key = searchParams.get('EMail_validate_Key');
    const PeopleID = searchParams.get('PeopleID');
    const history = useHistory();

    const formRef = useRef(null);

    const changePasswordMode = useCallback((name) => {
        const editor = formRef.current.instance.getEditor(name);
        editor.option('mode', editor.option('mode') === 'text' ? 'password' : 'text');
    }, []);

    const getPasswordOptions = useCallback(
        () => ({
        mode: 'password',
        valueChangeEvent: 'keyup',
        stylingMode: 'filled',
        onValueChanged: () => {
            const editor = formRef.current.instance.getEditor('ConfirmPassword');
            if (editor.option('value')) {
            const instance = Validator.getInstance(editor.element());
            instance.validate();
            }
        },
        buttons: [
            {
            name: 'password',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: eyeopen,
                onClick: () => changePasswordMode('Password'),
            },
            },
        ],
        }),
        [changePasswordMode],
    );
    const getConfirmOptions = useCallback(
        () => ({
        mode: 'password',
        valueChangeEvent: 'keyup',
        stylingMode: 'filled',
        buttons: [
            {
            name: 'password',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: eyeopen,
                onClick: () => changePasswordMode('ConfirmPassword'),
            },
            },
        ],
        }),
        [changePasswordMode],
    );

   const handleSubmit = (e) => {
        e.preventDefault();
        if (customer.Password === customer.ConfirmPassword) {
            const payload = {
                EMail_validate_Key: EMail_validate_Key,
                PeopleID: PeopleID,
                Password: customer.Password
            };
            Api.auth.changePassword(payload).then(res => {
                if (res.status === 200) {
                    notify({
                        message: t('pswdChangeSuccess'),
                        type: 'success',
                        displayTime: 5000
                    });
                    history.push('/login');
                }
            })
        } else {
            console.log('Passwörter sind nicht gleich');
            notify({
                message: t('pswdChangeFailed'),
                type: 'error',
                displayTime: 5000
            });
        }
    };

    const onOptionChanged = (e) => {

    };

    return (
        <form onSubmit={handleSubmit}>
            <Form
                ref={formRef}
                formData={customer}
                readOnly={false}
                onOptionChanged={onOptionChanged}
                showColonAfterLabel={true}
                showValidationSummary={true}
                validationGroup="customerData"
            >
                <GroupItem>
                
                    <SimpleItem
                        name={t('password')}
                        dataField="Password"
                        editorType="dxTextBox"
                        editorOptions={getPasswordOptions()}
                        cssClass={'password'}
                    >
                        <RequiredRule message={t('pswdInvalid')} />
                    </SimpleItem>
                    <SimpleItem
                        name={t('pswdConfirm')}
                        dataField="ConfirmPassword"
                        editorType="dxTextBox"
                        editorOptions={getConfirmOptions()}
                        cssClass={'password-confirm'}
                    >
                        <Label text={t('pswdConfirm')} />
                        <RequiredRule message={t('pswdConfirm')} />
                        <CompareRule
                            message={t('pswdNotMatch')}
                            comparisonTarget={passwordComparison}
                        />
                    </SimpleItem>
                </GroupItem>

                <ButtonItem>
                    <ButtonOptions 
                        text={t('save')} 
                        type={'default'}
                        width={'100%'}
                        useSubmitBehavior={true}
                        cssClass={'confirm-button'}
                    />
                </ButtonItem>
            </Form>
        </form>
    );
}