import React from 'react';




export default () => (
  <React.Fragment>
    
    <h2 className={'content-block'}>Einstellung Projekt</h2>
    <div className={'content-block'}>
      <div className={'dx-card responsive-paddings'}>
        Put your content here Projekt
      </div>
    </div>
  </React.Fragment>
    );


