import notify from "devextreme/ui/notify"

const DEFAULT_DISPLAY_TIME = 5000
export function notifySuccess(message = "OK", displayTime = DEFAULT_DISPLAY_TIME) {
    notify({
        message: message,
        type: "success",
        displayTime: displayTime
    });
}

export function notifyWarning(message = "Info", displayTime = DEFAULT_DISPLAY_TIME) {
    notify({
        message: message,
        type: "info",
        displayTime: displayTime
    });
}

export function notifyError(message = "Failed!", displayTime = DEFAULT_DISPLAY_TIME) {
    notify({
        message: message,
        type: "error",
        displayTime: displayTime
    });
}

