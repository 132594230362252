import React, { useState,useEffect,useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {Button} from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { Link } from 'react-router-dom'
import TreeList, {
    Column, ColumnChooser, HeaderFilter, SearchPanel, Selection, Lookup, Editing, Value, Paging } from 'devextreme-react/tree-list';
// import {Column,DataGrid, ColumnChooser, GroupPanel, Editing, GroupItem} from 'devextreme-react/data-grid';
import i18n from "i18next";
import Api from '../../utils/api'
import notify from "devextreme/ui/notify"
import ProjectlisteMobile from './Mobileansicht/ProjektlisteMobile';
import ProjectlistePC from './PCAnsicht/ProjektlistePC';
import './projektliste.scss';

function doneClick() {
  //  notify('The Done button was clicked');
}
function handleErrors(response) {
    if (response.status !== 200) {
        throw Error(response.statusText);
    }
    return response;
}

export default  function () {
    const p = i18n.getFixedT(null, 'projectList');

    const { recoveryCode } = useParams();
    const history = useHistory();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
    const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
    };
         
    window.addEventListener('resize', handleResize);
         
    // Cleanup function
    return () => {
    window.removeEventListener('resize', handleResize);
    };
    }, []);

    const t = i18n.getFixedT(null, 'projectList');


    return (
        
        <React.Fragment>
   <div className={'content-block'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Link to='Projekt'>
                            <Button icon="plus"
                                text={p('btnAddProject')}
                                onClick={doneClick}
                                className='myButton'
                            />
                        </Link>
                    </div>
                    <div style={{ textAlign: 'center', flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', height: '100%' }}>
                      {t('title')}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      {/* <DropDownButton
                        text={SelectedView === "Liste" ? "Tabellenansicht" : "Kanbanansicht"}
                        icon="table"
                        items={Ansichten}
                        onSelectionChanged={onViewSelected} 
                      /> */}
                    </div>
                  </div>
            
            <div className={'content-block'}>
               
                
                    <div className={'dx-card responsive-paddings'}>

                    { isMobile ?
                    <>
                    <ProjectlisteMobile t={t} /> 
                    </>
                    :
                    <>
                    <ProjectlistePC t={t} />
                    </>
                    
                    
                    }
                    </div>
                
            </div>
        </React.Fragment>
    )
};


