import React, {useState, useRef, useCallback, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import notify from "devextreme/ui/notify"
import { useAuth } from '../../contexts/auth';
import './verify-page.scss';
import i18n from "i18next";
import {useLocation} from 'react-router-dom'
import {notifyError, notifySuccess} from "../../utils/notifyManager";
import Api from '../../utils/api'
import {LoadIndicator} from "devextreme-react";

export default function (props) {
  const t = i18n.getFixedT(null, 'auth');
  const c = i18n.getFixedT(null, 'common');


  const location = useLocation()
  const history = useHistory();
  const { loginFunction } = useAuth();
  const formData = useRef({});
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false)

  function getVerificationID(){
    return new URLSearchParams(location.search).get('ID')
  }
  function getVerificationPeopleID(){
    return new URLSearchParams(location.search).get('PeopleID')
  }

  useEffect(() => {
    const verificationID = getVerificationID()
    const PeopleID = getVerificationPeopleID();
    setLoading(true)
      Api.auth.verifyUser({Key : verificationID , People : PeopleID})
          .then(res => res.data)
          .then(res => {
            notifySuccess(t('verificationSuccess'), 10000)
            setTimeout(() => {
              setLoading(false)
              history.push('/login')
            }, 500)
          })
          .catch(error => {
            setLoading(false)
            setMessage(t('verificationFailed'))
          })
  }, []);


  return (
      <>
        {loading && <LoadIndicator visible={loading}/>}
        {!loading &&
            <>
              <div className='info-txt'> {message} </div>
              <div className={'login-link'}> {i18n.t('glossary:accountExists?')}
              <Link
                  to={'/login'}>{i18n.t('auth:login')}</Link></div>
            </>
        }
      </>
  );
}


