import React, { useState, useEffect } from 'react';
import { Group, Button, FileButton, Modal, Box, ScrollArea } from '@mantine/core';
import { MdUploadFile, MdClose } from "react-icons/md";
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import notify from 'devextreme/ui/notify'
import Api from '../../utils/api';
import CustomStore from 'devextreme/data/custom_store';
import { Avatar, ActionIcon } from '@mantine/core';
import { MdModeEdit } from "react-icons/md";

import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';

function handleErrors(response) {
    if (!response.ok) {
        console.log('Fehler DS' + response.statusText);
        throw Error(response.statusText);
    } else {
        for (var pair of response.headers.entries()) {
            console.log('LARS' + pair[0] + ': ' + pair[1]);
        }
    }
    return response;
}

const ProjectPicture = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const [previewModalOpened, setPreviewModalOpened] = useState(false); // Neuer State für das Vorschaumodal
    const [file, setFile] = useState()
    const [previewUrl, setPreviewUrl] = useState(null)
    const [isPDF, setIsPDF] = useState(false)
    const location = useLocation();
    const [archiveBusinessObjektType, setArchiveBusinessObjektType] = useState(null)
    const [BussinesObjektID, setBusinessObjektID] = useState(location.pathname.split('/').pop())


    useEffect(() => {
        const location = window.location.pathname.split('/')
        console.log("Hier die Location: ", location[1])
        if (location[1] === 'Aufgabe') {
            setArchiveBusinessObjektType('TaskPicture')
        } else {
            setArchiveBusinessObjektType('ProjectPicture')
        }
        console.log("Hier die ID: ", BussinesObjektID)
        console.log("Dokuemnt: ", file)
    }, [file, location])

    useEffect(() => {
        if (file && file.size > 0) {
            saveDocuments()
        }
    }, [file])

    const DocDS = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        insert: (data) => {
            Api.Dokuments.createDokument(data, data.file)
                .then(handleErrors)
                .catch((handleErrors) => {
                    throw 'Network error'+handleErrors
                });
        }
    });


    async function saveDocuments() {
        if (file.length === 0) return;

        // Aktualisiere die Initialisierung von `data` hier, um die neuesten Werte zu erfassen
        let data = {
            "ObjektID": BussinesObjektID,
            "ObjektType": archiveBusinessObjektType,
            'file' : file
        }; 
        console.log("data: ", data);

        try {
          //  const response = await Api.Dokuments.createDokument(data, formData);
          DocDS.insert(data)
          .then(
              (dataObj) => {
                  notify('Daten wurden gespeichert')
              },
              (error) => {
                  notify('Fehler beim Update' + error)
              }
          );
            
          //  console.log("Response: ", response);
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    return (
        <>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <Avatar 
                    size={128} 
                    radius="xxl" 
                    src={process.env.PUBLIC_URL + '/project.png'} 
                    styles={{ 
                        root: { 
                            border: '1px solid #d2d3d5', // Fügt einen 2px breiten, soliden, schwarzen Rand hinzu
                            borderRadius: '50%' // Macht den Rand rund
                        } 
                    }}
                />
                <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
                    <FileButton 
                        onChange={setFile} 
                        accept="*img/*" 
                    >
                        {(props) =>
                        <ActionIcon
                            {...props} 
                            radius="xl" 
                            variant="default"
                        >
                            <MdModeEdit size={20} color='#39b201' />
                        </ActionIcon>}
                    </FileButton>
                </div>
            </div>
        </>
    )
}
export default ProjectPicture;