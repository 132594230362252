import React, { useState, useEffect } from 'react';
import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';
import { useHistory } from 'react-router-dom'


const SubProjectsLists = ({ p, subProjects, subProjectClick}) => {

    const history = useHistory();

    useEffect(() => {
        console.log('SubProjekte', subProjects);
    }, [subProjects]);

    const formatDate = (date) => {
      const d = new Date(date),
        day = ('0' + d.getDate()).slice(-2),
        month = ('0' + (d.getMonth() + 1)).slice(-2),
        year = d.getFullYear();
        
        return [day, month, year].join('.');
    };

    return (
      <section style={{paddingTop: '20px'}}> {/* padding 48px */}
        <div className="container">
          <div className="b-table">
            <div className="table-wrapper has-mobile-cards">
              <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                <thead>
                  <tr style={{fontSize: '16px'}}>
                    <th>{(p('subject'))}</th>
                    <th>{(p('start'))}</th>
                    <th>{(p('end'))}</th>
                  </tr>
                </thead>
                <tbody style={{fontSize: '14px', content: 'auto' }}>
                    {subProjects.map((project) => (
                    <tr key={project.id}>
                      <td style={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={() => subProjectClick(project.id)}>{project.subject}</td>
                      <td style={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={() => subProjectClick(project.id)}>{formatDate(project.start)}</td>
                      <td style={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={() => subProjectClick(project.id)}>{formatDate(project.end)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
}

export default SubProjectsLists;
