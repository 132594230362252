import React, { useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import FilterBuilder, { Field } from 'devextreme-react/filter-builder';
import Button from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';
import Api from '../../../utils/api';
import {useAuth} from "./../../../contexts/auth";
import { TextBox } from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import i18n from "i18next";
import './../Aufgabenliste.scss';
import EventBus from '../../../components/Mitgliederliste-Mobil/EventBus';
import { Badge } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { ScrollView } from 'devextreme-react';
import { TagBox } from 'devextreme-react/tag-box';


const FilterPopup = (props) => {

    const {isPopupVisible, setPopupVisibility, t, allRoles, dataArray, filteredData, setFilteredData, aktuelleFilterBedingung, filterObject, } = props;
    const bestehendeFavoritenListen = props.filter;

    const [filter, setFilter] = useState([]);
    const {user} = useAuth();
    const [filterNamePopupVisible, setFilterNamePopupVisible] = useState(false); // Popup für Filternamen
    const [filterName, setFilterName] = useState('');
    const n = i18n.getFixedT(null, 'notify');
    const [aufgabenMitglieder, setAufgabenMitglieder] = useState([]); // Liste der vorhandenen Favoritenlisten des Benutzers ungeparst
    const [vorhandeneFavoritenlisten, setVorhandeneFavoritenlisten] = useState([]); // Liste der vorhandenen Favoritenlisten des Benutzers Geparst
    const location = useLocation();
    const [selectedItems, setSelectedItems] = useState([]);

    const dropDowmData = [
        {
          label: t('subject'),
        },
        {
          label: t('start'),
        },
        {
          label: t('end'),
        },
        {
          label: t('status'),
        },
        {
          label: t('followUp'),
        },
        {
          label: t('company'),
        },
        {
          label: t('department'),
        },
        ...allRoles.map(role => ({ label: role })),
      ];
    
    useEffect(() => {
        if (bestehendeFavoritenListen) {
            console.log("Bestehende Favoritenlisten: ", bestehendeFavoritenListen);
            setVorhandeneFavoritenlisten(bestehendeFavoritenListen);
        }
    }, [bestehendeFavoritenListen]);

    useEffect(() => {
        if (filterObject) {
            const resultArray = [];
            const keys = Object.keys(filterObject);
            console.log("Keys: ", keys);

            keys.forEach((key, keyIndex) => {

                const modifiedKey = key === "status_Procent" ? t('status') : key === "follow_up" ? t('followUp') : key;

                filterObject[key].forEach((item, index) => {
                    let value;
                    if (modifiedKey !== "subject") {
                        value = item;
                    } else if (typeof item === 'string') {
                        value = item.split(":").slice(1).join(":").trim();
                    } else {
                        console.error(`Erwartet einen String, aber erhalten: ${typeof item}`);
                        return;
                    }
                    resultArray.push([t(modifiedKey), "=", value]);

                    // Fügt "and" zwischen allen Bedingungen hinzu, außer nach der letzten Bedingung jedes Schlüssels
                    if (index < filterObject[key].length - 1 || keyIndex < keys.length - 1) {
                        resultArray.push("and");
                    }
                });
            });

            setFilter(resultArray);
        }
    }, [filterObject]);

    const togglePopup = () => {
        setPopupVisibility(!isPopupVisible);
    };

    function randomId() {
        return Math.random().toString(36).substr(2, 9);
    }

    useEffect(() => {
        if (location.pathname === "/Aufgabenliste") {
            setFilter([]);
            return;
        }
    
        const formatDatum = (datumString) => {
            const date = new Date(datumString);
            return date;
        };
    
        const formatFilterBedingung = (bedingung) => {
            const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
        
            const formatItem = (item) => {
                if (Array.isArray(item)) {
                    return item.map(subItem => formatItem(subItem));
                } else if (typeof item === 'string' && dateRegex.test(item)) {
                    return formatDatum(item);
                }
                return item;
            };
        
            return bedingung.map(item => formatItem(item));
        };
    
        if (aktuelleFilterBedingung) {
            const formattedFilter = formatFilterBedingung(aktuelleFilterBedingung);
            setFilter(formattedFilter);
        }
    }, [aktuelleFilterBedingung, location]);
    

    useEffect(() => {
        if (filter) {
            console.log("Filter wurde gesetzt: ", filter);
        }
    }, [filter]);

    useEffect(() => {
        Api.OptionClient.getOptions().then((response) => {
            const AufgabenFavoritenSuche = response.data.filter(option => option.name.startsWith("Aufgabenfav_"));
            const AufgabenFavoriten = AufgabenFavoritenSuche.map(fav => fav.name.split('_')[1]);

            console.log('AufgabenFavoriten', AufgabenFavoriten);
        });
    }, []);

   useEffect(() => {
        const fullnames = dataArray.map(item => 
            item.people.map(person => `${person.member.first_Name} ${person.member.last_Name}`)
        ).flat();
        const uniqueFullnames = new Set(fullnames);
        const objectsWithName = Array.from(uniqueFullnames).map(name => ({ name }));
        console.log("Unique Fullnames: ", objectsWithName);
        setAufgabenMitglieder(objectsWithName);
    }, [dataArray]);

    useEffect(() => {
        console.log("Aktuelle Daten: ", filteredData);
    }, [filteredData]);

    const applyFilter = () => {
        if (!filter || filter.length === 0) {
            setFilteredData(dataArray);
            console.log("Kein Filter gesetzt");
            return;
        }
    
        console.log("Aktueller Filter: ", filter);
    
        const fieldMap = {
            [t('subject')]: 'subject',
            [t('start')]: 'start',
            [t('end')]: 'end',
            [t('status')]: 'status_Procent',
            [t('followUp')]: 'follow_up',
            [t('company')]: 'company',
            [t('department')]: 'department',
            [t('created')]: 'created'
        };
    
        const isDate = (value) => value instanceof Date;
        const isString = (value) => typeof value === 'string';
        const isNumber = (value) => typeof value === 'number';
    
        // Funktion zum Entfernen der Zeitkomponente
        const stripTime = (date) => {
            const newDate = new Date(date);
            newDate.setHours(0, 0, 0, 0);
            return newDate;
        };
    
        const evaluateCondition = (task, condition) => {
            const [field, operation, value] = condition;
            const mappedField = fieldMap[field] || field;
            const taskValue = task[mappedField];
            
            let compareValue = value;
            let taskCompareValue = taskValue;
            
            if (field === 'Ersteller (R)' || field === 'Bearbeiter (C)' || field === 'Gesamtverantwortlicher (A)' || field === 'Beobachter (I)') {
                return evaluateRoleFilter(task, field, operation, value);
            }
            
            if (isDate(value)) {
                compareValue = stripTime(value).getTime();
                taskCompareValue = stripTime(new Date(taskValue)).getTime();
            } else if (isString(value)) {
                compareValue = value.toLowerCase();
                taskCompareValue = taskValue.toLowerCase();
            } else if (isNumber(value)) {
                compareValue = Number(value);
                taskCompareValue = Number(taskValue);
            }
            
            switch (operation) {
                case '=':
                    return taskCompareValue === compareValue;
                case '!=':
                case '<>':
                    return taskCompareValue !== compareValue;
                case '<':
                    return taskCompareValue < compareValue;
                case '<=':
                    return taskCompareValue <= compareValue;
                case '>':
                    return taskCompareValue > compareValue;
                case '>=':
                    return taskCompareValue >= compareValue;
                case 'contains':
                    return isString(taskCompareValue) && taskCompareValue.includes(compareValue);
                case 'notcontains':
                    return isString(taskCompareValue) && !taskCompareValue.includes(compareValue);
                case 'startswith':
                    return isString(taskCompareValue) && taskCompareValue.startsWith(compareValue);
                case 'endswith':
                    return isString(taskCompareValue) && taskCompareValue.endsWith(compareValue);
                default:
                    return false;
            }
        };        
    
        const evaluateRoleFilter = (task, field, operation, value) => {
            return task.people.some(person => {
                if (field === 'Ersteller (R)') {
                    return evaluateRoleCondition(person, 'Ersteller (R)', operation, value);
                } else if (field === 'Bearbeiter (C)') {
                    return evaluateRoleCondition(person, 'Bearbeiter (C)', operation, value);
                } else if (field === 'Gesamtverantwortlicher (A)') {
                    return evaluateRoleCondition(person, 'Gesamtverantwortlicher (A)', operation, value);
                } else if (field === 'Beobachter (I)') {
                    return evaluateRoleCondition(person, 'Beobachter (I)', operation, value);
                }
                return false;
            });
        };
    
        const evaluateRoleCondition = (person, roleType, operation, value) => {
            const roleName = person.rolle.name;
            const fullName = `${person.member.first_Name} ${person.member.last_Name}`;
            switch (operation) {
                case '=':
                    return roleName === roleType && fullName === value;
                case '!=':
                case '<>':
                    return roleName === roleType && fullName !== value;
                case 'contains':
                    return roleName === roleType && fullName.includes(value);
                case 'notcontains':
                    return roleName === roleType && !fullName.includes(value);
                case 'startswith':
                    return roleName === roleType && fullName.startsWith(value);
                case 'endswith':
                    return roleName === roleType && fullName.endsWith(value);
                default:
                    return false;
            }
        };
    
        const evaluateFilter = (task, filter) => {
            if (Array.isArray(filter[0])) {
                let result = evaluateCondition(task, filter[0]); // Initiale Bedingung evaluieren
                for (let i = 1; i < filter.length; i += 2) {
                    const logicalOperator = filter[i];
                    const nextCondition = filter[i + 1];
                    if (logicalOperator.toLowerCase() === 'or') {
                        result = result || evaluateCondition(task, nextCondition);
                    } else if (logicalOperator.toLowerCase() === 'and') {
                        result = result && evaluateCondition(task, nextCondition);
                    }
                }
                return result;
            } else {
                return evaluateCondition(task, filter);
            }
        };
    
        const filteredTasks = dataArray.filter(task => evaluateFilter(task, filter));
    
        setFilteredData(filteredTasks);
        console.log("Gefilterte Daten: ", filteredTasks);
    };

    const saveFilter = () => {

        if (!filter) {
            return;
        } else {
            let listname = `Aufgabenfav_${user.id}`;
            let obj = null;
            let existingOption = null;
            let newOption = null;
            console.log("Filter: ", filter);
            console.log("Filtername: ", filterName);

            obj = {
                name: listname,
                option: {
                    id: randomId(),
                    filterName: filterName,
                    filterBedingung: filter,
                    spaltenwahl: selectedItems
                },
                personID: user.id
            };


            Api.OptionClient.getOptions().then((response) => {
                const data = response.data;
                existingOption = data.find(option => option.name === listname);
                
                let oldOption = JSON.parse(existingOption.option);
                console.log("Alte Optionen: ", oldOption);
                let parsedOptions = oldOption.map(item => {
                    if (typeof item === 'string') {
                        try {
                            return JSON.parse(item);
                        } catch (error) {
                            console.error("JSON Parse Error: ", error);
                            return item; // Rückgabe des ursprünglichen Elements, wenn das Parsen fehlschlägt
                        }
                    }
                    return item; // Rückgabe des ursprünglichen Elements, wenn es kein String ist
                });
                console.log("Geparste Optionen: ", parsedOptions);
                let newFilter = obj.option;
                console.log("Neuer Filter: ", newFilter);
            
                // Füge die neue Option zum Array hinzu
                let combinedOption = [...parsedOptions, newFilter];
                console.log("Kombinierte Filter: ", combinedOption);
            
                newOption = {
                    name: listname,
                    option: JSON.stringify(combinedOption),
                    personID: user.id
                };
            
                console.log("Neue Option: ", newOption);

                Api.OptionClient.updateOption(existingOption.name, newOption).then((response) => {
                    console.log("Filter wurde aktualisiert: ", response);
                    if (response.status === 201) {
                        notify({message: n('filterSuccess')}, "success", 3000);
                        setFilterName('');
                        setFilterNamePopupVisible(false);
                        EventBus.emit("filterCreated");
                    } else {
                        notify({message: n('filterFailed')}, "error", 3000);
                    }
                });
            });

        }
    };

    async function handleItemClick(e) {
        try {
            const response = await Api.OptionClient.getOptions();
            const existingOption = response.data.find(option => option.name === `Aufgabenfav_${user.id}`);

    
            let deeplyParsedArray = JSON.parse(existingOption.option).map(item => {
                try {
                    return JSON.parse(item);
                } catch (error) {
                    return item;
                }
            });
    
            const updateFilter = deeplyParsedArray.find(item => item.filterName === e);
    
            if (updateFilter.filterBedingung.operation !== 'and' && updateFilter.filterBedingung.operation !== 'or') {
                
                updateFilter.filterBedingung = [
                    updateFilter.filterBedingung,
                    'or',
                    filter
                ];

                console.log("Array", deeplyParsedArray);
                const stringifiedAll = JSON.stringify(deeplyParsedArray);
    
                const newOption = {
                    name: `Aufgabenfav_${user.id}`,
                    option: stringifiedAll,
                    personID: user.id
                };
    
                console.log("Neue Option: ", newOption);

                try {
                    Api.OptionClient.updateOption(existingOption.name, newOption).then((response) => {
                        console.log("Filter wurde aktualisiert: ", response);
                        if (response.status >= 200 && response.status < 300) {
                            notify({message: n('filterSuccess')}, "success", 3000);
                            EventBus.emit("filterCreated");
                        } else {
                            notify({message: n('filterFailed')}, "error", 3000);
                        }
                    });
                } catch (error) {
                    console.error("Error: ", error);
                }
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    const renderTitle = () => {
        return (
            <div className='filterBuilderTitle' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '76px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h5>Filter Popup</h5>
                    <Badge color="green" style={{ marginLeft: '10px' }}>V. 1.0</Badge>
                </div>
                <Button
                    icon="close"
                    stylingMode="text"
                    onClick={togglePopup}
                />
            </div>
        );
    };

    return (
        <>
            <Popup
                visible={isPopupVisible}
                hideOnOutsideClick={true}
                onHiding={togglePopup}
                defaultWidth={window.innerWidth < 767 ? "95%" : 750}
                defaultHeight={500} 
                resizeEnabled={true}
                dragEnabled={true}
                position="top"
                showTitle={true}
                titleRender={renderTitle}
                showCloseButton={true}
            >
                <FilterBuilder 
                    value={filter} 
                    onOptionChanged={(e) => setFilter(e.value)} 
                    width={window.innerWidth < 767 ? "50%" : "auto"}
                    groupOperations={["and", "or"]}
                >
                    <Field dataField={t('subject')} />
                    <Field dataField={t('start')} dataType="date" />
                    <Field dataField={t('end')} dataType="date" />
                    <Field dataField={t('status')} dataType="number"/>
                    <Field dataField={t('followUp')} dataType="date" />
                    <Field dataField={t('company')} />
                    <Field dataField={t('department')} />
                    <Field dataField={t('created')} dataType="date" />
                    {allRoles.map((role) => (
                        <Field
                            key={role}
                            dataField={role}
                            dataType="string"
                            lookup={{
                                dataSource: aufgabenMitglieder,
                                valueExpr: "name",
                                displayExpr: "name"
                            }}
                        />
                    ))}
                </FilterBuilder>

                <div className='filterBuilderButtons'>
                    {vorhandeneFavoritenlisten && (
                        <>
                            <DropDownButton
                                text={t('saveFilterToAnExistingList')}
                                items={vorhandeneFavoritenlisten.map(list => ({ text: list.filterName }))}
                                displayExpr="text"
                                dropDownOptions={{width: 300}}
                                stylingMode='contained'
                                style={{marginRight: '10px'}}
                                onItemClick={(e) => handleItemClick(e.itemData.text)}
                            />
                            
                            <Button
                                text={t('saveFilterAsCustomList')}
                                onClick={() => setFilterNamePopupVisible(true)}
                                style={{marginRight: '10px'}}
                            />
                            <Button
                                className='myButton'
                                text={t('filtering')}
                                onClick={applyFilter}
                            />
                        </>
                    )}
                </div>
            
            </Popup>

            <Popup
                visible={filterNamePopupVisible}
                hideOnOutsideClick={false}
                onHiding={() => setFilterNamePopupVisible(false)}
                defaultWidth={window.innerWidth < 767 ? "95%" : 400}
                defaultHeight={300} 
                resizeEnabled={true}
                dragEnabled={true}
                position="center"
                showTitle={true}
                title='Filter Name'
                showCloseButton={true}
            >
                <div className='popupContent'>
                    <TextBox
                        value={filterName}
                        onValueChanged={(e) => setFilterName(e.value)}
                        placeholder={t('filterNamePlaceholder')}
                        maxLength={40}
                        stylingMode='filled'
                    />
                    <TagBox
                        dataSource={dropDowmData}
                        valueExpr='label'
                        displayExpr='label'
                        placeholder='Spaltenauswahl'
                        value={selectedItems}
                        onValueChanged={e => {
                            console.log(e.value);
                            setSelectedItems(e.value); // Speichern der ausgewählten Elemente
                        }}
                        showSelectionControls={true}
                        stylingMode='filled'
                        width={250}
                        maxDisplayedTags={1}
                        buttons={[
                            {
                                name: 'spaltenwahl', 
                                location: 'after', 
                                options: { 
                                    icon: 'fields' 
                                } 
                            }
                        ]}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                    <Button
                        className='myButton'
                        text={t('saveFilter')}
                        onClick={() => { saveFilter(); setFilterNamePopupVisible(false); }}
                    />
                </div>
            </Popup>
        </>
    );
}
export default FilterPopup;