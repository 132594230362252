import React, {useState, useEffect} from "react";
import ResponsiveBox, {
    Row,
    Col,
    Item,
    Location,
} from 'devextreme-react/responsive-box';
import {
    Column,
    ColumnChooser,
    DataGrid,
    Editing,
    Selection
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import i18n from "i18next";
import Api from "../../utils/api";

//const [path, setPath] = useState();
const t = i18n.getFixedT(null, 'mails');

function onSelectionChanged(e) {
    const data = e.selectedRowsData[0];
  //  setSelectedRow(data)

};

function screen() {
  //  return screenSizeClass === 'screen-large' ? "lg" : "sm"
}

function getColSpanMaiLList() {
   // return selectedRow && selectedRow.subject ? 1 : 2
}



function renderMaiLList() {
    return <DataGrid

        key="id"
        id="id"
     //   dataSource={customDataSource}
        showBorders={true}
        hoverStateEnabled={true}
        // onSelectionChanged=
        //     {
        //         onSelectionChanged
        //     }
        // onRowPrepared={(rowElement, e) => {
        //     if (rowElement.data) {
        //         const isUnread = rowElement.data.unRead
        //         if (isUnread) {
        //             rowElement.element.className += " unread"
        //         }
        //     }
        // }
     // }


    >
        <Editing
            mode="row"
            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}
        />
        <Selection mode="single"/>
        <ColumnChooser enabled={false}/>
        <Column dataField="id" caption="ID" allowEditing={false} visible={false}> </Column>
        <Column dataField="from_Name" caption={t('from')}> </Column>
        <Column dataField="subject" caption={t('subject')}> </Column>
        <Column dataField="date" caption={t('receiving_date')} dataType="date"> </Column>


    </DataGrid>
}



export default function () 
{
    return(
    <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
    <Row ratio={1}/>

    <Col ratio={1}/>
    <Col ratio={2}/>

    <Item>
        <Location row={0} col={0} colspan={getColSpanMaiLList()} screen="lg"/>
        <Location row={0} col={0} colspan={getColSpanMaiLList()} screen="sm"/>

        <div className="layoutItem">
            {

              //  renderMaiLList()
            }
        </div>
    </Item>
    
    </ResponsiveBox>);

}