import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Api from '../../../utils/api';
import { useAuth } from '../../../contexts/auth';
import { Input, Divider, Modal, Button as MantineButton } from '@mantine/core';
import { Button } from 'devextreme-react';
import { useDisclosure } from '@mantine/hooks';
import { notifySuccess } from "../../../utils/notifyManager";

const customStyles = {
  overlay: {
    zIndex: 9999, // Set a high value for zIndex
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // You can adjust the background color and opacity of the overlay
  },
  content: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0',
    padding: '0',
    position: 'absolute',
  },
};


const NewContactButton = ({t, setDataArray, setContactlistChanged}) => {

  const {user} = useAuth()
  const [formData, setFormData] = useState({
    first_Name: '',
    last_Name: '',
    positionname: '',
    eMail: '',
  });
  const [opened, { open, close }] = useDisclosure(false);


  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  async function handleSave() {
    try {
      const payload = {
        Person_other: {
          first_Name: formData.first_Name,
          last_Name: formData.last_Name,
          positionname: formData.positionname,
          eMail: formData.eMail,
        },
        relationshipID:  2,
        person_MeID: user.id,
      };
  
      // Warte auf Abschluss des API-Aufrufs
      await Api.people.insertToNetwork(payload);
      close();
      setFormData({first_Name: '', last_Name: '', positionname: '', eMail: ''});
      notifySuccess(t('insertedLocalContact'));

      // Lade die Daten neu
      const response = await Api.people.getMyNetwork();
      setDataArray(response.data);
      setContactlistChanged(prevState => !prevState);
  
      // Schließe das Modal nach dem Neuladen der Daten
    } catch (error) {
      window.confirm('Fehler beim Speichern der Daten: ' + error);
    } 
  }
  
  

  return (
    <>
        <Button
            icon="plus"
            text={t('btnAddContact')}
            className='myButton'
            onClick={open}
        />

        <div>
        <Modal opened={opened} onClose={close} withCloseButton={false} closeOnClickOutside={false} centered size="lg">

        <div className="modal-head">
            {t('titleContactAdd')}
        </div>


    <form className='form'>

    <label className="label">{t('firstName')}</label>
    <Input
        name='first_Name'
        placeholder={t('firstName')}
        value={formData.first_Name}
        onChange={handleInputChange}
        styles={(theme) => ({
        input: {
            '&:focus': {
            borderColor: '#39b201',
            },
        },
        })}
    />

    <label className="label">{t('lastName')}</label>
    <Input
        name='last_Name'
        placeholder={t('lastName')}
        value={formData.last_Name}
        onChange={handleInputChange}
        styles={(theme) => ({
        input: {
            '&:focus': {
            borderColor: '#39b201',
            },
        },
        })}
    />

    <label className="label">{t('eMail')}</label>
    <Input
        name='eMail'
        placeholder={t('eMail')}
        value={formData.eMail}
        onChange={handleInputChange}
        styles={(theme) => ({
        input: {
            '&:focus': {
            borderColor: '#39b201',
            },
        },
        })}
    />

    <label className="label">{t('positionName')}</label>
    <Input
        name='positionname'
        placeholder={t('positionName')}
        value={formData.positionname}
        onChange={handleInputChange}
        styles={(theme) => ({
        input: {
            '&:focus': {
            borderColor: '#39b201',
            },
        },
        })}
    />
    
    </form>


            <div className="buttons">
            <MantineButton id='cancelButton' onClick={() => { close(); 
                setFormData({first_Name: '', last_Name: '', positionname: '', eMail: ''}); }} color='red'>{t('cancel')}
            </MantineButton>
            <MantineButton onClick={handleSave}
            styles={(theme) => ({
            root: {
                backgroundColor: '#39b201',
                color: 'white',
                '&:hover': {
                backgroundColor: '#39b201',
                color: 'white',
                },
            }})}
            >
            {t('btnAdd')}
            </MantineButton>
            </div>

        </Modal>
        </div>
    </>
  );
}

export default NewContactButton;