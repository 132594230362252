import React, {useCallback, useEffect} from 'react';

import {
    Column,
    Popup,
    Form,
    DataGrid,
    Editing,
    Lookup,

} from 'devextreme-react/data-grid';

import {TextBox, Button, SelectBox, LoadIndicator} from 'devextreme-react';
import {Item, Label, SimpleItem, GroupItem} from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import i18n from "i18next";
import Api from "../../utils/api"
import {
    RequiredRule, Validator
} from 'devextreme-react/validator';
import './Einstellungen_Mail.scss';
// import {updateDatasource} from "../../components/side-navigation-menu/side-navigation-menu";
import MailViewEinstellung from '../../components/MailViewMode-Komponente/MailViewEinstellung';


function handleErrors(response) {
    if (response.status !== 200) {
        console.log('Fehler' + response.statusText);
        throw Error(response.statusText);
    }
    return response;
}


function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
};

//const history = useHistory();


export default function () {
    let connType = undefined;
    const t = i18n.getFixedT(null, 'mailSettings');
    // const {selectedConnectionType, setSelectedConnectionType} = React.useState()
    const [datagrid, setDatagrid] = React.useState();
    const [viewMode, setViewMode] = React.useState();
    const customDataSource = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            return Api.mailOptions.getMailOptions()
                .then(data => {
                    data.data.forEach(d => {
                    // if(d.smtpUsername){
                    //     d.server= d.smtpServer ;
                    //     d.port = d.smtpPort;
                    //     d.password = d.smtpPassword ;
                    //     d.username = d.smtpUsername;
                    //     d.connType = "SMTP";
                    //
                    // }
                    if(d.popUsername){
                        d.serverIN= d.popServer ;
                        d.portIN = d.popPort;
                        d.password = d.popPassword ;
                        d.usernameIN = d.popUsername;
                        d.connType = "POP3";

                    }
                    else if(d.imapUsername){
                        d.serverIN= d.imapServer ;
                        d.portIN = d.imapPort;
                        d.password = d.imapPassword ;
                        d.usernameIN = d.imapUsername;
                        d.connType = "IMAP";
                    }

                    d.email = d.mailadresse;

                    });
                    console.log("loadedSuccessfull")
                    return data.data
                })
                .catch(e => {
                    console.log("in error by loadMailOptions")
                    return []
                })
        },
        remove: (key) => {
            Api.mailOptions.deleteMailOptions(key.id)
                .then(handleErrors)
                .then(() => {
                    console.log("removedSuccessfull")
                    refreshDatagrid()
                })
                .catch(() => {
                    console.log("in error by removeMailOptions")
                    throw 'Network error'
                });

        },

        update: (key, value) => {

            Api.mailOptions.updateMailOptions(key.id, converData(value))
                .then(handleErrors)
                .then( () => {
                    console.log("UpdateSuccessfull")
                    refreshDatagrid()
                })
                .catch(() => {
                    throw 'Network error'
                });
        },

        insert: (data) => {

            Api.mailOptions.createMailOptions(converData(data))
                .then(handleErrors)
                .then( () => {
                    console.log("insertedSuccessfull")
                    refreshDatagrid()
                })
                .catch(() => {
                    console.log("in error by createMailOptions")
                    refreshDatagrid()
                    throw 'Network error'
                });
        }
    });

   function refreshDatagrid(){
       datagrid.instance.refresh()
       // updateDatasource()
   }

    function converData(data){
        data.mailadresse = data.email
        switch(data.connType){
            case "POP3": {
                data.popServer = data.serverIN;
                data.popPort = data.portIN;
                data.popPassword = data.password;
                data.popUsername = data.usernameIN;
            }
            case "IMAP": {
                data.imapServer = data.serverIN;
                data.imapPort = data.portIN;
                data.imapPassword = data.password;
                data.imapUsername = data.usernameIN;
            }
        }

        data.smtpPassword = data.password;

        delete data.serverIN;
        delete data.portIN;
        delete data.password;
        delete data.usernameIN;
        delete data.email;

        return data
    }
    const passwordEditorOptions = {mode: 'password'};
    //
    // function connectionTypeChanged(event) {
    //
    //     connType = event.selectedItem.name
    //
    // }

    // function renderIncomingServer(param) {
    //     const data = [
    //
    //         {
    //             "id": 2,
    //             "name": "POP"
    //         }, {
    //             "id": 3,
    //             "name": "IMAP"
    //         }
    //     ]
    //                 return (
    //             <SelectBox
    //                 dataSource={data}
    //                 valueExpr="id"
    //                 displayExpr="name"
    //
    //                 isValid={connType !== undefined}
    //                  onSelectionChanged={connectionTypeChanged}
    //             >
    //                 <Validator>
    //                     <RequiredRule message="Country is required" />
    //                 </Validator>
    //             </SelectBox>
    //         )
    //
    //
    // }
    //
    //
    const dataConnTypes = [
        {
            "id": 1,
            "name": "POP3"
        }, {
            "id": 2,
            "name": "IMAP"
        }
    ]

    return (

        <React.Fragment>

            <h2 className={'content-block'}>{t('title')}</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    <DataGrid
                        columnHidingEnabled={true}
                        key="id"
                        id="id"
                        dataSource={customDataSource}
                        showBorders={true}
                        activeStateEnabled={true}
                        onRowUpdating={onRowUpdating}
                        selection={{mode: 'single'}}
                        hoverStateEnabled={true}
                        ref = {setDatagrid}
                        columnAutoWidth={true}
                        // onSelectionChanged={onSelectionChanged}
                    >
                        <Editing
                            mode="popup"
                            allowUpdating={true}
                            allowAdding={true}
                            allowDeleting={true}>
                            <Popup title={t('editMailSettingsTitle')} showTitle={true} maxWidth={700}/>
                            <Form colCount={2}>
                                <Item dataField="email" colSpan={2}>
                                    <RequiredRule message={i18n.t("common:requiredInput")} />
                                    <Label text={t('email')}></Label>
                                </Item>
                                <Item dataField="password" editorOptions={passwordEditorOptions} colSpan={2}>
                                    <RequiredRule message={i18n.t("common:requiredInput")} />
                                    <Label text={t('password')}></Label>
                                </Item>
                                <GroupItem caption={t('incoming')} colSpan={1} colCount={1} cssClass="groupItemLeft">

                                    {/*<SimpleItem render={renderIncomingServer} dataField="connType">*/}
                                    {/*    <Label text={t('connectionType')}/>*/}
                                    {/*</SimpleItem>*/}
                                    <Item dataField="connType">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('connectionType')}></Label>
                                    </Item>
                                    <Item  dataField="usernameIN">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('username')}></Label>
                                    </Item>
                                    <Item dataField="serverIN">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('server')}></Label>
                                    </Item>
                                    <Item dataField="portIN">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('port')}></Label>
                                    </Item>
                                </GroupItem>
                                <GroupItem caption={t('outgoing')} colSpan={1} colCount={1} >


                                    <SimpleItem render={() => (<TextBox value={"SMTP"} disabled={true}/>)}>
                                        <Label text={t('connectionType')}/>
                                    </SimpleItem>

                                    <Item  dataField="smtpUsername">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('username')}></Label>
                                    </Item>
                                    <Item dataField="smtpServer">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('server')}></Label>
                                    </Item>
                                    <Item dataField="smtpPort">
                                        <RequiredRule message={i18n.t("common:requiredInput")} />
                                        <Label text={t('port')}></Label>
                                    </Item>
                                </GroupItem>
                                {/*<GroupItem colCount={2}>*/}

                                {/*<SimpleItem><SelectBox*/}
                                {/*    dataSource={data}*/}
                                {/*    valueExpr="id"*/}
                                {/*    displayExpr="name"*/}

                                {/*    // value={getSelectedValue()}*/}
                                {/*    // value="name"*/}
                                {/*    // value={employee.language.name}*/}
                                {/*    onSelectionChanged={connectionTypeChanged}*/}
                                {/*>*/}
                                {/*    <Validator>*/}
                                {/*        <RequiredRule message="Country is required" />*/}
                                {/*    </Validator>*/}
                                {/*</SelectBox>*/}
                                {/*</SimpleItem>*/}







                                {/*</GroupItem>*/}
                                {/*/!*<Item itemType="group" caption="Home Address" colCount={2} colSpan={2}>*!/*/}
                                {/*/!*    <Item dataField="StateID" />*!/*/}
                                {/*/!*    <Item dataField="Address" />*!/*/}
                                {/*/!*</Item>*!/*/}
                            </Form>
                        </Editing>
                        {/*<Editing*/}
                        {/*    mode="row"*/}
                        {/*    allowAdding={false}*/}
                        {/*    allowDeleting={true}*/}
                        {/*    allowUpdating={false}*/}
                        {/*/>*/}
                        <Column dataField="id" caption="ID" width={50}> </Column>
                        {/*<Column dataField="connType" caption={t('connectionType')}> </Column>*/}
                        <Column dataField="connType" caption={t('connectionType')} width={125}>
                            <Lookup dataSource={dataConnTypes} valueExpr="name" displayExpr="name"/>
                        </Column>
                        <Column dataField="usernameIN" caption={t('usernameIN')}> </Column>
                        <Column dataField="email" caption={t('email')}> </Column>
                        <Column dataField="password" visible={false} caption={t('password')} editorOptions={passwordEditorOptions}> </Column>
                        <Column dataField="serverIN" caption={t('serverIN')}> </Column>
                        <Column dataField="portIN" caption={t('portIN')}> </Column>
                        <Column dataField="smtpUsername" caption={t('usernameOUT')}> </Column>
                        <Column dataField="smtpServer" caption={t('serverOut')}> </Column>
                        <Column dataField="smtpPort" caption={t('portOUT')}> </Column>

                    </DataGrid>

                    <MailViewEinstellung setViewMode={setViewMode}/>    
                </div>
            </div>
        </React.Fragment>
    );


};
