import React, {useState, useCallback, useEffect, useMemo} from 'react';
import CustomStore from 'devextreme/data/custom_store';
import SelectBox, { My } from "devextreme-react/select-box";
import Form, {Item, SimpleItem, EmptyItem, Label} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import {Button} from 'devextreme-react';
import {useAuth} from '../../contexts/auth';
import Api from '../../utils/api'
import './projekt.scss';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
    RequiredRule,
} from 'devextreme-react/data-grid';
import i18n from "i18next";
import MitgliederMobile from '../../components/Mitgliederliste-Mobil/MitgliederMobile';
import AddMitglied from '../../components/Mitgliederliste-Mobil/AddMitglied';
import { useScreenSizeClass } from '../../utils/media-query';
import AddAufgabe from '../../components/Aufgabenliste-Mobil/AddAufgabeButton';
import SubProjectsLists from '../../components/UnterprojekteTabelle/subProjectsLists';
import AufgabenlisteToolbar from '../../components/Aufgabenliste-Mobil/AufgabenlisteToolbar';
import AddDokuments from '../../components/Documents/AddDokuments';
import DocumentsList from '../../components/Documents/DocumentsList';
import ProjectTaskPicture from '../../components/Documents/ProjectTaskPicture';
import ProjectEditor from '../../components/HTMLEditor/projektEditor';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import TreeList, {
    Selection
} from 'devextreme-react/tree-list';

const PROJECT = {};




export default function (props) {
    const p = i18n.getFixedT(null, 'project');
    const history = useHistory();
    const location = useLocation();
    const {user} = useAuth();
    const screenSizeClass = useScreenSizeClass();
    const [projectId, setProjectId] = useState(null);
    const [file, setFile] = useState(null);
    const [Documents, setDocuments] = useState(null);
    const [reload, setReload] = useState(false);

    let isloading = true;
    let isrendcom = false;
    let isrendproj = false;

    function get_Empty_GUID(){
        return "00000000-0000-0000-0000-000000000000";
    };


    function getProjectID(){
        const path = location.pathname;
        let parts = path.split('/');
        const id = parts.pop(); // Number(parts.pop() || parts.pop());
        if(id  == "Projekt" ){
            return null
        }
        else
        {
              return id //  isNaN(id) ? -1 : id
        }
    }

    function handleNotification(response, action) {
        if (response >= 200 && response < 300) {
            switch (action) {
                case "insert":
                    notify(p('CreateProjectSuccess'), 'success', 3000);
                    break;
                case "update":
                    notify(p('dataSaved'), 'success', 3000);
                    break;
                default:
                    notify(p('success'), 'success', 3000);
                    break;
            }
        } else {
         notify(p('errorSaving'), 'error', 3000);
        }
    }

    useEffect(() => {
        if (projectId) {
            console.log("Neues Projekt erstelltmit ID: ", projectId);
            history.replace(`/projekt/${projectId}`);
        }
    }, [projectId]);


    useEffect(() => {

        console.log('Laden Paramter ID aus der URL:') // "top"
        const projectID = getProjectID()

        if (projectID != null && projectID != get_Empty_GUID() ) {

            customDataSource.byKey(
                projectID
            )
                .then(
                    (dataObj) => {
                        // PROJECT = dataObj;

                        PROJECT.id = dataObj.id;
                        PROJECT.subject = dataObj.subject;
                        PROJECT.start = dataObj.start;
                        PROJECT.follow_up = dataObj.follow_up;
                        PROJECT.end = dataObj.end;
                        PROJECT.description = dataObj.description;
                        PROJECT.documents = dataObj.documents;
                        if (dataObj.company != null) {
                            console.log('Laden Firma in UseEFfect:' + dataObj.company.id) // "top"
                            PROJECT.company = dataObj.company;
                            setCompany(dataObj.company);
                            // setState({ editBoxValue: dataObj.company.id });
                        }
                        if (dataObj.parent_Project != null) {
                            console.log('Laden Projekt in UseEFfect:' + dataObj.parent_Project.id) // "top"
                            PROJECT.project = dataObj.parent_Project;
                            setPProject(dataObj.parent_Project);
                            // setState({ editBoxValue: dataObj.company.id });
                        }
                        if (dataObj.members != null) {
                            console.log('Laden Member in UseEFfect:' + dataObj.members) // "top"
                            PROJECT.members = dataObj.members;
                            setMitglieder(dataObj.members);
                        }
                        if (dataObj.tasks != null) {
                            console.log('Laden Aufgaben in UseEFfect:' + dataObj.tasks) // "top"
                            PROJECT.tasks = dataObj.tasks;
                            setAufgaben(dataObj.tasks);
                        }
                        if (dataObj.subProjects != null) {
                            console.log('Laden Unterprojekte in UseEFfect:' + dataObj.subProjects) // "top"
                            PROJECT.subProjects = dataObj.subProjects;
                            setSubProjects(dataObj.subProjects);
                        }
                        if (dataObj.documents != null) {
                            console.log('Laden Dokumente in UseEFfect:' + dataObj.documents) // "top"
                            setDocuments(dataObj.documents);
                        }

                        setProject(PROJECT);

                    },
                    (error) => {
                        notify(p('errorLoading') + error)
                    }
                );
        } else {
            PROJECT.id = get_Empty_GUID();
            PROJECT.subject = String.EmptyItem;
            PROJECT.start = new Date(Date.now() + 0 * 24 * 60 * 60 * 1000)
            PROJECT.follow_up = new Date(Date.now() + 6 * 24 * 60 * 60 * 1000)
            PROJECT.end = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
            PROJECT.description = null;
            PROJECT.company = null;
            PROJECT.documents = null;
            setMitglieder([]);
            setAufgaben([]);
            setCompany(null);
            setPProject(null);
            setProject(PROJECT);
            setDocuments([]);

            //setState({ editBoxValue: null });
        }

        //  isloading = false ;
    }, [reload, location]);


    const customDataSource = new CustomStore({
        key: ['id'],
        cacheRawData: false,
        reshapeOnPush: true,
        //beforeSend: (e) => {
        //    e.headers = {
        //        'Authorization': 'BEAR eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJ0ZXN0QHdlYi5kZSIsIm5iZiI6MTU5Mjg1MjkzNSwiZXhwIjoxNTkyOTM5MzM1LCJpYXQiOjE1OTI4NTI5MzV9.dVBrLQ7ovjQfpDaA6i6-gdrpIIv2Zm8bR1D_tpqgybtYvj08Hv5lciONhCXsw4g2yyn4lOM0EIiYPH8EODvcBQ'

        //    };
        //},
        loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
        load: () => {
            Api.projects.getProjects()
                .then(response => response.data);

        },
        remove: (key) => {
            Api.projects.deleteProject(key)
                .catch(() => {
                    throw 'Network error'
                });
        },
        byKey: (key) => {

            return Api.projects.getProjectByKey(key)
                .then(response => response.data);

        },
        insert: (values) => {
            Api.projects.createProject(values)
                .then(response => {
                    console.log("Response", response);
                    setProjectId(response.data.id);
                    handleNotification(response.status, "insert");
                })
                .catch(() => {
                    throw 'Network error'
                });
        },
        update: (key, values) => {
            Api.projects.updateProject(key, values)
                .then(response => {
                    console.log("Response", response);
                    handleNotification(response.status, "update");
                })
                .catch(() => {
                    throw 'Network error'
                });
        }


    });

    const customStoreProject = useMemo(() => {
        return new CustomStore({

            key: 'id',
            valueExpr: 'id',
            displayExpr: 'Name',
            cacheRawData: true,
            reshapeOnPush: true,
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler

            load: () => {
                return Api.projects.getProjects()
                .then(response => response.data);
            }
        });
    }, []);

    const customStoreComp = useMemo(() => {
        return new CustomStore({
            key: 'id',
            valueExpr: 'id',
            displayExpr: 'Name',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            load: () => {
                return Api.companies.getCompanies()
                    .then(response => {
                        return response.data
                    });
            }
        });
    }, []);


    var customStoreRollen = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'Name',
            load: () => {
                return Api.roles.getRoles()
                    .then(response => response.data);
            }
        });
    }, []);

    var customStoreMitglieder = useMemo(() => {
        return new CustomStore({
            key: 'id',
            loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
            valueExpr: 'id',
            displayExpr: 'first_Name',
            load: () => {
                return Api.people.getMyNetwork()
                    .then(response => response.data);
            }
        });
    }, []);

    function goBack() {
        //history.push('/Projektliste');
        history.goBack();
        //  this.props.history.goBack();
    }

    function renderPicture() {
        return (
            <ProjectTaskPicture />
        );
    }

    const [Project, setProject] = useState(null);
    //const [Company, setCompany] = useState([]);
    //const [Project, setProject] = useState([]);
    //const [Mitglieder, setMitglieder] = useState([]);
    const [Company, setCompany] = useState(null);
    const [PProject, setPProject] = useState(null);
    const [Mitglieder, setMitglieder] = useState([]);
    const [Aufgaben, setAufgaben] = useState([]);
    const [PopupVisible, setPopupVisible] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true); // Edit und Delete Button
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [doDeleteTaskID, setDoDeleteTaskID] = useState(null);
    const [subProjects, setSubProjects] = useState([]);
    const [me, setMe] = useState(null);
    const [Kontaktliste, setKontaktliste] = useState([]);
    const [loading, setLoading] = useState(true);
    const [MentionsKontakte, setMentionsKontakte] = useState([]);

    useEffect(() => {
        console.log("PProjekt: ", PProject);
    }, [PProject]);
 
    useEffect(() => {
        Api.getMe()
          .then(response => {
            setMe(response.data);
            const MyID = response.data.id;
            const memberExists = Mitglieder.some((Mitglied) => Mitglied.member.id === MyID);
            const RolleID = 4; // ID der Rolle "Beobachter" 
            console.log('Eigene ID:', MyID);
            console.log('Mitglieder:', Mitglieder);
  
            if (memberExists && Mitglieder.find((Mitglied) => Mitglied.member.id === MyID).rolle.id === RolleID) {
              console.log(`Mitglied mit der ID ${MyID} existiert und ist ein Beobachter.`);
              setButtonVisible(false); //Buttons werden ausgeblendet
              setIsReadOnly(true); //Formular wird schreibgeschützt
            }else if (memberExists) {
              console.log(`Mitglied mit der ID ${MyID} existiert aber ist kein Beobachter.`);
            }else {
              console.log(`Mitglied mit der ID ${MyID} existiert nicht.`);
            }
          })
          .catch(error => {
            console.error('Fehler beim Abrufen der ID:', error);
          });
    }, [Mitglieder]);

    useEffect(() => {
        Api.people.getMyNetwork()
            .then(response => {
                const updatedData = response.data.map(person => ({
                    ...person,
                    fullname: person.first_Name + " " + person.last_Name
                }));
                setKontaktliste(updatedData);
                const fullnames = updatedData.map(person => ({ fullname: person.first_Name + " " + person.last_Name}));
                setMentionsKontakte(fullnames);
                setLoading(false);
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Mitglieder:', error);
            });
    }, []);

    const showPopup = () => {
        setPopupVisible(true);
    };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    function editBoxValueChangedCompany(e) {

        if (e != null) {
            console.log('Changed Company') // "top"
            //   e.selectedItem.Company = { id: Company.id };
            console.log('Changed Company new value' + e.selectedItem);
            setCompany(e.selectedItem);
            console.log('Changed Company change complett') // "top"
        }
    };

    function editBoxValueChangedProject(e) {
        console.log("Zeige e", e)
        console.log("Zeige PProject", PProject)
        console.log(isrendproj);
        if (e != null) {
            console.log('Changed Project'); // "top"
            const selectedItem = e.selectedItem || (Array.isArray(e.selectedRowsData) && e.selectedRowsData.length > 0 ? e.selectedRowsData[0] : null);
            if (selectedItem) {
                console.log(selectedItem);
                setPProject(selectedItem);
            }
        };
    };

    function editBoxValueChangedMitglieder(e) {
        console.log(isrendproj);
        if (e != null) {
            console.log('Changed Project') // "top"
            console.log(e.selectedItem);
            setPProject(e.selectedItem);
            console.log('Changed Project change complett') // "top"
        }
    };

    function renderComp(data) {

        if (Company != null) {
            console.log('Render Company in Selectbox:' + Company.id) // "top"
            return (

                <SelectBox
                    dataSource={{store: customStoreComp}}
                    valueExpr="id"
                    placeholder={i18n.t('common:selectPlaceholder')}
                    displayExpr="name"
                    searchEnabled={true}
                    showClearButton={true}
                    defaultValue={Company.id}
                    onSelectionChanged={editBoxValueChangedCompany}

                >
                </SelectBox>
            );
        } else {
            console.log('Render Company ohne ID ') // "top"
            return (

                <SelectBox dataField={Company}
                           dataSource={{store: customStoreComp}}
                           valueExpr="id"
                           placeholder={i18n.t('common:selectPlaceholder')}
                           displayExpr="name"
                           searchEnabled={true}
                           showClearButton={true}
                           onSelectionChanged={editBoxValueChangedCompany}


                    //onSelectionChanged={editBoxValueChangedTYP}
                >
                </SelectBox>
            );
        }

    }


    function renderProjects(data) {
        return (
            <DropDownBox
                placeholder={i18n.t('common:selectPlaceholder')}
                displayExpr="subject"
                showClearButton={true}
                value={PProject && PProject.subject ? PProject.subject : null}
                onValueChanged={(e) => {
                    if (e.value === null) {
                        setPProject(null);
                    }
                }}
            >
                <TreeList
                    dataSource={{store: customStoreProject}}
                    columns={[
                        { dataField: 'subject', caption: 'Betreff' }
                    ]}
                    showBorders={true} 
                    onSelectionChanged={editBoxValueChangedProject}
                >
                    <Selection mode="single" />
                </TreeList>
            </DropDownBox>
        );
    }


    function renderDescription() {
        return (
            <ProjectEditor PROJECT={PROJECT} MentionsKontakte={MentionsKontakte}/>
        )
    }

    function downloadClick(e) {
        console.log('Download :') // "top"
        console.log(e.row.data.id) // "top"
        console.log(window.location.hostname)
        if (window.location.hostname.indexOf("host") > 0) {


            window.open("https://localhost:25443/api/documents?id=" + e.row.data.id, "_blank");
        } else {

            window.open("https://api.maprota.com/api/documents?id=" + e.row.data.id, "_blank");
        }
    }

    function deleteClick(e) {
        console.log('delete :') // "top"
        console.log(e.row.data.id) // "top"
        console.log(window.location.hostname)
        if (window.location.hostname.indexOf("host") > 0) {
            // documentDS.remove(e.row.data.id);
            //window.open("https://localhost:25443/api/documents?id=" + e.row.data.id, "_blank");
        } else {
            // documentDS.remove(e.row.data.id);
            //window.open("https://api.maprota.com/api/documents?id=" + e.row.data.id, "_blank");
        }
        console.log('DELETE DOCUMENTS in :') // "top"
        console.log(Object.values(Documents));
        const newList = Documents.filter((item) => item.id !== e.row.data.id);

        setDocuments(newList);
        console.log('DELETE DOCUMENTS after remove in :') // "top"
        console.log(Object.values(Documents));
    }

    function renderDataGridDokumente() {

        return (
            <div>
                <AddDokuments />
                <DocumentsList Documents={Documents} />
            </div>
        );
    }

    function renderDataGrid(data) {

        //  if (PROJECT.people != null) {

        //      console.log(Object.values(Mitglieder));
        console.log('Render Mitglieder in :') // "top"
        console.log(Object.values(Mitglieder));
        return (    
            <>
                <AddMitglied 
                    p={p}
                    setMitglieder={setMitglieder}
                    buttonVisible={buttonVisible}
                    me={me}
                />
                <MitgliederMobile 
                    p={p} 
                    Mitglieder={Mitglieder}
                    setMitglieder={setMitglieder}
                    buttonVisible={buttonVisible}      
                />
            </>
        );
    }


    function renderDataGridTask(data) {

        //  if (PROJECT.people != null) {


        console.log('Render Task in :') // "top"
        console.log(Object.values(Aufgaben));
        return(
            <>
                {buttonVisible && (
                <AddAufgabe
                Aufgaben={Aufgaben}
                setAufgaben={setAufgaben}
                buttonVisible={buttonVisible}
                />
                )}
                
                {/*
                 <AufgabenMobile
                    p={p} 
                    Aufgaben={Aufgaben}
                    buttonVisible={buttonVisible}
                    setDoDeleteTaskID={setDoDeleteTaskID}
                />
                */}
                <AufgabenlisteToolbar
                    p={p} 
                    Aufgaben={Aufgaben}
                    setAufgaben={setAufgaben}
                    buttonVisible={buttonVisible}
                    setDoDeleteTaskID={setDoDeleteTaskID}
                    subProjects={subProjects}
                />
            </>
        );
    }

    function subProjectClick(id) {
        history.push(`/projekt/${id}`)
        setReload(!reload);
    }

    function renderDataSubProjects(data) {
        console.log(Object.values(subProjects));
        return (
            <SubProjectsLists
                p={p} 
                subProjects={subProjects}
                subProjectClick={subProjectClick}
            /> 
        );
    }

    const {recoveryCode} = useParams();


    const onSelectionChanged = useCallback((e) => {

        const data = e.selectedRowsData[0];
        console.log('Zeile ausgewählt' + data.id);
        history.push({
            pathname: '/Aufgabe/'+data.id,
            //search: '/' + data.id,
            state: {aufgabenuebergabe: e.selectedRowsData[0]},
            aufgabenuebergabe: e.selectedRowsData[0]
        });
        //history.push('/aufgabe');
    }, [history, recoveryCode]);


    function onValueChanged(e) {

        console.log("Aenderung Form");


    };


    function onClick_Button(e) {
        console.log("Zeige Projekt: " + PROJECT.id);
        console.log("Zeige Projekt Subject: " + PROJECT.subject);
        console.log("Zeige Projekt Description: " + PROJECT.description);

        if (PROJECT != null) {
            console.log("klickebutton" + PROJECT.id);
            //  console.log(Object.values(PROJECT));
            if (PROJECT.id == null || PROJECT.id == get_Empty_GUID() ) {

                if (Company != null) {

                    console.log("klickebutton Company vorhanden" + Company.id);
                    PROJECT["company"] = [];
                    PROJECT.company = {id: Company.id};
                } else {
                    console.log("klickebutton Company ist leer");
                    PROJECT["company"] = [];
                    PROJECT.company = null;
                }
                if (PProject != null) {
                    console.log("klickebutton Project" + PProject.id);
                    PROJECT["parent_Project"] = [];
                    PROJECT.parent_Project = {id: PProject.id};
                }
                if (Mitglieder != null) {
                    console.log("klickebutton Mitglieder");
                    console.log(Object.values(Mitglieder));
                    PROJECT["members"] = [];
                    PROJECT["members"] = Mitglieder;
                    console.log("klickebutton Mitgliederfertig");
                }
                if (Aufgaben != null) {
                    console.log(Object.values(Mitglieder));
                    PROJECT["tasks"] = [];
                    PROJECT["tasks"] = Aufgaben;
                    console.log("klickebutton Aufgabenfertig");
                } else {
                    console.log("klickebutton Aufgaben ist leer");
                    PROJECT["tasks"] = [];
                    PROJECT.tasks = null;
                }


                PROJECT.id =  get_Empty_GUID();
                console.log("klickebuttoninsert" + PROJECT.subject);
                customDataSource.insert(PROJECT)
            } else {
                console.log("klickupdate" + PROJECT.id);

                if (Company != null) {
                    PROJECT["company"] = [];
                    //PROJECT.Company = { id: Company.id };
                    PROJECT.company = Company;
                } else {
                    PROJECT["company"] = [];
                    PROJECT.company = null;
                }
                if (Project != null) {
                    console.log("klickebutton Update Project" + Project.id);
                    PROJECT["parent_Project"] = [];
                    //    PROJECT.Projects = { id: Project.id };
                    PROJECT.parent_Project = PProject;
                }
                if (Mitglieder != null) {
                    console.log("klickebutton Mitglieder");
                    console.log(Object.values(Mitglieder));
                    PROJECT["members"] = [];
                    PROJECT["members"] = Mitglieder;
                    console.log("klickebutton Mitgliederfertig");
                }
                if (Aufgaben != null) {
                    console.log(Object.values(Mitglieder));
                    PROJECT["tasks"] = [];
                    PROJECT["tasks"] = Aufgaben;
                    console.log("klickebutton Aufgabenfertig");
                }
                if (doDeleteTaskID !== null) {
                    Api.tasks.deleteTask(doDeleteTaskID);
                    console.log('Aufgabe wurde vollständig entfernt.');
                }

                customDataSource.update(PROJECT.id,PROJECT)
            }
        }
        //else {
        //    notify('Bitte alle Felder ausf�llen')
        //}


    };

    return <React.Fragment>

        <h2 className={'content-block'}>{p('title')}</h2>
        <div className={'content-block'}>

         {/*   <Popup  id='popup' showTitle={true} visible={isPopupVisible} 
                    hideOnOutsideClick={true}
                    onHiding={togglePopup} title={p('titleContactAdd')} width={'80%'} height={'90%'} 
            >
                 <KontaktGrid />
            </Popup>    */}

            <Button
                type="normal"
                text={i18n.t('common:btnBack')}
                onClick={goBack}
            />
            <div className={'dx-card responsive-paddings'}>

                <div className="form-container">
                    <Form

                        colCount={12}
                        id="form"
                        formData={Project}
                        onFieldDataChanged={onValueChanged}
                        readOnly={isReadOnly}

                    >

                       {/* <Item dataField="id" colSpan={12} disabled={true}/> */}
                        <Item dataField="subject" colSpan={8} isRequired={true}>
                            <RequiredRule message={i18n.t('common:requiredInput')} />
                            <Label text={p('subject')}/>
                        </Item>
                        <SimpleItem
                            colSpan={4}
                            dataField="picture"
                            render={renderPicture}

                        />
                        <Item colSpan={3} dataField="start" editorType="dxDateBox" format={i18n.t('common:dateFormat')}
                              editorOptions={{
                                  dataType: "date",
                                  displayFormat: i18n.t('common:dateFormat'),
                                  format: i18n.t('common:dateFormat')
                              }} isRequired={true}>

                            <RequiredRule message={i18n.t('common:requiredInput')} />
                            <Label text={p('start')}/>
                        </Item>
                        <Item colSpan={3} dataField="follow_up" editorType="dxDateBox" text="Erinnerung" dataType="date"
                              format={i18n.t('common:dateFormat')} editorOptions={{
                            dataType: "date",
                            displayFormat: i18n.t('common:dateFormat'),
                            format: i18n.t('common:dateFormat')
                        }}>
                            <RequiredRule message={i18n.t('common:requiredInput')} />
                            <Label text={p('followUp')}/>
                        </Item>

                        <Item colSpan={3} dataField="end" editorType="dxDateBox" isRequired={true} dataType="date"
                              format={i18n.t('common:dateFormat')} editorOptions={{
                            dataType: "date",
                            displayFormat: i18n.t('common:dateFormat'),
                            format: i18n.t('common:dateFormat')
                        }}>
                            <RequiredRule message={i18n.t('common:requiredInput')} />
                            <Label text={p('end')}/>
                        </Item>


                        {/*<SimpleItem  dataField="Companies"*/}
                        {/*            render={renderComp}*/}
                        {/*>*/}
                        {/*    <Label text={p('companies')}/>*/}
                        {/*</SimpleItem>*/}

                        <SimpleItem colSpan={3} dataField="Projects"
                                    render={renderProjects}
                                    disabled={isReadOnly}
                        >
                            <Label text={p('projects')}/>
                        </SimpleItem>

                        <EmptyItem colSpan={12}/>

                        {!loading && (
                        <SimpleItem dataField="description" colSpan={12}
                                    render={renderDescription}
                                >
                            <RequiredRule message={i18n.t('common:requiredInput')} />
                            <Label text={p('descr')}/>
                        </SimpleItem>
                        )}

                        <SimpleItem colSpan={12} dataField="Dokumente" name="documents"
                                render={renderDataGridDokumente}>
                            <Label text={p('documents')}/>
                        </SimpleItem>

                        <SimpleItem colSpan={12} dataField="Mitglieder" name="order"
                                    editorOptions={{caption: "Mitglieder"}} caption="Mitglieder"
                                    render={renderDataGrid}>
                            <Label text={p('participants')}/>
                        </SimpleItem>
                        <SimpleItem colSpan={12} dataField="Aufgaben" name="order" editorOptions={{caption: "Aufgaben"}}
                                    caption="Aufgaben" render={renderDataGridTask}>
                            <Label text={p('tasks')}/>
                        </SimpleItem>
                        {
                            subProjects && subProjects.length >= 1 ?
                            <SimpleItem colSpan={12} dataField="subProjects" name="order"
                                                    editorOptions={{caption: "Unterprojekte"}} caption="Unterprojekte"
                                                    render={renderDataSubProjects}>
                                <Label text={p('Unterprojekte')}/>
                            </SimpleItem>
                            : null
                        }

                        </Form>
                        {PROJECT.id == get_Empty_GUID() ? (
                           <Button
                           colSpan={4}
                           icon="plus"
                           text={p('btnCreateProject')}
                           onClick={onClick_Button}
                           useSubmitBehavior={true}
                           className='myButton'
                           />
                           ) : (
                           buttonVisible && (
                           <Button
                           colSpan={4}
                           //icon="plus"
                           text={p('btnSaveProject')}
                           onClick={onClick_Button}
                           useSubmitBehavior={true}
                           className='myButton'
                           />
                           )
                        )}

                </div>


            </div>
        </div>


    </React.Fragment>
};