export const navigation = [
    //{

    //    path: '/search',
    //    icon: 'search'
    //},
    //{
    //text: 'DASHBOARD',
    //path: '/home',
    //icon: 'home'
    //},

    //insert in text field the title of i18n file.
    // {
    //     text: 'mail',
    //     path: '/Mailliste',
    //     icon: 'email'
    // },
    {
        text: 'Neuigkeiten',
        path: '/feed',
        icon: 'comment',
        hasItems: false
    },
    {
        text: 'projectList',
        path: '/projektliste',
        icon: 'contentlayout',
        hasItems: false
    },
    {
        text: 'taskList',
        path: '/Aufgabenliste',
        icon: 'file',
        hasItems: false
    },
    {
        text: 'contacts',
        path: '/kontakte',
        icon: 'group',
        hasItems: false
    }
    ,
    // {
    //     text: 'appointmentList',
    //     path: '/Terminliste',
    //     icon: 'event',
    //     hasItems: false
    // },

  {
    text: 'settings',
      icon: 'preferences',
      path:'settings',
      items: [
          // {
          //     text: 'tasks',
          //     path: '/',
          //     parentId: 'settings',
          //     hasItems: false
          // },
          // {
          //     text: 'dms',
          //     path: '/1',
          //     parentId: 'settings',
          //     hasItems: false
          // },
          // {
          //     text: 'contacts',
          //     path: '/2',
          //     parentId: 'settings',
          //     hasItems: false
          // },
          // {
          //     text: 'projects',
          //     path: '/3',
          //     parentId: 'settings',
          //     hasItems: false
          // },
          // {
          //     text: 'Unternehmen',
          //     path: '/EinstellungUnternehmen',
          //     parentId: 'settings',
          //     hasItems: false
          // },
          {
              text: 'profile',
              path: '/Benutzerverwaltung',
              parentId: 'settings',
              hasItems: false
          },
          {
              text: 'Mail',
              path: '/EinstellungenMail',
              parentId: 'settings',
              hasItems: false
          }

     ]
  }
];
