import '../../../components/bulma-tabel-css/bulma.min.css';
import '../../../components/bulma-tabel-css/main.min.css';
import Api from '../../../utils/api';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import Modal from 'react-modal';
import { LuClipboardList } from 'react-icons/lu';
import { ScrollView } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import FilterPopup from './../PCAnsicht/Filterpopup';
import { FaSort } from "react-icons/fa";

const formatDate = new Intl.DateTimeFormat('de-DE').format;

const ProjectlisteMobile = ({ t }) => {
  const [dataArray, setDataArray] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [sortType, setSortType] = useState(''); // State für Sortierung
  const [filteredData, setFilteredData] = useState([]); // Gefilterte Daten für die Anzeige
  const [searchText, setSearchText] = useState(''); // State für Suchtext

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null)

  const allRoles = dataArray.reduce((acc, project) => {
    project.members.forEach(member => {
      if (!acc.includes(member.rolle.name)) {
        acc.push(member.rolle.name);
      }
    });
    return acc;
  }, []);

  // Funktion zum Laden der Daten
  useEffect(() => {
    Api.projects.getProjects().then(response => {
    const data = response.data;

    if (data.length === 0) {
      setNoDataMessage(t('noData'));
    } else {
      setDataArray(data);
      setFilteredData(data);
    }
    }).catch(error => {
    console.error(error);
    });
    }, []);

  const DataRow = ({ obj }) => {
    const history = useHistory();
    const handleClick = (id) => {
      history.push(`/projekt/${id}`);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDataArray, setNewDataArray] = useState([]);
    const t = i18n.getFixedT(null, 'projectList');
    const m = i18n.getFixedT(null, 'modal');
    const [modalData, setModalData] = useState(null);


    //function zum Löschen eines Projekts
    const handleDelete = (id) => {
      Api.projects
        .deleteProject(id)
        .then(() => {
          // Lade die aktualisierten Daten nach erfolgreichem Löschen
          return Api.projects.getProjects();
        })
        .then((response) => {
          setNewDataArray(response.data);
        })
        .catch((error) => {
          console.error('Fehler beim Löschen:', error);
        });
    };

    return (
      <React.Fragment>
        <tr key={obj.id}>
          <td data-label={t('subject')}>{obj.subject}</td>
          <td data-label={t('start')}>{formatDate(new Date(obj.start))}</td>
          <td data-label={t('end')}>{formatDate(new Date(obj.end))}</td>
          {/* <td data-label={t('company')}>{obj.company}</td> */}
          <td>
            <div className="DeleteButtons">
              <LuClipboardList
                size={30}
                color="#4CBB17"
                onClick={() => handleClick(obj.id)}
                style={{ position: 'absolute', left: 0, marginLeft: '1em' }}
              />
              <MdDelete
                size={30}
                color="red"
                onClick={() => {
                  setModalData(obj);
                  setIsModalOpen(true);
                }}
              />
              <Modal
                isOpen={isModalOpen}
                style={{
                  content: {
                    width: '85%',
                    height: '15%',
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    overflow: 'hidden',
                  },
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    backdropFilter: 'blur(1px)',
                    zIndex: 1000,
                  },
                }}
              >
                <p className="deleteDatensatz">{t('deleteProject')}</p>
                <div className="yesNoButtons" style={{ marginTop: '1em' }}>
                  <button
                    className="button is-success is-inverted"
                    style={{ marginRight: '1em' }}
                    onClick={() => {
                      handleDelete(modalData.id);
                      setIsModalOpen(false);
                    }}
                  >
                    {m('btnYes')}
                  </button>
                  <button
                    className="button is-success is-inverted"
                    onClick={() => setIsModalOpen(false)}
                  >
                    {m('btnNo')}
                  </button>
                </div>
              </Modal>
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  };

    // Funktion zum Sortieren des Arrays basierend auf dem Sortiertyp
    const sortArray = (type) => {
      const types = {
        "Alphabetisch aufsteigend": 'subject',
        "Alphabetisch absteigend": 'subject',
        "Startdatum aufsteigend": 'start',
        "Startdatum absteigend": 'start',
        "Enddatum aufsteigend": 'end',
        "Enddatum absteigend": 'end',
      };
      const sortProperty = types[type];
      const sorted = [...filteredData].sort((a, b) => {
        if (type.includes("absteigend")) {
          return b[sortProperty].localeCompare(a[sortProperty]);
        } else {
          return a[sortProperty].localeCompare(b[sortProperty]);
        }
      });
      setFilteredData(sorted);
    };
  
    // Funktion zum Filtern des Arrays basierend auf dem Suchtext
    const filterArray = (value) => {
      const filtered = dataArray.filter(obj => obj.subject.toLowerCase().includes(value.toLowerCase()));
      setFilteredData(filtered); // Aktualisieren Sie das gefilterte Array für die Anzeige
    };

  const textBoxOptions = {
    placeholder: 'Suchen...',
    width: 100,
    value: searchText,
    onValueChanged: ({ value }) => {
      setSearchText(value);
      if (value) {
        filterArray(value); // Filtern Sie das Array, wenn der Suchtext gesetzt ist
      } else {
        setFilteredData(dataArray); // Setzen Sie filteredData auf das vollständige Array, wenn der Suchtext geleert wird
      }
    },
    buttons: [{
      name: 'search',
      location: 'after',
      options: {
        icon: 'search',
        stylingMode: 'text',
        onClick: () => filterArray(searchText) // Startet die Suche, wenn der Button gedrückt wird
      }
    }],
  };

  const sortOptions = {
    items: [
      "Alphabetisch aufsteigend",
      "Alphabetisch absteigend",
      "Startdatum aufsteigend",
      "Startdatum absteigend",
      "Enddatum aufsteigend",
      "Enddatum absteigend",
    ],
    icon: 'sort',
    stylingMode: 'text',
    onItemClick: ({ itemData }) => {
      setSortType(itemData);
      sortArray(itemData);
    },
    width: 80,
    dropDownOptions: {
      width: 200, // Breite des Dropdown-Menüs
    },
  }

  const resetbuttonOptions = {
    icon: 'revert',
    stylingMode: 'contained',
    onClick: () => {
      setSearchText('');
      setFilteredData(dataArray);
    }
  };

  const filterOptions = {
    icon: 'filter',
    stylingMode: 'contained',
    onClick: () => setPopupVisibility(true)
  };

  return (
    <React.Fragment>
      <Toolbar>

        <Item
          location={'before'}
          widget="dxButton"
          options={resetbuttonOptions}
        />

        <Item  
          location={'before'}
          widget="dxTextBox" 
          options={textBoxOptions}
        />

        <Item
          location={'before'}
          widget="dxButton"
          options={filterOptions}
        />

        <Item
          location={'after'}
          widget="dxDropDownButton"
          options={sortOptions}
        />

      </Toolbar>

      {/* <ScrollView
      height='calc(100vh - 400px)'
      > */}
        <section className="section"> 
          <div className="container">
            <div className="b-table">
              <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth is-striped is-hoverable is-fullwidth" style={{marginTop: '-25px'}}>
                <div style={{ textAlign: 'center', marginTop: '1em', fontSize: '2em'  }}>
                  {noDataMessage && <p>{noDataMessage}</p>}
                  {/* Hier können Sie den Rest Ihrer Komponente mit den Daten dataArray rendern */}
                </div>
                  <thead>
                    <tr>
                      <th>{t('subject')}</th>
                      <th>{t('start')}</th>
                      <th>{t('end')}</th>
                      {/* <th>{t('company')}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((obj) => (
                      <DataRow obj={obj} key={obj.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
         </section>
     {/* </ScrollView> */}

      <FilterPopup 
        isPopupVisible={isPopupVisible} 
        setPopupVisibility={setPopupVisibility} 
        t={t} allRoles={allRoles} 
        dataArray={dataArray} 
        setFilteredData={setFilteredData}
        filteredData={filteredData}
        selectedFilter={selectedFilter}
      />
   </React.Fragment>
  );
};

export default ProjectlisteMobile;
