// src/DataGridCards.js
import '../../components/bulma-tabel-css/bulma.min.css';
import '../../components/bulma-tabel-css/main.min.css';
import Api from "../../utils/api";
import React, { useEffect, useState, useCallback } from 'react';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { useHistory } from 'react-router-dom'
import { Modal, Divider, Button, TextInput, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Switch } from "devextreme-react/switch";

const MitgliederMobile = ({p, Mitglieder, setMitglieder, buttonVisible, memberDS}) => {
  const [dataArray, setDataArray] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IsEditModalOpen,setIsEditModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [Roles, setRoles] = useState([]); // Array mit allen Rollen
  const [sichtbareMitglieder, setSichtbareMitglieder] = useState([]); // Array mit allen sichtbaren Mitgliedern
  const [opened, { open, close }] = useDisclosure(false);
  const history = useHistory();
  const [selectedMember, setSelectedMember] = useState(null);
  const [myContacts, setMyContacts] = useState([]);
  const [hintMessage, setHintMessage] = useState("Click to switch on");
  const [viewMemberValid, setViewMemberValid] = useState(false);
  
    //Funtion zum laden der Kontakte
    useEffect(() => {
      Api.people.getMyNetwork().then(response => {
        const data = response.data;

        if (data.length === 0) {
          setNoDataMessage(p('noData'));
        } else {
          setMyContacts(data); // Daten in myContacts speichern
        }
      }).catch(error => {
        console.error(error);
      });
    }, []);

    // Funktion zum Laden der Daten
    useEffect(() => {
    Api.roles.getRoles().then(response => {
    const data = response.data;

    if (data.length === 0) {
      setNoDataMessage(p('noData'));
    } else {
      setDataArray(data);
      setRoles(data); // Rollen werden in einem State-Variablen gespeichert
    }
    }).catch(error => {
    console.error(error);
    });
    }, []);

    // Funktion zum Löschen eines Mitglieds
    function deleteMember(member) {
      memberDS.remove(member);
    };

    const openWithMember = (member) => {
      setSelectedMember(member);
      open();
    };

    // Funktion zum Speichern der Änderungen
    const saveChanges = () => {
      const updatedMitglieder = Mitglieder.map(member =>
        member.id === selectedMember.id ? selectedMember : member
      );
      setMitglieder(updatedMitglieder);
    };

    const onValueChanged = useCallback((e) => {
      if (e.value) {
        setViewMemberValid(true);
      } else {
        setViewMemberValid(false);
      }
      const messageText = e.value ? "Click to switch off" : "Click to switch on";
      setHintMessage(messageText);
    }, []);

  return (
      <>
      <Switch hint={hintMessage} onValueChanged={onValueChanged}/>
        <section style={{paddingTop: '20px'}}> {/* padding 48px */}
          <div className="container">
            <div className="b-table">
              <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth is-striped is-hoverable is-fullwidth"> {/* table is-fullwidth is-striped is-hoverable is-fullwidth */}
                  <thead>
                  <tr style={{fontSize: '16px'}}>
                    {viewMemberValid &&
                      <th>Validation</th>
                    }
                    <th>{(p('Name'))}</th>
                    <th>{(p('role'))}</th>
                    <th>{/* Buttons */}</th>
                  </tr>
                  </thead>
                    <tbody style={{fontSize: '14px', content: 'auto' }}>
                      {Mitglieder ? Mitglieder.map((member) => (
                      <tr key={member.id}>
                      {viewMemberValid &&
                      <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label="Validation" onClick={() => history.push(`/profile/${member.member.id}`)}>
                        {
                          'Mitglied seit ' + new Date(member.validfrom).toLocaleDateString() + ' bis ' + 
                          (new Date(member.validto).getFullYear() === 9999 ? '∞' : new Date(member.validto).toLocaleDateString())
                        }
                      </td>
                      }
                      <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label="Name" onClick={() => history.push(`/profile/${member.member.id}`)}>
                        {
                            (member.member.first_Name || member.member.last_Name)
                                ? (member.member.first_Name ? member.member.first_Name : '') + ' ' + (member.member.last_Name ? member.member.last_Name : '')
                                : member.member.eMail
                        }
                      </td>
                      <td style={{verticalAlign: 'middle', cursor: 'pointer'}} data-label="Rolle" onClick={() => history.push(`/profile/${member.member.id}`)}>{member.rolle.name}</td>
                      <td class="is-actions-cell">
                      {buttonVisible && (
                        <div className="buttons is-right" style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '0px'}}>
                         {/* <Button 
                            variant="filled" 
                            size='/'
                            styles={(theme) => ({
                              root: {
                                backgroundColor: '#39b201',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: '#39b201',
                                  color: 'white',
                                },
                              }})}
                            style={{marginRight: '5px', width: '34px'}} onClick={() => openWithMember(member)}>
                          <MdModeEdit style={{height: '20px', width: '20px'}}/>
                          </Button> */}
                          <Button variant="filled" color="red" size='/' style={{width: '34px'}} onClick={() => deleteMember(member)}>
                            <MdDelete style={{height: '20px', width: '20px'}}/>
                          </Button>
                        </div>
                      )}
                      </td>
                    </tr>
                    )) : null }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Modal opened={opened} onClose={close} title= {<h5 className="modal-title">{p('editMember')}</h5>} centered size='lg' withCloseButton={false} closeOnClickOutside={false}>
        <Divider margins="xs" />
        <form className='fromular' style={{ margin: '0 1.5em', marginTop: "2em", overflow: 'hidden' }}>
          <label className="label" style={{ marginTop: "1em" }}>{p('Name')}</label>
              {selectedMember && (
                <Select
                  searchable
                  nothingFound="No options"
                  value={selectedMember.member.first_Name + ' ' + selectedMember.member.last_Name}
                  onChange={(value) => setSelectedMember({ ...selectedMember, member: { first_Name: value.split(' ')[0], last_Name: value.split(' ')[1] } })}
                  data={myContacts.map((contact) => ({
                    value: contact.first_Name + ' ' + contact.last_Name,
                    label: contact.first_Name + ' ' + contact.last_Name,
                    disabled: (contact.first_Name + ' ' + contact.last_Name) === (selectedMember.member.first_Name + ' ' + selectedMember.member.last_Name)
                  }))}
                  maxDropdownHeight={150}
                  styles={(theme) => ({
                    input: {
                      '&:focus': {
                        borderColor: '#39b201',
                      },
                    },
                  })}
                />
              )}
          <label className="label" style={{ marginTop: "1em" }}>{p('Role')}</label>
          {selectedMember && (
             <Select
             value={selectedMember.rolle.id} // Hier sollten Sie die ID der Rolle verwenden, um die Rolle eindeutig zu identifizieren
             onChange={(value) => setSelectedMember({ ...selectedMember, rolle: Roles.find(rolle => rolle.id === value) })}
             data={Roles.map((rolle) => ({
               value: rolle.id,
               label: rolle.name,
             }))}
             maxDropdownHeight={100}
             dropdownPosition='bottom'
             styles={(theme) => ({
              input: {
                '&:focus': {
                  borderColor: '#39b201',
                },
              },
            })}
           />
          )}
            <div className="cancelOrSaveButtons">
            <Button  variant='filled' color='red' style={{marginRight: '10px'}} onClick={close}>{p('cancel')}</Button>
            <Button 
              onClick={saveChanges}
              styles={(theme) => ({
                root: {
                  backgroundColor: '#39b201',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#39b201',
                    color: 'white',
                  },
                }})}
              >
              {p('save')}
            </Button> {/* Fehlermeldung: 400 */}
          </div>
        </form>
      </Modal>
      </>
  );
}

export default MitgliederMobile;