import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useLocation, useParams} from 'react-router';
import './MailContainer.scss';
import i18n from "i18next";
import Api from "../../utils/api";
import {useScreenSizeClass} from '../../utils/media-query';
import ResponsiveBox, {
    Row,
    Col,
    Item,
    Location,
}                      from 'devextreme-react/responsive-box';
import {Popup}         from 'devextreme-react/popup';
import MailView        from '../../components/mailView/mail'
import Mailtoolbar     from '../../components/MailToolbar/MailToolbar';
import MailGrid        from '../../components/mail-grid2/mail-grid2';
import MailNew         from '../../components/MailCreate/Mail';
import CustomStore from 'devextreme/data/custom_store';
import SlSplitPanel from '@shoelace-style/shoelace/dist/react/split-panel';
import Cookies from 'js-cookie';
import { Divider, Modal } from '@mantine/core';
import { IoCloseSharp } from "react-icons/io5";
import MailNewMobile from '../../components/MailCreate/mailMobile';
import MailToolbarMobile from '../../components/MailToolbar/MailToolbarMobile';
import MailViewEinstellung from '../../components/MailViewMode-Komponente/MailViewEinstellung';
import { Button } from 'devextreme-react/button';
import { set } from 'lodash';


export default function () {

    const screenSizeClass = useScreenSizeClass();
    const [selectedRow, setSelectedRow] = useState();
    const [IsNewMail, setIsNewMail] = useState(false);
    const [IsForwardMail, setIsForwardMail] = useState(false);
    const [IsAnserMail, setIsAnserMail] = useState(false);
    const [path, setPath] = useState({});
    //const t = i18n.getFixedT(null, 'mails');
    const location = useLocation()
    const params = useParams();
    const [customDataSource, setcustomDataSource]   = useState();  
    const [id,setid]  = useState(params.id);
    const [folderId,setfolderId] =   useState(params.folder); //'';
    const [DataS, setDataS] = useState();
    const initialSplitPanelValue = 40;
    const [SpliPanelPosition, setSplitPanelPosition] = useState(() => Cookies.get('splitPanelPosition') || initialSplitPanelValue);
    const [viewMode, setViewMode] = useState();
    const [newMailMobile, setNewMailMobile] = useState(false);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [localePath, setLocalePath] = useState();
 
    const togglePopup = () => {
        setPopupVisibility(!isPopupVisible);
    };

    useEffect(() => {
        console.log("selectedRow", selectedRow)
    }, [selectedRow])

    useEffect(() => {
        console.log("ViewMode", viewMode)
    }, [viewMode])

    useEffect(() => {
        if (SpliPanelPosition !== initialSplitPanelValue) {
            Cookies.set('splitPanelPosition', SpliPanelPosition);
        }
    }, [SpliPanelPosition]);

    useEffect(() => {
        console.log("ViewMode", viewMode)
    }, [viewMode])

    useEffect(() => {
        Api.OptionClient.getOptions()
        .then(response => {
            const Mailansicht = response.data.filter(item => item.name.startsWith('MailViewMode'));
            console.log("Zeige Mailansicht", Mailansicht[0]?.option);

            if (Mailansicht[0]?.option === "ListeOben") {
                setViewMode("ListeOben");
            } else if (Mailansicht[0]?.option === "ListeLinks") {
                setViewMode("ListeLinks");
            } else {
                // Wenn Mailansicht[0] nicht existiert, wird es erstellt
                Api.getMe()
                .then(response => {
                    const data = {
                        name: 'MailViewMode',
                        option: 'ListeLinks', // als Standard wird ListeLinks gesetzt
                        id: response.data.id
                    };
                    Api.OptionClient.createOption(data)
                    .then(response => {
                        console.log('Option erstellt', response);
                        setViewMode(data.option);
                    })
                    .catch(error => {
                        console.error('Fehler beim Erstellen der Option', error);
                    });
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der ID', error);
                });
            }
        })
    }, [viewMode]);

    function renderViewMode() {
        if (viewMode === "ListeLinks" || viewMode === null) {
            return (
                <div className="split-panel-divider" style={{display: 'flex'}} >
                            <SlSplitPanel 
                                position={SpliPanelPosition}
                                style={{ '--min': '400px', '--max': 'calc(100% - 700px)' }}
                                onSlReposition={handleReposition}>

                                <div
                                slot="start"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                >

                            <div className="layoutMaillist" style={{flex:'60%'}} >
                                {

                                  // renderMaiLList()
                                  
                                //     <child MailSelected = { (e) => handelOnSelectionChanged(e) } handelTest = { (e) => handelTest(e)} 
                                //    MailSetting = { params } FolderID = { path.folder }  TestV= { TestV } customDataSource ={customDataSource}  /> 
                                  
                            //    <MailGrid MailSelected = { (e) => handelOnSelectionChanged(e) } Name = { "Lars" }/>
                                     <MailGrid MailSelected = { (e) => handelOnSelectionChanged(e) } handelTest = { (e) => handelTest(e)} 
                                   MailSetting = { params } FolderID = { path.folder }  TestV= { TestV } customDataSource ={DataS} selectedMail={  selectedRow  }  localePath={localePath}/> 
                                }
                            </div>

                            </div>

                            <div
                                slot="end"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                >

                                <div className="layoutItem" style={{flex:'40%'}} >
                                    {
                                        IsNewMail === false && IsForwardMail === false && IsAnserMail === false?
                                          renderPageMail(selectedRow)
                                        :
                                          <MailNew selectedMail={  selectedRow  } Forward={IsForwardMail} AnserMail={IsAnserMail}  />

                                    }
                                </div>
                            </div>
                            </SlSplitPanel>
                        </div>
            )
        } else if (viewMode === "ListeOben") {
            return (
                <>
                    <div className="layoutMaillist-vertical" >
                        {
                            <MailGrid MailSelected = { (e) => handelOnSelectionChanged(e) } handelTest = { (e) => handelTest(e)} 
                            MailSetting = { params } FolderID = { path.folder }  TestV= { TestV } customDataSource ={DataS} selectedMail={  selectedRow  } localePath={localePath} /> 
                    }
                    </div>

                    <div className="layoutItem-vertical" >
                        {
                            IsNewMail === false && IsForwardMail === false && IsAnserMail === false?
                            renderPageMail(selectedRow)
                            :
                            <MailNew selectedMail={  selectedRow  } Forward={IsForwardMail} AnserMail={IsAnserMail}  />

                        }
                    </div>
                </>
            )
        }
    }




        useEffect(() => 
        {
            const localePath = location.pathname.replace('/Mail/', '').split('/')
            var Position = 0;
            var FolderID = "";
            localePath.forEach(localePaths => {
                Position = Position + 1;
                if(Position > 1){
                    if(FolderID != "")
                    {
                        FolderID = FolderID + "/"+ localePaths;
                    }
                    else
                    {
                     FolderID = FolderID + localePaths;
                    }
                }
            });
            console.log("localerPfad",localePath[1])
            setLocalePath(localePath[1]);
            setPath({
                "settingID": localePath[0],
                // "folder":localePath[1]
                "folder": FolderID
            });
            setSelectedRow(null);
            console.log("MAILContainer: USEEFFECT location",localePath[0])
            const DataSource =   new CustomStore({
                key: 'id',
                //cacheRawData: true,
                reshapeOnPush: false,
                loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
                load: () => {
          
                  return Api.mail.getMails(localePath[0], FolderID)
                    .then(response => {
                        console.log(response.data);
                      return response.data
                     
                    })
                    .then(data => {
                      data.forEach((item, index) => {
                        item.id = index
                       
                      })
                      return data
                    })
                    .catch(error => {
                    });
                }
          
              })
              console.log(DataSource);
              setDataS(DataSource);

        }, [location])


    function screen() {
        return screenSizeClass === 'screen-large' ? "lg" : "sm"
    }

   

    function closePopup() {
        setSelectedRow(undefined)
    }

    function renderPageMail() {
        return <React.Fragment>
            <div className="stats">
                <MailView selectedMail={selectedRow}/>
            </div>


        </React.Fragment>
    }

   function onClick(){
    console.log("Component MAILConatainer: TEST")
   }

   function handelOnSelectionChanged(e) {
    const data = e.selectedRowsData[0];
    console.log("Component MAILConatainer: Selected Mail") ;
    console.log('Component MAILConatainer:',data) ;
    setSelectedRow(undefined);
    setSelectedRow(data);
    setIsNewMail(false) ;
    setIsForwardMail(false);
    setIsAnserMail(false);
}

function handleButtonNewMail(e){
    setSelectedRow(undefined);
    setIsNewMail(true) ;
    setIsForwardMail(false);
    setIsAnserMail(false);
    
}

function handleButtonNewMailMobile(e){
    setIsNewMail(true);
    setNewMailMobile(PrevState => !PrevState);
    console.log("New Mail gedrückt", newMailMobile)
}



const [TestV, setTestV] = useState(2311);

function handelTest(e) {
    
    console.log("Component MAILConatainer: Test event in Parent",e) ;
    
     setTestV(TestV+1);
   
}





const child = useCallback(() => <MailGrid />, [id, folderId]);

function handleButtonAnserMail(e){
    console.log("MailContainer Button Anser");
    
    setIsNewMail(false) ;
    setIsAnserMail(true);
    setIsForwardMail(false);
}

function handleButtonAnserMailMobile(e){
    console.log("MailContainer Button Anser");
    
    setIsNewMail(false) ;
    setIsAnserMail(true);
    setIsForwardMail(false);
    setNewMailMobile(PrevState => !PrevState);
    console.log("Anser Mail gedrückt", newMailMobile, "Prüfe nochmal ausgewähle Mail:", selectedRow)
}

function handleButtonForwardMail(e){
    console.log("MailContainer Button Anser");
    setIsNewMail(false) ;
    setIsAnserMail(false);
    setIsForwardMail(true);  
}

function handleReposition(event) {
    setSplitPanelPosition(event.target.__position); 
}

    return (
        <React.Fragment>

    {/* {t('title')} */}
            <h2 className={'content-block'}></h2>
            <div className={'content-box'}>
            
                <div className={'dx-card responsive-paddings'} >
                    {screenSizeClass <= 'screen-medium' && (
                        <>
                            <div className="layoutMenu" >
                                
                                <Mailtoolbar 
                                    selectedMail          = {  selectedRow  }  
                                    handleButtonNewMail   = { (e) => handleButtonNewMail(e)} 
                                    handleButtonAnserMail = { (e) => handleButtonAnserMail(e)} 
                                    handleButtonForwardMail = { (e) => handleButtonForwardMail(e)} 
                                />
                                <Button 
                                    icon="preferences"
                                    onClick={togglePopup}
                                    hint='Anzeigeeinstellungen'
                                />
                            </div>
                        

                            <div className='MailContainer'>
                                {renderViewMode()}
                            </div>
                        </>
                    )}

                    <div className='mobileliste'>
                        {screenSizeClass >= 'screen-small' && (
                            <>
                            <div className="layoutMenu" >
                                
                                <Mailtoolbar 
                                    selectedMail          = {  selectedRow  }  
                                    handleButtonNewMail   = { (e) => handleButtonNewMailMobile(e)} 
                                    handleButtonAnserMail = { (e) => handleButtonAnserMail(e)} 
                                    handleButtonForwardMail = { (e) => handleButtonForwardMail(e)} 
                                />
                                <Button 
                                    icon="preferences"
                                    onClick={togglePopup}
                                    hint='Anzeigeeinstellungen'
                                />
                            </div>

                            <div>
                                <div className="layoutMaillist">
                                <MailGrid 
                                    MailSelected={(e) => handelOnSelectionChanged(e)} 
                                    handelTest={(e) => handelTest(e)}
                                    MailSetting={params} 
                                    FolderID={path.folder}  
                                    TestV={TestV} 
                                    customDataSource={DataS} 
                                    selectedMail={selectedRow} 
                                    localePath={localePath} 
                                /> 
                                </div>
                            </div>

                            {newMailMobile ? (
                                <Modal opened={newMailMobile} size="calc(100vw - 2rem)" withCloseButton={false} fullScreen>
                                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                        <div>
                                            <h6>Neue E-Mail</h6>
                                        </div>
                                        <div>
                                            <IoCloseSharp size={25}  onClick={() => setNewMailMobile(false)}/>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="neueMailMobile" style={{marginTop: '20px'}}>
                                        <MailNewMobile selectedMail={  selectedRow  } Forward={IsForwardMail} AnserMail={IsAnserMail} setNewMailMobile={setNewMailMobile} setSelectedRow={setSelectedRow}/>
                                    </div>
                                </Modal>
                            ) : (
                                null
                            )}
                            </>
                        )}
                    </div>
                    {
                        selectedRow && selectedRow.subject && screenSizeClass !== 'screen-large'
                    }

                </div>
            </div>

            <Popup
                visible={isPopupVisible}
                hideOnOutsideClick={true}
                onHiding={togglePopup}
                defaultWidth={500}
                defaultHeight={250} 
                resizeEnabled={true}
                position="center"
                showTitle={true}
                title="Anzeigeeinstellungen"
            >
                <div className="content">
                    <MailViewEinstellung setViewMode={setViewMode} />
                </div>
            </Popup>

            <Modal
                opened={(selectedRow && selectedRow.subject && screenSizeClass !== 'screen-large' && screenSizeClass !== 'screen-medium')}
                fullScreen
                withCloseButton={false}
            >

                <div style={{ position: 'sticky', top: 16, zIndex: 1, background :'white'}}>

                    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        <IoCloseSharp size={25}  onClick={closePopup}/>
                    </div>

                    <div className='mobilebar'>
                        <MailToolbarMobile
                            selectedMail={selectedRow}
                            handleButtonAnserMail={(e) => handleButtonAnserMailMobile(e)}
                            handleButtonForwardMail={(e) => handleButtonForwardMail(e)}
                            />
                    </div>
                
                </div>
                    
                <div className='mobileview' style={{ overflowY: 'scroll', height: '100%'}}>
                    <MailView selectedMail={selectedRow} />
                </div>

            </Modal>
        </React.Fragment>
    )


};