import React from 'react';
import './home.scss';
import appInfo from '../../app-info';

import CustomStore from 'devextreme/data/custom_store';

import PieChart, {
    Series,
    Label,
    Connector,
    Size,
    Export
} from 'devextreme-react/pie-chart';
import { Chart,  CommonSeriesSettings, Legend,  Tooltip, Title } from 'devextreme-react/chart';
import Box, {
    Item
} from 'devextreme-react/box';

function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    else {
     
    }
    return response;
}

const customStoreAufgabe = new CustomStore({
    key: ['id', 'ID']
    ,
    cacheRawData: false,
    reshapeOnPush: true,
    loadMode: 'raw', // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
    load: () => {
        return fetch('https://localhost:25443/api/Tasks')

            .then(response => response.json());

    },
    insert: (values) => {
        //console("Einfuegen");
        //console(values);
        //console(JSON.stringify(values));
        return fetch('https://localhost:25443/api/Tasks', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(handleErrors)
            .catch(() => { throw 'Network error' });
    },
    update: (key, values) => {
        return fetch('https://localhost:25443/api/Tasks/' + key, {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(handleErrors)
            .catch(() => { throw 'Network error' });
    },
    remove: (key, values) => {
        return fetch('https://localhost:25443/api/Tasks/' + key, {
            method: 'DELETE'

        })
            .then(handleErrors)
            .catch(() => { throw 'Network error' });
    },
    byKey: function (key) {
        return fetch('https://localhost:25443/api/Tasks/' + key)

            .then(response => response.json());

    }

});

const areas = [{
    country: 'Alpha Calcit',
    area: 12
}, {
        country: 'Alpha Compound',
        area: 7
    }
];

const dataSource = [{
    country: 'Alpha Calcit',
    offene: 59.8,
    bearbeitung: 937.6,
    ueberfaellig: 582,
    erledigt: 564.3
}, {
    country: 'Alpha Compound',
        offene: 74.2,
        bearbeitung: 937.6,

        ueberfaellig: 35.1,
        erledigt: 956.9
}, {
    country: 'Alpha Mineral',
        offene: 40,
        bearbeitung: 937.6,

        ueberfaellig: 361.8,
        erledigt: 105
    
}];

export default () => (
    <React.Fragment>
        <div className={'content-block'}>  
        <PieChart
            id="pie"
            dataSource={areas}
            palette="Bright"
            title="offene Aufgaben "
          
        >
            <Series
                argumentField="country"
                valueField="area"
            >
                <Label visible={true}>
                    <Connector visible={true} width={1} />
                </Label>
            </Series>

            <Size width={500} />
            <Export enabled={true} />
        </PieChart>

        <Chart
            id="chart"
            dataSource={dataSource}
        >
            <Title
                text="Aufgabenverwaltung"
                subtitle=""
            />
            <CommonSeriesSettings argumentField="country" type="fullstackedbar" />
            <Series valueField="offene" name="offene" />
            <Series valueField="bearbeitung" name="bearbeitung" />
            <Series valueField="ueberfaellig" name="ueberfaellig" />
            <Series valueField="erledigt" name="erledigt" />
            

            <Legend verticalAlignment="top"
                horizontalAlignment="center"
                itemTextPosition="right"
            />
          
            </Chart>
        </div>
  </React.Fragment>
);
