import '../../../components/bulma-tabel-css/bulma.min.css';
import '../../../components/bulma-tabel-css/main.min.css';
import Api from '../../../utils/api';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import './ProjektlistePC.css';
import { ScrollView, TextBox } from 'devextreme-react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Divider, Input } from '@mantine/core';
import { CheckBox } from 'devextreme-react/check-box';
import ProjektlisteBearbeiten from '../../../components/Projektlisten/ProjektlisteBearbeiten';
import ProjektlisteLöschen from '../../../components/Projektlisten/ProjektlisteLöschen';
import ProjektToolbar from '../../../components/Projektlisten/ProjektToolbar';
import { IoFilterOutline } from "react-icons/io5";
import DropDownButton from 'devextreme-react/drop-down-button';
import {
  DataGrid,
  Column,
  FilterRow,
  Selection
} from 'devextreme-react/data-grid';
import themes from 'devextreme/ui/themes';
import FilterPopup from './Filterpopup';
import { filter } from 'lodash';
import notify from 'devextreme/ui/notify';

const formatDate = new Intl.DateTimeFormat('de-DE').format;

const ProjectlistePC = ({ t }) => {
  const [dataArray, setDataArray] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [sortType, setSortType] = useState(''); // State für Sortierung
  const [filteredData, setFilteredData] = useState([]); // Gefilterte Daten für die Anzeige
  const [searchText, setSearchText] = useState(''); // State für Suchtext
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [ownLists, setOwnLists] = useState([]); // State für eigene Listen
  const [selectedList, setSelectedList] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // State für das Modal zum Löschen eigener Listen
  const [selectedListToDelete, setSelectedListToDelete] = useState([]); // State für die ausgewählte Liste zum Löschen
  const [openEditModal, setOpenEditModal] = useState(false); // State für das Modal zum Bearbeiten eigener Listen
  const [selectedListToEdit, setSelectedListToEdit] = useState(null); // State für die ausgewählte Liste zum Bearbeiten
  const [dataGridSource, setDataGridSource] = useState([...filteredData]); // State für die Datenquelle des DataGrid
  const [aktaulisere, setAktualisere] = useState(false);
  const [allMode, setAllMode] = useState('allPages');
  const [checkBoxesMode, setCheckBoxesMode] = useState(
    themes.current().startsWith('material') ? 'always' : 'onClick',
  );
  const [selectedFilter, setSelectedFilter] = useState(null); // State für den ausgewählten Filter
  const dataGridRef = useRef(null);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [ausgewählteSpalten, setAusgewählteSpalten] = useState([]);

  // Funktion zum Laden der Daten
  useEffect(() => {
    Api.projects.getProjects().then(response => {
    const data = response.data;

    if (data.length === 0) {
      setNoDataMessage(t('noData'));
    } else {
      setDataArray(data);
      setFilteredData(data);
    }
    }).catch(error => {
    console.error(error);
    });

    Api.OptionClient.getOptions().then(response => {
    console.log("OptionClient: ", response.data);
    let Projektfavoriten  = response.data.filter(item => {
      let decodedName = decodeURIComponent(item.name);
      return decodedName.startsWith("Projektfav_");
    }).map(item => {
      let decodedName = decodeURIComponent(item.name);
      return {...item, name: decodedName.substring("Projektfav_".length)};
    });
    setOwnLists(Projektfavoriten);
  }).catch(error => {
    console.error(error);
  });
  }, [aktaulisere]);

  useEffect(() => {
    console.log("Ausgewählte Projecte",selectedProjects);
    console.log("Ausgewählte Liste",selectedListToDelete);
  }, [selectedProjects, selectedListToDelete]);

  useEffect(() => {
    setDataGridSource([...filteredData]);
  }, [filteredData]);  

  useEffect(() => {
    console.log("selectedFilter: ", selectedFilter);

    if (selectedFilter && typeof selectedFilter === 'object' && selectedFilter !== null) {
      const arrayNames = Object.keys(selectedFilter);
      console.log("Array Names: ", arrayNames);

      let tempArray = []; // Gemeinsames tempArray

      // Überprüfen, ob irgendein Schlüssel nicht "subject", "start", "end" oder "company" ist
      if (arrayNames.some(name => !["subject", "start", "end", "company"].includes(name))) {
        for (let i = 0; i < arrayNames.length; i++) {
          for (let j = 0; j < selectedFilter[arrayNames[i]].length; j++) {
            const selectedValue = selectedFilter[arrayNames[i]][j]; // Der Wert des j-ten Elements im i-ten Array in selectedFilter
            console.log('selectedValue: ', selectedValue);
            console.log('arrayNames[i]: ', arrayNames[i]);

            const filtered = dataArray.filter(obj => {
              obj.members.some(member => {
                if (member.rolle.name === arrayNames[i] && (member.member.first_Name + ' ' + member.member.last_Name) === selectedValue) {
                  tempArray.push(obj);
                  setFilteredData([...tempArray]);
                }
              });   
            })
          }
        }
      } 
      
      if (arrayNames.length > 0 && arrayNames.every(name => ["subject", "start", "end", "company"].includes(name))) {
          for (let i = 0; i < arrayNames.length; i++) {
              for (let j = 0; j < selectedFilter[arrayNames[i]].length; j++) {
                  const selectedValue = selectedFilter[arrayNames[i]][j]; // Der Wert des j-ten Elements im i-ten Array in selectedFilter

                  const filtered = dataArray.filter(obj => obj[arrayNames[i]] === selectedValue);
                  tempArray.push(...filtered);
                  setFilteredData([...tempArray]);
              }
          }
      }
      console.log("TempArray: ", tempArray);
      if (tempArray.length === 0) {
        setFilteredData(dataArray);
      }
    }
  }, [selectedFilter]);

  const onValueChanged = useCallback((obj) => (e) => {
    const newCheckedItems = { ...checkedItems, [obj.id]: e.value };
    setCheckedItems(newCheckedItems);

    if (e.value) {
      console.log("Die Checkbox ist ausgewählt", obj);
      setSelectedListToDelete([...selectedListToDelete, obj]);
    } else {
      console.log("Die Checkbox ist nicht ausgewählt", obj);
      setSelectedListToDelete(selectedListToDelete.filter(project => project.id !== obj.id));
    }
  }, [checkedItems, selectedListToDelete]);

  // Funktion zum Filtern des Arrays basierend auf dem Suchtext
  const filterArray = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const filtered = dataArray.filter(obj => {
      // Überprüfen Sie, ob der Suchtext im 'subject' Feld enthalten ist
      if (obj.subject.toLowerCase().includes(lowerCaseValue)) {
        return true;
      }

      const memberNames = obj.members.map(member => {
        if (member.member.first_Name && member.member.last_Name) {
          return `${member.member.first_Name} ${member.member.last_Name}`;
        } else {
          return '';
        }
      });

      if (memberNames.some(name => name.toLowerCase().includes(lowerCaseValue))) {
        return true;
      }

      // Wenn der Suchtext weder im 'subject' Feld noch in den Mitgliedernamen enthalten ist, filtern Sie das Objekt aus
      return false;
    });

    setFilteredData(filtered); // Aktualisieren Sie das gefilterte Array für die Anzeige
  };

  const handleSelectAll = useCallback((e) => {
    const newCheckedItems = {};
    const newSelectedProjects = [];

    filteredData.forEach(obj => {
      newCheckedItems[obj.id] = e.value;
      if (e.value) {
        newSelectedProjects.push(obj);
      }
    });

    setSelectAllChecked(e.value);
    setCheckedItems(newCheckedItems);
    setSelectedProjects(newSelectedProjects);
  }, [filteredData]);

  const DataRow = ({ obj }) => {
    const history = useHistory();
    const handleClick = (id) => {
      history.push(`/projekt/${id}`);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDataArray, setNewDataArray] = useState([]);
    const t = i18n.getFixedT(null, 'projectList');
    const m = i18n.getFixedT(null, 'modal');
    const [modalData, setModalData] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);


    //function zum Löschen eines Projekts
    const handleDelete = (id) => {
      Api.projects
        .deleteProject(id)
        .then(() => {
          // Lade die aktualisierten Daten nach erfolgreichem Löschen
          return Api.projects.getProjects();
        })
        .then((response) => {
          setNewDataArray(response.data);
          notify(t('successfullyDeleted'), "success", 3000);
          setAktualisere(!aktaulisere);
        })
        .catch((error) => {
          console.error('Fehler beim Löschen:', error);
        });
    };

    function renderMembers(obj, role) {
      const members = obj.members.filter(member => member.rolle.name === role);
      
      return members.map((member, index) => {
        if (index >= 2) {
          return;
        }

        let memberName = '';
        if (member.member.first_Name && member.member.last_Name) {
          memberName = `${member.member.first_Name} ${member.member.last_Name}`;
        } else if (member.member.first_Name) {  //Fall, wenn nur der Vorname vorhanden ist
          memberName = member.member.first_Name;
        } else if (member.member.last_Name) { //Fall, wenn nur der Nachname vorhanden ist
          memberName = member.member.last_Name;
        } else {
          memberName = member.member.eMail;   //Fall, wenn nur die E-Mail vorhanden ist
        }
        // Wenn es mehr als ein Mitglied für diese Rolle gibt, füge ein Komma hinzu
        if (index < members.length - 1 && index < 1) {
          memberName += ', ';
        }
        return memberName;
      }).filter(Boolean).join('') + (members.length > 2 ? ',...' : '');
    }

    const onValueChanged = useCallback((obj) => (e) => {
      const newCheckedItems = { ...checkedItems, [obj.id]: e.value };
      setCheckedItems(newCheckedItems);

      if (e.value) {
        console.log("Die Checkbox ist ausgewählt", obj);
        setSelectedProjects([...selectedProjects, obj]);
      } else {
        console.log("Die Checkbox ist nicht ausgewählt", obj);
        setSelectedProjects(selectedProjects.filter(project => project.id !== obj.id));
      }
    }, [checkedItems, selectedProjects]);

    return (
      <React.Fragment>
        <tr key={obj.id} className='tbodycss'>
        <td class="is-checkbox-cell">
            <label class="checkbox">
            <CheckBox
              value={checkedItems[obj.id] || false}
              onValueChanged={onValueChanged(obj)}
            />
            </label>
          </td>
          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Betreff')) && (
            <td data-label={t('subject')} onClick={() => handleClick(obj.id)}>{obj.subject}</td>
          )}
          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Start')) && (
            <td data-label={t('start')} onClick={() => handleClick(obj.id)}>{formatDate(new Date(obj.start))}</td>
          )}
          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Ende')) && (
          <td data-label={t('end')} onClick={() => handleClick(obj.id)}>{formatDate(new Date(obj.end))}</td>
          )}
          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Unternehmen')) && (
          <td data-label={t('company')} onClick={() => handleClick(obj.id)}>{obj.company}</td>
          )}
          {window.innerWidth > 768 && (
            <>
              {allRoles.map(role => (
                (ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes(role)) && (
                  <td key={role}>
                    {obj.members.some(person => person.rolle.name === role) && (
                      renderMembers(obj, role)
                    )}
                  </td>
                )
              ))}
            </>
          )}
          {window.innerWidth <= 768 && (
            <>
              {allRoles.map(role => (
                (obj.members.some(person => person.rolle.name === role)) && (
                  <td key={role} data-label={t(role)} onClick={() => handleClick(obj.id)}>
                    {renderMembers(obj, role)}
                  </td>
                )
              ))}
            </>
          )}
          <td>
                <div className="DeleteButtons">
                <MdDelete
                    size={30}
                    color="red"
                    onClick={() => {
                    setModalData(obj);
                    {open()}
                    }}
                />
                </div>
            </td>
        </tr>
              <Modal opened={opened} onClose={close} closeOnClickOutside={false} withCloseButton={false} centered >  
                <div className="deleteDatensatz" style={{ fontSize: '15px'}}>
                  {t('deleteProject')}
                </div>
                <div className="yesNoButtons">
                  <Button
                    className='myButton'
                    style={{ marginRight: '1em' }}
                    onClick={() => {
                      handleDelete(modalData.id);
                      close();
                    }}
                  >
                    {m('btnYes')}
                  </Button>
                  <Button
                    onClick={() => { close(); }}
                    color="red"
                  >
                    {m('btnNo')}
                  </Button>
                </div>
              </Modal>
      </React.Fragment>
    );
  };


  if (dataArray.length === 0) {
    return (
      <div className="container">
        <h2>{noDataMessage}</h2>
      </div>
    );
  }

  // Extrahiere alle einzigartigen Rollennamen aus den Projektmitgliedern
  const allRoles = dataArray.reduce((acc, project) => {
    project.members.forEach(member => {
      if (!acc.includes(member.rolle.name)) {
        acc.push(member.rolle.name);
      }
    });
    return acc;
  }, []);

  const DropDownButtonOptions = {
    width: 250,
  }


  const dropDownContentRender = (key) => {
    return (
      <div>
        <ScrollView
          height={400}
          direction="both"
        >
          <DataGrid
            ref={dataGridRef}
            dataSource={dataArray}
            keyExpr={key}
            showBorders={true}
            onSelectionChanged={(e) => {
              const keysObject = { ...selectedFilter, [key]: e.selectedRowKeys };
              console.log("Key Objekte: ",keysObject);
              setSelectedFilter(keysObject);
              if (e.selectedRowsData.length === 0) {
                setFilteredData(dataArray);
              }
            }}
          >
            <Selection mode="multiple" selectAllMode={allMode} showCheckBoxesMode={checkBoxesMode}/>
            <FilterRow visible={true} />
            <Column 
              dataField={key} 
              dataType={key === "start" || key === "end" ? "date" : undefined}
              cellTemplate={(cellElement, cellInfo) => {
                if (key === "start" || key === "end") {
                  const date = new Date(cellInfo.value);
                  const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                  cellElement.textContent = formattedDate;
                } else {
                  cellElement.textContent = cellInfo.value;
                }
              }} 
            />
          </DataGrid>
        </ScrollView>
      </div>
    );
  };

  const dropDownContentRenderRole = (key) => {
    if (typeof key === 'object' && key !== null) {
      key = key.role;
    }

    const ersteller = Array.from(new Set(dataArray.flatMap(obj => 
    obj.members
        .filter(member => member.rolle.name === key)
        .map(member => `${member.member.first_Name} ${member.member.last_Name}`)
    )));

    return (
      <div>
        {/* <ScrollView
          height={400}
          direction="both"
        > */}
          <DataGrid
            ref={dataGridRef}
            dataSource={ersteller.map(name => ({ name }))}
            keyExpr="name"
            showBorders={true}
            onSelectionChanged={(e) => {
                const selectedNames = e.selectedRowKeys;
                console.log("SelectedNames: ", selectedNames);
                const neueDaten = dataArray.filter(obj =>
                    obj.members.some(member =>
                        selectedNames.includes(`${member.member.first_Name} ${member.member.last_Name}`)
                    )
                );
                if (selectedFilter === null) {
                    setFilteredData(neueDaten);
                } else {
                    setFilteredData(prevFilteredData => prevFilteredData.concat(neueDaten));
                }
                setSelectedFilter({ ...selectedFilter, [key]: selectedNames });
            }}
          >
            <Selection mode="multiple" selectAllMode={allMode} showCheckBoxesMode={checkBoxesMode}/>
            <FilterRow visible={true} />
            <Column 
              dataField="name" 
              cellTemplate={(cellElement, cellInfo) => {
                cellElement.textContent = cellInfo.value;
              }} 
            />
          </DataGrid>
        {/* </ScrollView> */}
      </div>
    );
  };

  const showFilterWithText = () => {
    return (
      <>
        <Divider />
        <div style={{display: 'flex', alignItems: 'center'}} className='hover-underline' onClick={() => setPopupVisibility(true)}>
          <IoFilterOutline />
          <p>
            {selectedFilter && Object.keys(selectedFilter).length > 0 
              ? Object.entries(selectedFilter)
                  .filter(([, values]) => values && values.length > 0)
                  .map(([key, values]) => {
                    if (key === "start" || key === "end") {
                      if (values[0]) {
                        const date = new Date(values[0]);
                        const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                        return `[${t(key)}] ${formattedDate}`;
                      } else {
                        return;
                      }
                    } else {
                      return `[${t(key)}] ${values.join(' & ')}`;
                    }
                  }).join(' UND ').length > 0 ? Object.entries(selectedFilter)
                  .filter(([, values]) => values && values.length > 0)
                  .map(([key, values]) => {
                    if (key === "start" || key === "end") {
                      if (values[0]) {
                        const date = new Date(values[0]);
                        const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                        return `[${t(key)}] ${formattedDate}`;
                      } else {
                        return;
                      }
                    } else {
                      return `[${t(key)}] ${values.join(' & ')}`;
                    }
                  }).join(' UND ') : "Create Filter"
              : "Create Filter"}
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <ProjektToolbar 
        setSearchText={setSearchText}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        dataArray={dataArray}
        searchText={searchText}
        filterArray={filterArray}
        selectedProjects={selectedProjects}
        ownLists={ownLists}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        setOpenEditModal={setOpenEditModal}
        setOpenDeleteModal={setOpenDeleteModal}
        setSortType={setSortType}
        setSelectedFilter={setSelectedFilter}
        dataGridRef={dataGridRef}
        ausgewählteSpalten={ausgewählteSpalten}
        setAusgewählteSpalten={setAusgewählteSpalten}
      />

      {/* <ScrollView
      height={'calc(100vh - 450px)'}
      width={'auto'}
      direction="vertical"
      > */}
          <section className="section">
              <div className="container">
                  <div className="b-table">
                  <div className="table-wrapper has-mobile-cards">
                      <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                      <thead>
                          <tr>
                          <th>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <CheckBox
                                value={selectAllChecked}
                                onValueChanged={handleSelectAll}
                              />
                            </div>
                          </th>
                          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Betreff')) && (
                          <th>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              {t('subject')}
                              <DropDownButton
                                icon='filter'
                                hint='Betreff auswählen'
                                stylingMode='text'
                                showArrowIcon={false}
                                valueExpr='id'
                                displayExpr='name'
                                onItemClick={(e) => {console.log(e.itemData)}}
                                dropDownContentRender={() => dropDownContentRender("subject")}
                                dropDownOptions={DropDownButtonOptions}
                              />
                            </div>
                          </th>
                          )}
                          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Start')) && (
                          <th>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                {t('start')}
                                <DropDownButton
                                icon='filter'
                                hint='Startzeitpunkt auswählen'
                                stylingMode='text'
                                showArrowIcon={false}
                                valueExpr='id'
                                displayExpr='name'
                                onItemClick={(e) => {console.log(e.itemData)}}
                                dropDownContentRender={() => dropDownContentRender("start")}
                                dropDownOptions={DropDownButtonOptions}
                              />
                              </div>
                          </th>
                          )}
                          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Ende')) && (
                          <th>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                {t('end')}
                                <DropDownButton
                                icon='filter'
                                hint='Endzeitpunkt auswählen'
                                stylingMode='text'
                                showArrowIcon={false}
                                valueExpr='id'
                                displayExpr='name'
                                onItemClick={(e) => {console.log(e.itemData)}}
                                dropDownContentRender={() => dropDownContentRender("end")}
                                dropDownOptions={DropDownButtonOptions}
                              />
                              </div>
                          </th>
                          )}
                          {(ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes('Unternehmen')) && (
                          <th>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                {t('company')}
                                <DropDownButton
                                icon='filter'
                                hint='Unternehemen auswählen'
                                stylingMode='text'
                                showArrowIcon={false}
                                valueExpr='id'
                                displayExpr='name'
                                onItemClick={(e) => {console.log(e.itemData)}}
                                dropDownContentRender={() => dropDownContentRender("company")}
                                dropDownOptions={DropDownButtonOptions}
                              />
                              </div>
                          </th>
                          )}
                          {/* Rendern der Spalten für jede Rolle */}
                          {allRoles.map(role => (
                            (ausgewählteSpalten.length === 0 || ausgewählteSpalten.includes(role)) && (
                            <th key={role}>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                {role}
                                <DropDownButton
                                icon='filter'
                                hint={`${role} auswählen`}
                                stylingMode='text'
                                showArrowIcon={false}
                                valueExpr='id'
                                displayExpr='name'
                                onItemClick={(e) => {console.log(e.itemData)}}
                                dropDownContentRender={() => dropDownContentRenderRole({role})}
                                dropDownOptions={DropDownButtonOptions}
                              />
                              </div>
                            </th>
                            )
                          ))}
                          <th>{/*Button Cell*/}</th>
                          </tr>
                      </thead>
                          <tbody>
                              {filteredData.map((obj) => (
                              <DataRow obj={obj} key={obj.id} />
                              ))}
                          </tbody>
                      </table>
                  </div>
                  </div>
              </div>
          </section>
      {/* </ScrollView> */}
      <div className="container-filtertext">
        <div className="filtertext">
          <p>{showFilterWithText()}</p>
        </div>
      </div>


      <ProjektlisteBearbeiten 
        openEditModal={openEditModal} 
        setOpenEditModal={setOpenEditModal} 
        selectedList={selectedList} 
        setSelectedList={setSelectedList}
        selectedListToEdit={selectedListToEdit}
        setSelectedListToEdit={setSelectedListToEdit}
        ownLists={ownLists}
        dataGridSource={dataGridSource}
        setDataGridSource={setDataGridSource}
      />

      <FilterPopup 
        isPopupVisible={isPopupVisible} 
        setPopupVisibility={setPopupVisibility} 
        t={t} allRoles={allRoles} 
        dataArray={dataArray} 
        setFilteredData={setFilteredData}
        filteredData={filteredData}
        selectedFilter={selectedFilter}
      />
      </>
  );
};

export default ProjectlistePC;