import React, { useEffect, useState } from "react";
import { Button } from "devextreme-react";
import Api from "../../utils/api";
import { MdFileDownload, MdClose } from "react-icons/md";
import { Button as MantineButton } from "@mantine/core";
import { MdDelete } from "react-icons/md";

export default function DocumentsList({ Documents }) {

    const [documents, setDocuments] = useState(Documents);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [isPDF, setIsPDF] = useState(false)

    const downloadDocument = async (doc) => {
        console.log(`Dokumenten-ID: ${doc.id}`);
        try {
            // API-Aufruf zum Abrufen des Dokuments
            const blob = await Api.Dokuments.getDokuments(doc.id); // response.data ist bereits ein Blob
            console.log("Api Antwort", blob);
    
            let filename = doc.name; // Standard-Dateiname
    
            // Erstellen einer URL aus dem Blob
            const downloadUrl = window.URL.createObjectURL(blob);
            console.log('Download-URL:', downloadUrl);
    
            // Erstellen eines temporären <a>-Elements zum Herunterladen
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            a.remove();
    
            // Aufräumen der URL
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Fehler beim Herunterladen des Dokuments:', error);
        }
    };
      
    
    const showDokument = async (document) => {
        console.log(`Dokumenten-ID: ${document.id}`);
        try {
            const blob = await Api.Dokuments.getDokuments(document.id);
            const objectURL = URL.createObjectURL(blob);

            setPreviewUrl(objectURL);
            setShowOverlay(true);
            setIsPDF(blob.type === 'application/pdf');
        } catch (error) {
            console.error('Fehler beim Anzeigen des Dokuments:', error);
        }
    };

    function closePreview() {
        setPreviewUrl(null);
        setShowOverlay(false);
        setIsPDF(false);
    }

    function handleDelete(id) {
        Api.Dokuments.deleteDokument(id)
            .then(() => {
                console.log('Dokument erfolgreich gelöscht');
                setDocuments(documents.filter(doc => doc.id !== id));
            })
            .catch((error) => {
                console.error('Fehler beim Löschen des Dokuments:', error);
            });
    }

    return (
        <>
            <section className="section" style={{paddingTop: '20px'}}>
                <div className="container">
                    <div className="b-table">
                        <div className="table-wrapper has-mobile-cards">
                            <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Download</th>
                                    </tr>
                                </thead>
                                <tbody id='tbody'>
                                    {documents?.map((document, index) => (
                                        <tr key={index} style={{verticalAlign: 'middle' }}>
                                            <td style={{cursor: 'pointer'}} onClick={() => showDokument(document)}>{document.id}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => showDokument(document)}>{document.name}</td>
                                            <td>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <MdFileDownload 
                                                        onClick={() => downloadDocument(document)}
                                                        color="#39b201"
                                                        size={28}
                                                        style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    />
                                                    <MdDelete 
                                                        size={28} 
                                                        color="red"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleDelete(document.id)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>   
                    </div>       
                </div>
            </section>

            {showOverlay && !isPDF &&(
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.85)',
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <img src={previewUrl} alt="Vorschau" style={{ maxWidth: '80%', maxHeight: '80%' }} />
                    <MantineButton
                        variant="filled"
                        color="red"
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                        onClick={closePreview}
                    >
                        <MdClose size={24} />
                    </MantineButton>
                </div>
            )}

            {showOverlay && isPDF && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.85)',
                    zIndex: 9999,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <iframe src={previewUrl} title="PDF Vorschau" style={{ width: '80%', height: '80%' }}></iframe>
                    <MantineButton
                        variant="filled"
                        color="red"
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                        }}
                        onClick={closePreview}
                    >
                        <MdClose size={24} />
                    </MantineButton>
                </div>
            )}
        </>
    );
}