import React, { useState, useEffect, createContext, useContext, useCallback, state } from 'react';
import CustomStore from "devextreme/data/custom_store";
import Api from "../utils/api";
import {changeWebpageLanguage} from "../utils/languageManager"
import {Route, Redirect, useHistory} from "react-router-dom";
import Content from '../Content';
const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

function PrivateContentRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const token = localStorage.getItem('authUser')
                if(auth.isAuthed === null) {
                    auth.refreshSession(token)
                    return <React.Fragment/>
                }else if(auth.isAuthed){
                    return <Content> {children} </Content>
                }else {
                    return <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                }
            }
            }
        />
    );
}

function AuthProvider(props) {

    const [user, setUser] = useState();
  const [isAuthed, setAuthed] = useState(null);
function loginFunction(user) {
    return Api.login(user)
        .then(Api.handleErrors)
        .then(response => response.data.tokenString)
        .then(token => {
            localStorage.setItem("authUser", token)
            // refreshSession(token)
            Api.init(token)
            return Api.getMe()
        })
        .then(res => res.data)
        .then(data => {
            setUser(data);
            setAuthed(true)
            return changeWebpageLanguage(data)
        })
        .catch(error => {
            throw error
        });
}

 const logIn = useCallback(async (username, password) => {
     try {
         await loginFunction({username, password})
     }catch (e) {
         if(e.message.includes("401"))
             throw new Error("Username or password incorrect")
         throw new Error("Server Error. Could not log in. Please try again later.")
     }
  }, []);

  function logOut(cb) {
    // Clear user data
      // history.replace('/login')
      localStorage.removeItem("authUser")
      Api.clear()
      cb()
      setUser();
  }

  function refreshSession(token){
      if(token === null){
          setAuthed(false)
          return
      }
      // setLoading(true)
      Api.init(token)
      return Api.getMe()
          .then(res => res.data)
          .then(data => {
              setUser(data);
              setAuthed(true)
              return changeWebpageLanguage(data)
          })
          .catch(() => {
              localStorage.removeItem("authUser")
              Api.clear()
              setAuthed(false)
          })
  }

  useEffect(() => {
    // Retrieve and save user data on initial load
  }, []);

  return (
    <AuthContext.Provider value={{ user, logOut, refreshSession, isAuthed, loginFunction}} {...props} />
  );
}

export { AuthProvider, useAuth, changeWebpageLanguage, PrivateContentRoute}
