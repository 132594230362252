import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import './Modal.css';
import Api from '../../../utils/api';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import { useAuth } from '../../../contexts/auth';
import { Input, Button, Divider } from '@mantine/core';

const customStyles = {
  overlay: {
    zIndex: 9999, // Set a high value for zIndex
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // You can adjust the background color and opacity of the overlay
  },
  content: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0',
    padding: '0',
    position: 'absolute',
  },
};


const ContactModal = ({t}) => {

  const [dataArray, setDataArray] = useState([]);
  const {user} = useAuth()
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    first_Name: '',
    last_Name: '',
    positionname: '',
    eMail: '',
  });

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }


  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  async function handleSave() {
    try {
      const payload = {
        Person_other: {
          first_Name: formData.first_Name,
          last_Name: formData.last_Name,
          positionname: formData.positionname,
          eMail: formData.eMail,
        },
        relationship: {
          id: 2,
        },
        person_MeID: user.id,
      };
  
      // Warte auf Abschluss des API-Aufrufs
      await Api.people.insertToNetwork(payload);
  
      // Lade die Daten neu
      const response = await Api.people.getMyNetwork();
      setDataArray(response.data);
  
      // Schließe das Modal nach dem Neuladen der Daten
      closeModal();
    } catch (error) {
      // Behandle Fehler, z.B. zeige eine Fehlermeldung
      window.confirm('Fehler beim Speichern der Daten: ' + error);
    }
  }
  
  

  return (
    <div>
      <BsFillPersonPlusFill size={25} color='#4CBB17' onClick={openModal} style={{position:"absolute", right:"1.5em"}} />
      
      <Modal
        isOpen={modalIsOpen}

        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

      <div className="modal-head" style={{ display: "flex", marginBottom: "10px" }}>
       
        <h5 style={{ 
          flex: "80%", 
          marginTop: "1.5em" , 
          textAlign: "center", 
          fontSize: "1.5rem" }}
          >
            {t('titleContactAdd')}</h5>
      </div>

      <Divider margins="sm"  style={{ marginLeft: '10px', marginRight: '10px' }}/>

  <form style={{ margin: '0 1.5em', marginTop: "3em" }}>

  <label className="label" style={{ marginTop: "1em" }}>{t('firstName')}</label>
  <Input
    name='first_Name'
    placeholder={t('firstName')}
    value={formData.first_Name}
    onChange={handleInputChange}
    styles={(theme) => ({
      input: {
        '&:focus': {
          borderColor: '#39b201',
        },
      },
    })}
  />

  <label className="label" style={{ marginTop: "1em" }}>{t('lastName')}</label>
  <Input
    name='last_Name'
    placeholder={t('lastName')}
    value={formData.last_Name}
    onChange={handleInputChange}
    styles={(theme) => ({
      input: {
        '&:focus': {
          borderColor: '#39b201',
        },
      },
    })}
  />

  <label className="label" style={{ marginTop: "1em" }}>{t('eMail')}</label>
  <Input
    name='eMail'
    placeholder={t('eMail')}
    value={formData.eMail}
    onChange={handleInputChange}
    styles={(theme) => ({
      input: {
        '&:focus': {
          borderColor: '#39b201',
        },
      },
    })}
  />

  <label className="label" style={{ marginTop: "1em" }}>{t('positionName')}</label>
  <Input
    name='positionname'
    placeholder={t('positionName')}
    value={formData.positionname}
    onChange={handleInputChange}
    styles={(theme) => ({
      input: {
        '&:focus': {
          borderColor: '#39b201',
        },
      },
    })}
  />
  
</form>


        <div className="buttons" style={{ position: 'absolute', bottom: '1.5em', right: '1.5em' }}>
        <Button onClick={closeModal} 
          styles={(theme) => ({
            root: {
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
              marginRight: '10px'
            }})}
        >
          {t('cancel')}
        </Button>
        <Button onClick={handleSave}
          styles={(theme) => ({
          root: {
            backgroundColor: '#39b201',
            color: 'white',
            '&:hover': {
              backgroundColor: '#39b201',
              color: 'white',
            },
          }})}
        >
          {t('btnAdd')}
        </Button>
        </div>

      </Modal>
    </div>
  );
}

export default ContactModal;